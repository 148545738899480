export const authReducer = (state = null, action) =>{
    switch (action.type) {
        case 'LOGIN':
            return state = action.payload
        case 'LOGOUT':
            return state = action.payload
        default:
            return state
    }
}

export const userUpdated = (state = false, action) =>{
    switch (action.type) {
        case 'SET_USER_UPDATED':
            return state = action.payload
        default:
            return state
    }
}