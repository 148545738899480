import React from 'react'

const short = {width: 150}
const long = {width: 350}

// pre A table column
export const PreAColumn = () =>{
    return (
        <tr>
            <th>No</th>
            <th style={short}>Topic</th>
            <th style={long}>Options</th>
            <th>Action</th>
        </tr>
    )
}

// pre A table column history
export const PreAColumnHistory = () =>{
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={long}>Options</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// pre A table column question
export const PreAColumnQuestion = () =>{
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={long}>Options</th>
        </tr>
    )
}

// A1 table column
export const A1Column = () =>{
    return (
        <tr>
            <th>No</th>
            <th style={short}>Topic</th>
            <th style={short}>Category/Synonym</th>
            <th style={long}>Correct Answer</th>
            <th style={long}>Random Words</th>
            <th>Action</th>
        </tr>
    )
}

// A1 table column history
export const A1ColumnHistory = () =>{
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={short}>Category/Synonym</th>
            <th style={long}>Correct Answer</th>
            <th style={long}>Random Words</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// A1 table column question
export const A1ColumnQuestion = () =>{
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={short}>Category/Synonym</th>
            <th style={long}>Correct Answer</th>
            <th style={long}>Random Words</th>
        </tr>
    )
}

// A2 & B1 table column
export const A2Column = () =>{
    return (
        <tr>
            <th>No</th>
            <th style={short}>Topic</th>
            <th style={long}>Sentence</th>
            <th style={long}>Wrong Options</th>
            <th>Action</th>
        </tr>
    )
}

// A2 & B1 table column history
export const A2ColumnHistory = () =>{
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={long}>Sentence</th>
            <th style={long}>Wrong Options</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// A2 & B1 table column question
export const A2ColumnQuestion = () =>{
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={long}>Sentence</th>
            <th style={long}>Wrong Options</th>
        </tr>
    )
}

// B2 table column
export const B2Column = () =>{
    return (
        <tr>
            <th>No</th>
            <th style={short}>Topic</th>
            <th style={long}>Sentence</th>
            <th>Action</th>
        </tr>
    )
}

// B2 table column history
export const B2ColumnHistory = () =>{
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={long}>Sentence</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// B2 table column question
export const B2ColumnQuestion = () =>{
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={long}>Sentence</th>
        </tr>
    )
}

// C1 table column
export const C1Column = () =>{
    return (
        <tr>
            <th>No</th>
            <th style={short}>Topic</th>
            <th style={long}>Passage</th>
            <th style={long}>Correct Answer</th>
            <th style={long}>Wrong Answer</th>
            <th>Action</th>
        </tr>
    )
}

// C1 table column history
export const C1ColumnHistory = () =>{
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={long}>Passage</th>
            <th style={long}>Correct Answer</th>
            <th style={long}>Wrong Answer</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// C1 table column question
export const C1ColumnQuestion = () =>{
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={long}>Passage</th>
            <th style={long}>Correct Answer</th>
            <th style={long}>Wrong Answer</th>
        </tr>
    )
}