import React from 'react'
/* Fields */
import { Select, Textarea } from '../fields'
import {Button, Label} from 'semantic-ui-react';
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html"

class B2Form extends React.Component{
    constructor(props){
        super(props)
        this.contentEditableSentence = React.createRef();
        this.state = {
            sentenceLength: 250,
            sentenceLengthConst: 250,
            sentenceWidth: 0,
            isTouch: false,
            showVarError: false,
            max_char: 1,
            html: '',
            max_separator: 6,
            min_separator: 4,
            separatorCount: 0
        }
    }

    // for set form data when edit
    componentDidMount(){
        let data = this.props.data
        if(data !== undefined){
            this.countWord(data.question_object.sentence)
            this.setState({
                html: data.question_object.sentence,
            })
        }

        setTimeout(()=>{
            this.setState({
                sentenceWidth: this.contentEditableSentence.current.clientWidth,
            })
        }, 0)
    }

    // sanitize html
    sanitizeConf = {
        allowedTags: ["a"],
        allowedAttributes: { a: ["class"] }
    };

    // handle editable content kye press
    handleKeyDown = (e) =>{
        let html = this.state.html
        if(e.which === 13) {
            html = html+ '&nbsp;'
            html = sanitizeHtml(html, this.sanitizeConf)
            this.setState({html: html})
            e.preventDefault()
        }

        if(e.which === 220){
            html = html+ `<a class="ui green label">|</a>&nbsp;`
            html = sanitizeHtml(html, this.sanitizeConf)
            this.setState({html: html})
            e.preventDefault()
        }

        this.countWord(html)
    }

    // count editable content
    countWord = (p) =>{
        let val = p
        val = sanitizeHtml(val, this.sanitizeConf)
        let list = val.split('<a class="ui green label">')
        let words = []
        list.forEach(ed => {
            ed = ed.trim()
            ed = ed.replace('</a>', '')
            words.push(ed)
        });

        let kl = words.join(' ')
        let textLength = this.state.sentenceLengthConst
        kl = kl.replace(/^\s+|\s+$/g, "").replace(/\s+/g, " ")
        kl = kl.replace(/&amp;/g, '&')

        let varData = p.match(/<a [^>]+>(.*?)<\/a>/g)

        this.setState({sentenceLength: kl.length === 0 ? textLength : 
            textLength - kl.length, separatorCount: varData !== null ? varData.length : 0});
    }

    // add tag/value on editable content
    addTag = () =>{
        let hint = ''
        hint = this.state.html
        hint += `<a class="ui green label">|</a>&nbsp;`
        this.setState({html: hint, isTouch: true, showVarError: false})
        this.contentEditableSentence.current.focus();
        setTimeout(()=>{
            this.countWord(hint)
        }, 0)
    }

    handleChange = evt => {
        let html = sanitizeHtml(evt.target.value, this.sanitizeConf)
        let varData = html.match(/<a [^>]+>(.*?)<\/a>/g)
        let valueText = html
        if(varData !== null){
            varData.forEach(val=>{
                let extractWord = /<a [^>]+>(.*?)<\/a>/g.exec(val)
                extractWord[1] = extractWord[1].replace('&nbsp;', ' ')
                extractWord[1] = extractWord[1].replace('&amp;', '&')
                if(extractWord[1].length > this.state.max_char){
                    valueText = valueText.replace(extractWord.input, '<a class="ui green label">'+extractWord[1].slice(0,this.state.max_char)+'</a>')
                }
            })
        }
        html = valueText
        this.countWord(html)
        this.setState({ html: html, isTouch: true});
    };

    render(){
        let sentenceError = ''
        // let isTouch = false
        let sentenceEl = document.getElementById('sentence')
        sentenceEl = sentenceEl !== null ? document.getElementById('sentence').getAttribute('class') : ''
        if(sentenceEl.includes('error')){
            sentenceError = ' error'
        }

        let sentenceVar = true
        if(this.state.html !== '' && !sanitizeHtml(this.state.html, this.sanitizeConf).includes('</a>')){
            sentenceVar = false
        }
        return <>
            <td>
                <Select label='Topic' name='topic' children={
                    this.props.topics.map((v, k)=>
                        <option value={v.category_id} key={k}>{v.name}</option>
                    )
                } required/>
            </td>
            <td style={this.state.sentenceWidth !== 0 ? {width: this.state.sentenceWidth} : null}>
                <div className='field'>
                    <label>Sentence <b style={{color:'#db2828'}}> *</b>
                    </label>
                    <ContentEditable 
                    innerRef={this.contentEditableSentence}
                    className={'editableContent'+ sentenceError} 
                    style={this.state.sentenceWidth !== 0 ? {width: this.state.sentenceWidth} : null}
                    html={this.state.html}
                    disabled={false}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    id='content_sentence'
                    />
                    {this.state.separatorCount < this.state.min_separator && this.state.isTouch ? 
                        <Label color='red' pointing>
                            Not enough separator
                        </Label>
                    : null}
                    {this.state.separatorCount > this.state.max_separator && this.state.isTouch ? 
                        <Label color='red' pointing>
                            Separator reached the limit : {this.state.max_separator}
                        </Label>
                    : null}
                    <span style={this.state.sentenceLength < 0 ? {color: '#db2828', textAlign: 'right', float: 'right'} : 
                    {textAlign: 'right', float: 'right'}}>{this.state.sentenceLength}</span>
                    <Textarea input_error={(this.state.sentenceLength < 0 || !sentenceVar
                    || this.state.separatorCount > this.state.max_separator || this.state.separatorCount < this.state.min_separator) && this.state.isTouch 
                    ? true : false} 
                    values={this.state.html} hidden={true} name="sentence" required/>
                </div>
                <Button type='button' secondary size='small' onClick={this.addTag} disabled={this.state.separatorCount === this.state.max_separator}>
                    Add separator
                </Button>
                <i style={{fontSize: 12, color: 'grey'}}>*min amount: 4 & max amount : 6</i>
            </td>
        </>
    }
}

export default B2Form