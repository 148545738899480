import React from 'react'
import {Icon, Dropdown, Grid} from 'semantic-ui-react'

const TaskText = (props) =>{
    let data = props.data
    let icon = 'check circle'
    let color = 'green'
    let content = ''
    let url = ''
    switch (data.type) {
        case 1:
            content = <span>
                Your new entry (ID: <b>{data.info.question_id}</b>) in <b>{data.info.mini_game_name}</b>, chapter <b>{data.info.chapter_name}</b>, subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> has been approved
            </span>
            url = '/subject/'+data.info.subject_code+'/'+data.info.chapter_mini_game_id+'/question'
            break;
        case 2:
            content = <span>
                Your edit (ID: <b>{data.info.question_id}</b>) in <b>{data.info.mini_game_name}</b>, chapter <b>{data.info.chapter_name}</b>, subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> has been approved
            </span>
            url = '/subject/'+data.info.subject_code+'/'+data.info.chapter_mini_game_id+'/question'
            break;
        case 3:
            content = <span>
                Your new entry in <b>{data.info.mini_game_name}</b>, chapter <b>{data.info.chapter_name}</b>, subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> has been declined with remarks: <b>{data.info.comment}</b>
            </span>
            url = '/subject/'+data.info.subject_code+'/'+data.info.chapter_mini_game_id+'/question'
            break;
        case 4:
            content = <span>
                Your edit (ID: <b>{data.info.question_id}</b>) in <b>{data.info.mini_game_name}</b>, chapter <b>{data.info.chapter_name}</b>, subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> has been declined with remarks: <b>{data.info.comment}</b>
            </span>
            url = '/subject/'+data.info.subject_code+'/'+data.info.chapter_mini_game_id+'/question'
            break;
        case 5:
            content = <span>
                Your new entry (ID: <b>{data.info.question_id}</b>) in <b>{data.info.topic_name_tree}</b>, subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> has been approved
            </span>
            url = '/subject/'+data.info.subject_code+'/'+data.info.topic_id+'/question'
            break;
        case 6:
            content = <span>
                Your edit (ID: <b>{data.info.question_id}</b>) in <b>{data.info.topic_name_tree}</b>, subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> has been approved
            </span>
            url = '/subject/'+data.info.subject_code+'/'+data.info.topic_id+'/question'
            break;
        case 7:
            content = <span>
                Your new entry in <b>{data.info.topic_name_tree}</b>, subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> has been declined with remarks: <b>{data.info.comment}</b>
            </span>
            url = '/subject/'+data.info.subject_code+'/'+data.info.topic_id+'/question'
            break;
        case 9:
            content = <span>
                Your new entry (ID: <b>{data.info.question_id}</b>) in diagnostic test game <b>{data.info.game_name}</b>, subject <b>{data.info.subject_name}</b> has been approved
            </span>
            url = '/diagnostic/'+data.info.subject_code+'/'+data.info.game_id
            break;
        case 10:
            content = <span>
                Your edit (ID: <b>{data.info.question_id}</b>) in diagnostic test game <b>{data.info.game_name}</b>, subject <b>{data.info.subject_name}</b> has been approved
            </span>
            url = '/diagnostic/'+data.info.subject_code+'/'+data.info.game_id
            break;
        case 11:
            content = <span>
                Your new entry in diagnostic test game <b>{data.info.game_name}</b>, subject <b>{data.info.subject_name}</b> has been declined with remarks: <b>{data.info.comment}</b>
            </span>
            url = '/diagnostic/'+data.info.subject_code+'/'+data.info.game_id
            break;
        case 12:
            content = <span>
                Your edit (ID: <b>{data.info.question_id}</b>) in diagnostic test game <b>{data.info.game_name}</b>, subject <b>{data.info.subject_name}</b> has been declined with remarks: <b>{data.info.comment}</b>
            </span>
            url = '/diagnostic/'+data.info.subject_code+'/'+data.info.game_id
            break;
        // type 8
        default:
            content = <span>
                Your edit (ID: <b>{data.info.question_id}</b>) in <b>{data.info.topic_name_tree}</b>, subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> has been declined with remarks: <b>{data.info.comment}</b>
            </span>
            url = '/subject/'+data.info.subject_code+'/'+data.info.topic_id+'/question'
            break;
    }

    if(data.type === 3 || data.type === 4 || data.type === 7 || data.type === 8 || data.type === 11 || data.type === 12 ){
        icon = 'remove circle'
        color = 'red'
    }

    if(props.type === 'all'){
        return (
            <Grid.Row className='activityWrapper link' onClick={()=>window.location.href=url}>
                <Grid.Column width='1'>
                    <Icon color={color} name={icon} circular/>
                    <i></i>
                </Grid.Column>
                <Grid.Column style={{marginLeft: 5}}>{content}</Grid.Column>
                <Grid.Column width='4'>
                    {data.time}
                </Grid.Column>
            </Grid.Row>
        )
    }

    return (
        <Dropdown.Item as='a' href={url}>
            <div>
                <Icon color={color} inverted name={icon} circular/>
                <i></i>
            </div>
            <div>
                {content}
                <p>{data.time}</p>
            </div>
        </Dropdown.Item>
    )
}

export default TaskText