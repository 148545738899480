import React from 'react'
/* Fields */
import { Textarea, Select } from '../fields'

// C1 form
const C1Form = (props) =>{
    return <>
        <td>
            <Select label='Topic' name='topic' children={
                props.topics.map((v, k)=>
                    <option value={v.category_id} key={k}>{v.name}</option>
                )
            } required/>
        </td>
        <td>
            <Textarea label='Passage' name="passage" required placeholder='Type here...' wlength={600}/>
        </td>
        <td>
            <Textarea label='Correct Answer' name="correct_answer" required placeholder='Type here...' wlength={60}/>
        </td>
        <td>
            <label>Wrong Answer</label>
            <Textarea name="wrong_answer1" required placeholder='Type here...' wlength={60}/>
            <Textarea name="wrong_answer2" placeholder='Type here...' wlength={60}/>
            <Textarea name="wrong_answer3" placeholder='Type here...' wlength={60}/>
        </td>
        
    </>
}

export default C1Form