import React from 'react';
import { Segment, Image, Breadcrumb, Icon,
    Message, Form,
    Button,
    Loader, Tab,
    Modal, Label, Header,
    Divider,
    Dimmer,
    Grid,
    Dropdown, MenuItem} from 'semantic-ui-react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom'
import { toast } from 'react-toastify';
import storage from 'redux-persist/lib/storage';
import ReactPlayer from 'react-player'
import {
    DateInput
} from 'semantic-ui-calendar-react';
import moment from 'moment-timezone'
import { getSubjectRedux } from '../../../helpers/redux';
import { PlatformInit } from '../../../helpers/global';
import EmptyComponent from '../../../components/empty';
import SlideShowComponent from '../../../components/slide_show';
import * as Sentry from "@sentry/browser";

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        add_subject_step: state.add_subject_step,
        is_banner_loading: state.is_banner_loading,
        is_rc_question_open: state.is_rc_question_open
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setIsNewQuestion: (n) => dispatch({ type: 'IS_NEW_QUESTION', payload: n }),
        setRCQuestionOpen: (n) => dispatch({ type: 'IS_RC_NEW_QUESTION', payload: n }),
        setBannerData: (n) => dispatch({ type: 'BANNER_DATA', payload: n })
    }
}

// banner section page
class BannerSection extends React.Component{
    constructor(props){
        super(props)
        let dropdownPlatform = []
        PlatformInit().forEach(x=>{
            dropdownPlatform.push({
                key: x.value,
                text: x.text,
                value: x.value
            })
        })
        this.state = {
            currentSubject: getSubjectRedux(this.props.add_subject_step, this.props.match.params.code),
            bannerInfo: null,
            loading: true,
            viewGameModal: false,
            screenshots: [],
            assigModal: false,
            contributors: [],
            assignForm:{
                username: '',
                due_date: moment(new Date()).format('YYYY-MM-DD')
            },
            refreshPreviewLoading: false,
            selectedPlatform: dropdownPlatform[0].value,
            platformList: dropdownPlatform,
            is_rc: false
        }
    }

    // fetch question banner info
    componentDidMount(){
        if(this.props.is_rc_question_open){
            this.props.setRCQuestionOpen(false)
        }
        if(this.state.currentSubject.adaptive){
            this.loadAdaptiveBanner()
        }else{
            this.loadBanner()
        }
        this.loadContributor()
    }

    // checking banner flag to refresh banner info
    componentDidUpdate(oldProps, oldStates){
        if(oldProps.is_banner_loading !== this.props.is_banner_loading){
            if(this.props.is_banner_loading){
                if(this.state.currentSubject.adaptive){
                    this.loadAdaptiveBanner()
                }else{
                    this.loadBanner()
                }
            }
        }
    }

    refreshPreview = () =>{
        this.setState({refreshPreviewLoading: true})
        if(this.state.currentSubject.adaptive){
            this.loadAdaptiveBanner()
        }else{
            this.loadBanner()
        }
    }

    loadContributor = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/contributors?code='+this.props.match.params.code,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            let opt = []
            res.data.forEach(x=>{
                if(x.role !== 'Owner'){
                    opt.push({
                        key: x.username,
                        text: x.username+' - '+x.email,
                        value: x.username,
                        image: { avatar: true, src: x.avatar !== null ? x.avatar : require('../../../assets/images/avatar-default.png') }
                    })
                }
            })
            this.setState({contributors: opt})
        })
    }

    loadAdaptiveBanner = () =>{
        let params = this.props.match.params
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/topics/'+params.mg+'/banner', {
            headers: this.props.myHeaders
        }).then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            this.props.setBannerData(res.data.topic_data)

            let game_ss = []
            res.data.mini_games_data.forEach(v=>{
                game_ss.push({ menuItem: <MenuItem key={v.minigame_id}>
                <Image src={v.icon} avatar />
                {v.minigame_name}
                </MenuItem>, render: () => <Tab.Pane>
                    {v.screenshot[this.state.selectedPlatform] !== undefined ? 
                    <SlideShowComponent id={"slide-about-"+v.minigame_id+"-"+this.state.selectedPlatform} images={v.screenshot[this.state.selectedPlatform]}/>
                    : <EmptyComponent/>}
                    <Divider hidden/>
                    <Header as='h4'>
                        <Icon name='video'/>
                        Video
                    </Header>
                    {v.video[this.state.selectedPlatform] !== undefined ? 
                    <ReactPlayer width={'100%'} url={v.video[this.state.selectedPlatform]}/>
                    : <EmptyComponent/>}
                </Tab.Pane> })
            })

            let is_rc = false
            try {
                let mg_id = res.data.mini_games_data[0].module_id
                if(mg_id === 19){
                    is_rc = true
                }
            } catch (error) {
            }

            this.setState({bannerInfo: res.data, loading: false, screenshots: game_ss, refreshPreviewLoading: false, is_rc: is_rc})
        })
        .catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // fetching banner info
    loadBanner = () =>{
        let params = this.props.match.params
        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+params.mg+'/banner', {
            headers: this.props.myHeaders
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if(res.status){
                let game_ss = []
                let v = res.data.minigame_data
                this.props.setBannerData(res.data.chapter_data)
                game_ss.push({ menuItem: <MenuItem key={v.minigame_id}>
                <Image src={v.icon} avatar />
                {v.name}
                </MenuItem>, render: () => <Tab.Pane>
                    {v.screenshots[this.state.selectedPlatform] !== undefined ? 
                    <SlideShowComponent id={"slide-about-"+v.minigame_id+"-"+this.state.selectedPlatform} images={v.screenshots[this.state.selectedPlatform]}/>
                    : <EmptyComponent/>}
                    <Divider hidden/>
                    <Header as='h4'>
                        <Icon name='video'/>
                        Video
                    </Header>
                    {v.video[this.state.selectedPlatform] !== undefined ? 
                    <ReactPlayer width={'100%'} url={v.video[this.state.selectedPlatform]}/>
                    : <EmptyComponent/>}
                </Tab.Pane> })

                let is_rc = false
                try {
                    let mg_id = res.data.chapter_data.module_id
                    if(mg_id === 19){
                        is_rc = true
                    }
                } catch (error) {
                }
                this.setState({bannerInfo: res.data, screenshots: game_ss, loading: false, refreshPreviewLoading: false, is_rc: is_rc})
            }
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // render required, review and valid question
    renderMessageInfo = (summary) =>{
        let messageInfo = {}
        if(summary.required_question !== 0){
            messageInfo = <Message size='mini' error>
                <Icon name='remove circle'/> 
                {summary.required_question} question(s) left to reach the minimum number of questions.
            </Message>
        }else if(summary.review_question !== 0){
            messageInfo = <Message size='mini' warning>
                <Icon name='exclamation triangle'/> {summary.review_question} question(s) are still not approved/declined yet.
            </Message>
        }else{
            messageInfo = <Message size='mini' success>
                <Message.Header>
                    <Icon name='check circle'/> {summary.valid_question} valid questions.
                </Message.Header>
            </Message>
        }
        return messageInfo
    }

    renderAdaptiveMessageInfo = (message) =>{
        let comp = {}
        switch (message.status) {
            case 'error':
                comp = <Message size='mini' error>
                    <Message.Header>
                        <Icon name='remove circle'/> {message.message}
                    </Message.Header>
                </Message>
                break;
            case 'warning':
                comp = <Message size='mini' warning>
                    <Message.Header>
                        <Icon name='exclamation triangle'/> {message.message}
                    </Message.Header>
                </Message>
                break;
            default:
                comp = <Message size='mini' success>
                    <Message.Header>
                        <Icon name='check circle'/> {message.message}
                    </Message.Header>
                </Message>
                break;
        }
        return comp
    }

    // when click add question will scroll to down of table
    scrollToDown = () =>{
        this.props.setIsNewQuestion(true)
        // let el = document.getElementById('questionTable')
        // el.scrollTo(0,el.scrollHeight)
    }

    markASDoneTopic = () =>{
        let param = this.state.bannerInfo.topic_data.assignment
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/assignments/finish/'+param.assignment_id,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                toast.success('Topic marked as done.')
                setTimeout(()=>{
                    window.location.href=''
                }, 1000)
            }else{
                toast.error('Cannot mark as done.')
            }

        })
        .catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    assignTopic = () =>{
        let form = this.state.assignForm
        if(form.name === '' || form.due_date === ''){
            toast.error('Please complete the form.')
            return false
        }

        form.topic_id = this.state.bannerInfo.topic_data.topic_id

        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/topics/assign',{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method:'post',
            body: JSON.stringify(form)
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                toast.success('Topic assigned.')
                setTimeout(()=>{
                    window.location.href=''
                }, 1000)
            }else{
                toast.error('Topic cannot be assigned.')
                return false
            }

            this.setState({assignForm: {
                username: '',
                due_date: moment(new Date()).format('YYYY-MM-DD')
            }, assigModal: false})
        })
        .catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    render() {
        return (
            <Segment style={{padding:0, minHeight: 140}}>
                {this.state.loading ? <Loader active/> :
                    this.state.currentSubject.adaptive ? 
                    <div className='questionBanner'>
                        <Image src={require('../../../assets/images/asset-folder.png')}/>
                        <div className='leftSection'>
                            <Breadcrumb size='huge'>
                                <Link to={'/subject/detail/'+this.state.currentSubject.code+'/content'} 
                                className="section">
                                    {this.state.currentSubject.name}
                                </Link>
                                <Breadcrumb.Divider />
                                <Link to={'/subject/detail/'+this.state.currentSubject.code+'/content/'+this.state.bannerInfo.topic_data.subject_module_id} 
                                className="section">
                                    {this.state.bannerInfo.topic_data.subject_module_name}
                                </Link>
                                <Breadcrumb.Divider />
                                <Link to={'/subject/detail/'+this.state.currentSubject.code+'/content/'+this.state.bannerInfo.topic_data.subject_module_id+'?l='+
                                this.state.bannerInfo.topic_data.subject_module_level_id} 
                                className="section">
                                    {this.state.bannerInfo.topic_data.subject_module_level_name}
                                </Link>
                                <Breadcrumb.Divider />
                                {this.state.bannerInfo.topic_data.topic_tree.map((v, k)=>{
                                    let comp = null
                                    if(v.id === this.state.bannerInfo.topic_data.topic_id){
                                        comp = <Breadcrumb.Section active key={k}>
                                            {v.name}
                                        </Breadcrumb.Section>
                                    }else{
                                        let ids = []
                                        for (let i = 0; i < k+1; i++) {
                                            ids.push(this.state.bannerInfo.topic_data.topic_tree[i].id)
                                        }
                                        comp = <React.Fragment key={k}>
                                        <Link to={'/subject/detail/'+this.state.currentSubject.code+'/content/'+this.state.bannerInfo.topic_data.subject_module_id+'?l='+
                                        this.state.bannerInfo.topic_data.subject_module_level_id+'&t='+ids.join(',')} 
                                        className="section">
                                            {v.name}
                                        </Link>
                                        <Breadcrumb.Divider />
                                    </React.Fragment>
                                    }
                                    return comp
                                }
                                )}
                            </Breadcrumb>
                            <div className='wrapper'>
                                <div>
                                    {this.renderAdaptiveMessageInfo(this.state.bannerInfo.topic_data.message)}
                                </div>
                            </div>
                        </div>
                        <div className='rightSection'>
                            <Button size='small' secondary onClick={()=>this.setState({viewGameModal: true})}>
                                <Icon name='image'/> View Games Display
                            </Button>
                            {this.state.currentSubject.role === 3 ? 
                            this.state.bannerInfo.topic_data.assignment !== null ? 
                                <Button size='small' secondary onClick={this.markASDoneTopic}>
                                    <Icon name='check circle'/> Mark as Done
                                </Button>
                            : <Button size='small' secondary onClick={()=>this.setState({assigModal: true})}>
                                <Icon name='user circle'/> Assign Contributor
                            </Button>
                            : null}

                            {!this.state.is_rc ? 
                            <Button size='small' primary onClick={this.scrollToDown}
                            disabled={this.state.currentSubject.role === null || this.state.currentSubject.is_demo}>
                                <Icon name='add'/> Add New Entry
                            </Button>
                            : <Button size='small' primary onClick={()=>{
                                this.props.setRCQuestionOpen(true)
                                localStorage.removeItem('passage')
                                localStorage.removeItem('question')
                                localStorage.removeItem('qgen_data')
                                localStorage.removeItem('rc_edit_id')
                            }}
                            disabled={this.state.currentSubject.role === null || this.state.currentSubject.is_demo}>
                                <Icon name='add'/> Add New Entry
                            </Button>}
                            
                            <div>
                                {this.state.bannerInfo.topic_data.assignment !== null ? 
                                <>
                                <Label as='a'>
                                    <Icon name='user circle' />
                                    Assignee : {this.state.bannerInfo.topic_data.assignment.assignee}
                                </Label>
                                <Label as='a'>
                                    <Icon name='calendar' />
                                    Due date : {this.state.bannerInfo.topic_data.assignment.date}
                                </Label>
                                </>
                                : 
                                <>
                                <Label style={{opacity:0.4}}>
                                    <Icon name='user circle' />
                                    Assignee : None
                                </Label>
                                <Label style={{opacity:0.4}}> 
                                    <Icon name='calendar'/>
                                    Due date : None
                                </Label>
                                </>
                                }
                                <br/>
                                <span>Last updated: {this.state.bannerInfo.summary.last_update}</span>
                                {/* <br/> */}
                                <span>by {this.state.bannerInfo.summary.last_editor}</span>
                            </div>
                        </div>
                    </div>
                    :
                    <div className='questionBanner'>
                        <Image src={this.state.bannerInfo.minigame_data.icon}/>
                        <div className='leftSection'>
                            <Breadcrumb size='huge'>
                                <Link to={'/subject/detail/'+this.state.currentSubject.code+'/content'} 
                                className="section">
                                    <Icon name='folder'/> {this.state.currentSubject.name}
                                </Link>
                                <Breadcrumb.Divider />
                                <Link to={'/subject/detail/'+this.state.currentSubject.code+'/content'} 
                                className="section">
                                    {this.state.bannerInfo.chapter_data.chapter_name}
                                </Link>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section active>
                                    {this.state.bannerInfo.minigame_data.name}
                                </Breadcrumb.Section>
                            </Breadcrumb>
                            <div className='wrapper'>
                                <div>
                                    {this.renderMessageInfo(this.state.bannerInfo.summary)}
                                </div>
                            </div>
                        </div>
                        <div className='rightSection'>
                            <Button secondary onClick={()=>this.setState({viewGameModal: true})} id='btn_game_display'>
                                <Icon name='image'/> View Games Display
                            </Button>
                            {!this.state.is_rc ? 
                            <Button primary onClick={this.scrollToDown}
                            disabled={this.state.currentSubject.role === null} id='btn_add_entry'>
                                <Icon name='add'/> Add New Entry
                            </Button>
                            : <Button size='small' primary onClick={()=>{
                                this.props.setRCQuestionOpen(true)
                                localStorage.removeItem('passage')
                                localStorage.removeItem('question')
                                localStorage.removeItem('qgen_data')
                                localStorage.removeItem('rc_edit_id')
                            }}
                            disabled={this.state.currentSubject.role === null || this.state.currentSubject.is_demo}>
                                <Icon name='add'/> Add New Entry
                            </Button>}
                            
                            <div>
                                <span>Last updated: {this.state.bannerInfo.summary.last_update}</span>
                                <span>by {this.state.bannerInfo.summary.last_editor}</span>
                            </div>
                        </div>
                    </div>
                }

                {/* modal */}
                {this.state.currentSubject.adaptive ? 
                <>
                <Modal size='mini' open={this.state.assigModal}>
                    <Modal.Header>Assigment</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Form.Dropdown options={this.state.contributors} 
                            selection label='Contributor' onChange={(e, data)=>{
                                let form = this.state.assignForm
                                form.username = data.value
                                this.setState({assignForm: form})
                            }}/>
                            <div className='field'>
                            <label>Due Date</label>
                            <DateInput
                                placeholder='Due Date'
                                minDate={new Date()}
                                popupPosition='right center'
                                duration={10}
                                hideMobileKeyboard
                                value={this.state.assignForm.due_date}
                                iconPosition='right'
                                preserveViewMode={false}
                                autoComplete='off'
                                dateFormat='YYYY-MM-DD'
                                closable
                                onChange={(event, {name, value})=>{
                                    let form = this.state.assignForm
                                    form.due_date = value
                                    this.setState({assignForm: form})
                                }}
                            />
                        </div>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={()=>this.setState({assigModal: false, assignForm: {
                            username: '',
                            due_date: moment(new Date()).format('YYYY-MM-DD')
                        }})}>Cancel</Button>
                        <Button primary onClick={this.assignTopic}>Save</Button>
                    </Modal.Actions>
                </Modal>
                </>
                : 
                null
                }

                <Modal open={this.state.viewGameModal} closeIcon centered={false}
                onClose={()=>this.setState({viewGameModal: false})}>
                    <Modal.Header>
                        Game Display
                        <p className='refreshModal'>Game display not loaded well? <Button size='mini' color='green' onClick={this.refreshPreview}>refresh</Button></p>
                    </Modal.Header>
                    <Modal.Content scrolling>
                        <Dimmer inverted active={this.state.refreshPreviewLoading}>
                            <Loader active>Loading...</Loader>
                        </Dimmer>
                        <Grid>
                            <Grid.Column width={4}>
                            </Grid.Column>
                            <Grid.Column width={12} style={{paddingBottom: 0}}>
                            <Header as="h4" style={{float: 'left'}}>
                                <Icon name='images'/>
                                Screenshot
                            </Header>
                            <span style={{float: 'right'}}>
                                Platform : {' '}
                                <Dropdown
                                    direction='left'
                                    inline
                                    options={this.state.platformList}
                                    defaultValue={this.state.selectedPlatform}
                                    onChange={(e, data)=>this.setState({selectedPlatform: data.value})}
                                />
                            </span>
                            
                            </Grid.Column>
                        </Grid>

                        {this.state.screenshots.length > 0 ? 
                        <Tab className="bannerTab" menu={{ vertical: true }} panes={this.state.screenshots}/>
                        : null}
                    </Modal.Content>
                </Modal>
            </Segment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BannerSection)