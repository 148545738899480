import React from 'react';
import BannerSection from './banner';
import './style.scss';
import { Divider, Loader } from 'semantic-ui-react';
import TableSection from './table';
import {connect} from 'react-redux';
import storage from 'redux-persist/lib/storage'
import {toast} from 'react-toastify';
import { filterSubjectRedux, getSubjectRedux } from '../../../helpers/redux';
import { CheckExistData } from '../../../helpers/global';
import RCTable from './rc_table';

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth,
        add_subject_step: state.add_subject_step,
    }
}

function mapDispatchToProps (dispatch) {  
    return {
        addSubjectStepAction: (n) => dispatch({ type: 'CURRENT_SUBJECT', payload: n })
    }
}

// question page root
class QuestionPage extends React.Component{
    constructor(props){
        super(props)
        const currentSubject = getSubjectRedux(this.props.add_subject_step, this.props.match.params.code)
        if(currentSubject !== null){
            if(this.props.match.params.mg === null){
                window.location.href = '/not-found'
                return false
            }else{
                CheckExistData({
                    header: new Headers({
                        'Authorization': 'Token '+this.props.auth.user.token
                    }),
                    category: currentSubject.adaptive ? 'topic' : 'chapter_mini_game',
                    identifier: this.props.match.params.mg
                }).then(val=>{
                    if(!val.status){
                        window.location.href = '/not-found'
                        return false
                    }
                })
            }
        }
        this.state = {
            myHeaders: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            currentSubject: currentSubject,
            module_id: null,
            params: this.props.match.params
        }
    }

    componentDidMount(){
        if(this.state.currentSubject === null){
            this.loadSubject()
        }else{
            let param = {
                "n": 1,
                "page": 1,
                "search": "",
                "is_adaptive": this.state.currentSubject.adaptive
            }
            fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+this.state.params.mg+'/list', {
                headers: this.state.myHeaders,
                method: 'post',
                body: JSON.stringify(param)
            }).then((res)=>{
                return res.json()
            }).then((res)=>{
                this.setState({module_id: res.module_id})
            })   
        }
    }

    loadSubject = () =>{
        let setProps = this.setProps
        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/'+this.state.params.code, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
        }).then(function(res){
            return res.json()
        }).then(function(res){
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                setProps(res.data)
            }else{
                toast.error('Content not found, you will be redirect to subject page.')
                setTimeout(()=>{
                    window.location.href = '/subject'
                }, 2000)
            }
        }).catch((e)=>{
            toast.error('Content not found, you will be redirect to subject page.')
            setTimeout(()=>{
                window.location.href = '/subject'
            }, 2000)
        })
    }

    setProps = (data) =>{
        this.props.addSubjectStepAction(filterSubjectRedux(this.props.add_subject_step, data))
        this.setState({currentSubject: data})
        fetch(process.env.REACT_APP_API_URL+'api/v1/questions/'+this.state.params.mg+'/list', {
            headers: this.state.myHeaders,
            method: 'post',
            body: JSON.stringify({
                "n": 1,
                "page": 1,
                "search": "",
                "is_adaptive": this.state.currentSubject.adaptive
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({module_id: res.module_id})
        })
    }

    render() {
        return (
            <div style={{marginBottom: 20}}>
                {this.state.currentSubject === null && this.state.module_id === null ? <Loader active/> : 
                <>
                <BannerSection myHeaders={this.state.myHeaders} {...this.props}/>
                <Divider hidden/>
                {this.state.module_id === null ? <Loader active/> : 
                this.state.module_id === 19 ? 
                <RCTable myHeaders={this.state.myHeaders} {...this.props}/>
                :
                <TableSection myHeaders={this.state.myHeaders} {...this.props}/>
                }
                </>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuestionPage)