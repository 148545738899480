import React, {useEffect, useState} from 'react'
import Input from '../fields/input'
import {
    DateInput
} from 'semantic-ui-calendar-react';
import { Radio, Select, TextArea as TextAreaNew, Input as InputNew, 
    Confirm, FormField, Button, Divider, Message, Label } from 'semantic-ui-react';

const Module34Form = (props) => {
    const {
        data, videos
    } = props
    var date = new Date();
    var year = date.getFullYear();
    var month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are zero-based
    var day = ("0" + date.getDate()).slice(-2);
    var formattedDate = year + "-" + month + "-" + day;

    const [currentDate, setCurrentDate] = useState(formattedDate)
    const [radio, setRadio] = useState('new')
    const [url, setUrl] = useState('')
    const [title, setTitle] = useState('')
    const [questions, setQuestions] = useState([])
    const [confirm, setConfirm] = useState(false)
    const [formOpen, setFormOpen] = useState(false)
    const [selectedIdx, setSelectedIdx] = useState(null)
    const [tempQuestions, setTempQuestions] = useState('')
    const [videoList, setVideoList] = useState([])
    const [urlId, setUrlId] = useState('')
    const [validLink, setValidLink] = useState(false)
    const [form, setForm] = useState({
        question: '',
        answer: '',
        wrong1: '',
        wrong2: '',
        wrong3: '',
    })
    const [duplicateTitle, setDuplicateTitle] = useState(false)

    useEffect(()=>{
        if(data) {
            setQuestions([data.question_object.data])
            setTempQuestions(JSON.stringify([data.question_object.data]))
            setFormOpen(true)
            setSelectedIdx(0)
            setForm(data.question_object.data)
            setRadio('new')
            setUrl(data.question_object.url)
            setCurrentDate(data.question_object.date)
            setUrlId(data.question_object.url_id)
            setValidLink(true)
            setTitle(data.question_object.url_title)
        }else{
            setVideoList(videos.map(function(v) {
                return { key: v.url_id, value: v.url, text: `${v.url_title} : ${v.url}`, }
            }))
        }
    }, [data, videos])

    const handleChange = (e, { value }) => {
        setUrl('')        
        setTitle('')
        setRadio(value)
        window.document.getElementById('date-input').disabled = value === 'old'
        setUrlId('')
        setCurrentDate(formattedDate)
    }

    const hint = (e) => {
        return <span style={{textAlign: 'right', float: 'right'}}>{e}</span>
    }

    const onChange = (e) => {
        switch (e.target.name) {
            case 'question':
                setForm({...form, question: e.target.value})
                break;
            case 'answer':
                setForm({...form, answer: e.target.value})
                break;
            case 'wrong1':
                setForm({...form, wrong1: e.target.value})
                break;
            case 'wrong2':
                setForm({...form, wrong2: e.target.value})
                break;
            case 'wrong3':
                setForm({...form, wrong3: e.target.value})
                break;
            default:
                break;
        }
    }

    const onTypeForm = (e) => {
        switch (e.target.name) {
            case 'question':
                setForm({...form, question: e.target.value})
                break;
            case 'answer':
                setForm({...form, answer: e.target.value})
                break;
            case 'wrong1':
                setForm({...form, wrong1: e.target.value})
                break;
            case 'wrong2':
                setForm({...form, wrong2: e.target.value})
                break;
            case 'wrong3':
                setForm({...form, wrong3: e.target.value})
                break;
            default:
                break;
        }
        if(data){
            if(form.answer === '' || form.question === '' || form.wrong1 === '' || form.wrong2 === '' || form.wrong3 === '') {
                setTempQuestions('')
            } else{
                setTempQuestions(JSON.stringify(form))
            }  
        }
    }

    const addQuestion = () => {
        if(selectedIdx !== null) {
            questions[selectedIdx] = form
            const _q = [...questions]
            setQuestions([...questions])
            resetForm()
            setFormOpen(false)
            setSelectedIdx(null)
            saveTempQuestions(_q)
            return
        }
        const _q = [...questions, form]
        setQuestions(_q)
        resetForm()
        setFormOpen(false)
        setSelectedIdx(null)
        saveTempQuestions(_q)
    }

    const resetForm = () => {
        setFormOpen(false)
        setForm({
            question: '',
            answer: '',
            wrong1: '',
            wrong2: '',
            wrong3: '',
        })
    }

    const removeQuestion = () => {
        const _q = questions.filter((v, k)=>k!==selectedIdx)
        setQuestions(_q)
        setConfirm(false)
        setSelectedIdx(null)
        saveTempQuestions(_q)
    }

    const editQuestion = (idx) => {
        setForm(questions[idx])
        setSelectedIdx(idx)
        setFormOpen(true)
    }

    const saveTempQuestions = (data) => {
        setTempQuestions(JSON.stringify(data))
    }

    const titleChange = (e) =>{
        let radioNew = document.getElementById('new')
        if(radioNew !== null){
            let _vid = videos.findIndex((x)=>x.url_title.toLowerCase() === e.nextValue.toLowerCase())
            if(data){
                _vid = videos.findIndex((x)=>x.url_title.toLowerCase() !== data.question_object.url_title.toLowerCase() && x.url_title.toLowerCase() === e.nextValue.toLowerCase())
            }
            
            setDuplicateTitle(_vid !== -1)
        }else{
            setDuplicateTitle(false)
        }
        setTitle(e.nextValue)
    }

    return <>
        <td>
            {!data && <>
                <label>Video Type <b style={{color:'#db2828'}}> *</b></label>
                <FormField>
                <Radio
                    label='New video'
                    name='radioGroup'
                    value='new'
                    checked={radio === 'new'}
                    onChange={handleChange}
                />
                <Radio
                    label='Previous video'
                    name='radioGroup'
                    value='old'
                    checked={radio === 'old'}
                    onChange={handleChange}
                    style={{marginLeft: 10}}
                />
                </FormField>
            </>}
            {radio === 'old' &&  <>
            <label>Youtube List (Title : URL)</label>
            <br/>
            <Select placeholder='Select youtube link' search options={videoList} fluid
            onChange={(e, data)=>{
                setTimeout(()=>{
                    setUrl(data.value)
                    setTitle(videos.find(v=>v.url === data.value).url_title)
                    setUrlId(videos.find(v=>v.url === data.value).url_id)
                    setCurrentDate(videos.find(v=>v.url === data.value).date)
                }, 10)
            }}/>
            <br/>
            </>}
            <Input required inva name="url_title" label="URL Title" placeholder="Type url title here" 
            input_error={duplicateTitle}
            disabled={radio === 'old'} id={radio} value={title} onChange={titleChange}/>
            {duplicateTitle && <Label style={{marginTop: 0}} pointing color='red'>Title already exist, try different one!</Label>}
            <Input
            label='Video link (https://www.youtube.com/watch?v=youtube_id)'
            name="url"
            placeholder='Type link here'
            required
            onChange={(e)=>{
                const youtubeUrlRegex = /^https?:\/\/(?:www\.)?youtube\.com\/watch\?v=[a-zA-Z0-9_-]+$/
                setUrl(e.nextValue)
                setValidLink(youtubeUrlRegex.test(e.nextValue))
            }}
            value={url}
            disabled={radio === 'old'}
            wlength={50} />

            {!validLink && url ? <Label color='red' pointing>Invalid youtube link</Label> : null}

            <Input required value={currentDate} type="hidden" touched name="datetime"/>
            <label>Schedule release chapter</label>
            <DateInput
                id="date-input"
                placeholder='Publish Date'
                minDate={data ? undefined : new Date()}
                popupPosition='right center'
                duration={10}
                hideMobileKeyboard
                value={currentDate}
                iconPosition='right'
                preserveViewMode={false}
                autoComplete='off'
                dateFormat='YYYY-MM-DD'
                closable
                onChange={(event, {name, value})=>{
                    setCurrentDate(value)
                }}
            />
            <Input
            value={urlId}
            type="hidden"
            touched
            name="url_id" />
        </td>
        <td>
            <Input
            value={tempQuestions}
            required
            touched={data}
            type="hidden"
            name="questions" />
            {questions.length > 0 && !data ? questions.map((v, k)=><div key={k} style={{ display: 'flex', flexDirection: 'column' }}>
                <h5>Question #{k+1}</h5>
                <p style={{ marginBottom: 2 }}>{v.question}</p>

                <h5>Correct Answer</h5>
                <p style={{ marginBottom: 2 }}>{v.answer}</p>

                <h5 style={{ color: 'red' }}>Wrong Answer</h5>
                <li>{v.wrong1}</li>
                <li>{v.wrong2}</li>
                <li>{v.wrong3}</li>
                <div style={{ alignSelf: 'flex-end', justifyItems: 'center' }}>
                    <Button
                        type="button"
                        key={1}
                        floated='left'
                        secondary
                        onClick={()=>editQuestion(k)}>
                        Edit
                    </Button>
                    <Button 
                        type="button"
                        key={2} negative onClick={() => {
                        setSelectedIdx(k)
                        setConfirm(true)
                    }}>Delete</Button>
                </div>
                <Divider/>
            </div>) : !data ? <Message color='orange'>
            <p>Please add at least 1 question to submit</p></Message> : null}
            {formOpen && <>
                <FormField>
                <TextAreaNew placeholder='Type here' name='question' onKeyUp={data ? onTypeForm : null} onChange={onChange} maxLength={200}
                value={form.question}/>
                {hint(200-form.question.length)}
            </FormField>
            <FormField>
            <label>Correct Answer</label>
            <InputNew
                name="answer"
                required
                onChange={onChange}
                onKeyUp={data ? onTypeForm : null}
                placeholder='Type your correct answer' maxLength={150}
                value={form.answer}/>
                {hint(150-form.answer.length)}
            </FormField>
            <FormField>
                <label style={{ color: 'red' }}>Wrong Answer*</label>
                <InputNew
                name="wrong1"
                required
                onChange={onChange}
                onKeyUp={data ? onTypeForm : null}
                placeholder='Type your wrong answer' maxLength={150}
                value={form.wrong1}/>
                {hint(150-form.wrong1.length)}
            </FormField>
            <FormField>
            <InputNew
                name="wrong2"
                required
                onChange={onChange}
                onKeyUp={data ? onTypeForm : null}
                placeholder='Type your wrong answer' maxLength={150}
                value={form.wrong2}/>
                {hint(150-form.wrong2.length)}
            </FormField>
            <FormField>
            <InputNew
                name="wrong3"
                required
                onChange={onChange}
                onKeyUp={data ? onTypeForm : null}
                placeholder='Type your wrong answer' maxLength={150}
                value={form.wrong3}/>
                {hint(150-form.wrong3.length)}
            </FormField>
            </>}
            {!data && formOpen ? <FormField>
                <Button type='button' disabled={form.question.length < 1 || form.answer.length < 1 
                || form.wrong1.length < 1 || form.wrong2.length < 1 || form.wrong3.length < 1}
                color='green' onClick={addQuestion}>
                    Save question
                </Button>
                <Button type='button' className='tertiary' onClick={resetForm}>
                    Decline
                </Button>
            </FormField> : !data ? <FormField>
                <Button type='button' color='green' disabled={!validLink || url === '' || duplicateTitle || title === ''} onClick={()=>setFormOpen(true)}>
                    Add new question
                </Button>
            </FormField> : null}
            
            
        </td>

        <Confirm
            centered
            open={confirm}
            onCancel={()=>setConfirm(false)}
            onConfirm={removeQuestion}
            content='Are you sure want to delete question?'
        /> 
    </>
}


export default Module34Form

