import React from 'react';
import { Divider, Image, Button, Form } from 'semantic-ui-react';
import { toast } from 'react-toastify';

class ForgotPasswordPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            email: '',
            loading: false
        }
    }

    handleChange = (e, d) =>{
        this.setState({email: d.value})
    }

    sendLink = () =>{
        this.setState({loading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/auth/forgot-password', {
            method: 'post',
            body: JSON.stringify({
                email: this.state.email
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status){
                toast.success('Your reset password link sent to your email. Please check your email and follow the steps.')
                this.setState({loading: false, email: ''})
            }else{
                toast.error(res.message)
                this.setState({loading: false})
            }
        })
    }

    render() {
        return (
            <div className="ui middle aligned container" style={{width: 400, minHeight: '66.5vh'}}>
            <div className="ui middle aligned grid" style={{height:'100vh'}}>
                <div className="row">
                    <div className="column">
                        <div className="ui segment">
                            <Image style={{width: 200}} centered src={require('../../assets/images/content.png')}/>
                            <Divider hidden/>
                            <div className='loginHeader'>
                                <span className='left'>Forgot Password</span>
                            </div>
                            <Divider hidden/>
                            <Form onSubmit={this.sendLink} id="forgot_form">
                                <Form.Input value={this.state.email} placeholder='Type email address'
                                onChange={this.handleChange} name='email' type='email' label='Email' required id="email"/>
                                <Button loading={this.state.loading} disabled={this.state.loading} fluid primary
                                id="forgot_btn_submit">
                                    Send Reset Password Link
                                </Button>
                            </Form>
                            <Divider hidden/>
                            <p className='footerTextLogin'>Remember your password? <a rel='link' className='link' href='/login'>Log In</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default ForgotPasswordPage