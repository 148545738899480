import React from 'react'
import { Input } from 'semantic-ui-react'
import { useDispatch, useSelector } from 'react-redux'
import { filterSubject, loadSubject } from '../../store/actions/subject'

const SearchComponent = (props) =>{
    const dispatch = useDispatch()
    const filterRedux = useSelector(x=>x.filter_subject)
    const handleSearch = (e, data) =>{
        let filter = filterRedux === null ? {
            search: '',
            sort: '0-a',
            category: '',
            filter:[],
            n: '6',
            page: '1'
        } : filterRedux
        filter.search = data.value
        dispatch(filterSubject(null))
        dispatch(filterSubject(filter))
        dispatch(loadSubject(true))
    }
    return (
        <div className="ui icon rounded input" style={{float:'right'}}>
            <span className='text_result'>
                {props.count > 0 ? 'There are '+props.count+' subject(s)' : 'No subject found...'}
            </span>
            <Input size='small' onChange={handleSearch} placeholder="Search Subject..." id="search_subject"/>
            <i className="search icon"></i>
        </div>
    )
}

export default SearchComponent