import React from 'react'
import {Icon, Dropdown, Grid} from 'semantic-ui-react'

const NotifText = (props) =>{
    let data = props.data
    let icon = 'folder'
    let content = ''
    let url = ''
    switch (data.type) {
        case 1:
            content = <span>
                Congrats! Your subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> is now verified!
            </span>
            url = '/subject/detail/'+data.identifier+'/content'
            break;
        case 2:
            content = <span>
                Congrats! Your user profile is now verified!
            </span>
            url = '/profile/me'
            break;
        case 3:
            content = <span>
                You have been invited by <b>{data.info.inviter_username}</b> to contribute to their subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </span>
            url = '/subject/detail/'+data.identifier+'/content'
            break;
        case 4:
            content = <span>
                Your request to contribute to <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> has been accepted
            </span>
            url = '/subject/detail/'+data.identifier+'/content'
            break;
        case 5:
            content = <span>
                Your request to contribute to <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> has been rejected
            </span>
            url = '/subject/detail/'+data.identifier+'/content'
            break;
        case 6:
            content = <span>
                You have been banned by admin because <b>{data.info.reason}</b>
            </span>
            url = '/faq'
            break;
        case 7:
            content = <span>
                Version <b>{data.info.version}</b> of your subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> has passed our verification process and has been published to the game
            </span>
            url = '/subject/detail/'+data.identifier+'/content'
            break;
        case 8:
            content = <span>
                Version <b>{data.info.version}</b> of your subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> has been rejected because <b>{data.info.reason}</b>
            </span>
            url = '/subject/detail/'+data.identifier+'/content'
            break;
        case 9:
            content = <span>
                You have stopped contributing to subject: <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </span>
            url = '/subject/detail/'+data.identifier+'/content'
            break;
        case 10:
            content = <span>
                You have been removed from contributing to subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </span>
            url = '/subject/detail/'+data.identifier+'/content'
            break;
        case 11:
            content = <span>
                You have been promoted to Manager in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </span>
            url = '/subject/detail/'+data.identifier+'/contributor'
            break;
        case 12:
            content = <span>
                You have been demoted to Contributor in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </span>
            url = '/subject/detail/'+data.identifier+'/contributor'
            break;
        case 14:
            content = <span>
                You have been assigned to <b>{data.info.topic_name_tree}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> by <b>{data.info.assignor_username}</b> with due at <b>{data.info.due_date}</b>
            </span>
            url = '/subject/'+data.info.subject_code+'/'+data.identifier+'/question'
            if(data.info.has_sub_topic){
                let t_ids = data.info.topic_ids !== undefined ? data.info.topic_ids.join(',') : ''
                url = '/subject/detail/'+data.info.subject_code+'/content/'+data.info.subject_module_id+'?l='+data.info.subject_module_level_id+'&t='+t_ids
            }else{
                url = '/subject/'+data.info.subject_code+'/'+data.identifier+'/question'
            }
            break;
        case 15:
            content = <span>
                <b>{data.info.username}</b> has finished working on <b>{data.info.topic_name_tree}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </span>
            if(data.info.has_sub_topic){
                let t_ids = data.info.topic_ids !== undefined ? data.info.topic_ids.join(',') : ''
                url = '/subject/detail/'+data.info.subject_code+'/content/'+data.info.subject_module_id+'?l='+data.info.subject_module_level_id+'&t='+t_ids
            }else{
                url = '/subject/'+data.info.subject_code+'/'+data.identifier+'/question'
            }
            break;
        case 16:
            content = <span>
                Your assigned task of <b>{data.info.topic_name_tree}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> is due tomorrow.
            </span>
            if(data.info.has_sub_topic){
                let t_ids = data.info.topic_ids !== undefined ? data.info.topic_ids.join(',') : ''
                url = '/subject/detail/'+data.info.subject_code+'/content/'+data.info.subject_module_id+'?l='+data.info.subject_module_level_id+'&t='+t_ids
            }else{
                url = '/subject/'+data.info.subject_code+'/'+data.identifier+'/question'
            }
            break;
        case 17:
            content = <span>
                <b>{data.info.username}</b> has passed the deadline for their assigned task of <b>{data.info.topic_name_tree}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </span>
            if(data.info.has_sub_topic){
                let t_ids = data.info.topic_ids !== undefined ? data.info.topic_ids.join(',') : ''
                url = '/subject/detail/'+data.info.subject_code+'/content/'+data.info.subject_module_id+'?l='+data.info.subject_module_level_id+'&t='+t_ids
            }else{
                url = '/subject/'+data.info.subject_code+'/'+data.identifier+'/question'
            }
            break;
        case 18:
            content = <span>
                You have received a response for ticket ID <b>{data.info.ticket_id}</b>. Please check your e-mail.
            </span>
            break;
        case 19:
            content = <span>
                <b>{data.info.applicant_username}</b> has sent a request to contribute to a subject you are managing.
            </span>
            url = '/subject/detail/'+data.info.subject_code+'/contributor'
            break;
        // type 13
        default:
            content = <span>
                Your subject: <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> is deleted by admin because of <b>{data.info.reason}</b>
            </span>
            break;
    }

    if(data.category_id === 1){
        icon = 'user'
    }

    if(props.type === 'all'){
        return (
            <Grid.Row className='activityWrapper link' onClick={()=>{
                if(url !== ''){
                    window.location.href=url
                }
            }}>
                <Grid.Column width='1'>
                    {data.type === 1 || data.type === 2 ? 
                    <><Icon name={icon} circular/>
                    <Icon name='check' color='green' circular/></> : 
                    <><Icon size='large' name={icon} circular/>
                    <i></i></>}
                </Grid.Column>
                <Grid.Column style={{marginLeft: 5}}>{content}</Grid.Column>
                <Grid.Column width='4'>
                    {data.time}
                </Grid.Column>
            </Grid.Row>
        )
    }

    return (
        <Dropdown.Item as='a' href={url}>
            <div>
                {data.type === 1 || data.type === 2 ? 
                <><Icon inverted name={icon} circular/>
                <Icon name='check' color='green' inverted circular/></> : 
                <><Icon size='large' inverted name={icon} circular/>
                <i></i></>}
            </div>
            <div>
                {content}
                <p>{data.time}</p>
            </div>
        </Dropdown.Item>
    )
}

export default NotifText