import React from 'react'
import { Menu, Image, Icon } from 'semantic-ui-react'
import storage from 'redux-persist/lib/storage'
import {connect} from 'react-redux'
import { Link } from 'react-router-dom'

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth,
        nav: state.nav
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        navAction: (n) => dispatch({ type: 'CHANGE_NAV', payload: n })
    }
}

class TopNavAdminComponent extends React.Component{
    logout = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/auth/logout',{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
        }).then(function(response) {
            return response.json()
        }).then(function(response) {
            storage.removeItem('persist:root')
            window.location = '/'
        })
    }

    render() {
        let pathName = window.location.pathname.split('/')
        pathName = pathName[pathName.length - 1]
        if(this.props.nav === null){
            this.props.navAction(pathName)
        }
        return (
            <Menu className='fixed TopNav'>
                <Menu.Item className='header item' as='a' href='/'>
                    <Image src={require('../assets/images/content.png')}/>
                </Menu.Item>
                <Link to='/dashboard' className={'item'+ (this.props.nav === 'dashboard' ? ' active' : '')}
                onClick={()=>this.props.navAction('dashboard')}>
                    <Icon name='dashboard'/> Dashboard
                </Link>
                <Link to='/users' className={'item'+ (this.props.nav === 'users' ? ' active' : '')}
                onClick={()=>this.props.navAction('users')}>
                    <Icon name='users'/> Users
                </Link>
                <Link to='/subjects' className={'item'+ (this.props.nav === 'subjects' ? ' active' : '')}
                onClick={()=>this.props.navAction('subjects')}>
                    <Icon name='folder'/> Subjects
                </Link>
                <Link to='/minigames' className={'item'+ (this.props.nav === 'minigames' ? ' active' : '')}
                onClick={()=>this.props.navAction('minigames')}>
                    <Icon name='gamepad'/> Minigames
                </Link>
                <Link to='/contact' className={'item'+ (this.props.nav === 'contact' ? ' active' : '')}
                onClick={()=>this.props.navAction('contact')}>
                    <Icon name='envelope open'/> Contact Us
                </Link>
                <Link to='/faq' className={'item'+ (this.props.nav === 'faq' ? ' active' : '')}
                onClick={()=>this.props.navAction('faq')}>
                    <Icon name='question circle'/> FAQ
                </Link>
                <Link to='/legal' className={'item'+ (this.props.nav === 'legal' ? ' active' : '')}
                onClick={()=>this.props.navAction('legal')}>
                    <Icon name='file alternate'/> Legal Page
                </Link>
                <Menu.Menu position='right'>
                    <Menu.Item onClick={this.logout}
                    name='Logout'
                    icon='sign-out'
                    />
                </Menu.Menu>
            </Menu>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNavAdminComponent)