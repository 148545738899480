import React from 'react';
import { Divider, Image, Button, Form, Icon } from 'semantic-ui-react';
import {connect} from 'react-redux'
import './style.scss'
import { toast } from 'react-toastify';

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        loginAction: (n) => {
            let state = {
                is_logged: true,
                user: n
            }
            dispatch({ type: 'LOGIN', payload: state })
        },
        navAction: (n) => dispatch({ type: 'CHANGE_NAV', payload: n })
    }
}

class LoginAdminPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            form: {
                username: '',
                password: '',
            },
            loading: false
        }
    }
    handleLogin = () =>{
        this.setState({loading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/auth/login', {
            method: 'post',
            body: JSON.stringify(this.state.form)
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            this.setState({loading: false})
            if(res.status){
                this.props.loginAction(res.data)
                this.props.navAction('dashboard')
            }else{
                toast.error(res.message)
            }
        })
    }

    handleChange = (e, data) =>{
        let form = this.state.form
        if(data.name === 'username'){
            form.username = data.value
        }else{
            form.password = data.value
        }
        this.setState({form: form})
    }

    render() {
        return (
            <div className="ui middle aligned container" style={{minHeight: '100vh', width: 400}}>
            <div className="ui middle aligned grid" style={{height:'100vh'}}>
                <div className="row">
                    <div className="column">
                        <div className="ui segment">
                            <Image style={{width: 200}} centered src={require('../../assets/images/content.png')}/>
                            <Divider hidden/>
                            <div className='loginHeader'>
                                <span className='left'>Log In</span>
                            </div>
                            <Divider hidden/>
                            <Form onSubmit={this.handleLogin}>
                                <label>Username</label>
                                <Form.Input name='username' placeholder='Type your username' 
                                onChange={this.handleChange} required/>
                                <label>Password</label>
                                <Form.Input name='password' placeholder='Type your password' type='password' 
                                onChange={this.handleChange} required/>
                                <Button primary fluid disabled={this.state.loading}
                                loading={this.state.loading}>
                                    <Icon name='sign in'/> Login
                                </Button>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginAdminPage)