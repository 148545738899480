import React from 'react'
import { Button, Modal, List, Icon, Dimmer, Loader } from 'semantic-ui-react'
import {toast} from 'react-toastify';
import './style.scss'


class DestinationFolderComponent extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            isLevel: true,
            levelList: [],
            topicList: [],
            headerAction: [],
            isMoveable: false,
            targetData: null,
            moveLoading: false,
            undoLoading: false
        }
    }

    itemClicked = false

    componentDidUpdate(prevProp, prevState){
        if(this.props !== prevProp && this.props.destinyModal){
            if(this.props.banner_data !== undefined){
                this.loadTopic(this.props.banner_data.subject_module_level_id, this.props.banner_data.parent_id)
                this.generateThumbnail(this.props.banner_data)
            }else{
                this.loadLevel()
            }
        }
    }

    generateThumbnail = (data) =>{
        let action = []
        action.push({
            name: data.subject_module_level_name,
            id: data.subject_module_level_id,
            id_sub: null,
            is_level: true
        })

        let i = 0
        data.topic_tree.forEach(x=>{
            if(i < data.topic_tree.length-1){
                if(i === 0){
                    action.push({
                        name: x.name,
                        id: data.subject_module_level_id,
                        id_sub: null,
                        is_level: false
                    })
                }else if(data.topic_tree.length > 1){
                    action.push({
                        name: x.name,
                        id: data.subject_module_level_id,
                        id_sub: data.topic_tree[i-1].id,
                        is_level: false
                    })
                }
            }

            i++
        })

        this.setState({headerAction: action})
    }

    loadLevel = () =>{
        this.setState({loading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/modules/'+this.props.id+'/levels?is_move_target=1',{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            let levels = []
            let data = res.data
            Object.keys(data).forEach(val=>{
                for (let i = 0; i < data[val].length; i++) {
                    data[val][i].selected = false
                }
                levels = levels.concat(data[val])
            })
            this.setState({loading: false, levelList: levels})
            if(!this.state.isLevel) this.setState({isLevel: true})
            if(this.state.isMoveable) this.setState({isMoveable: false})
        })
    }

    loadTopic = (id, topic_id = null) =>{
        if(topic_id === null){
            topic_id = ""
        }else{
            topic_id = "/"+topic_id
        }
        this.setState({loading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/adaptive/topics/'+id+topic_id,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            let data = res.data
            for (let i = 0; i < data.length; i++) {
                data[i].selected = false
            }
            this.setState({loading: false, topicList: data, isLevel: false})
            if(this.state.isMoveable) this.setState({isMoveable: false})
        })
    }

    levelItemClick = (data, e) =>{
        let levels = this.state.levelList
        if(!this.itemClicked){
            let idx_active = levels.findIndex(x=>x.selected)
            let idx = levels.findIndex(x=>x.subject_module_level_id === data.subject_module_level_id)
            
            if(idx_active !== -1){
                levels[idx_active].selected = !levels[idx_active].selected
            }

            if(idx !== -1){
                levels[idx].selected = !levels[idx].selected
            }

            this.setState({levelList: levels, isMoveable: true})
        }
        this.itemClicked = false
    }

    levelItemOpen = (data, e) =>{
        let action = this.state.headerAction
        this.itemClicked = true
        action.push({
            name: data.level_name,
            id: data.subject_module_level_id,
            id_sub: null,
            is_level: true
        })
        this.setState({headerAction: action})
        this.loadTopic(data.subject_module_level_id)
    }

    topicItemClick = (data, e) =>{
        let topics = this.state.topicList
        if(!this.itemClicked){
            let idx_active = topics.findIndex(x=>x.selected)
            let idx = topics.findIndex(x=>x.topic_id === data.topic_id)
            
            if(idx_active !== -1){
                topics[idx_active].selected = !topics[idx_active].selected
            }

            if(idx !== -1){
                topics[idx].selected = !topics[idx].selected
            }

            this.setState({topics: topics, isMoveable: true})
        }
        this.itemClicked = false
    }

    topicItemOpen = (data, e) =>{
        let action = this.state.headerAction
        this.itemClicked = true
        action.push({
            name: data.topic_name,
            id: data.subject_module_level_id,
            id_sub: data.parent_id !== null ? data.parent_id : null,
            is_level: false
        })
        this.setState({headerAction: action})
        this.loadTopic(data.subject_module_level_id, data.topic_id)
    }

    goBack = (is_level, e) =>{
        let action = this.state.headerAction
        if(is_level){
            this.loadLevel()
        }else{
            if(action.length === 0){
                this.loadLevel()
            }else{
                let param = action[action.length-1]
                this.loadTopic(param.id, param.id_sub)
            }
        }
        action.splice(action.length-1, 1)
        this.setState({headerAction: action})
    }

    moveAction = (is_force = false) =>{
        let source = this.props.sourceData
        let level = this.state.levelList.filter(x=>x.selected)
        let topic = this.state.topicList.filter(x=>x.selected)
        let targetType = ''

        if(level.length > 0 && this.state.isLevel){
            targetType = 'level'
            level = level[0]
        }else if(topic.length > 0){
            targetType = 'topic'
            topic = topic[0]
        }

        if(source.question_ids !== undefined){
            targetType = 'question'
        }

        let param = {}

        if(targetType === 'level'){
            param = {
                subject_code: this.props.subject,
                topic_id: source.topic_id,
                destination_subject_module_level_id: level.subject_module_level_id
            }
        }else if(targetType === 'topic'){
            param = {
                subject_code: this.props.subject,
                topic_id: source.topic_id,
                destination_topic_id: topic.topic_id
            }
        }else{
            param = {
                subject_code: this.props.subject,
                question_ids: source.question_ids,
                destination_topic_id: topic.topic_id
            }
        }

        this.setState({moveLoading: true})

        if(is_force === true) param.force = 1

        fetch(process.env.REACT_APP_API_URL+"api/v1/questions/move", {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth
            }),
            body: JSON.stringify(param)
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status){
                toast.dismiss()
                toast(<div>
                    {res.message}. <Button size="mini" disabled={this.state.undoLoading} loading={this.state.undoLoading}
                    onClick={this.undoProcess.bind(null, res.data, source)}>Undo</Button>
                </div>, {
                position: "bottom-left",
                autoClose: 60000,
                hideProgressBar: false,
                closeOnClick: false,
                closeButton: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                type: 'success'
                });

                this.props.hideDestiny()
            }else{
                if(res.is_movable !== undefined && res.is_movable){
                    if(window.confirm(res.message)){
                        this.moveAction(true)
                    }
                }else{
                    toast.error("Failed to move, "+res.message)
                }
            }
            this.setState({moveLoading: false})
        })
    }

    undoProcess = (data, source, e) =>{
        if(window.confirm("Are you sure want to undo the process?")){
            this.setState({undoLoading: true})
            fetch(process.env.REACT_APP_API_URL+"api/v1/questions/move/undo?moving_history_id="+data.moving_history_id+"&activity_history_id="+data.activity_history_id, {
                headers: new Headers({
                    'Authorization': 'Token '+this.props.auth
                })
            })
            .then((res)=>{
                return res.json()
            })
            .then((res)=>{
                if(res.status){
                    toast.dismiss()
                    toast.success(res.message)
                    if(source.question_ids !== undefined){
                        this.props.hideDestiny()
                    }else{
                        this.props.hideDestiny(source.subject_module_level_id)
                    }
                }else{
                    toast.error(res.message)
                }

                this.setState({undoLoading: false})
            })
        }
    }

    render() {
        let comp = null
        if(this.state.headerAction.length > 0){
            let param = this.state.headerAction[this.state.headerAction.length - 1]
            if(param.is_level){
                comp = <>
                <Icon name="angle left" link color="blue" onClick={this.goBack.bind(null, true)}/> {param.name}
                </>
            }else{
                comp = <>
                <Icon name="angle left" link color="blue" onClick={this.goBack.bind(null, false)}/> {param.name}
                </>
            }
        }
        return (
        <Modal size="mini" open={this.props.destinyModal}>
            <Modal.Header>
                {this.state.isLevel ? "Move Destination" : comp}
            </Modal.Header>
            <Modal.Content scrolling>
                <Dimmer active={this.state.loading} inverted>
                    <Loader active/>
                </Dimmer>
                <List selection verticalAlign='middle' size='large' className="destiny">
                    {this.state.isLevel ? 
                    this.state.levelList.map((v, k)=>
                    <List.Item key={k} onClick={this.levelItemClick.bind(null, v)} active={v.selected}>
                        <List.Content floated='right'>
                            <Icon link size='large' name="angle right" onClick={this.levelItemOpen.bind(null, v)}/>
                        </List.Content>
                        <Icon name='folder' size='large' color="blue"/>
                        <List.Content>{v.level_name}</List.Content>
                    </List.Item>
                    )
                    : 
                    this.state.topicList.length > 0 ? 
                        this.state.topicList.map((v, k)=>
                        <List.Item key={k} onClick={this.topicItemClick.bind(null, v)} active={v.selected}>
                            {v.has_sub_topic ? 
                            <List.Content floated='right'>
                                <Icon link size='large' name="angle right" onClick={this.topicItemOpen.bind(null, v)}/>
                            </List.Content>
                            : null}
                            <Icon name='folder' size='large' color="blue"/>
                            <List.Content>{v.topic_name}</List.Content>
                        </List.Item>
                        )
                    : <p>No topic found...</p>
                    }
                </List>
            </Modal.Content>
            <Modal.Actions>
                <Button className='tertiary' disabled={this.state.moveLoading} onClick={()=>this.props.closeDestiny()}>Cancel</Button>
                <Button primary disabled={!this.state.isMoveable || this.state.moveLoading} onClick={this.moveAction}
                loading={this.state.moveLoading}>Move</Button>
            </Modal.Actions>
        </Modal>
      )
    };
}

export default DestinationFolderComponent 