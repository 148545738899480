import React from "react";
import { Header, Container, Form, Divider, Button, Popup } from "semantic-ui-react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { CheckBadWords } from '../../../../helpers/global';

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setStepActiveIndex: (n) => dispatch({ type: 'CHAPTER_INDEX_ACTIVE', payload: n }),
        setChapterRequestData: (n) => dispatch({ type: 'CHAPTER_REQUEST_DATA', payload: n })
    }
}

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth,
        chapter_step_active: state.chapter_step_active,
        chapter_request_data: state.chapter_request_data
    }
}

// includes chapter title
class ChapterSection extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            title: this.props.chapter_request_data !== null ? 
            this.props.chapter_request_data.chapter_name : '',
            value: this.props.chapter_request_data !== null ? 
            this.props.chapter_request_data.completion_rate : 0
        }
        this.onChangeInput = this.onChangeInput.bind(this)
    }

    componentDidMount(){
        // set request chapter data
        this.props.setChapterRequestData(null);
    }

    // save & continue process
    saveChapterTitle = () =>{
        if(this.state.title === ''){
            toast.error('Please fill your chapter titler first.')
            return false
        }else{
            CheckBadWords({
                header: new Headers({
                    'Authorization': 'Token '+this.props.auth.user.token
                }),
                text: this.state.title
            }).then(val=>{
                if(!val.status){
                    toast.error(val.message)
                    return false
                }else{
                    // set request chapter data
                    this.props.setChapterRequestData(
                        {
                            "code": this.props.subject.code,
                            "chapter_name": this.state.title,
                            "module_id": null,
                            "minigame_id": null,
                            "question_object" : {},
                            "module_id_game" : null,
                            "completion_rate": this.state.value,
                            "mg_name": '',
                            "platforms": [],
                            "teaching_format": []
                        }
                    );
                    // set active step
                    this.props.setStepActiveIndex(2);
                }
            })
        }
    }

    // onchange handle title input
    onChangeInput = (e, data) =>{
        this.setState({title: data.value})
    }

    render() {
        return (
            <Container textAlign='center'>
                <Header as='h2' style={{color: 'rgba(3, 28, 48, 0.65)'}}>Create a chapter title for your subject.</Header>
                <p>You can edit this later.</p>
                <Divider hidden/>
                <b>Chapter Title</b>
                <Form.Input value={this.state.title} onChange={this.onChangeInput} size='big' style={{width: '50%'}} placeholder='Title: Introduction' id='chapter_title'/>
                <Divider hidden/>
                <Button primary onClick={this.saveChapterTitle} id='chapter_save_btn'>Save & Continue</Button>
                <Divider hidden/>
                <Divider hidden/>
                <Popup basic content='A chapter is a category within your subject. You can divide your content into different chapters.' 
                trigger={<span style={{color:'grey', cursor:'pointer', 
                textDecoration:'underline'}}>What is chapter?</span>}/>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterSection)