import React from 'react'
import { connect } from 'react-redux'
import EmptyComponent from '../../../components/empty'
import ActivityText from './activity_text'
import {Button, Loader, Grid, Divider} from 'semantic-ui-react'
import storage from 'redux-persist/lib/storage';
import {toast} from 'react-toastify'
import { getSubjectRedux } from '../../../helpers/redux'
import * as Sentry from "@sentry/browser";

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth,
        add_subject_step: state.add_subject_step
    }
}

class SubjectActivitiesPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            activePage: 1,
            loading: false,
            activityHistory: [],
            isEmpty: false,
            currentSubject: getSubjectRedux(this.props.add_subject_step, this.props.match.params.code)
        }
    }

    divRef = null

    componentDidMount(){
        this.loadData()
    }

    loadData = (activePage = 1) =>{
        this.setState({loading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/history/'+this.props.match.params.code, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method:'post',
            body: JSON.stringify({
                page: activePage,
                n: 10
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            let oldData = this.state.activityHistory
            let data = []
            let isEmpty = false

            if(res.data.length === 0 || res.data.length < 10){
                isEmpty = true
            }

            data = oldData.concat(res.data)

            this.setState({activityHistory: data, loading: false, isEmpty: isEmpty})

            if(activePage > 1){
                this.divRef.scrollTop = this.divRef.scrollHeight;
            }
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    loadMore = () =>{
        this.setState({activePage: this.state.activePage + 1})
        this.loadData(this.state.activePage + 1)
    }

    render() {
        return (
            <div className='ui segment' style={{height: '60vh', overflow:'auto'}} ref={(el) => { this.divRef = el }}>
                {this.state.loading ? <Loader active/>
                : 
                    this.state.activityHistory.length > 0 ?
                    <>
                        <Grid columns='equal'>
                        {this.state.activityHistory.map((v,k)=>
                            <ActivityText key={k} data={v}/>
                        )}
                        </Grid>

                        <Divider hidden/>
                        {!this.state.isEmpty ? 
                            <div style={{textAlign: 'center'}}>
                                <Button loading={this.state.loading}
                                onClick={this.loadMore} primary>
                                    Load More
                                    </Button>
                            </div>
                        : null}
                    </>
                    : <EmptyComponent size='tiny'/>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps)(SubjectActivitiesPage)