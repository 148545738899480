import React, { useEffect, useState } from 'react';
import { Grid, Icon, Divider, Loader, Pagination, Header,Label, Dimmer } from 'semantic-ui-react';
import { useDispatch, useSelector, connect } from 'react-redux';
import FilterComponent from '../../components/filter';
import EmptySubjectComponent from '../../components/subject/empty';
import CardComponent from '../../components/card';
import { filterSubject, loadSubject, setPreqSubject } from '../../store/actions/subject'
import SearchComponent from '../../components/filter/search';
import storage from 'redux-persist/lib/storage';

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        filter_subject: state.filter_subject,
        is_load: state.filter_subject !== null ? true : false,
        preq_subject: state.preq_subject
    }
}

// display list of public subject
const BrowsePage = (props) =>{
    let url = new URL(window.location.href);
    let search = url.searchParams.get("search");
    useEffect(()=>{
        // init the filter
        dispatch(filterSubject(null))
        loadData(true)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const dispatch = useDispatch()
    const userInfo = useSelector(x=>x.auth)
    const myHeaders = new Headers({
        'Authorization': 'Token '+userInfo.user.token
    });
    const isLoad = useSelector(x=>x.is_subject_load)
    const [filterParam, setFilterParam] = useState({
        search: search !== null ? search : '',
        sort: '0-a',
        category: '',
        filter:[],
        n: '6',
        page: '1'
    })
    const [mySubject, setMySubject] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingPage, setLoadingPage] = useState(false)
    const [totalPage, setTotalPage] = useState(0)
    const subjectFilter = useSelector(x=>x.filter_subject)
    const [totalSubject, setTotalSubject] = useState(0)
    const [selectedPreq, setSelectedPreq] = useState(props.preq_subject)

    // fetching subject data
    const loadData = (is_fresh = false) =>{
        if(subjectFilter === null){
            dispatch(filterSubject(filterParam))
        }
        let params = null
        if(is_fresh){
            params = filterParam
        }else{
            params = subjectFilter === null ? filterParam : subjectFilter
        }

        if(props.is_preq !== undefined && props.is_preq){
            params.is_prerequisite = true
            params.current = props.current
        }

        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects', {
            method: 'post',
            headers: myHeaders,
            body: JSON.stringify(params)
        }).then(function(res){
            return res.json()
        }).then(function(res){
            setLoading(false)
            setLoadingPage(false)
            if(res.status !== undefined){
                dispatch(loadSubject(false))
                setTotalPage(res.total_page)
                let storeSubject = []
                res.data.forEach(val => {
                    if(selectedPreq !== null && selectedPreq.length > 0){
                        let idx = selectedPreq.findIndex(x=>x.code === val.code && x.is_selected)
                        val.is_selected = idx !== -1 ? true : false
                    }else{
                        val.is_selected = false
                    }
                    storeSubject.push(val)
                });
                setMySubject(storeSubject)
                setTotalSubject(res.total_record)
            }else{
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
        })
    }

    // handle pgination change
    const handleChangePage = (page) =>{
        setLoadingPage(true)
        let filter = filterParam
        filter.page=page
        setFilterParam(filter)
        loadData()
    }

    // will fired loadData if store/state is load equal true
    if(isLoad){
        loadData()
    }

    // used in prerequisite select card of subject
    const selectCard = (id, event) =>{
        setMySubject([])
        dispatch(setPreqSubject([]))

        let data = mySubject
        let selectedPreqData = selectedPreq
        let index = data.findIndex(x=>x.code === id)
        if(index !== -1){
            data[index].is_selected = !data[index].is_selected
            if(data[index].is_selected){
                selectedPreqData.push(data[index])
            }else{
                let idx = selectedPreqData.findIndex(x=>x.code === id)
                if(idx !== -1){
                    selectedPreqData.splice(idx, 1)
                }
            }
        }
        
        setSelectedPreq(selectedPreqData)
        setTimeout(()=>{
            setMySubject(data)
            dispatch(setPreqSubject(selectedPreqData))
        }, 0)
    }

    // render component based on type, prerequisite or browser page
    const renderComponent = () =>{
        let component = <CardComponent data={mySubject}/>
        if(props.is_preq !== undefined && props.is_preq){
            let adaptiveSubject = mySubject.filter(x=>x.adaptive)
            let nonAdaptiveSubject = mySubject.filter(x=>!x.adaptive)
            component = <div>
                {props.is_preq !== undefined && selectedPreq.length > 0 ? 
                    <div>
                        <Header as='h4' color='grey'>Selected Subject</Header>
                        <Label.Group>
                            {selectedPreq.map((v,k)=>
                                <Label as='a' key={k}>
                                    {v.name} <Icon name='delete' onClick={selectCard.bind(null, v.code)}/>
                                </Label>
                            )}
                        </Label.Group>
                        <br/>
                    </div>
                    : ''
                }
                <Header as='h4' color='grey'>Adaptive Subject</Header>
                <CardComponent data={adaptiveSubject} type='simple' act={selectCard}/>
                <br/>
                <Header as='h4' color='grey'>Non-adaptive Subject</Header>
                <CardComponent data={nonAdaptiveSubject} type='simple' act={selectCard}/>
            </div>
        }
        return component
    }

    return(
        <Grid columns='equal'>
            <Grid.Column width={4}>
                <FilterComponent is_preq={props.is_preq}/>
            </Grid.Column>
            <Grid.Column>
                <Grid columns='equal'>
                    <Grid.Column>
                        <div className='c_header'>
                            <Icon name='folder'/>
                            Browse Subject
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <SearchComponent count={totalSubject}/>
                    </Grid.Column>
                </Grid>
                <Divider/>
                {loading ? 
                    <Loader active style={{marginTop: 60}}>Loading...</Loader>
                : 
                    mySubject.length > 0 ? 
                        <div>
                            {search !== null ?
                            <p>
                                <Label size='big'>
                                Search Result for “<b>{search}</b>”
                                <Icon name='delete' onClick={()=>{
                                    window.location.href='/browse'
                                }}/>
                                </Label>
                            </p>
                            
                            : null}
                            
                            {renderComponent()}
                            <Divider hidden/>
                            <div style={props.is_preq !== undefined ? {textAlign:'left'} : {textAlign:'center'}}>
                                <Pagination onPageChange={(event, data) => handleChangePage(data.activePage)} 
                                defaultActivePage={1} totalPages={totalPage} />
                            </div>
                            <Dimmer inverted active={loadingPage}>
                                <Loader active/>
                            </Dimmer>
                        </div> 
                    : <EmptySubjectComponent/>}
            </Grid.Column>
        </Grid>
    )
}

export default connect(mapStateToProps)(BrowsePage);