import React from 'react'
import { Input, Textarea } from '../fields'
import { Image, Button, Divider, Icon, Header, Popup } from 'semantic-ui-react'
import ImageCropperComponent from '../../components/image_cropper'
import { toast } from 'react-toastify'
import TooltipVariableComponent from '../../components/tooltip_variable'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// module 29 form
class Module29Form extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            image: null,
            modalOpen: false,
            feedback_wrong:'',
            feedback_wrong_id: ''
        }
    }

    // set editable content fix width & set form data when edit
    componentDidMount(){
        let data = this.props.data

        if(data !== undefined){
            // show current image
            this.setImageDisplay(data.question_object.image)
            this.setState((prev) => ({
                ...prev,
                feedback_wrong: data.question_object?.data?.feedback_wrong || '', 
                feedback_wrong_id: data.question_object?.data?.feedback_wrong_id || ''
            }))
        }
    }

    fileInput = null

    // process the image after attached
    onChangeImage = (e)=>{
        e.preventDefault();
        let files;

        if(e.target.files[0] === undefined){
            return false
        }

        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            return false
        }

        if(e.target.files[0].size > 5000000){
            toast.error('Image should be equal to or less than 5MB.')
            return false
        }
        
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        const reader = new FileReader();
        reader.onload = () => {
            this.setState((prev) =>({...prev, image: reader.result, modalOpen: true}))
        };
        reader.readAsDataURL(files[0]);
    }

    // set image value after crop
    setImageDisplay = (src) =>{
        this.fileInput.value = null
        if(src === null){
            this.setState((prev) =>({...prev, image: null, modalOpen: false}))
            return false
        }
        this.setState((prev) => ({...prev,image: src, modalOpen: false}))
    }



    render() {
        const wrongAnswerInfo = <Popup trigger={<Icon name='question circle' color='blue' style={{fontSize: 'small'}} link/>} content={
            <span>Wrong input must be different with <b>"Correct Answer"</b> and cannot be duplicate with other wrong input on each round!</span>
        }/>
        return(
            <>
                <td>
                    <Image style={{width: 100, height: 100}} size='small' src={this.state.image !== null ? this.state.image : require('../../assets/images/default.png')}/>
                    <Input value={this.state.image} type='hidden' name="image"/>
                    <input style={{display:'none'}} accept="image/jpeg" ref={(file)=>this.fileInput = file} id='q_image' 
                    type="file" onChange={this.onChangeImage}/>
                    <Popup trigger={
                        <Button type='button' size='mini' secondary onClick={()=>{
                            this.setState((prev) => ({...prev, image: null}))
                            this.fileInput.click()
                        }}>Upload Image</Button>
                    } content='Image size (Max: 5MB)'></Popup>
                    <ImageCropperComponent open={this.state.modalOpen} loading={false} imgSrc={this.state.image} fileInput={this.fileInput} 
                    ratio={1 / 1} act={this.setImageDisplay}/>
                </td>
                {this.props.is_adaptive !== undefined ? 
                <td>
                    <Input name="topic" label='Topic' required placeholder='Type here...' wlength={70}/>
                </td>
                : null}
                <td>
                    <Textarea label='Question' name="question1" required placeholder='Type here...' wlength={200}/>

                    <label>Answer <b style={{color:'#db2828'}}> * </b>
                    <TooltipVariableComponent open={false}/>
                    </label>
                    <Input name="answer1" required placeholder='Exp: are' wlength={150}/>
                    <Divider hidden/>
                    <Header color='red' as='h5'>Wrong Answers {wrongAnswerInfo}</Header>
                    <Input name="a1_wrong1" required placeholder='Exp: are' wlength={150}/>
                    <Input name="a1_wrong2" required placeholder='Exp: are' wlength={150}/>
                    <Input name="a1_wrong3" required placeholder='Exp: are' wlength={150}/>

                    <Header as='h4' color='orange'>Feedback when wrong (EN)</Header>
                    <ReactQuill  theme="snow" value={this.state.feedback_wrong} onChange={(e) =>this.setState((prev) => ({...prev, feedback_wrong: e}))}/>
                    <Input value={this.state.feedback_wrong} hidden type='text' name="feedback_wrong"/>


                    <Header as='h4' color='orange'>Feedback when wrong (ID)</Header>
                    <ReactQuill  theme="snow" value={this.state.feedback_wrong_id} onChange={(e) => this.setState((prev) => ({...prev, feedback_wrong_id: e}))} />
                    <Input value={this.state.feedback_wrong_id} hidden type='text' name="feedback_wrong_id"/>
                </td>
            </>
        )
    }
}

export default Module29Form