import React from 'react'
import { Input, Textarea } from '../fields'
import { Image, Button, Popup } from 'semantic-ui-react'
import ImageCropperComponent from '../../components/image_cropper'
import { toast } from 'react-toastify'
import ReactQuill from 'react-quill';
import { Header} from 'semantic-ui-react'

import 'react-quill/dist/quill.snow.css';

// module 33 form: VOCABULARY FORM TEMPLATE
class Module33Form extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            image: null,
            modalOpen: false,
            feedback_wrong:'',
            feedback_wrong_id: '',
            feedback_correct: '',
            feedback_correct_id: '',
        }
    }

    // set editable content fix width & set form data when edit
    componentDidMount(){
        let data = this.props.data
        if(data !== undefined){
            // show current image
            this.setImageDisplay(data.question_object.image)
            this.setState({
                feedback_wrong: data.question_object?.data?.feedback_wrong || '', 
                feedback_wrong_id: data.question_object?.data?.feedback_wrong_id || '',
                feedback_correct: data.question_object?.data?.feedback_correct || '', 
                feedback_correct_id: data.question_object?.data?.feedback_correct_id || ''
            })
        }
    }

    fileInput = null

    // process the image after attached
    onChangeImage = (e)=>{
        e.preventDefault();
        let files;

        if(e.target.files[0] === undefined){
            return false
        }

        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            return false
        }

        if(e.target.files[0].size > 5000000){
            toast.error('Image should be equal to or less than 5MB.')
            return false
        }
        
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({image: reader.result, modalOpen: true})
        };
        reader.readAsDataURL(files[0]);
    }

    // set image value after crop
    setImageDisplay = (src) =>{
        this.fileInput.value = null
        if(src === null){
            this.setState({image: null, modalOpen: false})
            return false
        }
        this.setState({image: src, modalOpen: false})
    }

    render() {
        return(
            <>
                <td>
                    <Image style={{width: 100, height: 100}} size='small' src={this.state.image !== '' && this.state.image !== null ? this.state.image : require('../../assets/images/default.png')}/>
                    <Input value={this.state.image} type='hidden' name="image"/>
                    <input style={{display:'none'}} accept="image/jpeg" ref={(file)=>this.fileInput = file} id='q_image' 
                    type="file" onChange={this.onChangeImage}/>
                    <Popup trigger={
                        <Button type='button' size='mini' secondary onClick={()=>{
                            this.setState({image: null})
                            this.fileInput.click()
                        }}>Upload Image</Button>
                    } content='Image size (Max: 5MB)'></Popup>
                    <ImageCropperComponent open={this.state.modalOpen} loading={false} imgSrc={this.state.image} fileInput={this.fileInput} 
                    ratio={1 / 1} act={this.setImageDisplay}/>
                </td>
                <td>
                    <Input label='Word' name='word' required placeholder='Type the word' wlength={75}/>
                    <Input label='Transcription/Instruction' name='instruction' placeholder='Type the transcription/instruction' wlength={75}/>
                    <Textarea label='Correct Definition' name="correct_definition" required placeholder='Type your correct definition' wlength={200}/>
                    <Textarea label='Wrong Definition' name="wrong_definition" required placeholder='Type your wrong definition' wlength={200}/>

                    <Header as='h4' color='orange'>Feedback when wrong (EN)</Header>
                    <ReactQuill name="feedback_wrong" theme="snow" value={this.state.feedback_wrong} onChange={(e) => this.setState({feedback_wrong: e})} />
                    <Input value={this.state.feedback_wrong} type='hidden' name="feedback_wrong"/>


                    <Header as='h4' color='orange'>Feedback when wrong (ID)</Header>
                    <ReactQuill name="feedback_wrong_id" theme="snow" value={this.state.feedback_wrong_id} onChange={(e) => this.setState({feedback_wrong_id: e})} />
                    <Input value={this.state.feedback_wrong_id} type='hidden' name="feedback_wrong_id"/>


                    <Header as='h4' color='green'>Feedback when correct (EN)</Header>
                    <ReactQuill name="feedback_correct" theme="snow" value={this.state.feedback_correct} onChange={(e) => this.setState({feedback_correct: e})} />
                    <Input value={this.state.feedback_correct} type='hidden' name="feedback_correct"/>


                    <Header as='h4' color='green'>Feedback when correct (ID)</Header>
                    <ReactQuill name="feedback_correct_id" theme="snow" value={this.state.feedback_correct_id} onChange={(e) => this.setState({feedback_correct_id: e})} />
                    <Input value={this.state.feedback_correct_id} type='hidden' name="feedback_correct_id"/>

                </td>
            </>
        )
    }
}

export default Module33Form