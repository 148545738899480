import React from 'react'
import {connect} from 'react-redux'
import { Header, Icon, Divider, Loader, Card, Grid, Image } from 'semantic-ui-react'
import EmptyComponent from '../../components/empty'
import storage from 'redux-persist/lib/storage';
import './style.scss'
import CardComponent from '../../components/card';
import { Link } from 'react-router-dom'
import { filterSubjectRedux } from '../../helpers/redux';

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth,
        add_subject_step: state.add_subject_step
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        addSubjectStepAction: (n) => dispatch({type: 'CURRENT_SUBJECT', payload: n}),
    }
}

class MainSearchPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            search: localStorage.getItem('main_search'),
            loading: true,
            searchResult: {}
        }
    }

    componentDidMount(){
        let param = encodeURIComponent(this.state.search);
        fetch(process.env.REACT_APP_API_URL+'api/v1/search?keyword='+param, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                localStorage.removeItem('main_search')
                window.location.href = '/'
            }

            this.setState({loading: false, searchResult: res.data})
        })
    }

    loadSubject = (data, e) =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/'+data.subject_code, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            
            this.props.addSubjectStepAction(filterSubjectRedux(this.props.add_subject_step, res.data))
            if(data.chapter_mini_game_id){
                window.location.href = '/subject/'+data.subject_code+'/'+data.chapter_mini_game_id+'/question?id='+data.question_id
            }else{
                window.location.href = '/subject/'+data.subject_code+'/'+data.topic_id+'/question?id='+data.question_id
            }
        })
    }

    render() {
        return (
            <div>
                <p style={{fontSize: 18}}>Search Result for “<b>{this.state.search}</b>”</p>
                <Divider hidden/>
                <div className='searchHeader'>
                    <Header as='h2'>
                        <Icon name='file alternate' />
                        <Header.Content>Entry</Header.Content>
                    </Header>
                    {!this.state.loading ? 
                        this.state.searchResult.entries.length > 3 ? 
                        <Link to='/search/entry'>See All</Link>
                        : null
                    : null}
                    
                </div>
                <Divider/>
                {this.state.loading ? <Loader active inline='centered'/> : 
                    this.state.searchResult.entries.length > 0 ? 
                    <Card.Group itemsPerRow={2} className='entryCard'>
                        {this.state.searchResult.entries.map((v,k)=>
                            <Card raised key={k} onClick={this.loadSubject.bind(null, v)} link>
                                <Card.Content header={
                                    v.subject_name + ' / ' + 
                                    (
                                        v.chapter_mini_game_id ?
                                        v.chapter_name+' / '+v.mini_game_name :
                                        v.topic_name_tree
                                    )
                                    } />
                                <div className="content">
                                    <div className="description" dangerouslySetInnerHTML={{__html:v.highlight}}/>
                                </div>
                            </Card>
                        )}
                    </Card.Group>
                    : <EmptyComponent/>
                }
                <Divider hidden/>

                <div className='searchHeader'>
                    <Header as='h2'>
                        <Icon name='folder' />
                        <Header.Content>Subject</Header.Content>
                    </Header>
                    {!this.state.loading ? 
                        this.state.searchResult.subjects.length > 4 ? 
                        <Link to={'/browse?search='+this.state.search}>See All</Link>
                        : null
                    : null}
                </div>
                <Divider/>
                {this.state.loading ? <Loader active inline='centered'/> : 
                    this.state.searchResult.subjects.length > 0 ? 
                    <CardComponent data={this.state.searchResult.subjects} size={5} type='thumbnail'/>
                    : <EmptyComponent/>
                }
                <Divider hidden/>

                <div className='searchHeader'>
                    <Header as='h2'>
                        <Icon name='user' />
                        <Header.Content>User</Header.Content>
                    </Header>
                    {!this.state.loading ? 
                        this.state.searchResult.users.length > 4 ? 
                        <Link to='/search/user'>See All</Link>
                        : null
                    : null}
                </div>
                <Divider/>
                {this.state.loading ? <Loader active inline='centered'/> : 
                    this.state.searchResult.users.length > 0 ? 
                    <Grid className='userSection'>
                        <Grid.Row columns={5}>
                        {this.state.searchResult.users.map((v, k)=>
                            <Grid.Column key={k} as={Link} to={'/profile/'+v.username}>
                                <Image circular src={
                                    v.avatar !== null && v.avatar !== '' ? v.avatar
                                    : require('../../assets/images/avatar-default.png')
                                }/>
                                <Header as='h3' textAlign='center'>
                                    {v.username}{v.verified ? <Icon name='check circle' color='green' size='small'/> : null}
                                </Header>
                            </Grid.Column>
                        )}
                        </Grid.Row>
                    </Grid>
                    : <EmptyComponent/>
                }
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MainSearchPage)