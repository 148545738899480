export const navReducer = (state = 'null', action) =>{
    switch (action.type) {
        case 'CHANGE_NAV':
            return state = action.payload
        default:
            return state
    }
}

export const subNavReducer = (state = 'null', action) =>{
    switch (action.type) {
        case 'CHANGE_SUB_NAV':
            return state = action.payload
        default:
            return state
    }
}

export const setTaskManager = (state = false, action) =>{
    switch (action.type) {
        case 'SET_TASK_MANAGER':
            return state = action.payload
        default:
            return state
    }
}
