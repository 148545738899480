import React from 'react'
import { Divider,  Button, Loader, Grid, Header } from 'semantic-ui-react'
import {toast} from 'react-toastify'
import storage from 'redux-persist/lib/storage'
import {connect} from 'react-redux'
import NotifText from '../../components/notif_text'
import EmptyComponent from '../../components/empty'
import * as Sentry from "@sentry/browser";

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth
    }
}

class NotificationPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            notifList: [],
            activePage: 1,
            isEmpty: false
        }
    }

    divRef = null

    componentDidMount(){
        this.loadData()
    }

    loadData = (activePage = 1) =>{
        this.setState({loading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/notifications', {
            method: 'post',
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            body: JSON.stringify({
                n: 10,
                page: activePage
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            let oldData = this.state.notifList
            let data = []
            let isEmpty = false

            if(res.data.length === 0 || res.data.length < 10){
                isEmpty = true
            }

            data = oldData.concat(res.data)

            this.setState({notifList: data, loading: false, isEmpty: isEmpty})

            if(activePage > 1){
                this.divRef.scrollTop = this.divRef.scrollHeight;
            }
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    loadMore = () =>{
        this.setState({activePage: this.state.activePage + 1})
        this.loadData(this.state.activePage + 1)
    }

    render() {
        return (
            <>
            <Header as='h1'>All Notifications</Header>
            <div className='ui segment' style={{height: '75vh', overflow:'auto'}} ref={(el) => { this.divRef = el }}>
            {this.state.loading ? <Loader active/>
            : 
                this.state.notifList.length > 0 ?
                <>
                    <Grid columns='equal'>
                    {this.state.notifList.map((v,k)=>
                        <NotifText type='all' data={v} key={k}/>
                    )}
                    </Grid>

                    <Divider hidden/>
                    {!this.state.isEmpty ? 
                        <div style={{textAlign: 'center'}}>
                            <Button loading={this.state.loading}
                            onClick={this.loadMore} primary>
                                Load More
                                </Button>
                        </div>
                    : null}
                </>
                : <EmptyComponent size='tiny'/>
            }
            </div>
            </>
        )
    }
}

export default connect(mapStateToProps)(NotificationPage)