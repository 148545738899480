import React from 'react'
import { Container, Image, Divider, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'

// display when chapter is not available
const EmptyChapterComponent = (props) =>{
    return(
        <Container text className='empty_section'>
            <Image centered src={require('../../assets/images/empty.png')}/>
            <p style={{color:'grey'}}>No chapters are available.</p>
            <Divider hidden/>
            {props.subject.role === 3 ? 
                <Link id='btn_add_chapter' to={'/chapter/add/'+props.subject.code} className='ui primary button' style={{marginLeft:10}}>
                    <Icon name='add'/> Add a New One
                </Link>
                : ''
            }
        </Container>
    )
}

export default EmptyChapterComponent