import React from 'react';
import { Container, Divider, Checkbox, Grid } from 'semantic-ui-react';
import {connect} from 'react-redux';
import GeneralStatistic from './general_statistic';
import {DatesRangeInput} from 'semantic-ui-calendar-react';
import ActiveUsers from './active_users';
import PlayedSubjects from './played_subjects';
import DailyAnalytics from './daily_analytics';
import SubjectDistribution from './subject_distribution';

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        auth: state.auth
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setUpdateAdminDashboard: (n) => dispatch({ type: 'DASHBOARD_UPDATE', payload: n })
    }
}

class DashboardPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            dateRange: '',
            paramDate: {
                start: '',
                end: '',
                all_time: true
            }
        }
    }

    componentDidMount(){
        this.props.setUpdateAdminDashboard({
            query: ''
        })
    }

    handleDateFilter = (event, {name, value}) => {
        let date = this.state.paramDate
        let val = value.split('-')
        for (let i = 0; i < val.length; i++) {
            val[i] =  val[i].trim()           
        }
        let start = val[0]
        let end = val[1]
        if(start !== '' && end !== ''){
            date.start = start.replace('/', '-')
            date.start = date.start.replace('/', '-')
            date.end = end.replace('/', '-')
            date.end = date.end.replace('/', '-')
            date.all_time = false
            this.setState({paramDate: date})
            this.props.setUpdateAdminDashboard({
                query: '?start='+date.start+'&end='+date.end
            })
        }
        this.setState({dateRange: value})
    }

    checkBoxHandling = (e, data) =>{
        let param = this.state.paramDate
        param.start = ''
        param.end = ''
        param.all_time = data.checked
        this.setState({paramDate: param, dateRange: ''})
        if(data.checked){
            this.props.setUpdateAdminDashboard({
                query: ''
            })
        }
    }

    render(){
        return(
            <Container className='dashboardContainer' style={{paddingTop: 80, minHeight: '66.5vh'}}>
                <GeneralStatistic auth={this.props.auth}/>
                <Divider/>
                <div className='FilterSection'>
                    <span>Date Filter</span>
                    <DatesRangeInput
                    maxDate={new Date()}
                    animation='scale'
                    duration={200}
                    hideMobileKeyboard
                    iconPosition='right'
                    autoComplete='off'
                    dateFormat='YYYY/MM/DD'
                    placeholder="Start to End"
                    value={this.state.dateRange}
                    onChange={this.handleDateFilter}
                    closable
                    />
                    <Checkbox label='All Time' checked={this.state.paramDate.all_time} 
                    onChange={this.checkBoxHandling}/>
                </div>
                <Divider hidden/>
                <Grid columns={2}>
                    <Grid.Column>
                        <ActiveUsers auth={this.props.auth}/>
                    </Grid.Column>
                    <Grid.Column>
                        <PlayedSubjects auth={this.props.auth}/>
                    </Grid.Column>
                </Grid>
                <Divider hidden/>
                <DailyAnalytics auth={this.props.auth}/>
                <Divider hidden/>
                <SubjectDistribution auth={this.props.auth}/>
            </Container>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage)