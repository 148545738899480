import React from 'react';
import { Header, Icon, Segment, Grid, Statistic, Dimmer, Loader, Popup } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import storage from 'redux-persist/lib/storage';
import { kFormatter } from '../../../helpers/file_size';

class GeneralStatistic extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            plays: 0,
            entries: 0,
            subjects: 0,
            users: 0,
            loading: true
        }
    }

    componentDidMount(){
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/dashboard/general-statistics', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({loading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                this.setState({entries: res.data.total_entries, plays: res.data.play_session, 
                    subjects: res.data.total_subject, users: res.data.total_user})
            }
        }).catch((e)=>{
            toast.error('General statistic not found.')
        })
    }

    render(){
        return (
            <>
                <Header as='h3'>
                    <Icon name='chart bar'/> General Statistic
                </Header>
                <Segment>
                    <Dimmer inverted active={this.state.loading}>
                        <Loader active/>
                    </Dimmer>
                    <Grid columns={4} divided textAlign='center'>
                        <Grid.Column>
                            <Statistic>
                                {this.state.users >  999 ? 
                                <Popup content={this.state.users} inverted hoverable basic
                                trigger={<Statistic.Value>{kFormatter(this.state.users)}</Statistic.Value>}/>
                                : 
                                <Statistic.Value>{kFormatter(this.state.users)}</Statistic.Value>
                                }
                                <Statistic.Label>
                                    <Icon name='users'/> Total Users
                                </Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                        <Grid.Column>
                            <Statistic>
                                {this.state.subjects >  999 ? 
                                <Popup content={this.state.subjects} inverted hoverable basic
                                trigger={<Statistic.Value>{kFormatter(this.state.subjects)}</Statistic.Value>}/>
                                : 
                                <Statistic.Value>{kFormatter(this.state.subjects)}</Statistic.Value>
                                }
                                <Statistic.Label>
                                    <Icon name='folder'/> Total Subjects
                                </Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                        <Grid.Column>
                            <Statistic>
                                {this.state.entries >  999 ? 
                                <Popup content={this.state.entries} inverted hoverable basic
                                trigger={<Statistic.Value>{kFormatter(this.state.entries)}</Statistic.Value>}/>
                                : 
                                <Statistic.Value>{kFormatter(this.state.entries)}</Statistic.Value>
                                }
                                <Statistic.Label>
                                    <Icon name='clipboard list'/> Total Entries
                                </Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                        <Grid.Column>
                            <Statistic>
                                {this.state.plays >  999 ? 
                                <Popup content={this.state.plays} inverted hoverable basic
                                trigger={<Statistic.Value>{kFormatter(this.state.plays)}</Statistic.Value>}/>
                                : 
                                <Statistic.Value>{kFormatter(this.state.plays)}</Statistic.Value>
                                }
                                <Statistic.Label>
                                    <Icon name='play'/> Play Sessions at DOC
                                </Statistic.Label>
                            </Statistic>
                        </Grid.Column>
                    </Grid>
                </Segment>
            </>
        )
    }
}

export default GeneralStatistic