import React from 'react'
import { Container, Icon, Divider, Loader, Button } from 'semantic-ui-react'
import {connect} from 'react-redux'
import { toast } from 'react-toastify'

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        loginAction: (n) => {
            let state = {
                is_logged: true,
                user: n
            }
            dispatch({ type: 'LOGIN', payload: state })
        }
    }
}

class VerifyPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            authData: null,
            isValid: false
        }
    }

    componentDidMount(){
        fetch(process.env.REACT_APP_API_URL+'api/v1/auth/verify/'+this.props.match.params.token)
        .then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({loading: false, authData: res.data, isValid: res.status})
        })
    }

    setAuth = () =>{
        if(this.state.authData !== null){
            this.props.loginAction(this.state.authData)
            setTimeout(()=>{
                window.location.href='/completion'
            }, 1000)
        }else{
            toast.error('Cannot login with toke key, you will redirect to landing page in 3 seconds.')
            setTimeout(()=>{
                window.location.href='/'
            }, 3000)
        }
    }

    render() {
        return (
            <Container textAlign='center' style={{marginTop:100, minHeight: '66.5vh'}}>
                {this.state.loading ? <Loader active/> : 
                this.state.isValid ? 
                <div>
                    <h1>Congratulation!</h1>
                    <Icon circular size='huge' inverted color='green' name='check' />
                    <h3>Your account is verified. Please click button below to access content+</h3>
                    <Divider hidden/>
                    <Button positive size='large' onClick={this.setAuth}>Go to Content+</Button>
                </div>
                :
                <div>
                    <h1>Token is invalid or expired!</h1>
                    <Icon circular size='huge' inverted color='red' name='remove' />
                    <h3>Please click button below to go to content+ page</h3>
                    <Divider hidden/>
                    <a className='ui primary button' href='/' size='large'>
                        Go to Content+
                    </a>
                </div>
                }
            </Container>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(VerifyPage)