import React from 'react'
/* Fields */
import { Input } from '../fields'

// module 2 form
const Module2Form = (props) =>{
    return <>
        <td>
            <Input label='Word' name="word" required placeholder='Exp: English' wlength={20}/>
        </td>
    </>
}

export default Module2Form