import React from 'react';
import { Header, Divider, Segment, Container, Icon, Loader } from 'semantic-ui-react';

class PrivacyPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading: true,
            tos: ''
        }
    }

    componentDidMount(){
        fetch(process.env.REACT_APP_API_URL+'api/v1/statics/privacy')
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            this.setState({loading: false, tos: res.data})
        })
    }
    render(){
        return(
            <Container style={{paddingTop: 80, paddingBottom: 80, minHeight: '66.5vh'}}>
                <Header as='h1'>
                    <Icon name='file alternate'/>Privacy Policy
                </Header>
                <Divider/>
                {this.state.loading ? <Loader active/> : 
                    <Segment dangerouslySetInnerHTML={{__html:this.state.tos}}/>
                }
            </Container>
        )
    }
}

export default PrivacyPage