import React from 'react';
import { Divider, Image, Button } from 'semantic-ui-react';
import LoginForm from '../../forms/auth/login';
import { FormProvider } from 'react-advanced-form'
import messages from '../../forms/auth/validate_message';
import rules from '../../forms/auth/validate_rule';
import { GoogleLogin } from 'react-google-login';
import {connect} from 'react-redux'
import './style.scss'

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        loginAction: (n) => {
            let state = {
                is_logged: true,
                user: n
            }
            dispatch({ type: 'LOGIN', payload: state })
        },
        navAction: (n) => dispatch({ type: 'CHANGE_NAV', payload: n })
    }
}

class LoginPage extends React.Component{
    responseGoogle = (response) => {
        let googleResponse = response.profileObj
        if(googleResponse !== undefined){
            fetch(process.env.REACT_APP_API_URL+'api/v1/auth/login/google', {
                method: 'post',
                body: JSON.stringify({
                    username: googleResponse.email,
                    googleId: googleResponse.googleId,
                    tokenId: response.tokenId
                })
            })
            .then((res)=>{
                return res.json()
            })
            .then((res)=>{
                if(res.status){
                    this.props.loginAction(res.data)
                    this.props.navAction('subject')
                }
            })
        }
    }

    render() {
        return (
            <div className="ui middle aligned container" style={{minHeight: '100vh', width: 400}}>
            <div className="ui middle aligned grid" style={{height:'100vh'}}>
                <div className="row">
                    <div className="column">
                        <div className="ui segment">
                            <Image style={{width: 200}} centered src={require('../../assets/images/content.png')}/>
                            <Divider hidden/>
                            <div className='loginHeader'>
                                <span className='left'>Log In</span>
                                <a rel='link' className='link' href='/register'>Sign Up</a>
                            </div>
                            <Divider hidden/>
                            <FormProvider rules={rules} messages={messages}>
                                <LoginForm/>
                            </FormProvider>
                            <Divider/>
                            <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                render={renderProps => (
                                    <Button className='google' onClick={renderProps.onClick} disabled={renderProps.disabled} 
                                    fluid>
                                        <div>
                                        <img alt='google' src={require('../../assets/images/google.png')}/>
                                        Log In with Google
                                        </div>
                                    </Button>
                                )}
                                buttonText="Login"
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogle}
                            />
                            <Divider hidden/>
                            <p className='footerTextLogin'>Didn’t have an account? <a rel='link' className='link' href='/register'>Sign Up</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)