import React from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Segment, Header, Icon, Dimmer, Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import storage from 'redux-persist/lib/storage';
import EmptyComponent from '../../../components/empty';
import {connect} from 'react-redux';

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        dashboard_update: state.dashboard_update
    }
}

class DailyAnalytics extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            data: [],
            options: {
                title: {
                  text: ''
                },
                credits: {
                    enabled: false
                },
                xAxis: {
                    categories: [],
                    crosshair: true
                },
                yAxis: [{ // Primary yAxis
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    },
                    title: {
                        text: 'Values',
                        style: {
                            color: Highcharts.getOptions().colors[1]
                        }
                    }
                }, { // Secondary yAxis
                    title: {
                        text: 'Values',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    labels: {
                        format: '{value}',
                        style: {
                            color: Highcharts.getOptions().colors[0]
                        }
                    },
                    opposite: true
                }],
                tooltip: {
                    shared: true
                },
                series: []
            }
        }
    }

    componentDidMount(){
        this.loadData()
    }

    componentDidUpdate(oldProps, oldState){
        if(oldProps.dashboard_update !== this.props.dashboard_update){
            this.loadData(this.props.dashboard_update.query)
        }
    }

    loadData = (query = '') =>{
        this.setState({loading: true, data: []})
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/dashboard/daily-analytics'+query, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({loading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                let opt = this.state.options
                let cat = []
                let entry_added = []
                let entry_verified = []
                let new_user = []
                let subject_added = []

                res.data.forEach(x=>{
                    cat.push(x.date)
                    entry_added.push(x.entries_made)
                    entry_verified.push(x.entries_verified)
                    new_user.push(x.new_users)
                    subject_added.push(x.subjects_made)
                })

                opt.xAxis.categories = cat
                opt.series = [
                    {name: 'Numbers of entries made', yAxis: 1, data: entry_added},
                    {name: 'Numbers of entries verified', yAxis: 1, data: entry_verified},
                    {name: 'Numbers of subjects made', data: subject_added},
                    {name: 'Numbers of new users', data: new_user},
                ]

                this.setState({data: res.data, options: opt})
            }
        }).catch((e)=>{
            toast.error('Daily analytics not found.')
        })
    }

    render(){
        return(
            <>
            <Header as='h3'>
                <Icon name='line graph'/> Daily Analytics
            </Header>
            <Segment>
                <Dimmer inverted active={this.state.loading}>
                    <Loader active/>
                </Dimmer>
                {this.state.data.length > 0 || this.state.loading ? <HighchartsReact
                    highcharts={Highcharts}
                    options={this.state.options}
                /> : <EmptyComponent/>}
            </Segment>
            </>
        )
    }
}

export default connect(mapStateToProps)(DailyAnalytics)