// redux action of add subject step
export const addSubjectStepAction = (n) =>{
    return{
        type: 'CURRENT_SUBJECT',
        payload: n
    }
}

// redux action of filterSubject
export const filterSubject = (n) =>{
    return{
        type: 'FILTER_SUBJECT',
        payload: n
    }
}

// redux action of loadSubject
export const loadSubject = (n) =>{
    return{
        type: 'SUBJECT_LOAD',
        payload: n
    }
}

// redux action of setPreqSubject
export const setPreqSubject = (n) =>{
    return{
        type: 'PREQ_SUBJECT',
        payload: n
    }
}