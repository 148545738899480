import React from 'react'
/* Fields */
import {Input} from '../fields'

const ChangePasswordForm = () =>{
    return(
        <div>
            <Input type='password' label='Old Password'
            name="old_password"
            required placeholder='Type your old password...'/>

            <Input type='password' label='New Password'
            name="new_password"
            required placeholder='Type your new password...'/>

            <Input type='password' label='Confirm Password'
            name="confirm_password"
            required placeholder='Type your confirm password...'/>
        </div>
    )
}

export default ChangePasswordForm