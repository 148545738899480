import React from 'react'
import { Grid, Button, Icon, Divider, Table, Pagination, Dropdown,
Modal,
Loader,
Container,
Input} from 'semantic-ui-react'
import '../style.scss'
import {connect} from 'react-redux'
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        auth: state.auth
    }
}

class LegalPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            legalList: [],
            loading: true,
            filter: {
                page: 1,
                n: 10,
                search: ''
            },
            totalPage: 0,
            totalRecord: 0,
            formModal: false,
            loadingSave: false,
            selectedLegal: null,
            content: ''
        }
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/legal', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify(this.state.filter)
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            this.setState({legalList: res.data, loading: false, 
                totalPage: res.total_page, 
                totalRecord: res.total_record})
        }).catch((e)=>{
            toast.error('something wrong happened on getting legals data.')
        })
    }

    handleChangePagination = (e, data) =>{
        let filter = this.state.filter
        filter.page = data.activePage
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    handleChangeEntries = (e, data) =>{
        let filter = this.state.filter
        filter.n = data.value
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    handleFilter = (e, data) =>{
        let filter = this.state.filter
        filter.search = data.value
        this.setState({filter: filter})
        setTimeout(()=>{
            this.loadData()
        }, 200)
    }

    renderNumber = (k)=>{
        let n = this.state.filter.n * this.state.filter.page
        n = n - this.state.filter.n + k + 1
        return n
    }

    saveLegal = () =>{
        this.setState({loadingSave: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/legal/update/'+this.state.selectedLegal.type, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify({content: this.state.content})
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                toast.success(this.state.selectedLegal.page_name+' saved.')
                this.setState({selectedLegal: null, loadingSave: false, formModal: false})
                this.loadData()
            }else{
                toast.error(this.state.selectedLegal.page_name+' cannot be saved.')
            }
        }).catch((e)=>{
            toast.error('something wrong happened on saving legals.')
        })
    }

    handleFormChange = (value) =>{
        this.setState({content: value})
    }

    render() {
        return (
            <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                {this.state.loading ? <Loader active/> :
                    <>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <div className="ui icon rounded input">
                                    <Input type="text" icon='search' name='search' onChange={this.handleFilter}
                                    placeholder="Search a word or sentences..."/>
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Table fixed sortable className='admin' striped>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}>
                            No
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Legal Type
                            </Table.HeaderCell>
                            <Table.HeaderCell width={2}>
                            Action
                            </Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {this.state.legalList.map((v, k)=>
                        <Table.Row key={k} className={v.banned ? 'banned' : ''}>
                            <Table.Cell>{this.renderNumber(k)}</Table.Cell>
                            <Table.Cell>{v.page_name}</Table.Cell>
                            <Table.Cell>
                                <Button size='mini' secondary
                                onClick={()=>this.setState({formModal: true, selectedLegal: v, content: v.content})}>
                                    <Icon name='pencil'/> Edit
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                        )}
                        </Table.Body>
                    </Table>
                    
                    
                    {/* pagination */}
                    <Divider hidden/>
                    <div>
                        Show <Dropdown defaultValue={10} compact selection options={[
                            {key: 10, text: '10', value: 10},
                            {key: 25, text: '25', value: 25},
                            {key: 50, text: '50', value: 50},
                            {key: 100, text: '100', value: 100},
                        ]} onChange={this.handleChangeEntries} /> entries
                        <div className='pagination'>
                            <span style={{marginRight: 10}}>
                                Showing {this.state.legalList.length} of {this.state.totalRecord} entries
                            </span>
                            <Pagination activePage={this.state.filter.page} size='small' onPageChange={this.handleChangePagination} 
                            totalPages={this.state.totalPage} />
                        </div>
                    </div>
                    </>
                }

                <Modal open={this.state.formModal}>
                    {this.state.selectedLegal !== null ? 
                    <Modal.Header>Edit {this.state.selectedLegal.page_name}
                    </Modal.Header> : null}
                    <Modal.Content>
                        <ReactQuill theme="snow" value={this.state.content} onChange={this.handleFormChange}/>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className='tertiary' disabled={this.state.loadingSave}
                        onClick={()=>this.setState({formModal: false, selectedFAQ: null, form:{
                            question: '',
                            answer: '',
                            image: '',
                        }})}>Cancel</Button>
                        <Button primary onClick={this.saveLegal} disabled={this.state.loadingSave}
                        loading={this.state.loadingSave}>Save Changes</Button>
                    </Modal.Actions>
                </Modal>
            </Container>
        )
    }
}

export default connect(mapStateToProps)(LegalPage)