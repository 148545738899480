import React from 'react';
import { Header, Icon, Table, Button, Segment, Dimmer, Modal, Loader } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import storage from 'redux-persist/lib/storage';
import EmptyComponent from '../../../components/empty';
import {connect} from 'react-redux';

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        dashboard_update: state.dashboard_update
    }
}

class SubjectDistribution extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            data: []
        }
    }

    componentDidMount(){
        this.loadData()
    }

    componentDidUpdate(oldProps, oldState){
        if(oldProps.dashboard_update !== this.props.dashboard_update){
            this.loadData(this.props.dashboard_update.query)
        }
    }

    loadData = (query = '') =>{
        this.setState({loading: true, data: []})
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/dashboard/subject-distribution-category'+query, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({loading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                this.setState({data: res.data})
            }
        }).catch((e)=>{
            toast.error('Subject not found.')
        })
    }

    render(){
        return(
            <>
            <Header as='h3'>
                <Icon name='pie chart'/> Subject Distribution Per Category
            </Header>
            <Segment className='subjectDist'>
                <Dimmer active={this.state.loading} inverted>
                    <Loader active/>
                </Dimmer>
                {this.state.loading || this.state.data.length > 0 ? 
                <Table>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>No.</Table.HeaderCell>
                            <Table.HeaderCell>Category</Table.HeaderCell>
                            <Table.HeaderCell>Most Used Sub-Category</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>Subject on This Category</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>Action</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {this.state.data.map((v,k)=>
                        <Table.Row key={k}>
                            <Table.Cell>{k+1}</Table.Cell>
                            <Table.Cell>{v.category_name}</Table.Cell>
                            <Table.Cell>{v.most_used_sub_category}</Table.Cell>
                            <Table.Cell textAlign='right'>{v.subject_count}</Table.Cell>
                            <Table.Cell textAlign='right'>
                                {v.sub_categories.length > 0 ? 
                                <Modal closeIcon size='tiny' trigger={
                                    <Button secondary size='mini'>
                                        <Icon name='eye'/> View Subcategory
                                    </Button>
                                }>
                                    <Modal.Content>
                                    <Table>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>No.</Table.HeaderCell>
                                                <Table.HeaderCell>Subcategory Name</Table.HeaderCell>
                                                <Table.HeaderCell textAlign='right'>Subject Use on Category</Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {v.sub_categories.map((x,y)=>
                                                <Table.Row key={y}>
                                                    <Table.Cell>{y+1}</Table.Cell>
                                                    <Table.Cell>{x.sub_category_name}</Table.Cell>
                                                    <Table.Cell textAlign='right'>{x.sub_category_count}</Table.Cell>
                                                </Table.Row>
                                            )}
                                        </Table.Body>
                                    </Table>
                                    </Modal.Content>
                                </Modal>
                                : <Button disabled secondary size='mini'>
                                    <Icon name='eye'/> View Subcategory
                                </Button>}
                            </Table.Cell>
                        </Table.Row>
                        )}
                    </Table.Body>
                </Table>
                : <EmptyComponent/>}
            </Segment>
            </>
        )
    }
}

export default connect(mapStateToProps)(SubjectDistribution)