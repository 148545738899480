import { authReducer, userUpdated } from './auth'
import {navReducer, subNavReducer, setTaskManager} from './nav'
import { addSubjectReducer, filterSubjectReducer, loadSubjectReducer, setPreqSubject,
    setPreqSubjectSelected, chapterStepActive, chapterRequestData, isNewQuestion, isBannerLoading, addSubjectListReducer,
isRCQuestionOpen, bannerData } from './subject'
import { loadContributorReducer } from './contributor'
import { combineReducers } from 'redux'

const setUpdateAdminDashboard = (state = {}, action) =>{
    switch (action.type) {
        case 'DASHBOARD_UPDATE':
                return state = action.payload
        default:
            return state
    }
}

// combine all reducers into one group state
const allReducers = combineReducers({
    auth: authReducer,
    nav: navReducer,
    subnav: subNavReducer,
    add_subject_step: addSubjectReducer,
    filter_subject: filterSubjectReducer,
    is_subject_load: loadSubjectReducer,
    is_contributor_load: loadContributorReducer,
    preq_subject: setPreqSubject,
    preq_subject_selected: setPreqSubjectSelected,
    chapter_step_active: chapterStepActive,
    chapter_request_data: chapterRequestData,
    is_new_question: isNewQuestion,
    is_banner_loading: isBannerLoading,
    user_updated: userUpdated,
    task_manager_called: setTaskManager,
    dashboard_update: setUpdateAdminDashboard,
    list_subject: addSubjectListReducer,
    is_rc_question_open: isRCQuestionOpen,
    banner_data: bannerData
})

export default allReducers