export const filterSubjectRedux = (state, data) => {
    let currentSubject = state
    if(currentSubject !== undefined && currentSubject !== null){
        let i_exist = currentSubject.findIndex(x=>x.code === data.code)
        if(i_exist !== -1){
            currentSubject[i_exist] = data
        }else{
            currentSubject.push(data)
        }
    }else{
        currentSubject = []
        currentSubject.push(data)
    }

    return currentSubject
}

export const getSubjectRedux = (state, code)=>{
    let subject = null
    let currentSubject = state
    if(currentSubject !== undefined && currentSubject !== null){
        let i_exist = currentSubject.findIndex(x=>x.code === code)
        if(i_exist !== -1){
            subject = currentSubject[i_exist]
        }
    }
    
    return subject
}