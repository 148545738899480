import React from 'react'
import { Icon, Image, Card } from 'semantic-ui-react';
import './style.scss'
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { addSubjectStepAction } from '../../store/actions/subject'
import { filterSubjectRedux } from '../../helpers/redux';

// card component used by subject, browser
const CardComponent = (props) =>{
    const dispatch = useDispatch()
    let count = props.data
    let defImg = require('../../assets/images/default-cover.jpg')
    defImg = defImg+'?'+new Date().getTime()
    let add_step = useSelector(x=>x.add_subject_step)


    const handleClick = (data, e) =>{
        dispatch(addSubjectStepAction(filterSubjectRedux(add_step, data)))
    }

    // general card style type
    if(props.type !== undefined){
        // for cover image preview
        if(props.type === 'single'){
            return(
                count.map((v, k)=>
                    <Card style={{width:280}} className='ui card wrapper_card' key={k}>
                        <div></div>
                        <div className='cover' style={{background: 'url('+(v.image !== null ? v.image+'?'+ new Date().getTime() : defImg)+')'}}></div>
                        <div style={{height:'50%'}}>
                            <p>{v.name}{v.verified ? <Icon name='check circle' color='green'/> : null} {v.private ? <Icon name='lock'/>:''}</p>
                            <p className='subject_desc'>{v.category_name}</p>
                            <div className='extras'><span><Icon name='play circle'/> 0 times</span>
                            <span><Icon name='user'/> {v.contributors} contributors</span></div>
                        </div>
                        <div className='footer'>
                            <div className='cont_ava'>
                                <Image avatar src={v.owner_avatar !== null ? v.owner_avatar : 
                                require('../../assets/images/avatar-default.png')}/> 
                                <b>{v.owner_username}</b>{v.owner_official ? <Icon size='small' name='check circle' color='green'/> : null}
                            </div>
                            <div className='date_sub'>
                                <Icon name='calendar'/> <b>{v.created}</b>
                            </div>
                        </div>
                        <div></div>
                    </Card>
                )
            )
        }else if(props.type === 'thumbnail'){
            let size = props.size !== undefined ? props.size : 3
            return(
                <Card.Group itemsPerRow={size}>
                {
                    count.map((v, k)=>
                    <Link onClick={handleClick.bind(null, v)} to={'/subject/detail/'+v.code+'/content'} 
                    className='ui card wrapper_card simple' key={k}>
                        <div></div>
                        <div className='cover-simple' 
                        style={{background: 'url('+(v.image !== null ? v.image+'?'+new Date().getTime() : defImg)+') no-repeat center center'}}/>
                        <div>
                            <p>{v.name}{v.verified ? <Icon name='check circle' color='green'/> : null}</p>
                            <p className='subject_desc'>{v.category_name}</p>
                        </div>
                    </Link>
                    )
                }
                </Card.Group>
            )
        }else{
            // for prerequisite
            return(
                <Card.Group itemsPerRow={3}>
                    {
                        count.map((v, k)=>
                        <div onClick={props.act.bind(null, v.code)} 
                        className={'ui card wrapper_card simple'+ (v.is_selected ? ' active' : '')} key={k}>
                            <div></div>
                            {v.is_selected ? <div className='triangle-topright'>
                                <Icon name='check'/>
                            </div> : ''}
                            <div className='cover-simple' 
                            style={{background: 'url('+(v.image !== null ? v.image+'?'+new Date().getTime() : defImg)+') no-repeat center center'}}/>
                            <div>
                                <p>{v.name}</p>
                                <p className='subject_desc'>{v.category_name}</p>
                            </div>
                        </div>)
                    }
                </Card.Group>
            )
        }
    }
    // for general list of cards
    return(
        <Card.Group itemsPerRow={3}>
            {
                count.map((v, k)=>
                <Link onClick={handleClick.bind(null, v)} to={'/subject/detail/'+v.code+'/content'} className='ui card wrapper_card' key={k}>
                    <div></div>
                    <div className='cover' style={{background: 'url('+(v.image !== null ? v.image+'?'+new Date().getTime() : defImg)+') no-repeat center center'}}></div>
                    {!v.published ? <div className='draft'><span>DRAFT</span></div> : ''}
                    <div style={{height:'50%'}}>
                        <p>{v.name}{v.verified ? <Icon name='check circle' color='green'/> : null} {v.private ? <Icon name='lock'/>:''}</p>
                        <p className='subject_desc'>{v.category_name}</p>
                        <div className='extras'><span><Icon name='play circle'/> 0 times</span>
                        <span><Icon name='user'/> {v.contributors} contributors</span></div>
                    </div>
                    <div className='footer'>
                        <div className='cont_ava'>
                            <Image avatar src={v.owner_avatar !== null ? v.owner_avatar : 
                            require('../../assets/images/avatar-default.png')}/>  
                            <b>{v.owner_username}</b>{v.owner_official ? <Icon size='small' name='check circle' color='green'/> : null}
                        </div>
                        <div className='date_sub'>
                            <Icon name='calendar'/> <b>{v.created}</b>
                        </div>
                    </div>
                    <div></div>
                </Link>)
            }
        </Card.Group>
    )
}

export default CardComponent