import React from 'react'
import { Popup, Icon, List } from 'semantic-ui-react'

const TooltipVariableComponent = (props) =>{
    return(
        <Popup wide defaultOpen={props.open} content={<>
        Here are some helpful tips to input your answers:<br/>
        <List bulleted>
            <List.Item>
            Add your correct answer by clicking on <b>"Add Correct Answer"</b> button below. The answer will be highlighted in green!
            </List.Item>
            <List.Item>
            Press <b>ENTER (↵)</b> key if you want to continue typing words to complete the sentence beside the <b>"Answer"</b>
            </List.Item>
            <List.Item>
            Press <b>Shift + ~ (⇧ + ~)</b> key to create the answer box without clicking on the button
            </List.Item>
            <List.Item>
            Be mindful of the character limit
            </List.Item>
            <List.Item>
            Click on the preview button (eye icon) to see how it looks like in the game
            </List.Item>
        </List>
        </>} position='right center' hoverable
        trigger={<Icon name='question circle' color='blue' link/>} />
    )
}

export default TooltipVariableComponent