import React from 'react';
import {Button, Icon, Loader, Divider,Dropdown, Pagination, Breadcrumb, Message, Segment,
    Checkbox, Popup, Modal, Image, Dimmer, List, Label, TextArea} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import {PreAColumn, PreAColumnHistory, PreAColumnQuestion, 
A1Column, A1ColumnHistory, A1ColumnQuestion, 
A2Column, A2ColumnHistory, A2ColumnQuestion, 
B2Column, B2ColumnHistory, B2ColumnQuestion, 
C1Column, C1ColumnHistory, C1ColumnQuestion} from './diagnostic_table_columns';
import { Form } from 'react-advanced-form';
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify';
import {connect} from 'react-redux';
import messages from '../../../forms/question/validation_message';
import { getSubjectRedux } from '../../../helpers/redux';
import PreAForm from '../../../forms/diagnostic/pre_a';
import A1Form from '../../../forms/diagnostic/a1';
import A2B1Form from '../../../forms/diagnostic/a2_b1';
import B2Form from '../../../forms/diagnostic/b2';
import C1Form from '../../../forms/diagnostic/c1';
import { processDiagnosticQuestionObject } from '../../../helpers/questions';
import * as Sentry from "@sentry/browser";

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        add_subject_step: state.add_subject_step,
        is_new_question: state.is_new_question,
        auth: state.auth
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setIsNewQuestion: (n) => dispatch({ type: 'IS_NEW_QUESTION', payload: n }),
        setBannerLoading: (n) => dispatch({ type: 'IS_BANNER_LOADING', payload: n }),
        setTaskManager: (n) => dispatch({ type: 'SET_TASK_MANAGER', payload: n })
    }
}

class DiagnosticTablePage extends React.Component{
    constructor(props){
        super(props)

        const currentSubject = getSubjectRedux(this.props.add_subject_step, this.props.match.params.code)
        if(currentSubject.role === null && currentSubject.private){
            window.location.href = '/subject/detail/'+currentSubject.code+'/content'
            return false
        }
        
        this.state = {
            currentSubject: currentSubject,
            module_game_id: parseInt(this.props.match.params.mg),
            loading: true,
            loadingBanner: true,
            questionList: [],
            formNew: [],
            questionModal: false,
            topics: [],
            saveLoading: false,
            bannerInfo: null,
            currentQuestionId: null,
            totalRecord: 0,
            totalPage: 0,
            filter:{
                "n": 10,
                "page": 1,
                "search": "",
                "is_review": 0
            },
            is_on_edit: false,
            deleteConfirm: false,
            selectedEntry: {},
            deleteLoading: false,
            previewLoading: false,
            historyList: [],
            history_modal: false,
            comment: '',
            declineModal: false,
            declineID: null
        }
    }

    // decalre ref form
    form = {}

    componentDidMount = () =>{
        this.loadBanner()
        this.loadQuestion()
    }

    componentDidUpdate(prevState, prevProp){
        if(this.state.questionModal || this.state.is_on_edit){
            window.onbeforeunload = function(e) {
                return "Changes you made may not be saved.";
            };
        }else{
            window.onbeforeunload = null;
        }
    }

    loadQuestion = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/diagnostic/questions/'+this.props.match.params.code+'/'+this.props.match.params.mg, {
            headers: this.props.myHeaders,
            body: JSON.stringify(this.state.filter),
            method: 'post'
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            this.setState({loading: false, questionList: res.data, totalRecord: res.total_record, 
                totalPage: res.total_page})

        })
        .catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    loadBanner = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/diagnostic/games/'+this.props.match.params.code+'/'+this.props.match.params.mg, {
            headers: this.props.myHeaders
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            this.setState({loadingBanner: false, formNew: this.renderModuleFormQuestion(this.state.module_game_id, res.data.categories), topics: res.data.categories,
                bannerInfo: res.data})

        })
        .catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // render form based on module
    renderModuleFormQuestion = (mid, topics, data=null) =>{
        let k = []
        if(data !== null){
            k.push(<td key='no'>{data.question_number}</td>)
            switch (mid) {
                // PRE A
                case 1:
                    k.push(<PreAForm data={data} key='fr' topics={topics}/>)
                    break;
                // A1
                case 2:
                    k.push(<A1Form data={data} key='fr' topics={topics}/>)
                    break;
                // B2
                case 5:
                    k.push(<B2Form data={data} key='fr' topics={topics}/>)
                    break;
                // C1
                case 6:
                    k.push(<C1Form data={data} key='fr' topics={topics}/>)
                    break;
                // A2 & B1
                default:
                    k.push(<A2B1Form data={data} key='fr' topics={topics}/>)
                    break;
            }

            k.push(<td key='act'>
                <Button type='button' secondary size='mini' disabled={this.state.saveLoading}
                onClick={this.onCancelClick}>Cancel</Button>
                <Button disabled={this.state.saveLoading} onClick={this.saveButtonClick} type='button'
                loading={this.state.saveLoading} primary size='mini'>
                    <Icon name='save'/> Save
                </Button>
            </td>)
        }else{
            switch (mid) {
                // PRE A
                case 1:
                    k.push(<PreAForm key='fr' topics={topics}/>)
                    break;
                // A1
                case 2:
                    k.push(<A1Form key='fr' topics={topics}/>)
                    break;
                // B2
                case 5:
                    k.push(<B2Form key='fr' topics={topics}/>)
                    break;
                // C1
                case 6:
                    k.push(<C1Form key='fr' topics={topics}/>)
                    break;
                // A2 & B1
                default:
                    k.push(<A2B1Form key='fr' topics={topics}/>)
                    break;
            }
        }
        
        return k
    }

    // render required, review and valid question
    renderMessageInfo = () =>{
        let messageInfo = {}
        let summary = this.state.bannerInfo
        if(summary.required_question !== 0){
            messageInfo = <Message size='mini' error>
                <Icon name='remove circle'/> 
                {summary.required_question} question(s) left to reach the minimum number of questions.
            </Message>
        }else if(summary.review_question !== 0){
            messageInfo = <Message size='mini' warning>
                <Icon name='exclamation triangle'/> {summary.review_question} question(s) are still not approved/declined yet.
            </Message>
        }else{
            messageInfo = <Message size='mini' success>
                <Message.Header>
                    <Icon name='check circle'/> {summary.valid_question} valid questions.
                </Message.Header>
            </Message>
        }
        return messageInfo
    }

    // render table body for history, view changes and table display
    renderTableBody = (data, key) =>{
        if(key === 'topic'){
            let topic = this.state.topics
            topic = topic.find(x=>x.category_id === parseInt(data))
            data = topic.name ? topic.name : data
        }

        let component = <div dangerouslySetInnerHTML={{__html:data}} />

        if(this.state.module_game_id === 6){
            if(key === 'wrong_answer'){
                let wrongList = data.split(',')
                component = <List bulleted>
                    {wrongList.map((v,k)=>
                    <List.Item key={k}>{v}</List.Item>
                    )}
                </List>
            }
        }
        return component
    }

    // save question record
    saveQuestion = ({serialized, fields}) =>{
        const keys = Object.keys(fields)
        let message = []
        let isValid = true
        keys.forEach(k => {
            if(fields[k].invalid){
                message.push(<div>
                    Please check requirement input on <b style={{textTransform:'uppercase'}}>{k}</b>
                </div>)
                isValid = false
            }
        });
        if(!isValid){
            toast.error(<List bulleted>
                {message.map((v,k)=><List.Item key={k}>{v}</List.Item>)}
            </List>)
            return new Promise((resolve) => resolve())
        }
        this.setState({saveLoading: true})
        let q_object = {
            question_object: processDiagnosticQuestionObject(serialized, this.state.module_game_id)
        }

        let url = process.env.REACT_APP_API_URL+'api/v1/diagnostic/questions/'+this.props.match.params.code+'/'+this.props.match.params.mg+'/save'
        if(this.state.is_on_edit){
            url = url+'/'+this.state.currentQuestionId
        }
        fetch(url, {
            headers: this.props.myHeaders,
            method: 'post',
            body: JSON.stringify(q_object)
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            this.setState({saveLoading: false})

            if(res.status){
                let role = this.state.currentSubject.role
                let message = 'Submitted as draft. Waiting for owner/manager approval.'
                if(role === 3){
                    message = 'Entry submitted.'
                }
                toast.success(message)
                
                this.loadBanner()
                this.loadQuestion()

                if(!this.state.is_on_edit){
                    setTimeout(()=>{
                        let filter = this.state.filter
                        filter.page = this.state.totalPage
                        this.setState({filter: filter})
                        this.handleChangePagination(null, {activePage: this.state.totalPage})
                    }, 10)
                }
                this.setState({questionModal: false})
            }else{
                if(res.data.length > 1){
                    toast.error(
                        <div>
                            Fail to submit : <br/>
                            <List bulleted>
                            {res.data.map((v,k)=>
                            <List.Item key={k}>{v}</List.Item>
                            )}
                            </List>
                        </div>
                    )
                }else{
                    if(res.data.length === 1){
                        toast.error('Fail to submit. '+res.data[0])
                    }else{
                        toast.error('Fail to submit. '+res.message)
                    }
                }
            }
        })
        .catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
        
        return new Promise((resolve) => resolve())
    }

    saveButtonClick = () =>{
        let is_error = false
        let inputs = []
        let fields = this.form.state.fields
        Object.keys(fields).forEach(x=>{
            if(!fields[x].valid && fields[x].required){
                is_error = true
                inputs.push(x)
            }
        })
        if(is_error){
            let msg = <p>
                <b>{inputs.slice(0, -1).join(', ')+
                (inputs.length > 1 ? ' and '+inputs.slice(-1) : inputs.join(', '))}</b> {inputs.length > 1 ? 'are' : 'is'} required.
                Please complete the form before submitting your entry!
            </p>
            this.setState({portalOpen: true, portalMessage: msg})
        }
        this.form.submit()
    }

    // when click edit button to load form on row
    onEditClick = (data, e) =>{
        let id = data.question_id
        this.setState({currentQuestionId: id, is_on_edit: true})
        let questions = this.state.questionList
        let idx = questions.findIndex(x=>x.question_id === id)
        let idxo = questions.findIndex(x=>x.is_edit)
        if(idxo !== -1){
            questions[idxo].is_edit = false
        }
        this.setState({questionList: questions})
        
        setTimeout(()=>{
            if(idx !== -1){
                questions[idx].is_edit = true
            }
            this.setState({questionList: questions})

            setTimeout(()=>{
                this.setModuleFormValue(data)
            }, 100)
        }, 100)
    }

    // when click cancel button on edit or add new question process
    onCancelClick = () =>{
        let questions = this.state.questionList
        let idxo = questions.findIndex(x=>x.is_edit)
        if(idxo !== -1){
            questions[idxo].is_edit = false
        }
        this.form.reset()
        this.setState({questionList: questions, currentQuestionId: null, is_on_edit: false})
    }

    // set module default value when click edit
    setModuleFormValue = (data) =>{
        switch (this.state.module_game_id) {
            // PRE A
            case 1:
                this.form.setValues({
                    topic: data.question_object.topic,
                    options: data.question_object.options
                })
                break;
            // A1
            case 2:
                this.form.setValues({
                    topic: data.question_object.topic,
                    category: data.question_object.category,
                    correct_answer: data.question_object.correct_answer,
                    random_words: data.question_object.random_words
                })
                break;
            // B2
            case 5:
                this.form.setValues({
                    topic: data.question_object.topic,
                    sentence: data.question_object.sentence
                })
                break;
            // B2
            case 6:
                let wrong_answer = data.question_object.wrong_answer.split(',')
                this.form.setValues({
                    topic: data.question_object.topic,
                    passage: data.question_object.passage,
                    correct_answer: data.question_object.correct_answer,
                    wrong_answer1: wrong_answer[0] !== undefined ? wrong_answer[0] : '',
                    wrong_answer2: wrong_answer[1] !== undefined ? wrong_answer[1] : '',
                    wrong_answer3: wrong_answer[2] !== undefined ? wrong_answer[2] : ''
                })
                break;
            // A2 & B1
            default:
                this.form.setValues({
                    topic: data.question_object.topic,
                    sentence: data.question_object.sentence,
                    wrong_options: data.question_object.wrong_options
                })
                break;
        }
    }

    // on search table handler
    onChangeSearch = (e) =>{
        let filter = this.state.filter
        filter.search = e.target.value
        filter.page = 1
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadQuestion()
        }, 0)
    }

    // show only question need to review filter
    showReviewContent = (e, data) =>{
        let filter = this.state.filter
        filter.is_review = data.checked ? 1 : 0
        filter.page = 1
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadQuestion()
        }, 0)
    }

    // pagination change handler
    handleChangePagination = (e, data) =>{
        let filter = this.state.filter
        filter.page = data.activePage
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadQuestion()
        }, 0)
    }

    deleteEntry = () =>{
        this.setState({deleteLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/diagnostic/questions/'+this.props.match.params.code+'/'+this.props.match.params.mg+'/delete/'+this.state.selectedEntry.question_id,{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'delete'
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            if(res.status){
                let filter = this.state.filter
                if(this.state.questionList.length === 1){
                    filter.page = this.state.totalPage - 1
                }
                toast.success('Entry deleted.')
                this.setState({deleteLoading: false, deleteConfirm: false, filter: filter})
                this.loadBanner()
                this.loadQuestion()
            }else{
                toast.error(res.message)
            }
        })
        .catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    openHistory = (id, e) =>{
        this.setState({previewLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/diagnostic/questions/'+this.props.match.params.code+'/'+this.props.match.params.mg+'/history/'+id, {
            headers: this.props.myHeaders
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({previewLoading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                this.setState({history_modal: true, historyList: res.data})
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    approveDeclineQuestion = (type, id) =>{
        this.setState({previewLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/diagnostic/questions/'+this.props.match.params.code+'/'+this.props.match.params.mg+'/'+type, {
            headers: this.props.myHeaders,
            method: 'post',
            body: JSON.stringify({
                question_ids: [id],
                comment: this.state.comment
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({previewLoading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                let filter = this.state.filter
                let msg = 'Entry approved.'
                if(type === 'decline'){
                    msg = 'Entry declined.'
                    if(this.state.questionList.length === 1){
                        filter.page = this.state.totalPage - 1
                    }
                }
                toast.success(msg)
                this.loadBanner()
                this.loadQuestion()
                this.setState({declineModal: false, filter: filter})
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    addSuggestedReason = (text, e) =>{
        let comment = this.state.comment
        comment += text+' '
        this.setState({comment: comment})
    }

    render(){
        let col = null
        let colModal = null
        let colForm = null
        if(this.state !== null && this.state.module_game_id !== null){
            switch (parseInt(this.state.module_game_id)) {
                // PRE A
                case 1:
                    col = <PreAColumn/>
                    colModal = <PreAColumnHistory/>
                    colForm = <PreAColumnQuestion/>
                    break;
                // A1
                case 2:
                    col = <A1Column/>
                    colModal = <A1ColumnHistory/>
                    colForm = <A1ColumnQuestion/>
                    break;
                // B2
                case 5:
                    col = <B2Column/>
                    colModal = <B2ColumnHistory/>
                    colForm = <B2ColumnQuestion/>
                    break;
                // C1
                case 6:
                    col = <C1Column/>
                    colModal = <C1ColumnHistory/>
                    colForm = <C1ColumnQuestion/>
                    break;
                // A2 & B1
                default:
                    col = <A2Column/>
                    colModal = <A2ColumnHistory/>
                    colForm = <A2ColumnQuestion/>
                    break;
            }
        }

        let entries = [
            {key: 10, text: '10', value: 10},
            {key: 25, text: '25', value: 25},
            {key: 50, text: '50', value: 50},
            {key: 100, text: '100', value: 100},
        ]

        const suggestedReason = [
            'Not relevant.',
            'Typo(s) detected.',
            'Bad word(s) detected.',
            'Duplicate.'
        ]

        return(
            <div style={{marginBottom: 20}}>
                {/* banner */}
                <Segment style={{padding:0}}>
                    <Dimmer active={this.state.loadBanner}>
                        <Loader active/>
                    </Dimmer>
                    {this.state.bannerInfo !== null ? 
                    <div className='questionBanner'>
                        <Image src={require('../../../assets/images/asset-folder.png')}/>
                        <div className='leftSection'>
                            <Breadcrumb size='huge'>
                                <Link to={'/subject/detail/'+this.state.currentSubject.code+'/diagnostic'} 
                                className="section">
                                    <Icon name='folder'/> {this.state.currentSubject.name}
                                </Link>
                                <Breadcrumb.Divider />
                                <Breadcrumb.Section active>
                                    {this.state.bannerInfo !== null ? this.state.bannerInfo.name : null}
                                </Breadcrumb.Section>
                            </Breadcrumb>
                            <div className='wrapper'>
                                <div>
                                    {this.renderMessageInfo()}
                                </div>
                            </div>
                        </div>
                        <div className='rightSection'>
                            <Button primary id='btn_add_entry' onClick={()=>{
                                this.onCancelClick()
                                setTimeout(()=>{
                                    this.setState({questionModal: true, is_on_edit: false})
                                }, 10)
                            }}>
                                <Icon name='add'/> Add New Entry
                            </Button>
                        </div>
                    </div>
                    : null}
                </Segment>
                <Divider hidden/>
                {/* table filter */}
                <div className="ui icon rounded input">
                    <input onChange={this.onChangeSearch} type="text" placeholder="Search a word or sentences" id='qst_search'/>
                    <i className="search icon"></i>
                </div>
                <Checkbox style={{marginLeft:10}} toggle label='Show on review content only'
                onChange={this.showReviewContent}/>
                
                <div className='questionTable' id='questionTable'>
                    {this.state.loading ? <Loader active/> : 
                    <Form messages={messages} ref={el => this.form = el} action={this.saveQuestion} className='ui form'>
                        <table className='ui fixed table' id='entry_table'>
                            <thead>
                                {col !== null ? col : null}
                            </thead>
                            <tbody>
                                <Dimmer inverted active={this.state.previewLoading}>
                                    <Loader active/>
                                </Dimmer>
                                {this.state.questionList.map((v,k)=>{
                                    const keys = Object.keys(v.question_object)
                                    return(
                                        <tr valign="top" key={k}>
                                            {v.is_edit ? this.renderModuleFormQuestion(this.state.module_game_id, this.state.topics, v) : 
                                                <>
                                                <td>
                                                    {v.question_number !== undefined ? v.question_number : k+1}
                                                    <Popup content={
                                                        this.state.currentSubject.role === 3 ? 'Question is need to approved or declined.'
                                                        : 'Question is being reviewed.'
                                                    } position='right center' trigger={
                                                        v.status ? 
                                                            this.state.currentSubject.role === 3 ? 
                                                            <div className="ui yellow left corner tiny label">
                                                                <i className="exclamation triangle tiny icon"></i>
                                                            </div>
                                                            : <div className="ui blue info left corner tiny label">
                                                                <i className="exclamation circle tiny icon"></i>
                                                            </div>
                                                        : null
                                                    }/>
                                                </td>
                                                {keys.map((i,j)=><td key={j}>
                                                    {this.state.topics.length > 0 ? 
                                                        this.renderTableBody(v.question_object[i], i)
                                                    : null}
                                                </td>)}
                                                <td>
                                                    <div className='actionBtn'>
                                                        {v.status && this.state.currentSubject.role === 3 ?
                                                            <div>
                                                                <Button type='button' positive size='mini' 
                                                                onClick={this.approveDeclineQuestion.bind(null, 'approve', v.question_id)}
                                                                >
                                                                    <Icon name='check'/> Approve
                                                                </Button>
                                                                <Button type='button' negative size='mini'
                                                                onClick={()=>this.setState({declineID: v.question_id, declineModal: true, comment: ''})}
                                                                >
                                                                    <Icon name='remove'/> Decline
                                                                </Button>
                                                            </div>
                                                        : null}
                                                        {this.state.currentSubject.role === 3 && v.status ? <Divider/> : null}
                                                        <div>
                                                            <Button type='button' 
                                                                onClick={this.openHistory.bind(null, v.question_id)}
                                                                secondary size='mini'>
                                                                <Icon name='history'/> History
                                                            </Button>
                                                            {this.state.currentSubject.role === 3 ? 
                                                                <>
                                                                <Button type='button' onClick={this.onEditClick.bind(null, v)}
                                                                secondary size='mini'>
                                                                    <Icon name='pencil'/> Edit
                                                                </Button>
                                                                <Button type='button' onClick={()=>this.setState({deleteConfirm: true, selectedEntry: v})}
                                                                secondary size='mini'>
                                                                    <Icon name='trash'/> Delete
                                                                </Button>
                                                                </>
                                                                : 
                                                                <Button disabled={v.status || this.state.currentSubject.role === null || this.state.currentSubject.is_demo} type='button'
                                                                onClick={this.onEditClick.bind(null, v)}
                                                                secondary size='mini'>
                                                                    <Icon name='pencil'/> Edit
                                                                </Button>
                                                            }
                                                        </div>
                                                    </div>
                                                </td>
                                                </>
                                            }
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                        {/* question form modal */}
                        <Modal open={this.state.questionModal} size='large'>
                            <Modal.Header>
                                Add New Question
                                <Modal closeIcon trigger={
                                    <Button style={{marginLeft: 5, verticalAlign: 'bottom'}} color='teal' size='mini'>
                                        <Icon name='keyboard'/> Keyboard
                                    </Button>
                                }>
                                    <Modal.Content>
                                        <iframe title='keyboard' src="https://mathkeyboard.dawnofcivilization.net/" width="100%" height="500"></iframe>
                                    </Modal.Content>
                                </Modal>
                            </Modal.Header>
                            <Modal.Content style={{overflow: 'auto'}}>
                                <table className='ui fixed table form'>
                                    <thead>
                                        {colForm !== null ? colForm : null}
                                    </thead>
                                    <tbody>
                                        <tr valign="top">
                                            {this.state.formNew}
                                        </tr>
                                    </tbody>
                                </table>
                            </Modal.Content>
                            <Modal.Actions>
                                <Button type='button' disabled={this.state.saveLoading} 
                                onClick={()=>this.setState({questionModal: false})} className='tertiary'>Cancel</Button>
                                <Button primary onClick={this.saveButtonClick}
                                disabled={this.state.saveLoading} loading={this.state.saveLoading}>Save</Button>
                            </Modal.Actions>
                        </Modal>
                    </Form>
                    }    
                </div>
                {/* delete confirm modal */}
                <Modal size='small' open={this.state.deleteConfirm}>
                    <Modal.Content style={{padding: 20, paddingBottom: 50}}>
                        <div className='publishModal'>
                            <div>
                                <Icon name='exclamation triangle' circular/>
                            </div>
                            <div className='content'>
                                <h3>Are you sure you want to delete this question?</h3>
                                <p>
                                    You cannot undo this. This question will immediately be deleted from Content+ and deleted permanently from the game when the subject is published.
                                </p>
                            </div>
                        </div>
                        <Divider hidden/>
                        <Button onClick={()=>this.setState({deleteConfirm: false})} 
                        floated='right' disabled={this.state.deleteLoading} primary>Cancel</Button>
                        <Button onClick={this.deleteEntry} disabled={this.state.deleteLoading}
                        loading={this.state.deleteLoading} floated='right' secondary>Yes</Button>
                    </Modal.Content>
                </Modal>
                {/* history modal */}
                <Modal centered={false} open={this.state.history_modal} closeIcon onClose={()=>this.setState({history_modal: false})} size='large'>
                    <Modal.Header>History</Modal.Header>
                    <Modal.Content>
                        <div style={{maxHeight: 500, overflow: 'auto'}}>
                            <table className='ui fixed table'>
                                <thead>
                                    {colModal !== null ? colModal : null}
                                </thead>
                                <tbody>
                                    {this.state.historyList.map((v,k)=>{
                                        const keys = Object.keys(v.question_object)
                                        return(
                                            <tr valign="top" key={k}>
                                                {keys.map((i,j)=><td key={j}>
                                                    {i === 'image' ? <img alt='img' style={{width: 100, height: 100}} src={v.question_object[i]}/>
                                                        : this.renderTableBody(v.question_object[i], i)
                                                    }
                                                </td>)}
                                                <td>{v.editor}</td>
                                                <td>{v.updated_at}</td>
                                                <td>{v.approved === null ? 'On Review' : v.approved ? 'Approved' : 'Declined'}</td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </Modal.Content>
                </Modal>
                {/* decline modal */}
                <Modal size='small' open={this.state.declineModal}>
                    <Modal.Header>Decline Entry</Modal.Header>
                    <Modal.Content>
                        <p>Why do you decline this entry?</p>
                        <div className='ui form'>
                        <TextArea placeholder='Type your comment' value={this.state.comment} 
                        onChange={(e, data)=>this.setState({comment: data.value})}/>
                        </div>
                        <Divider hidden/>
                        <p>User suggested reason:</p>
                        <Label.Group color='blue'>
                            {suggestedReason.map((v,k)=>
                                <Label as='a' key={k} onClick={
                                    this.addSuggestedReason.bind(null, v)
                                }>
                                    {v}
                                </Label>
                            )}
                        </Label.Group>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className='tertiary' onClick={()=>{
                            this.setState({comment: ''})
                            this.setState({declineModal: false})
                        }} disabled={this.state.previewLoading}>Cancel</Button>
                        <Button negative onClick={this.approveDeclineQuestion.bind(null, 'decline', this.state.declineID)}
                        loading={this.state.previewLoading} disabled={this.state.previewLoading}>Decline</Button>
                    </Modal.Actions>
                </Modal>

                {/* pagination */}
                <Divider hidden/>
                <div>
                    Show <Dropdown onChange={this.changeEntries} defaultValue={10} compact selection options={entries} /> entries
                    <div className='pagination'>
                        <span style={{marginRight: 10}}>
                            Showing {this.state.questionList.length} of {this.state.totalRecord} entries
                        </span>
                        <Pagination activePage={this.state.filter.page} size='small' 
                        onPageChange={this.handleChangePagination} 
                        totalPages={this.state.totalPage} />
                    </div>
                </div>
                
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiagnosticTablePage)