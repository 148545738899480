import React from 'react';
import {Segment, Container, Grid, Header, List, Icon} from 'semantic-ui-react';

const FooterComponent = () =>{
    return(
        <Segment className='footer' vertical>
            <Container>
                <Grid stackable columns='equal'>
                <Grid.Row>
                    <Grid.Column className='firstSection'>
                    <Header as='h5' content='Solve Education' />
                    <List bulleted horizontal link>
                        <List.Item as='a' href='https://solveeducation.org'>About</List.Item>
                        <List.Item as='a' href='https://solveeducation.org/who-we-are/'>Our Team</List.Item>
                        <List.Item as='a' href='https://solveeducation.org/category/blog-en/'>Blog</List.Item>
                    </List>
                    <List bulleted horizontal link>
                        <List.Item as='a' href='https://solveemployment.com'>Solve Employment!</List.Item>
                    </List>
                    </Grid.Column>
                    <Grid.Column>
                    <Header as='h5' content='More' />
                    <List bulleted horizontal link>
                        <List.Item as='a' href='/faq'>FAQ</List.Item>
                        <List.Item as='a' href='/contact'>Contact Us</List.Item>
                        <List.Item as='a' href='/privacy'>Privacy Policy</List.Item>
                    </List>
                    <List bulleted horizontal link>
                        <List.Item as='a' href='/tos'>Terms of Service</List.Item>
                        <List.Item as='a' href='/cookie'>Cookie and Local Storage Policy</List.Item>
                    </List>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <Grid stackable columns='equal'>
                            <Grid.Row>
                                <Grid.Column className='contactUs'>
                                    <Header as='h5' content='Follow Us' />
                                    <Icon link name='linkedin' circular 
                                    onClick={()=>window.location.href='https://www.linkedin.com/company/solve-education-/'}/>
                                    <Icon link name='facebook f' circular 
                                    onClick={()=>window.location.href='https://www.facebook.com/solveeducation/'}/>
                                    <Icon link name='instagram' circular 
                                    onClick={()=>window.location.href='https://www.instagram.com/solveeducation/'}/>
                                    <Icon link name='youtube play' circular 
                                    onClick={()=>window.location.href='https://www.youtube.com/channel/UCXFFHqO7BzyiaG1qt8MBhlg'}/>
                                </Grid.Column>  
                                <Grid.Column>
                                    <span style={{
                                        fontSize: 12,
                                        float: 'right',
                                        color: 'rgba(3, 28, 48, 0.5)'
                                    }}>© 2017-2020 Solve Education | All Rights Reserved.</span>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Grid.Column>
                </Grid.Row>
                </Grid>
            </Container>
            </Segment>
    )
}

export default FooterComponent