import React from 'react'
import { Input, Textarea } from '../fields'
import { Image, Button, Popup } from 'semantic-ui-react'
import ImageCropperComponent from '../../components/image_cropper'
import { toast } from 'react-toastify'
import Toggle from '../fields/toggle'

// module 30 form
class Module30Form extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            image: null,
            modalOpen: false,
        }
    }

    // set editable content fix width & set form data when edit
    componentDidMount(){
        let data = this.props.data
        if(data !== undefined){
            // show current image
            this.setImageDisplay(data.question_object.image)
        }
    }

    fileInput = null

    // process the image after attached
    onChangeImage = (e)=>{
        e.preventDefault();
        let files;

        if(e.target.files[0] === undefined){
            return false
        }

        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            return false
        }

        if(e.target.files[0].size > 5000000){
            toast.error('Image should be equal to or less than 5MB.')
            return false
        }
        
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({image: reader.result, modalOpen: true})
        };
        reader.readAsDataURL(files[0]);
    }

    // set image value after crop
    setImageDisplay = (src) =>{
        this.fileInput.value = null
        if(src === null){
            this.setState({image: null, modalOpen: false})
            return false
        }
        this.setState({image: src, modalOpen: false})
    }

    render() {
        return(
            <>
                <td>
                    <Image style={{width: 100, height: 100}} size='small' src={this.state.image !== '' && this.state.image !== null ? this.state.image : require('../../assets/images/default.png')}/>
                    <Input value={this.state.image} type='hidden' name="image"/>
                    <input style={{display:'none'}} accept="image/jpeg" ref={(file)=>this.fileInput = file} id='q_image' 
                    type="file" onChange={this.onChangeImage}/>
                    <Popup trigger={
                        <Button type='button' size='mini' secondary onClick={()=>{
                            this.setState({image: null})
                            this.fileInput.click()
                        }}>Upload Image</Button>
                    } content='Image size (Max: 5MB)'></Popup>
                    <ImageCropperComponent open={this.state.modalOpen} loading={false} imgSrc={this.state.image} fileInput={this.fileInput} 
                    ratio={1 / 1} act={this.setImageDisplay}/>
                </td>
                <td>
                    <Input label='Instruction' name='instruction' required placeholder='Type the instruction' wlength={50}/>
                    <Textarea label='Question' name="question" required placeholder='Type your question...' wlength={300}/>
                    <Toggle toggle label="Use Text-to-Speech" name="is_tts" id="is_tts"/>
                    <Textarea label='Answer' name="answer" required placeholder='Type your answer...' wlength={300}/>
                    <Textarea label='Alternate Answer' name="alt_answer" placeholder='Type your alternate answer (optional)' wlength={300}/>
                    <Toggle toggle label="Use Speech-to-Text" name="is_stt" id="is_stt"/>
                </td>
            </>
        )
    }
}

export default Module30Form