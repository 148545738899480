import React from 'react'
import PropTypes from 'prop-types'
import { createField, fieldPresets } from 'react-advanced-form'
import { Label } from 'semantic-ui-react'

export class Select extends React.Component {
  static propTypes = {
    /* General */
    id: PropTypes.string,
    className: PropTypes.string,
    label: PropTypes.string,
    multiple: PropTypes.bool,

    /* Inherited */
    fieldProps: PropTypes.object.isRequired,
    fieldState: PropTypes.object.isRequired,
  }

  render() {
    const {
      fieldProps,
      fieldState,
      id,
      className,
      name,
      multiple,
      label,
      required
    } = this.props

    let selectClassNames = ['ui dropdown', className]
    .filter(Boolean)
    .join(' ')

    let is_error = false
    if(fieldState.touched || fieldState.validatedSync){
        is_error = fieldState.value === "" ? true : false
        selectClassNames = is_error ? selectClassNames+' error' : ''
    }

    
    return (
      <div className="field">
        {label && (
          <label htmlFor={id || name}>
            {label}
            {required && <b style={{color:'#db2828'}}> *</b>}
          </label>
        )}
        <select
          id={id || name}
          multiple={multiple}
          className={'ui dropdown '+selectClassNames}
          {...fieldProps}
        >
            <option value="">Choose {label}</option>
            {this.props.children}
        </select>
        {is_error ? 
        <Label color='red' pointing>Please provide the required field</Label>
        : null}
      </div>
    )
  }
}

export default createField(fieldPresets.select)(Select)