export const navAction = (n) =>{
    return{
        type: 'CHANGE_NAV',
        payload: n
    }
}

export const subNavAction = (n) =>{
    return{
        type: 'CHANGE_SUB_NAV',
        payload: n
    }
}