import React from "react";
import { Step } from 'semantic-ui-react';
import { connect } from "react-redux";
import './style.scss';

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setStepActiveIndex: (n) => dispatch({ type: 'CHAPTER_INDEX_ACTIVE', payload: n }),
        setChapterRequestData: (n) => dispatch({ type: 'CHAPTER_REQUEST_DATA', payload: n })
    }
}

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        chapter_step_active: state.chapter_step_active
    }
}

// the steps includes 4 section
class ChapterSteps extends React.Component{
    // set initiate value for chapter active and request data
    componentDidMount(){
        this.props.setStepActiveIndex(1)
        this.props.setChapterRequestData(null)
    }

    render() {
        return (
            <Step.Group size='mini' stackable='tablet' style={{width: '100%'}} className='customStep'>
                <Step active={this.props.chapter_step_active === 1 ? true : false}>
                    <div className='chapterNumber'>
                        <h1>1</h1>
                    </div>
                    <Step.Content>
                        <Step.Title>Chapter</Step.Title>
                        <Step.Description>Create you chapter</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={this.props.chapter_step_active === 2 ? true : false}>
                    <div className='chapterNumber'>
                        <h1>2</h1>
                    </div>
                    <Step.Content>
                        <Step.Title>Completion Percentage</Step.Title>
                        <Step.Description>Choose completion percentage</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={this.props.chapter_step_active === 3 ? true : false}>
                    <div className='chapterNumber'>
                        <h1>3</h1>
                    </div>
                    <Step.Content>
                        <Step.Title>Teaching Format</Step.Title>
                        <Step.Description>Choose your teaching format</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={this.props.chapter_step_active === 4 ? true : false}>
                    <div className='chapterNumber'>
                        <h1>4</h1>
                    </div>
                    <Step.Content>
                        <Step.Title>Minigame</Step.Title>
                        <Step.Description>Choose the minigame</Step.Description>
                    </Step.Content>
                </Step>
                <Step active={this.props.chapter_step_active === 5 ? true : false}>
                    <div className='chapterNumber'>
                        <h1>5</h1>
                    </div>
                    <Step.Content>
                        <Step.Title>Content & Preview</Step.Title>
                        <Step.Description>Try to create content and preview</Step.Description>
                    </Step.Content>
                </Step>
            </Step.Group>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChapterSteps)