import React from 'react'
import {Image, Divider, Form, Button, Icon, Input, Label} from 'semantic-ui-react'
import { GoogleLogin } from 'react-google-login';
import { toast } from 'react-toastify';
import {connect} from 'react-redux'

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        loginAction: (n) => {
            let state = {
                is_logged: true,
                user: n
            }
            dispatch({ type: 'LOGIN', payload: state })
        },
        navAction: (n) => dispatch({ type: 'CHANGE_NAV', payload: n })
    }
}

class RegisterPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            form: {
                email: {
                    value: '',
                    error: false,
                    message: ''
                },
                username: {
                    value: '',
                    error: false,
                    message: ''
                },
                password: {
                    value: '',
                    error: false,
                    message: ''
                },
                re_password: {
                    value: '',
                    error: false,
                    message: ''
                }
            },
            loading: false
        }
    }

    responseGoogle = (response) => {
        let googleResponse = response.profileObj
        if(googleResponse !== undefined){
            fetch(process.env.REACT_APP_API_URL+'api/v1/auth/login/google', {
                method: 'post',
                body: JSON.stringify({
                    username: googleResponse.email,
                    googleId: googleResponse.googleId,
                    tokenId: response.tokenId
                })
            })
            .then((res)=>{
                return res.json()
            })
            .then((res)=>{
                if(res.status){
                    this.props.loginAction(res.data)
                    this.props.navAction('subject')
                }
            })
        }
    }

    handleChange = (e, data) =>{
        let form = this.state.form
        let url = 'api/v1/validate/username?value='+data.value

        switch (data.name) {
            case 'email':
                url = 'api/v1/validate/email?value='+data.value
                form.email.value = data.value
                break;
            case 'username':
                form.username.value = data.value
                break;
            case 'password':
                form.password.value = data.value
                form.re_password.value = ''
                form.password.error = false
                form.re_password.error = false
                if(data.value.length < 6){
                    form.password.error = true
                    form.password.message = 'Password length minimum is 6'
                }
                break;
            default:
                form.re_password.value = data.value
                form.re_password.error = false
                if(data.value !== form.password.value){
                    form.re_password.error = true
                    form.re_password.message = 'Password not match'
                }
                break;
        }

        if(!data.name.includes('password')){
            fetch(process.env.REACT_APP_API_URL+url)
            .then((res)=>{
                return res.json()
            })
            .then((res)=>{
                let isError = false
                if(!res.status){
                    isError = true
                }
    
                if(data.name === 'email'){
                    form.email.error = isError
                    form.email.message = res.message 
                }else{
                    form.username.error = isError
                    form.username.message = res.message 
                }

                this.setState({form: form})
            })
        }else{
            this.setState({form: form})
        }
    }

    handleSubmitForm = () =>{
        let form = this.state.form
        let isError = false
        Object.keys(form).forEach(item=>{
            if(form[item].error){
                isError = true
            }
        })

        if(isError){
            toast.error('Your input is invalid!')
            return false
        }

        this.setState({loading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/auth/register', {
            method: 'post',
            body: JSON.stringify({
                email: form.email.value,
                username: form.username.value,
                password: form.password.value
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status){
                this.props.loginAction(res.data)
                this.props.navAction('subject')
                toast.success('Your account registered successfully.')
                this.setState({loading: false})
            }   
        })
    }

    render() {
        return (
            <div className="ui middle aligned container" style={{minHeight: '100vh', width: 400, marginBottom: 200}}>
            <div className="ui middle aligned grid" style={{height:'100vh'}}>
                <div className="row">
                    <div className="column">
                        <div className="ui segment">
                            <Image style={{width: 200}} centered src={require('../../assets/images/content.png')}/>
                            <Divider hidden/>
                            <div className='loginHeader'>
                                <span className='left'>Sign Up</span>
                                <a rel='link' className='link' href='/login'>Log In</a>
                            </div>
                            <Divider hidden/>
                            <Form onSubmit={this.handleSubmitForm} id="register_form">
                                <Form.Field>
                                    <label>Email</label>
                                    <Input onChange={this.handleChange} placeholder='your@email.com' 
                                    icon={
                                        this.state.form.email.value !== '' ?
                                        this.state.form.email.error ? <Icon name='remove circle' color='red'/> : 
                                        <Icon name='check circle' color='green'/>
                                        : false
                                    } type='email' required name='email' id='email'/>
                                    {this.state.form.email.error && this.state.form.email.value !== '' ? 
                                    <Label color='red' pointing>{this.state.form.email.message}</Label>
                                    : null}
                                </Form.Field>
                                <Form.Field>
                                    <label>Username</label>
                                    <Input onChange={this.handleChange} icon={
                                        this.state.form.username.value !== '' ?
                                        this.state.form.username.error ? <Icon name='remove circle' color='red'/> : 
                                        <Icon name='check circle' color='green'/>
                                        : false
                                    }
                                    placeholder='yourname' required name='username' id='username'/>
                                    {this.state.form.username.error && this.state.form.username.value !== '' ? 
                                    <Label color='red' pointing>{this.state.form.username.message}</Label>
                                    : null}
                                </Form.Field>
                                <Form.Field>
                                    <label>Password</label>
                                    <Input onChange={this.handleChange} type='password' placeholder='At least 6 characters' 
                                    icon={
                                        this.state.form.password.value !== '' ?
                                        this.state.form.password.error ? <Icon name='remove circle' color='red'/> : 
                                        <Icon name='check circle' color='green'/>
                                        : false
                                    } required name='password' id='password'/>
                                    {this.state.form.password.error && this.state.form.password.value !== '' ? 
                                    <Label color='red' pointing>{this.state.form.password.message}</Label>
                                    : null}
                                </Form.Field>
                                <Form.Field>
                                    <label>Confirm Password</label>
                                    <Input type='password' onChange={this.handleChange} placeholder='Type the password again' 
                                    icon={
                                        this.state.form.re_password.value !== '' ?
                                        this.state.form.re_password.error ? <Icon name='remove circle' color='red'/> : 
                                        <Icon name='check circle' color='green'/>
                                        : false
                                    } required name='re-password' value={this.state.form.re_password.value} id='confirm_password'/>
                                    {this.state.form.re_password.error && this.state.form.re_password.value !== '' ? 
                                    <Label color='red' pointing>{this.state.form.re_password.message}</Label>
                                    : null}
                                </Form.Field>
                                <p className='agreement'>
                                By clicking Agree & Join, you agree to the Content+ <a rel='ua' href='/tos'>User Agreement</a>, <a rel='pp' href='/privacy'>Privacy Policy</a>, and <a rel='cp' href='/cookie'>Cookie Policy</a>.
                                </p>
                                <Button fluid primary loading={this.state.loading} disabled={this.state.loading} id='register_btn_submit'>
                                    <Icon name='signup'/> Sign Up
                                </Button>
                            </Form>
                            <Divider/>
                            <GoogleLogin
                                clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                                render={renderProps => (
                                    <Button className='google' onClick={renderProps.onClick} disabled={renderProps.disabled} 
                                    fluid>
                                        <div>
                                        <img alt='google' src={require('../../assets/images/google.png')}/>
                                        Sign Up with Google
                                        </div>
                                    </Button>
                                )}
                                buttonText="Login"
                                onSuccess={this.responseGoogle}
                                onFailure={this.responseGoogle}
                            />
                            <Divider hidden/>
                            <p className='footerTextLogin'>Already have an account? <a rel='link' className='link' href='/login'>Log In</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterPage)