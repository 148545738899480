import React from 'react'
/* Fields */
import { Textarea, Select, Input } from '../fields'
import {Button, Icon, Grid, Label, Input as UIInput, Popup} from 'semantic-ui-react';

class A1Form extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            passageWidth: 0,
            random_words: this.props.data !== undefined ? this.props.data.question_object.random_words.split(',') : [],
            showAnswer: false,
            words: '',
            selectedKey: null,
            random_words_text: this.props.data !== undefined ? this.props.data.question_object.random_words : '',
            isTouch: false,
            showVarError: false,
            min_random_words: 3,
            max_random_words: 15,

            correct_answer: this.props.data !== undefined ? this.props.data.question_object.correct_answer.split(',') : [],
            showAnswer2: false,
            words2: '',
            selectedKey2: null,
            correct_answer_text: this.props.data !== undefined ? this.props.data.question_object.correct_answer : '',
            isTouch2: false,
            showVarError2: false,
            max_correct_answer: 3,
            max_char: 20
        }
    }

    addTag = (type, e) =>{
        if(type === 'random'){
            let word = this.state.words
            if(word.trim() === ''){
                return false
            }
            if(word.length > this.state.max_char){
                this.setState({showVarError: true})
                return false
            }
            word = word.replace(/[^\w\s]/gi, '')
            let key = this.state.selectedKey
            let random_words = this.state.random_words
            if(word !== ''){
                if(key !== null){
                    random_words[key] = word
                }else{
                    random_words.push(word)
                }
            }
            this.setState({random_words: random_words, showAnswer: false, words: '', selectedKey: null, 
            random_words_text: random_words.join(','), isTouch: true})
        }else{
            let word = this.state.words2
            if(word.trim() === ''){
                return false
            }
            if(word.length > this.state.max_char){
                this.setState({showVarError2: true})
                return false
            }
            word = word.replace(/[^\w\s]/gi, '')
            let key = this.state.selectedKey2
            let correct_answer = this.state.correct_answer
            if(word !== ''){
                if(key !== null){
                    correct_answer[key] = word
                }else{
                    correct_answer.push(word)
                }
            }
            this.setState({correct_answer: correct_answer, showAnswer2: false, words2: '', selectedKey2: null, 
            correct_answer_text: correct_answer.join(','), isTouch2: true})
        }
    }

    removeTag = (type, key, e) =>{
        if(type === 'random'){
            let random_words = this.state.random_words
            random_words.splice(key, 1)
            this.setState({random_words: random_words, random_words_text: random_words.join(','), isTouch: true})
        }else{
            let correct_answer = this.state.correct_answer
            correct_answer.splice(key, 1)
            this.setState({correct_answer: correct_answer, correct_answer_text: correct_answer.join(','), isTouch2: true})
        }
    }

    render(){
        return <>
            <td>
                <Select label='Topic' name='topic' children={
                    this.props.topics.map((v, k)=>
                        <option value={v.category_id} key={k}>{v.name}</option>
                    )
                } required/>
            </td>
            <td>
                <Input label='Category' name="category" required placeholder='Type here...' wlength={30}/>
            </td>
            <td style={this.state.passageWidth !== 0 ? {width: this.state.passageWidth} : null}>
                <div className='field'>
                    <label>Correct Answer <b style={{color:'#db2828'}}> *</b></label>
                    <div className={(this.state.correct_answer.length > this.state.max_correct_answer) && this.state.isTouch2 ? 'editableContent error' : 'editableContent'}>
                        {this.state.correct_answer.map((v,k)=>
                            <Label key={k} as='a' style={{marginTop: 5}}>
                                <span onClick={()=>this.setState({
                                    showAnswer2: true, words2: v, selectedKey2: k
                                })}>{v}</span>
                                <Icon name='delete' onClick={this.removeTag.bind(null, 'correct', k)}/>
                            </Label>
                        )}
                    </div>
                    {this.state.correct_answer.length > this.state.max_correct_answer && this.state.isTouch2 ? 
                        <Label color='red' pointing>
                            Correct answer reached the limit : {this.state.max_correct_answer} words
                        </Label>
                    : null}
                    <Textarea input_error={(this.state.correct_answer.length > this.state.max_correct_answer) && this.state.isTouch2 
                    ? true : false} 
                    values={this.state.correct_answer_text} hidden={true} name="correct_answer" required/>
                </div>
                
                {this.state.showAnswer2 ? 
                    <Grid columns='equal'>
                        <Grid.Column width={10}>
                            <Popup content={'Max characters is '+this.state.max_char} trigger={
                                <div className='field'>
                                    <UIInput id='correct_answer_i' placeholder='Type here...' onChange={(e, data)=>this.setState({words2: data.value})} 
                                    value={this.state.words2} onKeyDown={(e)=>{
                                        this.setState({showVarError2: this.state.words2.length > this.state.max_char ? true : false})
                                        if ((e.key === 'Enter' || e.key === 'Tab') && this.state.words2.trim() !== '') {
                                            if(this.state.words2.length <= this.state.max_char){
                                                this.addTag('correct')
                                            }
                                        }
                                    }}/>
                                    {this.state.showVarError2 ? 
                                    <div className="ui red pointing label">Your input is more than {this.state.max_char} characters</div>
                                    : null}
                                </div>
                            }/>
                        </Grid.Column>
                        <Grid.Column>
                            <Button className='tertiary' disabled={this.state.words2 === '' || this.state.showVarError2 ? true : false}
                            size='small' type='button' icon onClick={this.addTag.bind(null, 'correct')}>
                                <Icon color='green' name='check'/>
                            </Button>
                            <Button className='tertiary' 
                            size='small' type='button' icon onClick={()=>this.setState({showAnswer2: false})}>
                                <Icon color='grey' name='remove'/>
                            </Button>
                        </Grid.Column>
                    </Grid> : 
                    <div>
                    <Button type='button' secondary size='small' 
                    disabled={this.state.correct_answer.length === this.state.max_correct_answer ? true : false}
                    onClick={()=>{
                        this.setState({showAnswer2: true, words2: ''})
                        setTimeout(()=>{
                            document.getElementById('correct_answer_i').focus()
                        }, 100)
                    }}>
                        Add Answer
                    </Button>     
                    <i style={{fontSize: 12, color: 'grey'}}>*max words : {this.state.max_correct_answer}</i>
                    </div>}
            </td>
            <td style={this.state.passageWidth !== 0 ? {width: this.state.passageWidth} : null}>
                <div className='field'>
                    <label>Random Words <b style={{color:'#db2828'}}> *</b></label>
                    <div className={(this.state.random_words.length < this.state.min_random_words || this.state.random_words.length > this.state.max_random_words) && this.state.isTouch ? 'editableContent error' : 'editableContent'}>
                        {this.state.random_words.map((v,k)=>
                            <Label key={k} as='a' style={{marginTop: 5}}>
                                <span onClick={()=>this.setState({
                                    showAnswer: true, words: v, selectedKey: k
                                })}>{v}</span>
                                <Icon name='delete' onClick={this.removeTag.bind(null, 'random', k)}/>
                            </Label>
                        )}
                    </div>
                    {this.state.random_words.length < this.state.min_random_words && this.state.isTouch ? 
                        <Label color='red' pointing>
                            Not enough random word
                        </Label>
                    : null}
                    {this.state.random_words.length > this.state.max_random_words && this.state.isTouch ? 
                        <Label color='red' pointing>
                            Random words reached the limit : 15 words
                        </Label>
                    : null}
                    <Textarea input_error={(this.state.random_words.length < this.state.min_random_words || this.state.random_words.length > this.state.max_random_words) && this.state.isTouch 
                    ? true : false} 
                    values={this.state.random_words_text} hidden={true} name="random_words" required/>
                </div>
                
                {this.state.showAnswer ? 
                    <Grid columns='equal'>
                        <Grid.Column width={10}>
                            <Popup content={'Max characters is '+this.state.max_char} trigger={
                                <div className='field'>
                                    <UIInput id='random_word_i' placeholder='Type here...' onChange={(e, data)=>this.setState({words: data.value})} 
                                    value={this.state.words} onKeyDown={(e)=>{
                                        this.setState({showVarError: this.state.words.length > this.state.max_char ? true : false})
                                        if ((e.key === 'Enter' || e.key === 'Tab') && this.state.words.trim() !== '') {
                                            if(this.state.words.length <= this.state.max_char){
                                                this.addTag('random')
                                            }
                                        }
                                    }}/>
                                    {this.state.showVarError ? 
                                    <div className="ui red pointing label">Your input is more than {this.state.max_char} characters</div>
                                    : null}
                                </div>
                            }/>
                        </Grid.Column>
                        <Grid.Column>
                            <Button className='tertiary' disabled={this.state.words === '' || this.state.showVarError ? true : false}
                            size='small' type='button' icon onClick={this.addTag.bind(null, 'random')}>
                                <Icon color='green' name='check'/>
                            </Button>
                            <Button className='tertiary' 
                            size='small' type='button' icon onClick={()=>this.setState({showAnswer: false})}>
                                <Icon color='grey' name='remove'/>
                            </Button>
                        </Grid.Column>
                    </Grid> : 
                    <div>
                    <Button type='button' secondary size='small' 
                    disabled={this.state.random_words.length === this.state.max_random_words ? true : false}
                    onClick={()=>{
                        this.setState({showAnswer: true, words: ''})
                        setTimeout(()=>{
                            document.getElementById('random_word_i').focus()
                        }, 100)
                    }}>
                        {this.state.min_random_words - this.state.random_words.length <= 0 ? 
                        'Add Word'
                        : 'Add Word ('+(this.state.min_random_words - this.state.random_words.length)+')'
                        }
                    </Button>     
                    <i style={{fontSize: 12, color: 'grey'}}>*min words : {this.state.min_random_words} & max words : {this.state.max_random_words}</i>
                    </div>}
            </td>
        </>
    }
}

export default A1Form