import React from "react";
import {
    Container, Header, List, Button, Icon, Modal, Image,
    Table, Loader, Dimmer, Tab, Grid, Dropdown, MenuItem
} from "semantic-ui-react";
import { Form } from 'react-advanced-form';
import Module4Form from "../../../../forms/question/module4";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import storage from 'redux-persist/lib/storage';
import { Link } from "react-router-dom";
import Module2Form from "../../../../forms/question/module2";
import { processQuestionObject } from '../../../../helpers/questions';
import messages from '../../../../forms/question/validation_message';
import Module1Form from "../../../../forms/question/module1";
import Module1AForm from "../../../../forms/question/module1a";
import Module7Form from "../../../../forms/question/module7";
import Module10Form from "../../../../forms/question/module10";
import Module11Form from "../../../../forms/question/module11";
import {
    Module1ColumnQuestion, Module4ColumnQuestion, Module7ColumnQuestion, Module10ColumnQuestion, Module11ColumnQuestion, Module2ColumnQuestion, Module14ColumnQuestion, Module12ColumnQuestion,
    Module13ColumnQuestion, Module29ColumnQuestion, Module31ColumnQuestion, Module30ColumnQuestion, Module32ColumnQuestion, Module33ColumnQuestion, Module34ColumnQuestion, Module35ColumnQuestion
} from "../../questions/table_columns";
import Module14Form from "../../../../forms/question/module14";
import Module12Form from "../../../../forms/question/module12";
import Module13Form from "../../../../forms/question/module13";
import Module29Form from "../../../../forms/question/module29";
import EmptyComponent from "../../../../components/empty";
import { PlatformInit } from "../../../../helpers/global";
import SlideShowComponent from "../../../../components/slide_show";
import RCForm from "../../../../forms/reading_comp";
import Module30Form from "../../../../forms/question/module30";
import Module32Form from "../../../../forms/question/module32";
import * as Sentry from "@sentry/browser";
import Module33Form from "../../../../forms/question/module33";
import Module34Form from "../../../../forms/question/module34";
import Module35Form from "../../../../forms/question/module35";
import Module31Form from "../../../../forms/question/module31";

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return {
        chapter_step_active: state.chapter_step_active,
        chapter_request_data: state.chapter_request_data
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return {
        setStepActiveIndex: (n) => dispatch({ type: 'CHAPTER_INDEX_ACTIVE', payload: n }),
        setChapterRequestData: (n) => dispatch({ type: 'CHAPTER_REQUEST_DATA', payload: n }),
        setTaskManager: (n) => dispatch({ type: 'SET_TASK_MANAGER', payload: n })
    }
}

// content & review section
class ContentReviewSection extends React.Component {
    constructor(props) {
        super(props)
        let dropdownPlatform = []
        PlatformInit().forEach(x => {
            dropdownPlatform.push({
                key: x.value,
                text: x.text,
                value: x.value
            })
        })
        this.state = {
            is_save: false,
            modalOpen: false,
            previewModal: false,
            previewImage: [],
            mg_chapter_id: null,
            is_mounted: false,
            loading: false,
            portalOpen: false,
            portalMessage: '',
            refreshPreviewLoading: false,
            selectedPlatform: dropdownPlatform[0].value,
            platformList: dropdownPlatform
        }
    }

    componentDidMount() {
        this.setState({ is_mounted: true })
    }

    componentWillUnmount() {
        this.setState({ is_mounted: false })
    }

    form = null

    // create chapter
    saveAction = ({ serialized, fields }) => {
        const keys = Object.keys(fields)
        let message = []
        let isValid = true
        keys.forEach(k => {
            if (fields[k].invalid) {
                message.push(<div>
                    Please check maximum character or correct input on <b style={{ textTransform: 'uppercase' }}>{k}</b>
                </div>)
                isValid = false
            }
        });

        if (!isValid) {
            toast.error(<List bulleted>
                {message.map((v, k) => <List.Item key={k}>{v}</List.Item>)}
            </List>)
            return new Promise((resolve) => resolve())
        }

        let param = this.props.chapter_request_data
        param.question_object = processQuestionObject(serialized, this.props.chapter_request_data.module_id_game)

        if (this.state.is_save) {
            this.saveProcess(param)
        } else {
            this.previewProcess(param)
        }
        this.setState({ is_save: false })
        return new Promise((resolve) => resolve())
    }

    saveReadingComp = (data) => {
        let param = this.props.chapter_request_data
        param.question_object = data
        this.saveProcess(param)
    }

    refreshPreview = () => {
        this.setState({ refreshPreviewLoading: true })
        let param = this.props.chapter_request_data
        this.previewProcess(param)
    }

    skipCreation = () => {
        let param = this.props.chapter_request_data
        this.saveProcess(param)
    }

    // preview example content with minigame
    previewProcess = (param) => {
        this.setState({ loading: true })
        fetch(process.env.REACT_APP_API_URL + 'api/v1/questions/generate-preview', {
            method: 'post',
            headers: this.props.myHeaders,
            body: JSON.stringify(param)
        }).then((res) => {
            return res.json()
        }).then((res) => {
            this.setState({ loading: false })
            if (res.status === undefined) {
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if (res.status) {
                let tabPlatform = []
                let v = res.data
                tabPlatform.push({
                    menuItem: <MenuItem key={v.mini_game_id}>
                        <Image src={v.icon} avatar />
                        {v.mini_game_name}
                    </MenuItem>, render: () => <Tab.Pane>
                        {v.preview[this.state.selectedPlatform] !== undefined && v.preview[this.state.selectedPlatform].length > 0 ?
                            <SlideShowComponent id={"slide-about-" + v.mini_game_id + "-" + this.state.selectedPlatform} images={v.preview[this.state.selectedPlatform]} />
                            : <EmptyComponent />}
                    </Tab.Pane>
                })
                this.setState({ previewImage: tabPlatform, previewModal: true, refreshPreviewLoading: false })
            } else {
                let _messages = []
                if (res.data.length > 0) {
                    res.data.forEach(e => {
                        _messages.push(
                            <List.Item>{e}</List.Item>
                        )
                    })
                } else {
                    _messages.push(
                        <List.Item>Chapter cannot be preview.</List.Item>
                    )
                }
                toast.error(
                    <List bulleted>
                        {_messages}
                    </List>
                )
            }
        }).catch((e) => {
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // save process to database
    saveProcess = (param) => {
        this.setState({ loading: true })
        fetch(process.env.REACT_APP_API_URL + 'api/v1/chapters/create', {
            method: 'post',
            headers: this.props.myHeaders,
            body: JSON.stringify(param)
        }).then((res) => {
            return res.json()
        }).then((res) => {
            this.setState({ loading: false })
            if (res.status === undefined) {
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if (res.status) {
                this.setState({ modalOpen: true, mg_chapter_id: res.data.minigames[0].chapter_minigame_id })
                this.props.setTaskManager(true)
            } else {
                let _messages = []
                if (res.data.length > 0) {
                    res.data.forEach(e => {
                        _messages.push(
                            <List.Item>{e}</List.Item>
                        )
                    })
                } else {
                    _messages.push(
                        <List.Item>Chapter cannot be saved.</List.Item>
                    )
                }
                toast.error(
                    <List bulleted>
                        {_messages}
                    </List>
                )
            }
        }).catch((e) => {
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // back to teaching format
    backToMinigameStep = () => {
        this.props.setStepActiveIndex(4)
    }

    // trigger submit form
    saveButtonClick = (e, data) => {
        let is_error = false
        let inputs = []
        let fields = this.form.state.fields
        Object.keys(fields).forEach(x => {
            if (!fields[x].valid && fields[x].required) {
                is_error = true
                inputs.push(x)
            }
        })
        if (is_error) {
            let msg = <p>
                <b>{inputs.slice(0, -1).join(', ') +
                    (inputs.length > 1 ? ' and ' + inputs.slice(-1) : inputs.join(', '))}</b> {inputs.length > 1 ? 'are' : 'is'} required.
                Please complete the form before submitting your entry!
            </p>
            this.setState({ portalOpen: true, portalMessage: msg })
        }
        this.setState({ is_save: data.id === 'save' ? true : false })
        this.form.submit()
    }



    render() {
        let form = {}
        let columnModule = {}
        let module_id = this.props.chapter_request_data.module_id_game
        if (module_id >= 20 && module_id <= 25) {
            module_id = 4
        } else if (module_id >= 26 && module_id <= 28) {
            module_id = 11
        }
        switch (module_id) {
            case 1:
                form = <Module1Form />
                columnModule = <Module1ColumnQuestion />
                break;
            // module 1a
            case 99:
                form = <Module1AForm />
                columnModule = <Module1ColumnQuestion />
                break;
            case 4:
                form = <Module4Form />
                columnModule = <Module4ColumnQuestion />
                break;
            case 7:
                form = <Module7Form />
                columnModule = <Module7ColumnQuestion />
                break;
            case 10:
                form = <Module10Form />
                columnModule = <Module10ColumnQuestion />
                break;
            case 11:
                form = <Module11Form />
                columnModule = <Module11ColumnQuestion />
                break;
            case 12:
                form = <Module12Form />
                columnModule = <Module12ColumnQuestion />
                break;
            case 13:
                form = <Module13Form is_review={true} />
                columnModule = <Module13ColumnQuestion />
                break;
            case 14:
                form = <Module14Form />
                columnModule = <Module14ColumnQuestion />
                break;
            case 15:
                form = <Module7Form />
                columnModule = <Module7ColumnQuestion />
                break;
            case 16:
                form = <Module11Form />
                columnModule = <Module11ColumnQuestion />
                break;
            case 17:
                form = <Module10Form />
                columnModule = <Module10ColumnQuestion />
                break;
            case 18:
                form = <Module13Form />
                columnModule = <Module13ColumnQuestion />
                break;
            case 29:
                form = <Module29Form />
                columnModule = <Module29ColumnQuestion />
                break;
            case 30:
                form = <Module30Form/> 
                columnModule= <Module30ColumnQuestion/>
                break;
            case 31:
                form = <Module31Form passages={[]}/>
                columnModule = <Module31ColumnQuestion />
                break
            case 32:
                form = <Module32Form />
                columnModule= <Module32ColumnQuestion />
                break;
            case 33:
                form = <Module33Form/>
                columnModule= <Module33ColumnQuestion/>
                break;
            case 34:
                form = <Module34Form videos={[]}/>
                columnModule = <Module34ColumnQuestion/>
                break;
            case 35:
                form = <Module35Form/>
                columnModule = <Module35ColumnQuestion/>
                break;
            default:
                form = <Module2Form />
                columnModule = <Module2ColumnQuestion />
                break;
        }
        if (this.state.is_mounted) {
            return (
                <div>
                    <Dimmer active={this.state.loading} inverted>
                        <Loader inverted>Processing...</Loader>
                    </Dimmer>
                    <Container>
                        <Header as='h2' style={{ color: 'rgba(3, 28, 48, 0.65)' }}>
                            Create a content for {this.props.chapter_request_data.mg_name} now!
                        </Header>
                        <Grid columns='equal'>
                            <Grid.Column>
                                <p>In {this.props.chapter_request_data.mg_name}, you need to complete the form.</p>
                            </Grid.Column>
                            <Grid.Column width='4'>
                                <Button className='tertiary' floated='right'
                                    onClick={this.skipCreation}>Skip Chapter Creation <Icon name='arrow right' /></Button>
                            </Grid.Column>
                        </Grid>
                    </Container>
                    {this.props.chapter_request_data.module_id_game !== 19 ?
                        <Form messages={messages} action={this.saveAction} style={{ padding: 10, marginBottom: 10, overflow: 'auto' }}
                            className='ui form' ref={el => this.form = el} id='content_form_chapter'>
                            <Table fixed>
                                <Table.Header>
                                    {columnModule}
                                </Table.Header>
                                <Table.Body>
                                    <Table.Row valign="top">
                                        {form}
                                    </Table.Row>
                                </Table.Body>
                            </Table>
                        </Form>
                        : <RCForm header={this.props.myHeaders} mg={this.props.chapter_request_data.module_id_game} action={this.saveReadingComp} is_topic={true} />}

                    {this.props.chapter_request_data.module_id_game !== 19 ?
                        <>
                            <Button className='tertiary' onClick={this.backToMinigameStep}>
                                <Icon name='arrow left' /> Back to choose the other minigame
                            </Button>
                            <Button floated='right' id='save' positive onClick={this.saveButtonClick}>
                                Save & Finish
                            </Button>
                            <Button floated='right' id='preview' primary onClick={this.saveButtonClick}>
                                <Icon name='eye' /> Preview
                            </Button>
                        </>
                        : null}


                    {/* modal success */}
                    <Modal size='mini' open={this.state.modalOpen}>
                        <Modal.Content>
                            <Container textAlign='center'>
                                <Icon.Group size='huge'>
                                    <Icon size='big' color='green' name='circle outline' />
                                    <Icon name='check' color='green' />
                                </Icon.Group>
                                <Header as='h2'>Congratulation!</Header>
                                <p>That was a good start!</p>
                                <Link to={'/subject/' + this.props.subject.code + '/' + this.state.mg_chapter_id + '/question'}
                                    className='ui button fluid primary'>Complete The Content</Link>
                                <Link to={'/subject/detail/' + this.props.subject.code + '/content'}
                                    className='ui button fluid secondary' style={{ marginTop: 5 }}>Back to Subject Dashboard</Link>
                            </Container>
                        </Modal.Content>
                    </Modal>
                    {/* modal preview */}
                    <Modal open={this.state.previewModal} onClose={() => this.setState({ previewModal: false })} closeIcon>
                        <Modal.Header>
                            Content on Game Preview
                            <p className='refreshModal'>Preview not loaded well? <Button size='mini' color='green' onClick={this.refreshPreview}>refresh</Button></p>
                        </Modal.Header>
                        <Modal.Content>
                            <Dimmer inverted active={this.state.refreshPreviewLoading}>
                                <Loader active>Loading...</Loader>
                            </Dimmer>
                            <Grid>
                                <Grid.Column width={4}>
                                </Grid.Column>
                                <Grid.Column width={12} style={{ paddingBottom: 0 }}>
                                    <Header as="h4" style={{ float: 'left' }}>
                                        <Icon name='images' />
                                        Screenshot
                                    </Header>
                                    <span style={{ float: 'right' }}>
                                        Platform : {' '}
                                        <Dropdown
                                            direction='left'
                                            inline
                                            options={this.state.platformList}
                                            defaultValue={this.state.selectedPlatform}
                                            onChange={(e, data) => this.setState({ selectedPlatform: data.value })}
                                        />
                                    </span>

                                </Grid.Column>
                            </Grid>

                            {this.state.previewImage.length > 0 ?
                                <Tab className="bannerTab" menu={{ vertical: true }} panes={this.state.previewImage} />
                                : null}
                        </Modal.Content>
                    </Modal>
                    <Modal size='mini' open={this.state.portalOpen} className='formErrorModal' closeIcon
                        onClose={() => this.setState({ portalOpen: false })}>
                        <Modal.Content>
                            <Header>Form is not complete!</Header>
                            {this.state.portalMessage}
                            <div style={{ textAlign: 'center' }}>
                                <Button primary
                                    content='Ok'
                                    onClick={() => this.setState({ portalOpen: false })}
                                />
                            </div>
                        </Modal.Content>
                    </Modal>
                </div>
            )
        }
        return null
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentReviewSection)