import React from 'react'
import {Accordion, Image, Container, Header, Icon, Grid, Divider, Loader} from 'semantic-ui-react'
import './style.scss'
import EmptyComponent from '../../components/empty'

class FaqPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            activeIndex: null,
            faq: [],
            loading: true
        }
    }

    componentDidMount(){
        fetch(process.env.REACT_APP_API_URL+'api/v1/statics/faq')
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            this.setState({faq: res.data, loading: false})
        })
    }

    handleClick = (e, titleProps) => {
        const { index } = titleProps
        const { activeIndex } = this.state
        const newIndex = activeIndex === index ? -1 : index
        this.setState({ activeIndex: newIndex })
    }

    render() {
        return (
            <Container style={{paddingTop: 80, paddingBottom: 80, minHeight: '66.5vh'}}>
                <Grid columns='equal'>
                    <Grid.Column>
                        <Header as='h1'>
                            <Icon name='question circle'/> Frequently Asked Questions
                        </Header>
                    </Grid.Column>
                    <Grid.Column>
                        <a href='/contact' rel='contact' className='ui right floated primary button'>Contact Us</a>
                    </Grid.Column>
                </Grid>
                <Divider/>
                <Divider hidden/>

                {this.state.loading ? <Loader active/> : 
                    this.state.faq.length > 0 ? 
                    <Accordion fluid>
                        {this.state.faq.map((v, k)=>
                            <div className='faqAccordion' key={k}>
                                <Accordion.Title
                                active={this.state.activeIndex === k}
                                index={k}
                                onClick={this.handleClick}
                                >
                                <Icon style={{float:'right'}} name='dropdown' />
                                {v.question}
                                </Accordion.Title>
                                <Accordion.Content active={this.state.activeIndex === k}>
                                {v.answer.text !== null && v.answer.image ? 
                                <Grid columns={2}>
                                    <Grid.Row>
                                        <Grid.Column><p>{v.answer.text}</p></Grid.Column>
                                        <Grid.Column><Image src={v.answer.image}/></Grid.Column>
                                    </Grid.Row>
                                </Grid> 
                                : v.answer.text !== null && v.answer.text !== '' ? <p>{v.answer.text}</p>
                                : v.answer.image !== null && v.answer.image !== '' ? <Image src={v.answer.image}/>
                                : null
                                }
                                </Accordion.Content>
                            </div>
                        )}
                    </Accordion>
                    : <EmptyComponent/>
                }                
            </Container>
        )
    }
}

export default FaqPage