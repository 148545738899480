import React from 'react'
import { Grid, Button, Icon, Divider, Table, Pagination, Dropdown,
Modal,
Loader,
Checkbox,
Container,
Input,
Form,
Popup} from 'semantic-ui-react'
import {connect} from 'react-redux'
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify'
import { downloadCSV } from '../../../helpers/download';

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        auth: state.auth
    }
}

class SubjectsPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            subjectList: [],
            loading: true,
            activePage: 1,
            filter: {
                page: 1,
                n: 10,
                order_by: 4,
                asc: 0,
                verified_only: 0,
                published_only: 0,
                search: ''
            },
            totalPage: 0,
            totalRecord: 0,
            chapterModal: false,
            selectedContent: [],
            chapterSort: {
                order_by: 0,
                asc: 0
            },
            banModal: false,
            reason: '',
            selectedSubject: '',
            afterLoading: false,
            handleActionStatus: false,
        }
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/subjects', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify(this.state.filter)
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            this.setState({subjectList: res.data, loading: false, 
                totalPage: res.total_page, 
                totalRecord: res.total_record, afterLoading: false})
        }).catch((e)=>{
            toast.error('something wrong happened on getting subject data.')
        })
    }

    handleSort = (clickedColumn) => () => {
        let filter = this.state.filter
        filter.order_by = clickedColumn
        filter.asc = filter.asc === 1 ? 0 : 1
        this.setState({
            filter: filter
        })

        setTimeout(()=>{
            this.setState({afterLoading: true})
            this.loadData()
        }, 100)
    }

    handleChangePagination = (e, data) =>{
        let filter = this.state.filter
        filter.page = data.activePage
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.setState({afterLoading: true})
            this.loadData()
        }, 100)
    }

    handleChangeEntries = (e, data) =>{
        let filter = this.state.filter
        filter.n = data.value
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.setState({afterLoading: true})
            this.loadData()
        }, 100)
    }

    handleActions = (code, e, btn) =>{
        this.setState({handleActionStatus: true})
        let config = {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        }
        let type = btn.id
        let subject_code = code !== '' ? code : this.state.selectedSubject
        if(type === 'delete'){
            config.method = 'delete'
            config.body = JSON.stringify({reason: this.state.reason})
        }
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/subjects/'+type+'/'+subject_code, config)
        .then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                toast.success(type+ ' subject success.')
                this.setState({afterLoading: true})
                this.loadData()
                this.setState({selectedSubject: '', reason: '', banModal: false, handleActionStatus: false})
            }else{
                toast.error('error '+type+ ' user.')
            }
        }).catch((e)=>{
            toast.error('something wrong happened on handling '+type+' of subject.')
        })
    }

    handleFilter = (e, data) =>{
        let filter = this.state.filter
        switch (data.name) {
            case 'search':
                filter.search = data.value
                break;
            case 'verified':
                filter.verified_only = data.checked
                break;
            default:
                filter.published_only = data.checked
                break;
        }
        this.setState({filter: filter})
        setTimeout(()=>{
            this.setState({afterLoading: true})
            this.loadData()
        }, 200)
    }

    downloadContent = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/subjects/download', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.text()
        }).then((res)=>{
            downloadCSV('subjects.csv', res)
        }).catch((e)=>{
            toast.error('something wrong happened on downloading subject.')
        })
    }

    sortChapter = (clickedColumn, target=null) => () =>{
        let data = target !== null ? target : this.state.selectedContent
        let chapter_sort = this.state.chapterSort
        switch (clickedColumn) {
            case 0:
                if(target === null){
                    data.sort(function(a, b){
                        if(a.chapter_name < b.chapter_name) { return -1 }
                        if(a.chapter_name > b.chapter_name) { return 1 }
                        return 0
                    })

                    if(clickedColumn === chapter_sort.order_by && chapter_sort.asc){
                        data.reverse()   
                        chapter_sort.asc = 0
                    }else{
                        chapter_sort.asc = 1
                    }
                }else{
                    data.sort(function(a, b){
                        if(a.chapter_name < b.chapter_name) { return -1 }
                        if(a.chapter_name > b.chapter_name) { return 1 }
                        return 0
                    })
                    chapter_sort.asc = 1
                }
                chapter_sort.order_by = 0
                break;
            case 1:
                data.sort(function(a, b){
                    return a.chapter_completion_rate-b.chapter_completion_rate
                })

                if(clickedColumn === chapter_sort.order_by && chapter_sort.asc){
                    data.reverse()   
                    chapter_sort.asc = 0
                }else{
                    chapter_sort.asc = 1
                }
                chapter_sort.order_by = 1
                break;
            default:
                data.sort(function(a, b){
                    return a.number_of_entries-b.number_of_entries
                })

                if(clickedColumn === chapter_sort.order_by && chapter_sort.asc){
                    data.reverse()   
                    chapter_sort.asc = 0
                }else{
                    chapter_sort.asc = 1
                }
                chapter_sort.order_by = 2
                break;
        }
        if(target !== null){
            this.setState({selectedContent: data, chapterSort: chapter_sort, chapterModal: true})
        }else{
            this.setState({selectedContent: data, chapterSort: chapter_sort})
        }
    }

    renderNumber = (k)=>{
        let n = this.state.filter.n * this.state.filter.page
        n = n - this.state.filter.n + k + 1
        return n
    }

    render() {
        return (
            <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                {this.state.loading ? <Loader size='large' active content="Fetching subject data. Please wait..."/> :
                    <>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <div className="ui icon rounded input">
                                    <Popup content='Push enter button for search' on='focus' position='right center' trigger={
                                        <Input type="text" icon='search' name='search' onKeyPress={(e)=>{
                                            if(e.key === 'Enter'){
                                                this.handleFilter(null, {
                                                    name: 'search',
                                                    value: this.state.filter.search
                                                })
                                            }
                                        }} onChange={(e, data)=>{
                                            let filter = this.state.filter
                                            filter.search = data.value
                                            if(filter.search === ''){
                                                this.handleFilter(null, {
                                                    name: 'search',
                                                    value: ''
                                                })
                                            }
                                            this.setState({filter: filter})
                                        }}
                                        placeholder="Search a word or sentences..."/>
                                    }/>
                                </div>
                                <Checkbox name='verified' toggle onChange={this.handleFilter}
                                label='Verified Subjects Only' style={{marginLeft: 15}}/>
                                <Checkbox name='published' toggle onChange={this.handleFilter}
                                label='Published Subjects Only' style={{marginLeft: 15}}/>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Button primary floated='right' onClick={this.downloadContent}>
                                    <Icon name='download'/> Download Table Data
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Table fixed sortable className='admin' striped>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}>
                            No
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 0 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(0)}
                            >
                            Name
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 1 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(1)}
                            >
                            Owner
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 2 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(2)}
                            >
                            Members
                            </Table.HeaderCell>
                            <Table.HeaderCell sorted={this.state.filter.order_by === 3 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(3)}>
                            Content
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 4 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(4)}
                            >
                            Created Date
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 5 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(5)}
                            >
                            Version
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 6 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(6)}
                            >
                            Last Publish
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Action
                            </Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {this.state.subjectList.map((v, k)=>
                        <Table.Row key={k} className={v.deleted ? 'banned':null}>
                            <Table.Cell>{this.renderNumber(k)}</Table.Cell>
                            <Table.Cell>
                                {v.subject_name} {v.verified ? <Icon name='check circle' color='green'/>:null}
                            </Table.Cell>
                            <Table.Cell>{v.owner_username}</Table.Cell>
                            <Table.Cell>
                                <p>Managers: <b>{v.members_manager}</b></p>
                                <p>Contributors: <b>{v.members_contributor}</b></p>
                            </Table.Cell>
                            <Table.Cell>
                                <p>Chapters: <b>{v.content_chapters}</b></p>
                                <p>Entries: <b>{v.content_entries}</b></p>
                                <div className='seeDetail' onClick={this.sortChapter(0, v.content_details)}>See details</div>
                            </Table.Cell>
                            <Table.Cell>{v.date_of_creation}</Table.Cell>
                            <Table.Cell>{v.published_version}</Table.Cell>
                            <Table.Cell>{v.published_publication}</Table.Cell>
                            <Table.Cell>
                                {v.deleted ?  
                                <Button size='mini' color='red' disabled>Deleted</Button>
                                :
                                <>
                                {v.verified ? 
                                    <Button size='mini' onClick={this.handleActions.bind(null, v.subject_code)}
                                    id='unverify' secondary disabled={this.state.handleActionStatus}>
                                        <Icon name='remove circle' color='red'/> Unverify
                                    </Button>
                                : 
                                    <Button size='mini' onClick={this.handleActions.bind(null, v.subject_code)}
                                    id='verify' secondary disabled={this.state.handleActionStatus}>
                                        <Icon name='check circle' color='green'/> Verify
                                    </Button>
                                }
                                <Button size='mini'
                                onClick={()=>this.setState({banModal: true, selectedSubject: v.subject_code})}
                                secondary>
                                    <Icon name='trash' color='blue'/> Delete
                                </Button>
                                </>
                                }
                            </Table.Cell>
                        </Table.Row>
                        )}
                        </Table.Body>
                    </Table>
                    
                    {/* pagination */}
                    <Divider hidden/>
                    <div>
                        Show <Dropdown defaultValue={10} compact selection options={[
                            {key: 10, text: '10', value: 10},
                            {key: 25, text: '25', value: 25},
                            {key: 50, text: '50', value: 50},
                            {key: 100, text: '100', value: 100},
                        ]} onChange={this.handleChangeEntries} /> entries
                        <div className='pagination'>
                            <span style={{marginRight: 10}}>
                                Showing {this.state.subjectList.length} of {this.state.totalRecord} entries
                            </span>
                            <Pagination activePage={this.state.filter.page} size='small' onPageChange={this.handleChangePagination} 
                            totalPages={this.state.totalPage} />
                        </div>
                    </div>
                    </>
                }

                <Modal open={this.state.banModal} size='tiny'>
                    <Modal.Header>Delete Subject</Modal.Header>
                    <Modal.Content>
                        <p>Why do you delete this subject?</p>
                        <Form>
                            <Form.TextArea onChange={(e, data)=>this.setState({reason: data.value})} 
                            placeholder='Tell your reason here...'/>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className='tertiary'
                        onClick={()=>this.setState({banModal: false})}>Cancel</Button>
                        <Button id='delete' disabled={this.state.reason === '' || this.state.handleActionStatus ? true : false} 
                        negative onClick={this.handleActions.bind(null, '')} loading={this.state.handleActionStatus}>Delete Subject</Button>
                    </Modal.Actions>
                </Modal>

                <Modal open={this.state.chapterModal} size='small' closeIcon 
                onClose={()=>this.setState({chapterModal: false, selectedContent:[]})}>
                    <Modal.Content scrolling>
                        <Table fixed sortable className='admin' striped>
                            <Table.Header>
                                <Table.Row>
                                <Table.HeaderCell width={1}>
                                No
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                sorted={this.state.chapterSort.order_by === 0 ? 
                                    this.state.chapterSort.asc === 1 ? 'ascending' : 'descending'    
                                : null}
                                onClick={this.sortChapter(0)}
                                >
                                Chapter Name
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                sorted={this.state.chapterSort.order_by === 1 ? 
                                    this.state.chapterSort.asc === 1 ? 'ascending' : 'descending'    
                                : null}
                                onClick={this.sortChapter(1)}
                                >
                                Completion Percentage
                                </Table.HeaderCell>
                                <Table.HeaderCell
                                sorted={this.state.chapterSort.order_by === 2 ? 
                                    this.state.chapterSort.asc === 1 ? 'ascending' : 'descending'    
                                : null}
                                onClick={this.sortChapter(2)}
                                >
                                Number of Entries
                                </Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.selectedContent.map((v,k)=>
                                <Table.Row key={k}>
                                    <Table.Cell>{k+1}</Table.Cell>
                                    <Table.Cell>{v.chapter_name}</Table.Cell>
                                    <Table.Cell>{v.chapter_completion_rate} %</Table.Cell>
                                    <Table.Cell>{v.number_of_entries}</Table.Cell>
                                </Table.Row>
                                )}
                            </Table.Body>
                        </Table>
                    </Modal.Content>
                </Modal>

                <Modal open={this.state.afterLoading} size='mini' basic>
                    <Modal.Content>
                        <Loader active size='large' content='Fetching subject data. Please wait...'/>
                    </Modal.Content>
                </Modal>
            </Container>
        )
    }
}

export default connect(mapStateToProps)(SubjectsPage)