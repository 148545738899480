import React, {useEffect, useState} from 'react'
import { Segment, Icon, Divider,
    Dropdown, Popup, Checkbox, List } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { filterSubject, loadSubject } from '../../store/actions/subject'
import './style.scss'
import storage from 'redux-persist/lib/storage';

// filter component for subject
const FilterComponent = (props) =>{
    const dispatch = useDispatch()
    const filterRedux = useSelector(x=>x.filter_subject)
    const userInfo = useSelector(x=>x.auth)
    const [catOpened, setCatOpened] = useState(false)
    const [categories, setCategory] = useState([])
    const [selectedSort, setSelectedSort] = useState('0-d')
    const [selectedCategory, setSelectedCategory] = useState({name: 'All Categories', id: 0})
    const sortList = [
        {key: '0-d',text: 'Most Popular',value: '0-d'},
        {key: '0-a',text: 'Most Unpopular',value: '0-a'},
        {key: '1-d',text: 'Most Contributors',value: '1-d'},
        {key: '1-a',text: 'Least Contributors',value: '1-a'},
        {key: '2-d',text: 'Alphabetically A - Z',value: '2-a'},
        {key: '2-a',text: 'Alphabetically Z - A',value: '2-d'},
        {key: '3-d',text: 'Latest Update',value: '3-d'},
        {key: '3-a',text: 'Old Update',value: '3-a'},
    ]
    const [listFilter, setListFilter] = useState([
        {name: 'Verified Subject', value: 0, checked: false},
        {name: 'Public Subject', value: 1, checked: false},
        {name: 'Created by Official', value: 2, checked: false},
        {name: 'Created by Me', value: 3, checked: false},
        {name: 'Drafted Subject', value: 4, checked: false},
        {name: 'Published Subject', value: 5, checked: false}
    ])

    useEffect(()=>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/categories/menu', {
            headers: new Headers({
                'Authorization': 'Token '+userInfo.user.token
            })
        }).then(function(response) {
            return response.json()
        }).then(function(response) {
            if(response.status !== undefined){
                setCategory(response.data)
            }else{
                storage.removeItem('persist:root')
                window.location.href='/'
            }
        })
    },[userInfo])

    // category selection filter
    const handleCategorySelect = (id, sub_id = 0) =>{
        let sCat = categories.filter(x=>x.id === id)
        let idc = 0
        if(sCat.length > 0){
            sCat = sCat[0]
            let c_name = sCat.name
            idc = sCat.id
            if(sub_id !== 0){
                let subct = sCat.sub_list.filter(x=>x.id === sub_id)
                subct = subct[0]
                c_name = sCat.name +' / '+subct.name
                idc = subct.id
            }
            setSelectedCategory({name: c_name, id:idc})
        }else{
            setSelectedCategory({name: 'All Categories', id: 0})
        }
        setCatOpened(false)

        let filter = filterRedux === null ? {
            search: '',
            sort: '0-d',
            category: '',
            filter:[],
            n: '6',
            page: '1'
        } : filterRedux
        filter.category = idc
        dispatch(filterSubject(null))
        dispatch(filterSubject(filter))
        dispatch(loadSubject(true))
    }

    // sort change filter
    const handleSortChange = (data) =>{
        let filter = filterRedux === null ? {
            search: '',
            sort: '0-d',
            category: '',
            filter:[],
            n: '6',
            page: '1'
        } : filterRedux
        filter.sort = data.value
        setSelectedSort(data.value)
        dispatch(filterSubject(null))
        dispatch(filterSubject(filter))
        dispatch(loadSubject(true))
    }

    // fired every time filter component changed
    const handleFilter = (e, data) =>{
        let localFilterList = listFilter
        let idxF = listFilter.findIndex(x=>x.value === data.value)
        localFilterList[idxF].checked = data.checked
        setListFilter(localFilterList)

        let filter = filterRedux === null ? {
            search: '',
            sort: '0-d',
            category: '',
            filter:[],
            n: '6',
            page: '1'
        } : filterRedux
        const idx = filter.filter.findIndex(x=>x === data.value)
        if(idx !== -1){
            if(!data.checked){
                filter.filter.splice(idx, 1)
            }else{
                filter.filter.push(data.value)
            }
        }else{
            filter.filter.push(data.value)
        }
        dispatch(filterSubject(null))
        dispatch(filterSubject(filter))
        dispatch(loadSubject(true))
    }

    // reset filter to default
    const clearFilter = () =>{
        setSelectedSort('0-d')
        setSelectedCategory({name: 'All Categories', id: 0})
        setListFilter([
            {name: 'Verified Subject', value: 0, checked: false},
            {name: 'Public Subject', value: 1, checked: false},
            {name: 'Created by Official', value: 2, checked: false},
            {name: 'Created by Me', value: 3, checked: false},
            {name: 'Drafted Subject', value: 4, checked: false},
            {name: 'Published Subject', value: 5, checked: false}
        ])
        let filter = {
            search: '',
            sort: '0-d',
            category: '',
            filter:[],
            n: '6',
            page: '1'
        }
        dispatch(filterSubject(null))
        dispatch(filterSubject(filter))
        dispatch(loadSubject(true))
    }

    return(
        <Segment id='sidebar_segment'>
            {props.is_preq === undefined ?
                <div>
                    <Link to='/subject/add' 
                    className='ui primary button' id='btn_add_subject'>
                        <Icon name='add'/> New Subject
                    </Link>
                    <Divider hidden/>
                </div>
                : ''
            }
            <b>Sort By</b>
            <Dropdown id='sortby' style={{fontSize:12}}
                placeholder='Select Friend'
                fluid
                selection
                value={selectedSort}
                options={sortList}
                onChange={(e, data)=>handleSortChange(data)}
            />
            <Divider hidden/>
            <b>Category</b><br/>
            <Popup open={catOpened} trigger={<p onClick={()=>setCatOpened(!catOpened)}>{selectedCategory.name} <Icon name='caret right'/></p>} hideOnScroll={false} basic hoverable position='right center'>
                <List selection verticalAlign='middle'>
                    <List.Item onClick={handleCategorySelect.bind(this, 0, 0)}>
                        <List.Content>
                            <List.Header>All Categories</List.Header>
                        </List.Content>
                    </List.Item>
                    {categories.map((v,k)=><List.Item key={k}>
                        {v.sub_list.length > 0 ? <List.Content floated='right'>
                            <Icon name='caret right'/>
                        </List.Content>
                         : ''}
                        <Icon name={v.icon}/>
                        {v.sub_list.length > 0 ? 
                        <Popup trigger={
                            <List.Content onClick={handleCategorySelect.bind(null, v.id, 0)}>
                                <List.Header>{v.name}</List.Header>
                            </List.Content>
                        } hideOnScroll={false} basic hoverable position='right center'>
                            <List selection verticalAlign='middle'>
                                {v.sub_list.map((o, p)=><List.Item key={p} onClick={handleCategorySelect.bind(null, v.id, o.id)}>
                                <Icon name={o.icon}/>
                                <List.Content>
                                    <List.Header>{o.name}</List.Header>
                                </List.Content>
                                </List.Item>)}
                            </List>
                        </Popup>
                         : <List.Content onClick={handleCategorySelect.bind(null, v.id, 0)}>
                            <List.Header>{v.name}</List.Header>
                        </List.Content>}
                    </List.Item>
                    )}
                    
                </List>
            </Popup>
            <Divider hidden/>
            <b>Filter</b><br/>
            {listFilter.map((v,k)=>{
                let path = window.location.pathname
                let comp = ''
                if(path === '/browse' || props.is_preq !== undefined){
                    if(v.value !== 4 && v.value !== 3){
                        comp = <Checkbox key={k} value={v.value} onChange={handleFilter} label={v.name} checked={v.checked} />
                    }
                }else{
                    if(v.value !== 5){
                        comp = <Checkbox key={k} value={v.value} onChange={handleFilter} label={v.name} checked={v.checked} />
                    }
                }
                return comp
            })}
            <Divider hidden/>
            <b onClick={clearFilter} className='clear'>Clear All</b>
        </Segment>
    )
}

export default FilterComponent