import ReactGA from 'react-ga4'

export const PlatformInit = () =>{
    return [
        {text: 'DOC Game App', value: 'doc', active: false, icon:'gamepad'},
        {text: 'Telegram Chatbot', value: 'chatbot', active: false, icon:'telegram'},
        {text: 'edbot.ai', value: 'webed', active: false, icon:'globe'}
    ]
}

export const CheckExistData = async(param) =>{
    if(param.identifier === null){
        window.location.href = '/not-found'
        return false
    }
    let response = await fetch(process.env.REACT_APP_API_URL+'api/v1/reference-check/'+param.category+'/'+param.identifier, {
        headers: param.header
    })
    let data = await response.json()
    return data;
}

export const CheckBadWords = async(param) =>{
    let response = await fetch(process.env.REACT_APP_API_URL+'api/v1/bad-words-check?text='+param.text, {
        headers: param.header
    })
    let data = await response.json()
    return data;
}

export const UsePageViews = (path) =>{
    ReactGA.initialize(process.env.REACT_APP_TRACKING_ID);
    ReactGA.send({ hitType: "pageview", page: path });
}