import React from 'react';
import { Button, Divider, Form, Header, Icon, Image, Input, Message, Modal, Popup } from 'semantic-ui-react';
import {
    DateInput
} from 'semantic-ui-calendar-react';
import ImageCropperComponent from '../../components/image_cropper'
import moment from 'moment-timezone'
import {toast} from 'react-toastify';

export default class RCPassageForm extends React.Component{
    constructor(props){
        super(props)
        let data = localStorage.getItem('passage')
        data = JSON.parse(data)
        let qgen_data = localStorage.getItem('qgen_data')
        let qid = localStorage.getItem('rc_edit_id')
        qgen_data = JSON.parse(qgen_data)
        let formTemp = {
            topic: {
                value: '',
                is_touch: false
            },
            passage: {
                value: '',
                is_touch: false
            },
            source_type: 'Web Article',
            source_title: {
                value: '',
                is_touch: false
            },
            source_url: {
                value: '',
                is_touch: false
            },
            retrieve_date: moment(new Date()).format('YYYY-MM-DD'),
            image: '',
            show_image: 0,
            reading_time: 1,
            is_automatic: 1
        }
        if(data !== null){
            formTemp.topic.value = data.topic
            formTemp.passage.value = data.passage
            formTemp.source_type = data.source_type
            formTemp.source_title.value = data.source_title
            formTemp.source_url.value = data.source_url
            formTemp.retrieve_date = data.retrieve_date
            formTemp.image = data.image
            formTemp.show_image = data.show_image
            formTemp.reading_time = data.reading_time
            formTemp.is_automatic = data.is_automatic
        }
        this.state = {
            form: formTemp,
            cropModal: false,
            saveDisabled: data !== null ? false : true,
            qgen_data: qgen_data,
            question_id: qid,
            modalConfirm: false
        }
    }

    fileInput = null

    formChange = (e, data) =>{
        let form = this.state.form
        let is_error = false
        switch (data.name) {
            case 'topic':
                form.topic.value = data.value
                form.topic.is_touch = true
                break;
            case 'passage':
                form.passage.value = data.value
                form.passage.is_touch = true
                break;
            case 'source_type':
                form.source_type = data.value
                break;
            case 'source_title':
                form.source_title.value = data.value
                form.source_title.is_touch = true
                break;
            case 'source_url':
                form.source_url.value = data.value
                form.source_url.is_touch = true
                break;
            case 'show_image':
                form.show_image = data.value
                break;
            case 'reading_time':
                if(data.value.charAt(0) === '0'){
                    data.value = data.value.replace('0','');
                }
                form.reading_time = data.value
                break;
            default:
                form.is_automatic = data.value
                break;
        }

        if(form.topic.value === ''){
            is_error = true
        }

        if(form.passage.value === ''){
            is_error = true
        }

        if(form.source_type === 'Web Article' || form.source_type === 'Text Book'){
            if(form.source_title.value === ''){
                is_error = true
            }
            if(form.retrieve_date === ''){
                is_error = true
            }
            if(form.source_type === 'Web Article' && form.source_url.value === ''){
                is_error = true
            }
        }

        this.setState({form: form, saveDisabled: is_error})
    }

    onChangeImage = (e) =>{
        e.preventDefault();
        let files;
        if(e.target.files[0] === undefined){
            return false
        }
        
        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            return false
        }

        if(e.target.files[0].size > 5000000){
            toast.error('Image should be equal to or less than 5MB.')
            return false
        }
        
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        const reader = new FileReader();
        reader.onload = () => {
            let form = this.state.form
            form.image = reader.result
            this.setState({form: form, cropModal: true})
        };
        reader.readAsDataURL(files[0]);
    }

    // set image value after crop
    setImageDisplay = (src) =>{
        this.fileInput.value = null
        let form = this.state.form
        if(src === null){
            form.image = null
            this.setState({form: form, cropModal: false})
            return false
        }
        form.image = src
        this.setState({form: form, cropModal: false})
    }

    continueAction = () =>{
        let data = localStorage.getItem('passage')
        data = JSON.parse(data)
        data = data !== null ? data : {passage: ''}
        if(this.state.question_id !== null && this.state.form.passage.value !== data.passage && this.state.form.is_automatic){
            this.setState({modalConfirm: true})
        }else{
            this.props.action({type: 'passage', data: this.state.form})
        }
    }

    cancelAction = () =>{
        let data = localStorage.getItem('passage')
        data = JSON.parse(data)
        data = data !== null ? data : {passage: ''}
        let form = this.state.form
        form.passage.value = data.passage
        this.setState({modalConfirm: false, form: form})
    }

    render() {
      return (
        <>
        <Header as='h2'>
            Add New Passage
        </Header>
        <Form>
            <Form.Input label='Title' required placeholder='Topic' value={this.state.form.topic.value} onChange={this.formChange} name='topic' 
            error={this.state.form.topic.value === '' && this.state.form.topic.is_touch ? { content: 'Topic is required', pointing: 'above' } : false}/>
            <Form.TextArea label='Passage' required id="passage_text" placeholder='Enter the passage here' value={this.state.form.passage.value} onChange={this.formChange} name='passage'
            error={this.state.form.passage.value === '' && this.state.form.passage.is_touch ? { content: 'Passage is required', pointing: 'above' } : false}/>
            <Form.Group widths='equal'>
                <Form.Dropdown selection placeholder='Source Type' label='Source Type' required options={[
                    {text: 'Web Article', value: 'Web Article', key: 'Web Article'},
                    {text: 'Original', value: 'Original', key: 'Original'},
                    {text: 'Text Book', value: 'Text Book', key: 'Text Book'},
                ]} value={this.state.form.source_type} onChange={this.formChange} name='source_type'/>
                
                {this.state.form.source_type === 'Web Article' || this.state.form.source_type === 'Text Book' ? 
                <Form.Input placeholder='Source Title' value={this.state.form.source_title.value}
                onChange={this.formChange} name='source_title' label='Source Title' required
                error={this.state.form.source_title.value === '' && this.state.form.source_title.is_touch ? { content: 'Source Title is required', pointing: 'above' } : false}/>
                : null}

                {this.state.form.source_type === 'Web Article'? 
                <Form.Input placeholder='Source URL' value={this.state.form.source_url.value}
                onChange={this.formChange} name='source_url' label='Source URL' required
                error={this.state.form.source_url.value === '' && this.state.form.source_url.is_touch ? { content: 'Source URL is required', pointing: 'above' } : false}/>
                : null}
                
                {this.state.form.source_type === 'Web Article' || this.state.form.source_type === 'Text Book' ? 
                <DateInput
                    placeholder='Retrieve Date'
                    animation='scale'
                    duration={0}
                    hideMobileKeyboard
                    value={this.state.form.retrieve_date}
                    iconPosition='right'
                    preserveViewMode={false}
                    autoComplete='off'
                    dateFormat='YYYY-MM-DD'
                    closable
                    className='test'
                    onChange={(event, {name, value})=>{
                        let form = this.state.form
                        form.retrieve_date = value
                        this.setState({form: form})
                    }}
                    label="Retrieve Date"
                    required
                />
                : null}
            </Form.Group>

            <Form.Field>
            <input style={{display:'none'}} accept="image/jpeg" ref={(file)=>this.fileInput = file} id='q_image' 
            type="file" onChange={this.onChangeImage}/>
            <Popup trigger={
                <Button type='button' secondary onClick={()=>{
                    let form = this.state.form
                    form.image = null
                    this.setState({form: form})
                    this.fileInput.click()
                }}>Upload Image (optional)</Button>
            } content='Image size (Max: 5MB)'></Popup>
            {this.state.form.image !== null && this.state.form.image !== '' ? 
            <>
            <Divider hidden/>
            <Image src={this.state.form.image} 
            style={{marginTop: 5}} size='medium' label={{ as: 'a', corner: 'right', icon: 'trash', color: 'red', onClick:()=>{
                let form = this.state.form
                form.image = null
                this.setState({form: form})
            } }}/>
            </>
            : null}

            <ImageCropperComponent open={this.state.cropModal} loading={false} imgSrc={this.state.form.image} fileInput={this.fileInput} 
            ratio={1 / 1} act={this.setImageDisplay}/>
            </Form.Field>

            {this.state.form.image !== null && this.state.form.image !== '' ? 
            <Form.Dropdown selection options={[
                {text: 'Before the Passage', value: 0, key: 0},
                {text: 'After the Passage', value: 1, key: 1},
            ]} inline label='Show Image' value={this.state.form.show_image} name='show_image' 
            onChange={this.formChange}/>
            : null}
            
            <Form.Field inline required>
                <label>Set Reading Time</label>
                <Input type='number' name='reading_time' value={this.state.form.reading_time} onChange={this.formChange}
                min={1}/>
                <span> minutes</span>
            </Form.Field>
            {this.state.question_id === null ? 
            <Form.Field required>
                <label>Passage Questions</label>
                <Form.Radio
                    className='radioCast'
                    label='Generate Questions Automatically'
                    value={1}
                    style={{fontWeight:500}}
                    name='is_automatic'
                    checked={this.state.form.is_automatic === 1 ? true : false}
                    onChange={this.formChange}
                />
                <Form.Radio
                    className='radioCast'
                    label='Input Questions Manually'
                    value={0}
                    name='is_automatic'
                    checked={this.state.form.is_automatic === 0 ? true : false}
                    onChange={this.formChange}
                />
            </Form.Field>
            : 
            <Message color={this.state.form.is_automatic ? 'green' : 'blue'}>
                <Icon name='exclamation circle'/> {this.state.form.is_automatic ? 'Question Generated Automatically' : 'Input Questions Manually'}
            </Message>}
            
            <Form.Button floated='right' content='Continue' primary disabled={this.state.saveDisabled}
            onClick={this.continueAction}/>
        </Form>

        <Modal size="mini" open={this.state.modalConfirm}>
            <Modal.Content>
                <p>This question is generated automatically if you change anything in this page, you need to wait until the question is regenerated. Do you want to continue?</p>
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={this.cancelAction}>No, reset back</Button>
                <Button primary onClick={this.props.action.bind(null, {type: 'passage', data: this.state.form})}>Yes</Button>
            </Modal.Actions>
        </Modal>
        </>
      )
    };
}