import React, {createRef} from 'react';
import {Grid, Menu} from 'semantic-ui-react'
import './style.scss'
import {
    Route, Switch, Link
  } from "react-router-dom";
import ProfileSubjectSection from './subject';
import ProfileActivitySection from './activity';
import ProfileSettingSection from './setting';
import ProfileInfoSection from './info';
import { connect } from 'react-redux';
import {CheckExistData} from '../../helpers/global';

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth
    }
}

class ProfilePage extends React.Component{
    constructor(props){
        super(props)
        if(this.props.match.params.username !== 'me'){
            CheckExistData({
                header: new Headers({
                    'Authorization': 'Token '+this.props.auth.user.token
                }),
                category: 'user',
                identifier: this.props.match.params.username
            }).then(val=>{
                if(!val.status){
                    window.location.href = '/not-found'
                    return false
                }
            })
        }
    }
    contextRef = createRef()
    render() {
        let path = this.props.location.pathname.split('/')
        path = path[path.length - 1]
        return (
            <Grid columns='equal'>
                <Grid.Row>
                    <Grid.Column width='4'>
                        <ProfileInfoSection {...this.props}/>
                    </Grid.Column>
                    <Grid.Column>
                        <Menu id='profile_menu'>
                            <Menu.Item as={Link} to={'/profile/'+this.props.match.params.username}
                                icon='folder'
                                name='subject'
                                active={path !== 'activity' && path !== 'setting' ? true : false}
                            />
                            <Menu.Item as={Link} to={'/profile/'+this.props.match.params.username+'/activity'}
                                icon='history'
                                name='activity history'
                                active={path === 'activity' ? true : false}
                            />

                            {this.props.match.params.username === 'me' ? 
                            <Menu.Menu position='right'>
                                <Menu.Item as={Link} to={'/profile/'+this.props.match.params.username+'/setting'}
                                    icon='setting'
                                    name='Profile Setting'
                                    active={path === 'setting' ? true : false}
                                />
                            </Menu.Menu> : null}
                        </Menu>
                        
                        <Switch>
                            <Route path="/profile/:username" exact>
                                <ProfileSubjectSection {...this.props}/>
                            </Route>
                            <Route path="/profile/:username/activity" exact>
                                <ProfileActivitySection {...this.props}/>
                            </Route>
                            {this.props.match.params.username === 'me' ? 
                            <Route path="/profile/:username/setting" exact>
                                <ProfileSettingSection/>
                            </Route> : null}
                        </Switch>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }
}

export default connect(mapStateToProps)(ProfilePage)