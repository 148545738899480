import React, { useState } from 'react'
import { Form } from 'react-advanced-form'
import { useDispatch } from 'react-redux'
import { loginAction } from '../../store/actions/auth'
import { navAction } from '../../store/actions/nav'
import { toast } from 'react-toastify';
import {Checkbox, Divider} from 'semantic-ui-react'

/* Fields */
import {Input} from '../fields'
import { Button, Icon } from 'semantic-ui-react'

const LoginForm = () =>{
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch();
    const registerUser = ({ serialized }) => {
        setLoading(true)
        fetch(process.env.REACT_APP_API_URL+'api/v1/auth/login/', {
            method: 'post',
            body: JSON.stringify(serialized)
        }).then(function(response) {
            return response.json()
        }).then(function(response) {
            setLoading(false)
            let data = response
            if(data.status){
                dispatch(loginAction(response.data))
                dispatch(navAction('subject'))
            }else{
                toast.dismiss();
                toast.error('Cannot login, please check your credential!');
            }
        })
    
        return new Promise((resolve) => resolve())
    }
    return (
        <Form action={registerUser} className='ui form'>
            <Input label='Username or Email'
            name="username"
            required placeholder='Type your username or email...'/>
            <Input label='Password'
            name="password"
            type="password" placeholder='Type your password...'
            required />
            <div className='loginHeader'>
                <Checkbox label='Remember Me'/>
                <a href='/forgot' rel='forgot'>Forgot Password?</a>
            </div>
            <Divider hidden/>
            <Button id="login_btn" fluid primary loading={loading} disabled={loading}>
                <Icon name='sign in'/> Login
            </Button>
        </Form>
    )
}



export default LoginForm