import React from 'react'
import { Grid, Pagination, Divider, Dropdown, Loader, Image, Header, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import {connect} from 'react-redux'
import storage from 'redux-persist/lib/storage';

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth
    }
}

class UserSection extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            activePage: 1,
            totalPage: 0,
            users: [],
            loading: true,
            search: localStorage.getItem('main_search')
        }
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = (activePage = 1, filter = 2) =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/search/users/all?keyword='+this.state.search, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify({
                page: activePage,
                n: 10,
                filter: filter
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                localStorage.removeItem('main_search')
                window.location.href = '/'
            }

            this.setState({loading: false, users: res.data, totalPage: res.total_page !== undefined ? res.total_page : 1})
        })
    }

    handleChangePage = (data) =>{
        this.setState({activePage: data})
        this.loadData(data)
    }

    filterUser = (e, d) =>{
        this.loadData(this.state.activePage, d.value)
    }

    render() {
        let filter = [
            {text: 'Verified user', key:'1', value: 0},
            {text: 'Most active', key:'2', value: 1},
            {text: 'Last created', key:'3', value: 2},
            {text: 'Connected to me', key:'4', value: 3}
        ]
        return (
            <div style={{paddingBottom: 50}}>
                <p style={{fontSize: 18}}>Search Result for “<b>{this.state.search}</b>”</p>
                <div className='searchHeader'>
                    <Link to='/search'>Back to All Search</Link>
                    <div>
                        <span style={{marginRight:5}}>sort by</span>
                        <Dropdown onChange={this.filterUser} defaultValue={0} labeled selection options={filter}/>
                    </div>
                </div>
                <Divider/>
                {this.state.loading ? <Loader active/> : <>
                    <Grid className='userSection'>
                        <Grid.Row columns={5}>
                        {this.state.users.map((v, k)=>
                            <Grid.Column key={k} as={Link} to={'/profile/'+v.username}>
                                <Image circular src={
                                    v.avatar !== null && v.avatar !== '' ? v.avatar
                                    : require('../../assets/images/avatar-default.png')
                                }/>
                                <Header as='h3' textAlign='center'>
                                    {v.username} {v.verified ? <Icon name='check circle' color='green' size='small'/> : null}
                                </Header>
                            </Grid.Column>
                        )}
                        </Grid.Row>
                    </Grid>
                    <Divider hidden/>
                    <div style={{display:'flex', justifyContent: 'center'}}>
                        <Pagination onPageChange={(event, data) => this.handleChangePage(data.activePage)} 
                            defaultActivePage={this.state.activePage} totalPages={this.state.totalPage} />
                    </div>
                </>}
            </div>
        )
    }
}
export default connect(mapStateToProps)(UserSection)