import React from "react";
import { Button, Icon, Container, Card, Header, Image, Popup, Loader } from "semantic-ui-react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import storage from 'redux-persist/lib/storage';
import { PlatformInit } from "../../../../helpers/global";
import EmptyComponent from "../../../../components/empty";
import * as Sentry from "@sentry/browser";

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        chapter_step_active: state.chapter_step_active,
        chapter_request_data: state.chapter_request_data
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setStepActiveIndex: (n) => dispatch({ type: 'CHAPTER_INDEX_ACTIVE', payload: n }),
        setChapterRequestData: (n) => dispatch({ type: 'CHAPTER_REQUEST_DATA', payload: n })
    }
}

// display teaching format/module list
class TeachingFormatSection extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            teachingFormats: [],
            loading: true,
            is_mounted: false,
            platform: PlatformInit(),
            selectedPlatform: ''
        }
    }

    // fetching module data
    componentDidMount(){
        this.setState({is_mounted: true})
        setTimeout(()=>{
            if(this.state.is_mounted){
                this.loadData()
            }
        }, 0)
    }

    loadData = () =>{
        let url = process.env.REACT_APP_API_URL+'api/v1/modules'
        url = this.state.selectedPlatform !== '' ? url+'?platform='+this.state.selectedPlatform : url
        this.setState({loading: true})
        fetch(url, {
            headers: this.props.myHeaders
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(this.state.selectedPlatform === ''){
                let chaperRequestData = this.props.chapter_request_data
                chaperRequestData.teaching_format = res.data
                this.props.setChapterRequestData(chaperRequestData)
            }
            this.setState({loading: false, teachingFormats: res.data})
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    componentWillUnmount(){
        this.setState({is_mounted: false})
    }

    // go back to chapter section
    backToTitleStep = () =>{
        this.props.setStepActiveIndex(2)
    }

    // when click the module/teaching format
    cardSelect = (moduleId, e) =>{
        let chaperRequestData = this.props.chapter_request_data
        chaperRequestData.module_id = moduleId
        chaperRequestData.platforms = this.state.platform
        this.props.setChapterRequestData(chaperRequestData)
        this.props.setStepActiveIndex(4)
    }

    filterPlatform = (e, data) =>{
        let platform = this.state.platform
        platform = platform.filter(x=>x.value !== '').map(x=>{
            if(x.value === data.id){
                x.active = !x.active
            }
            return x
        })
        let selected = platform.filter(x=>x.active).map(x=>{
            return x.value
        }).join(',')

        this.setState({platform: platform, selectedPlatform: selected})
        setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    render() {
        if(this.state.is_mounted){
            return (
                <div>
                    <Container textAlign='center'>
                        <Header as='h2' style={{color: 'rgba(3, 28, 48, 0.65)'}}>Choose a suitable teaching format.</Header>
                        <p>The most appropriate minigames will be recommended to you after you choose one of the following formats.</p>
                        <Button.Group className='platformSwitch'>
                            {this.state.platform.map((v,k)=>
                            <Button key={k} secondary id={v.value} active={v.active}
                            onClick={this.filterPlatform}>
                                <Icon name={v.icon}/> {v.text}
                            </Button>
                            )}
                        </Button.Group>
                        {this.state.loading ? 
                            <Loader active/>
                            : this.state.teachingFormats.length > 0 ? 
                            <Card.Group className='teachingFormat' itemsPerRow={5} centered>
                                {this.state.teachingFormats.map((v, k)=>
                                    <Card key={k} onClick={this.cardSelect.bind(null, v.module_id)} link>
                                        <Card.Content>
                                            <Image src={v.logo}/>
                                            <Header as='h4'>{v.teaching_material_name}</Header>
                                        </Card.Content>
                                    </Card>
                                )}
                            </Card.Group>
                            : <EmptyComponent/>
                        }
                        <Popup basic content='The original format of the content that you have or would like to create.' trigger={<span style={{color:'grey', cursor:'pointer', 
                        textDecoration:'underline'}}>What is teaching format?</span>}/>
                    </Container>
                    <Button className='tertiary' onClick={this.backToTitleStep}>
                        <Icon name='arrow left'/> Back to set chapter’s completion percentage
                    </Button>
                </div>
            )
        }
        return null
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TeachingFormatSection)