import React from 'react'
/* Fields */
import { Textarea } from '../fields'
import { Header, Divider } from 'semantic-ui-react'

// module 11 form
const Module11Form = (props) =>{
    return <>
        <td>
            <Header as='h5' color='green'>Player Script</Header>
            <Textarea name="script1" required placeholder='Type here...' wlength={90}/>
            <Divider hidden/>
            <Header as='h5' color='red'>Wrong Script</Header>
            <Textarea name="wrong1_script1" required placeholder='Type here...' wlength={120}/>
            <Textarea name="wrong2_script1" required placeholder='Type here...' wlength={120}/>
            <Textarea name="wrong3_script1" required placeholder='Type here...' wlength={120}/>
        </td>
        <td>
            <Textarea label='Reply' name="reply1" required placeholder='Type here...' wlength={140}/>
        </td>
        <td>
            <Header as='h5' color='green'>Player Script</Header>
            <Textarea name="script2" required placeholder='Type here...' wlength={90}/>
            <Divider hidden/>
            <Header as='h5' color='red'>Wrong Script</Header>
            <Textarea name="wrong1_script2" required placeholder='Type here...' wlength={120}/>
            <Textarea name="wrong2_script2" required placeholder='Type here...' wlength={120}/>
            <Textarea name="wrong3_script2" required placeholder='Type here...' wlength={120}/>
        </td>
        <td>
            <Textarea label='Reply' name="reply2" required placeholder='Type here...' wlength={140}/>
        </td>
        <td>
            <Header as='h5' color='green'>Player Script</Header>
            <Textarea name="script3" required placeholder='Type here...' wlength={90}/>
            <Divider hidden/>
            <Header as='h5' color='red'>Wrong Script</Header>
            <Textarea name="wrong1_script3" required placeholder='Type here...' wlength={120}/>
            <Textarea name="wrong2_script3" required placeholder='Type here...' wlength={120}/>
            <Textarea name="wrong3_script3" required placeholder='Type here...' wlength={120}/>
        </td>
        {
            props.is_adaptive !== undefined && props.is_adaptive ?
            <>
            <td>
                <Textarea label='Reply' initialValue='-' name="reply3" required placeholder='Type here...' wlength={140}/>
            </td>
            <td>
                <Header as='h5' color='green'>Player Script</Header>
                <Textarea name="script4" required placeholder='Type here...' wlength={90} initialValue='-'/>
                <Divider hidden/>
                <Header as='h5' required color='red'>Wrong Script</Header>
                <Textarea required name="wrong1_script4" placeholder='Type here...' wlength={120} initialValue='-'/>
                <Textarea required name="wrong2_script4" placeholder='Type here...' wlength={120} initialValue='-'/>
                <Textarea required name="wrong3_script4" placeholder='Type here...' wlength={120} initialValue='-'/>
            </td>
            <td>
                <Textarea required label='Reply' name="reply4" placeholder='Type here...' wlength={140} initialValue='-'/>
            </td>
            <td>
                <Header as='h5' color='green'>Player Script</Header>
                <Textarea required name="script5" placeholder='Type here...' wlength={90} initialValue='-'/>
                <Divider hidden/>
                <Header as='h5' color='red'>Wrong Script</Header>
                <Textarea required name="wrong1_script5" placeholder='Type here...' wlength={120} initialValue='-'/>
                <Textarea required name="wrong2_script5" placeholder='Type here...' wlength={120} initialValue='-'/>
                <Textarea required name="wrong3_script5" placeholder='Type here...' wlength={120} initialValue='-'/>
            </td>
            </>
            : null
        }
    </>
}

export default Module11Form