import React from 'react'
/* Fields */
import { Input, Textarea } from '../fields'
import {Button, Icon, Grid, Label, Input as UIInput, Divider, Image, Popup} from 'semantic-ui-react';
import ImageCropperComponent from '../../components/image_cropper'
import { toast } from 'react-toastify'


// module 12 form
class Module12Form extends React.Component{
    constructor(props){
        super(props)
        this.contentEditablePassage = React.createRef();
        this.state = {
            passageWidth: 0,
            keywords: this.props.data !== undefined ? this.props.data.question_object.other_keywords.split(',') : [],
            showAnswer: false,
            words: '',
            selectedKey: null,
            keywordsText: this.props.data !== undefined ? this.props.data.question_object.other_keywords : '',
            isTouch: false,
            image: this.props.data !== undefined ? this.props.data.question_object.image : null,
            modalOpen: false,
            showVarError: false
        }
    }

    fileInput = null

    // process the image after attached
    onChangeImage = (e)=>{
        e.preventDefault();
        let files;

        if(e.target.files[0] === undefined){
            return false
        }

        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            return false
        }

        if(e.target.files[0].size > 5000000){
            toast.error('Image should be equal to or less than 5MB.')
            return false
        }
        
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({image: reader.result, modalOpen: true})
        };
        reader.readAsDataURL(files[0]);
    }

    // set image value after crop
    setImageDisplay = (src) =>{
        this.fileInput.value = null
        if(src === null){
            this.setState({image: null, modalOpen: false})
            return false
        }
        this.setState({image: src, modalOpen: false})
    }

    addTag = () =>{
        let word = this.state.words
        if(word.trim() === ''){
            return false
        }
        if(word.length > 20){
            this.setState({showVarError: true})
            return false
        }
        word = word.replace(/[^\w\s]/gi, '')
        let key = this.state.selectedKey
        let keywords = this.state.keywords
        if(word !== ''){
            if(key !== null){
                keywords[key] = word
            }else{
                keywords.push(word)
            }
        }

        this.setState({keywords: keywords, showAnswer: false, words: '', selectedKey: null, 
        keywordsText: keywords.join(','), isTouch: true})
    }

    removeTag = (key, e) =>{
        let keywords = this.state.keywords
        keywords.splice(key, 1)
        this.setState({keywords: keywords, keywordsText: keywords.join(','), isTouch: true})
    }

    render(){
        return <>
            <td>
                <Image style={{width: 100, height: 100}} size='small' src={this.state.image !== null ? 
                    this.state.image : require('../../assets/images/default.png')}/>
                <Input required value={this.state.image} type='hidden' name="image"/>
                <input style={{display:'none'}} accept="image/jpeg" ref={(file)=>this.fileInput = file} id='q_image' 
                type="file" onChange={this.onChangeImage}/>
                <Popup trigger={
                    <Button type='button' size='mini' secondary onClick={()=>{
                        this.setState({image: null})
                        this.fileInput.click()
                    }}>Upload Image</Button>
                } content='Image size (Max: 5MB)'></Popup>
                <ImageCropperComponent open={this.state.modalOpen} loading={false} imgSrc={this.state.image} 
                fileInput={this.fileInput} ratio={1 / 1} act={this.setImageDisplay}/>
            </td>
            <td>
                <Textarea label='Question' name="question" required placeholder='Type here...' wlength={120}/>
            </td>
            <td>
                <Input name="required_keywords" required placeholder='Enter Keywords' wlength={20}/>
                <Divider hidden/>
                <Input name="optional_keywords" placeholder='Enter Keywords(Optional)' wlength={20}/>
            </td>
            <td style={this.state.passageWidth !== 0 ? {width: this.state.passageWidth} : null}>
                <div className='field'>
                    <label>Keywords <b style={{color:'#db2828'}}> *</b></label>
                    <div className={this.state.keywords.length < 3 && this.state.isTouch ? 'editableContent error' : 'editableContent'}>
                        {this.state.keywords.map((v,k)=>
                            <Label key={k} as='a' style={{marginTop: 5}}>
                                <span onClick={()=>this.setState({
                                    showAnswer: true, words: v, selectedKey: k
                                })}>{v}</span>
                                <Icon name='delete' onClick={this.removeTag.bind(null, k)}/>
                            </Label>
                        )}
                    </div>
                    {this.state.keywords.length < 3 && this.state.isTouch ? 
                        <Label color='red' pointing>
                            Not enough keywords
                        </Label>
                    : null}
                    <Textarea input_error={this.state.keywords.length < 3 && this.state.isTouch 
                    ? true : false} 
                    values={this.state.keywordsText} hidden={true} name="other_keywords" required/>
                </div>
                
                {this.state.showAnswer ? 
                    <Grid columns='equal'>
                        <Grid.Column width={10}>
                            <Popup content='Max characters is 20' trigger={
                                <div className='field'>
                                    <UIInput id='keyword_i' placeholder='Type here...' onChange={(e, data)=>this.setState({words: data.value})}
                                    value={this.state.words} onKeyDown={(e)=>{
                                        this.setState({showVarError: this.state.words.length > 20 ? true : false})
                                        if ((e.key === 'Enter' || e.key === 'Tab') && this.state.words.trim() !== '') {
                                            if(this.state.words.length <= 20){
                                                this.addTag()
                                            }
                                        }
                                    }}/>
                                    {this.state.showVarError ? 
                                    <div className="ui red pointing label">Your input is more than 20 characters</div>
                                    : null}
                                </div>
                            }/>
                        </Grid.Column>
                        <Grid.Column>
                            <Button className='tertiary' disabled={this.state.words === '' || this.state.showVarError ? true : false}
                            size='small' type='button' icon onClick={this.addTag}>
                                <Icon color='green' name='check'/>
                            </Button>
                            <Button className='tertiary' 
                            size='small' type='button' icon onClick={()=>this.setState({showAnswer: false})}>
                                <Icon color='grey' name='remove'/>
                            </Button>
                        </Grid.Column>
                    </Grid> : 
                    <div>
                    <Button type='button' secondary size='small' 
                    disabled={this.state.keywords.length === 30 ? true : false}
                    onClick={()=>{
                        this.setState({showAnswer: true, words: ''})
                        setTimeout(()=>{
                            document.getElementById('keyword_i').focus()
                        }, 100)
                    }}>
                        {3 - this.state.keywords.length <= 0 ? 
                        'Add Keyword'
                        : 'Add Keyword ('+(3 - this.state.keywords.length)+')'
                        }
                    </Button>     
                    <i style={{fontSize: 12, color: 'grey'}}>*minimum keywords is 3</i>
                    </div>}
            </td>
        </>
    }
}

export default Module12Form