import React from 'react'
import { Icon, Popup, Button, Modal, Form, Checkbox } from 'semantic-ui-react'
import { toast } from 'react-toastify'

const short = { width: 150 }
const long = { width: 350 }

// column header for module 2
export const Module2Column = (props) => {
    return (
        <tr>
            {props.is_adaptive ?
                <th><Checkbox onChange={(e, data) => props.checkAll(data.checked)} checked={props.is_checked} /> No</th>
                :
                <th>No</th>
            }
            <th style={short}>Word</th>
            <th>Action</th>
        </tr>
    )
}

// column header for module 2 history and review changes
export const Module2ColumnHistory = () => {
    return (
        <tr>
            <th style={short}>Word</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// column header for module 2 add question
export const Module2ColumnQuestion = () => {
    return (
        <tr>
            <th style={short}>Word</th>
        </tr>
    )
}

// column header for module 4
export const Module4Column = (props) => {
    return (
        <tr>
            {props.is_adaptive ?
                <th><Checkbox onChange={(e, data) => props.checkAll(data.checked)} checked={props.is_checked} /> No</th>
                :
                <th>No</th>
            }
            <th style={short}>Image</th>
            <th style={short}>Word</th>
            <th>Action</th>
        </tr>
    )
}

// column header for module 4 history and review changes
export const Module4ColumnHistory = () => {
    return (
        <tr>
            <th style={short}>Image</th>
            <th style={short}>Word</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// column header for module 4 add question
export const Module4ColumnQuestion = () => {
    return (
        <tr>
            <th style={short}>Image</th>
            <th style={short}>Word</th>
        </tr>
    )
}

// column header for module 1
export const Module1Column = (props) => {
    return (
        <tr>
            {props.is_adaptive ?
                <th><Checkbox onChange={(e, data) => props.checkAll(data.checked)} checked={props.is_checked} /> No</th>
                :
                <th>No</th>
            }
            <th style={short}>Word</th>
            <th style={long}>Definition</th>
            <th style={long}>Hint 1</th>
            <th style={long}>Hint 2</th>
            <th style={long}>Hint 3</th>
            <th>Action</th>
        </tr>
    )
}

// column header for module 1 history and review changes
export const Module1ColumnHistory = () => {
    return (
        <tr>
            <th style={short}>Word</th>
            <th style={long}>Definition</th>
            <th style={long}>Hint 1</th>
            <th style={long}>Hint 2</th>
            <th style={long}>Hint 3</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// column header for module 1 add question
export const Module1ColumnQuestion = () => {
    return (
        <tr>
            <th style={short}>Word</th>
            <th style={long}>Definition</th>
            <th style={long}>Hint 1</th>
            <th style={long}>Hint 2</th>
            <th style={long}>Hint 3</th>
        </tr>
    )
}

// column header for module 7
export const Module7Column = (props) => {
    return (
        <tr>
            {props.is_adaptive ?
                <th><Checkbox onChange={(e, data) => props.checkAll(data.checked)} checked={props.is_checked} /> No</th>
                :
                <th>No</th>
            }
            <th style={short}>Image</th>
            {props.is_adaptive !== undefined && props.is_adaptive ? <th style={short}>Topic</th> : null}
            <th style={long}>Round 1</th>
            <th style={long}>Round 2</th>
            <th style={long}>Round 3</th>
            <th style={long}>Round 4</th>
            <th>Action</th>
        </tr>
    )
}

// column header for module 7 history and review changes
export const Module7ColumnHistory = (props) => {
    return (
        <tr>
            <th style={short}>Image</th>
            {props.is_adaptive !== undefined && props.is_adaptive ? <th style={short}>Topic</th> : null}
            <th style={long}>Round 1</th>
            <th style={long}>Round 2</th>
            <th style={long}>Round 3</th>
            <th style={long}>Round 4</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// column header for module 7 add question
export const Module7ColumnQuestion = (props) => {
    return (
        <tr>
            <th style={short}>Image</th>
            {props.is_adaptive !== undefined && props.is_adaptive ? <th style={short}>Topic</th> : null}
            <th style={long}>Round 1</th>
            <th style={long}>Round 2</th>
            <th style={long}>Round 3</th>
            <th style={long}>Round 4</th>
        </tr>
    )
}

// column header for module 10
export const Module10Column = (props) => {
    return (
        <tr>
            {props.is_adaptive ?
                <th><Checkbox onChange={(e, data) => props.checkAll(data.checked)} checked={props.is_checked} /> No</th>
                :
                <th>No</th>
            }
            <th style={long}>Passage</th>
            <th style={short}>Wrong Answers</th>
            <th>Action</th>
        </tr>
    )
}

// column header for module 10 history and review changes
export const Module10ColumnHistory = () => {
    return (
        <tr>
            <th style={long}>Passage</th>
            <th style={short}>Wrong Answers</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// column header for module 10 add question
export const Module10ColumnQuestion = () => {
    return (
        <tr>
            <th style={long}>Passage</th>
            <th style={short}>Wrong Answers</th>
        </tr>
    )
}

// column header for module 11
export const Module11Column = (props) => {
    return (
        <tr>
            {props.is_adaptive ?
                <th><Checkbox onChange={(e, data) => props.checkAll(data.checked)} checked={props.is_checked} /> No</th>
                :
                <th>No</th>
            }
            <th style={long}>Dialogue 1 <Popup hoverable basic content='Dialogue 1 is chosen by the user first to start the conversation'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>NPC Reply 1 <Popup hoverable basic content='NPC stands for non-player character, referring to the character that the user is conversing with in the game.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>Dialogue 2 <Popup hoverable basic content='Chosen by the user to respond to what the NPC says'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>NPC Reply 2 <Popup hoverable basic content='The game is a conversation between the user and the NPC. This is the NPC’s reply to the correct answer that the user has chosen.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>Dialogue 3 <Popup hoverable basic content='Chosen by the user to respond to what the NPC says'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            {
                props.is_adaptive !== undefined && props.is_adaptive ?
                    <>
                        <th style={long}>NPC Reply 3 <Popup hoverable basic content='The game is a conversation between the user and the NPC. This is the NPC’s reply to the correct answer that the user has chosen.'
                            trigger={<Icon color='blue' link
                                name='exclamation circle' />} /></th>
                        <th style={long}>Dialogue 4 <Popup hoverable basic content='Chosen by the user to respond to what the NPC says'
                            trigger={<Icon color='blue' link
                                name='exclamation circle' />} /></th>
                        <th style={long}>NPC Reply 4 <Popup hoverable basic content='The game is a conversation between the user and the NPC. This is the NPC’s reply to the correct answer that the user has chosen.'
                            trigger={<Icon color='blue' link
                                name='exclamation circle' />} /></th>
                        <th style={long}>Dialogue 5 <Popup hoverable basic content='Chosen by the user to respond to what the NPC says'
                            trigger={<Icon color='blue' link
                                name='exclamation circle' />} /></th>
                    </>
                    : null
            }
            <th>Action</th>
        </tr>
    )
}

// column header for module 11 history and review changes
export const Module11ColumnHistory = (props) => {
    return (
        <tr>
            <th style={long}>Dialogue 1 <Popup hoverable basic content='Dialogue 1 is chosen by the user first to start the conversation'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>NPC Reply 1 <Popup hoverable basic content='NPC stands for non-player character, referring to the character that the user is conversing with in the game.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>Dialogue 2 <Popup hoverable basic content='Chosen by the user to respond to what the NPC says'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>NPC Reply 2 <Popup hoverable basic content='The game is a conversation between the user and the NPC. This is the NPC’s reply to the correct answer that the user has chosen.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>Dialogue 3 <Popup hoverable basic content='Chosen by the user to respond to what the NPC says'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            {
                props.is_adaptive !== undefined && props.is_adaptive ?
                    <>
                        <th style={long}>NPC Reply 3 <Popup hoverable basic content='The game is a conversation between the user and the NPC. This is the NPC’s reply to the correct answer that the user has chosen.'
                            trigger={<Icon color='blue' link
                                name='exclamation circle' />} /></th>
                        <th style={long}>Dialogue 4 <Popup hoverable basic content='Chosen by the user to respond to what the NPC says'
                            trigger={<Icon color='blue' link
                                name='exclamation circle' />} /></th>
                        <th style={long}>NPC Reply 4 <Popup hoverable basic content='The game is a conversation between the user and the NPC. This is the NPC’s reply to the correct answer that the user has chosen.'
                            trigger={<Icon color='blue' link
                                name='exclamation circle' />} /></th>
                        <th style={long}>Dialogue 5 <Popup hoverable basic content='Chosen by the user to respond to what the NPC says'
                            trigger={<Icon color='blue' link
                                name='exclamation circle' />} /></th>
                    </>
                    : null
            }
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// column header for module 11 add question
export const Module11ColumnQuestion = (props) => {
    return (
        <tr>
            <th style={long}>Dialogue 1 <Popup hoverable basic content='Dialogue 1 is chosen by the user first to start the conversation'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>NPC Reply 1 <Popup hoverable basic content='NPC stands for non-player character, referring to the character that the user is conversing with in the game.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>Dialogue 2 <Popup hoverable basic content='Chosen by the user to respond to what the NPC says'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>NPC Reply 2 <Popup hoverable basic content='The game is a conversation between the user and the NPC. This is the NPC’s reply to the correct answer that the user has chosen.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>Dialogue 3 <Popup hoverable basic content='Chosen by the user to respond to what the NPC says'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            {
                props.is_adaptive !== undefined && props.is_adaptive ?
                    <>
                        <th style={long}>NPC Reply 3 <Popup hoverable basic content='The game is a conversation between the user and the NPC. This is the NPC’s reply to the correct answer that the user has chosen.'
                            trigger={<Icon color='blue' link
                                name='exclamation circle' />} /></th>
                        <th style={long}>Dialogue 4 <Popup hoverable basic content='Chosen by the user to respond to what the NPC says'
                            trigger={<Icon color='blue' link
                                name='exclamation circle' />} /></th>
                        <th style={long}>NPC Reply 4 <Popup hoverable basic content='The game is a conversation between the user and the NPC. This is the NPC’s reply to the correct answer that the user has chosen.'
                            trigger={<Icon color='blue' link
                                name='exclamation circle' />} /></th>
                        <th style={long}>Dialogue 5 <Popup hoverable basic content='Chosen by the user to respond to what the NPC says'
                            trigger={<Icon color='blue' link
                                name='exclamation circle' />} /></th>
                    </>
                    : null
            }
        </tr>
    )
}

// column header for module 14
export const Module14Column = (props) => {
    return (
        <tr>
            {props.is_adaptive ?
                <th><Checkbox onChange={(e, data) => props.checkAll(data.checked)} checked={props.is_checked} /> No</th>
                :
                <th>No</th>
            }
            <th style={short}>Topic</th>
            <th style={long}>Question</th>
            <th style={long}>Keywords <Popup hoverable basic content='Words that the user can get points for if mentioned in their speech.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th>Action</th>
        </tr>
    )
}

// column header for module 14 history and review changes
export const Module14ColumnHistory = () => {
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={long}>Question</th>
            <th style={long}>Keywords <Popup hoverable basic content='Words that the user can get points for if mentioned in their speech.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// column header for module 14 add question
export const Module14ColumnQuestion = () => {
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={long}>Question</th>
            <th style={long}>Keywords <Popup hoverable basic content='Words that the user can get points for if mentioned in their speech.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
        </tr>
    )
}

// column header for module 12
export const Module12Column = (props) => {
    return (
        <tr>
            {props.is_adaptive ?
                <th><Checkbox onChange={(e, data) => props.checkAll(data.checked)} checked={props.is_checked} /> No</th>
                :
                <th>No</th>
            }
            <th style={short}>Image</th>
            <th style={long}>Question</th>
            <th style={long}>Required Keywords <Popup hoverable basic content='Word that the user must speak out loud to obtain points for the question.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>Other Keywords <Popup hoverable basic content='Other words that will give additional points if spoken by the user.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th>Action</th>
        </tr>
    )
}

// column header for module 12 history and review changes
export const Module12ColumnHistory = () => {
    return (
        <tr>
            <th style={short}>Image</th>
            <th style={long}>Question</th>
            <th style={long}>Required Keywords <Popup hoverable basic content='Word that the user must speak out loud to obtain points for the question.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>Other Keywords <Popup hoverable basic content='Other words that will give additional points if spoken by the user.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// column header for module 12 add question
export const Module12ColumnQuestion = () => {
    return (
        <tr>
            <th style={short}>Image</th>
            <th style={long}>Question</th>
            <th style={long}>Required Keywords <Popup hoverable basic content='Word that the user must speak out loud to obtain points for the question.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
            <th style={long}>Other Keywords <Popup hoverable basic content='Other words that will give additional points if spoken by the user.'
                trigger={<Icon color='blue' link
                    name='exclamation circle' />} /></th>
        </tr>
    )
}

// column header for module 29
export const Module29Column = (props) => {
    return (
        <tr>
            {props.is_adaptive ?
                <th><Checkbox onChange={(e, data) => props.checkAll(data.checked)} checked={props.is_checked} /> No</th>
                :
                <th>No</th>
            }
            <th style={short}>Image</th>
            {props.is_adaptive !== undefined && props.is_adaptive ? <th style={short}>Topic</th> : null}
            <th style={long}>Answer and Question</th>
            <th>Action</th>
        </tr>
    )
}

// column header for module 29 history and review changes
export const Module29ColumnHistory = (props) => {
    return (
        <tr>
            <th style={short}>Image</th>
            {props.is_adaptive !== undefined && props.is_adaptive ? <th style={short}>Topic</th> : null}
            <th style={long}>Answer and Question</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// column header for module 29 add question
export const Module29ColumnQuestion = (props) => {
    return (
        <tr>
            <th style={short}>Image</th>
            {props.is_adaptive !== undefined && props.is_adaptive ? <th style={short}>Topic</th> : null}
            <th style={long}>Answer and Question</th>
        </tr>
    )
}

/**
 * Column header for module 31.
 * While the code is being written, module 31 is Reading Comprehension
 */

export const Module31ColumnQuestion = (props) => {
    return (
        <tr>
            <th>Passage</th>
            <th>Questions</th>
        </tr>
    )
}

export const Module31Column = (props) => {
    return <tr>
        <th>No</th>
        <th style={long}>Passage</th>
        <th style={long}>Question and Answer</th>
        <th>Action</th>
    </tr>
}

export const Module31ColumnHistory = (props) => {
    return <tr>
        <th style={long}>Passage</th>
        <th style={long}>Question and Answer</th>
        <th>Editor</th>
        <th>Datetime</th>
        <th>Status</th>
    </tr>
}

export const Module34ColumnQuestion = (props) => {
    return (
        <tr>
            <th style={long}>Video Link & Datetime</th>
            <th>Questions</th>
        </tr>
    )
}

export const Module34Column = (props) => {
    return <tr>
        <th>No</th>
        <th style={long}>Video Link & Datetime</th>
        <th style={long}>Question and Answer</th>
        <th>Action</th>
    </tr>
}

export const Module34ColumnHistory = (props) => {
    return <tr>
        <th style={long}>Video Link & Datetime</th>
        <th style={long}>Question and Answer</th>
        <th>Editor</th>
        <th>Datetime</th>
        <th>Status</th>
    </tr>
}

export const Module35ColumnQuestion = (props) => {
    return (
        <tr>
            <th style={short}>Image</th>
            <th style={long}>Content</th>
        </tr>
    )
}

export const Module35Column = (props) => {
    return <tr>
        <th>No</th>
        <th style={short}>Image</th>
        <th style={long}>Content</th>
        <th>Action</th>
    </tr>
}

export const Module35ColumnHistory = (props) => {
    return <tr>
        <th style={short}>Image</th>
        <th style={long}>Content</th>
        <th>Editor</th>
        <th>Datetime</th>
        <th>Status</th>
    </tr>
}

// column header for module 30
export const Module30Column = (props) => {
    return (
        <tr>
            {props.is_adaptive ?
                <th><Checkbox onChange={(e, data) => props.checkAll(data.checked)} checked={props.is_checked} /> No</th>
                :
                <th>No</th>
            }
            <th style={short}>Image</th>
            <th style={long}>Content</th>
            <th>Action</th>
        </tr>
    )
}

// column header for module 30 history and review changes
export const Module30ColumnHistory = (props) => {
    return (
        <tr>
            <th style={long}>Content</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// column header for module 30 add question
export const Module30ColumnQuestion = (props) => {
    return (
        <tr>
            <th style={short}>Image</th>
            <th style={long}>Content</th>
        </tr>
    )
}

// column header for module 32
export const Module32Column = (props) => {
    return (
        <tr>
            {props.is_adaptive ?
                <th><Checkbox onChange={(e, data) => props.checkAll(data.checked)} checked={props.is_checked} /> No</th>
                :
                <th>No</th>
            }
            <th style={short}>Image</th>
            <th style={long}>Content</th>
            <th>Action</th>
        </tr>
    )
}

// column header for module 32 add question
export const Module32ColumnQuestion = (props) => {
    return (
        <tr>
            <th style={short}>Image</th>
            <th style={long}>Content</th>
        </tr>
    )
}

// column header for module 32 history and review changes
export const Module32ColumnHistory = (props) => {
    return (
        <tr>
            <th style={long}>Content</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}


// column 33 for vocabulary form
export const Module33Column = (props) => {
    return (
        <tr>
            {props.is_adaptive ?
                <th><Checkbox onChange={(e, data) => props.checkAll(data.checked)} checked={props.is_checked} /> No</th>
                :
                <th>No</th>
            }
            <th style={short}>Image</th>
            <th style={long}>Content</th>
            <th>Action</th>
        </tr>
    )
}

// column header for module 33 add question
export const Module33ColumnQuestion = (props) => {
    return (
        <tr>
            <th style={short}>Image</th>
            <th style={long}>Content</th>
        </tr>
    )
}

// column header for module 33 history and review changes
export const Module33ColumnHistory = (props) => {
    return (
        <tr>
            <th style={long}>Content</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}



// column header for module 13
export const Module13Column = (props) => {
    let list = [
        'Was the answer relevant to the question posed?',
        'Did the answer contain good/correct grammar?',
        'Were the words in the answer repetitive?',
    ]
    const [modalOpen, setModalOpen] = React.useState(false)
    const [listEV, setListEV] = React.useState(null)

    if (props.is_adaptive !== undefined && props.is_adaptive) {
        list.push('')
        list.push('')
    }

    if (props.res_ev !== null) {
        list = []
        let res_ev = props.res_ev.response_evaluation
        for (let i = 0; i < res_ev.length; i++) {
            if (res_ev[i].text !== undefined) {
                list.push(res_ev[i].text)
            }
        }
        if (listEV === null) {
            setListEV(list)
        }
    } else {
        if (listEV === null) {
            setListEV(list)
        }
    }

    const configure = () => {
        let mg_id = props.match.params.mg
        let paramEv = listEV.filter(function (el) {
            return el !== "";
        });

        fetch(process.env.REACT_APP_API_URL + 'api/v1/response-eval/' + mg_id + '/save', {
            headers: props.myHeaders,
            method: 'post',
            body: JSON.stringify({ data: paramEv, is_adaptive: props.is_adaptive })
        })
            .then((res) => {
                return res.json()
            })
            .then((res) => {
                if (res.status) {
                    let identifier = props.match.params.code + '-' + mg_id
                    let prevData = window.localStorage.getItem('res_ev')
                    prevData = JSON.parse(prevData)
                    if (prevData !== null) {
                        let exist = prevData.findIndex(x => x.identifier === identifier)
                        if (exist !== -1) {
                            prevData[exist].response = listEV
                        } else {
                            prevData.push({
                                identifier: identifier,
                                response: listEV
                            })
                        }
                        window.localStorage.setItem('res_ev', JSON.stringify(prevData))
                    } else {
                        window.localStorage.setItem('res_ev', JSON.stringify([{
                            identifier: identifier,
                            response: listEV
                        }]))
                    }
                    toast.success(res.message)
                    setTimeout(() => {
                        window.location.href = ''
                    }, 1000)
                } else {
                    toast.error(res.message)
                }
            })
    }

    const onChangeInput = (e, data) => {
        let list_eval = listEV
        switch (data.name) {
            case 'first':
                list_eval[0] = data.value
                break;
            case 'second':
                list_eval[1] = data.value
                break;
            case 'fourth':
                list_eval[3] = data.value
                break;
            case 'fifth':
                list_eval[4] = data.value
                break;
            default:
                list_eval[2] = data.value
                break;
        }
        setListEV(list_eval)
    }

    return (
        <>
            <tr>
                {props.is_adaptive ?
                    <th><Checkbox onChange={(e, data) => props.checkAll(data.checked)} checked={props.is_checked} /> No</th>
                    :
                    <th>No</th>
                }
                <th style={short}>Topic</th>
                <th style={long}>Question</th>
                <th style={long}>Response</th>
                <th style={long}>
                    Response Evaluation <Button type='button' className='tertiary' onClick={() => setModalOpen(true)}>
                        <Icon color='blue' link
                            name='cog' /> Configure
                    </Button>
                </th>
                <th>Action</th>
            </tr>
            <Modal size='tiny' open={modalOpen}>
                <Modal.Header>Response Evaluation</Modal.Header>
                <Modal.Content>
                    <p>This configuration will be used for this chapter.</p>
                    {listEV !== null ?
                        <Form>
                            <Form.Input defaultValue={listEV[0]} onChange={onChangeInput} name='first' />
                            <Form.Input defaultValue={listEV[1]} onChange={onChangeInput} name='second' />
                            <Form.Input defaultValue={listEV[2]} onChange={onChangeInput} name='third' />
                            {props.is_adaptive !== undefined && props.is_adaptive ?
                                <>
                                    <Form.Input defaultValue={listEV[3]}
                                        placeholder='Optional' onChange={onChangeInput} name='fourth' />
                                    <Form.Input defaultValue={listEV[4]} onChange={onChangeInput}
                                        placeholder='Optional' name='fifth' />
                                </>
                                : null}
                        </Form>
                        : null}
                </Modal.Content>
                <Modal.Actions>
                    <Button type='button' onClick={() => setModalOpen(false)} className='tertiary'>Cancel</Button>
                    <Button type='button' onClick={configure} primary>Save Changes for All</Button>
                </Modal.Actions>
            </Modal>
        </>
    )
}

// column header for module 13 history and review changes
export const Module13ColumnHistory = () => {
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={long}>Question</th>
            <th style={long}>Response</th>
            <th style={long}>Response Evaluation</th>
            <th style={short}>Editor</th>
            <th style={short}>Datetime</th>
            <th style={short}>Status</th>
        </tr>
    )
}

// column header for module 13 add question
export const Module13ColumnQuestion = () => {
    return (
        <tr>
            <th style={short}>Topic</th>
            <th style={long}>Question</th>
            <th style={long}>Response</th>
            <th style={long}>Response Evaluation</th>
        </tr>
    )
}