import React from 'react'
/* Fields */
import { Textarea, Select } from '../fields'
import {Button, Icon, Grid, Label, Input as UIInput, Popup} from 'semantic-ui-react';

class PreAForm extends React.Component{
    constructor(props){
        super(props)
        this.contentEditablePassage = React.createRef();
        this.state = {
            passageWidth: 0,
            options: this.props.data !== undefined ? this.props.data.question_object.options.split(',') : [],
            showAnswer: false,
            words: '',
            selectedKey: null,
            optionsText: this.props.data !== undefined ? this.props.data.question_object.options : '',
            isTouch: false,
            showVarError: false,
            min_options: 6,
            max_option: 30,
            max_char: 20
        }
    }

    addTag = () =>{
        let word = this.state.words
        if(word.trim() === ''){
            return false
        }
        if(word.length > this.state.max_char){
            this.setState({showVarError: true})
            return false
        }
        word = word.replace(/[^\w\s]/gi, '')
        let key = this.state.selectedKey
        let options = this.state.options
        if(word !== ''){
            if(key !== null){
                options[key] = word
            }else{
                options.push(word)
            }
        }
        this.setState({options: options, showAnswer: false, words: '', selectedKey: null, 
        optionsText: options.join(','), isTouch: true})
    }

    removeTag = (key, e) =>{
        let options = this.state.options
        options.splice(key, 1)
        this.setState({options: options, optionsText: options.join(','), isTouch: true})
    }

    render(){
        return <>
            <td>
                <Select label='Topic' name='topic' children={
                    this.props.topics.map((v, k)=>
                        <option value={v.category_id} key={k}>{v.name}</option>
                    )
                } required/>
            </td>
            <td style={this.state.passageWidth !== 0 ? {width: this.state.passageWidth} : null}>
                <div className='field'>
                    <label>Option <b style={{color:'#db2828'}}> *</b></label>
                    <div className={this.state.options.length < this.state.min_options && this.state.isTouch ? 'editableContent error' : 'editableContent'}>
                        {this.state.options.map((v,k)=>
                            <Label key={k} as='a' style={{marginTop: 5}}>
                                <span onClick={()=>this.setState({
                                    showAnswer: true, words: v, selectedKey: k
                                })}>{v}</span>
                                <Icon name='delete' onClick={this.removeTag.bind(null, k)}/>
                            </Label>
                        )}
                    </div>
                    {this.state.options.length < this.state.min_options && this.state.isTouch ? 
                        <Label color='red' pointing>
                            Not enough options
                        </Label>
                    : null}
                    <Textarea input_error={this.state.options.length < this.state.min_options && this.state.isTouch 
                    ? true : false} 
                    values={this.state.optionsText} hidden={true} name="options" required/>
                </div>
                
                {this.state.showAnswer ? 
                    <Grid columns='equal'>
                        <Grid.Column width={10}>
                            <Popup content={'Max characters is '+this.state.max_char} trigger={
                                <div className='field'>
                                    <UIInput id="option_i" placeholder='Type here...' onChange={(e, data)=>this.setState({words: data.value})} 
                                    value={this.state.words} onKeyDown={(e)=>{
                                        this.setState({showVarError: this.state.words.length > this.state.max_char ? true : false})
                                        if ((e.key === 'Enter' || e.key === 'Tab') && this.state.words.trim() !== '') {
                                            if(this.state.words.length <= this.state.max_char){
                                                this.addTag()
                                            }
                                        }
                                    }}/>
                                    {this.state.showVarError ? 
                                    <div className="ui red pointing label">Your input is more than {this.state.max_char} characters</div>
                                    : null}
                                </div>
                            }/>
                        </Grid.Column>
                        <Grid.Column>
                            <Button className='tertiary' disabled={this.state.words === '' || this.state.showVarError ? true : false}
                            size='small' type='button' icon onClick={this.addTag}>
                                <Icon color='green' name='check'/>
                            </Button>
                            <Button className='tertiary' 
                            size='small' type='button' icon onClick={()=>this.setState({showAnswer: false})}>
                                <Icon color='grey' name='remove'/>
                            </Button>
                        </Grid.Column>
                    </Grid> : 
                    <div>
                    <Button type='button' secondary size='small' 
                    disabled={this.state.options.length === this.state.max_option ? true : false}
                    onClick={()=>{
                        this.setState({showAnswer: true, words: ''})
                        setTimeout(()=>{
                            document.getElementById('option_i').focus()
                        }, 100)
                    }}>
                        {this.state.min_options - this.state.options.length <= 0 ? 
                        'Add Keyword'
                        : 'Add Keyword ('+(this.state.min_options - this.state.options.length)+')'
                        }
                    </Button>     
                    <i style={{fontSize: 12, color: 'grey'}}>*minimum options : {this.state.min_options}</i>
                    </div>}
            </td>
        </>
    }
}

export default PreAForm