import React from 'react';
import { Divider, Image, Button, Form, Icon } from 'semantic-ui-react';
import { toast } from 'react-toastify';

class ResetPasswordPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            form: {
                password: {
                    value: '',
                    error: false
                },
                re_password: {
                    value: '',
                    error: false
                }
            },
            loading: false
        }
    }

    handleChange = (e, d) =>{
        let form = this.state.form
        
        if(d.name === 'password'){
            form.password.value = d.value
            if(d.value.length < 6){
                form.password.error = true
            }else{
                form.password.error = false
            }
            form.re_password.value = ''
            form.re_password.error = false
        }else{
            form.re_password.value = d.value
            if(form.password.value !== d.value){
                form.re_password.error = true
            }else{
                form.re_password.error = false
            }
        }
        this.setState({form: form})
    }

    sendLink = () =>{
        let form = this.state.form
        let error = false
        Object.keys(form).forEach(x=>{
            if(form[x].error){
                error = true
            }
        })

        if(!error){
            this.setState({loading: true})
            fetch(process.env.REACT_APP_API_URL+'api/v1/auth/reset-password/'+this.props.match.params.token, {
                method: 'post',
                body: JSON.stringify({
                    new_pass: this.state.form.re_password.value
                })
            })
            .then((res)=>{
                return res.json()
            })
            .then((res)=>{
                if(res.status){
                    toast.success('Your password changed, you will be redirect to login page.')
                    this.setState({loading: false})
                    setTimeout(()=>{
                        window.location.href = '/login'
                    }, 3000)
                }
            })
        }else{
            toast.error('Please complete form with valid input.')
        }

        
    }

    render() {
        return (
            <div className="ui middle aligned container" style={{width: 400, minHeight: '66.5vh'}}>
            <div className="ui middle aligned grid" style={{height:'100vh'}}>
                <div className="row">
                    <div className="column">
                        <div className="ui segment">
                            <Image style={{width: 200}} centered src={require('../../assets/images/content.png')}/>
                            <Divider hidden/>
                            <div className='loginHeader'>
                                <span className='left'>Forgot Password</span>
                            </div>
                            <Divider hidden/>
                            <Form onSubmit={this.sendLink}>
                                <Form.Input name='password' value={this.state.password} onChange={this.handleChange} 
                                type='password' label='New Password' placeholder='At least 6 characters' required icon={
                                    this.state.form.password.value !== '' ?
                                    this.state.form.password.error ? <Icon name='remove circle' color='red'/> : 
                                    <Icon name='check circle' color='green'/>
                                    : false
                                }/>
                                <Form.Input name='re_password' value={this.state.re_password} onChange={this.handleChange} 
                                type='password' label='Confirm Password' placeholder='Type confirm password' required icon={
                                    this.state.form.re_password.value !== '' ?
                                    this.state.form.re_password.error ? <Icon name='remove circle' color='red'/> : 
                                    <Icon name='check circle' color='green'/>
                                    : false
                                }/>
                                <Button loading={this.state.loading} disabled={this.state.loading} fluid primary>
                                    Reset Password
                                </Button>
                            </Form>
                            <Divider hidden/>
                            <p className='footerTextLogin'>Remember your password? <a rel='link' className='link' href='/login'>Log In</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

export default ResetPasswordPage