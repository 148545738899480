import React from 'react';
import {
  Route, Switch, Redirect, withRouter
} from "react-router-dom";
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify';

// modules
import LandingPage from './modules/landing_page';
import LoginPage from './modules/auth/login';
import SubjectPage from './modules/subject'
import TopNavComponent from './components/top_nav';
import TopNavPublicComponent from './components/top_nav_public';
import BrowsePage from './modules/browse';
import AddSubjectPage from './modules/subject/add';
import { Container } from 'semantic-ui-react';
import DetailPage from './modules/subject/details';
import AddChapterPage from './modules/subject/chapter';
import QuestionPage from './modules/subject/questions';
import ProfilePage from './modules/profile';
import VerifyPage from './modules/auth/verify'
import NotificationPage from './modules/notifications/notif';
import TaskPage from './modules/notifications/task';
import FaqPage from './modules/statics/faq';
import RegisterPage from './modules/auth/register';
import ForgotPasswordPage from './modules/auth/forgot';
import ResetPasswordPage from './modules/auth/reset';
import CompletionProfilePage from './modules/profile/completion';
import MainSearchPage from './modules/search';
import EntrySection from './modules/search/entry';
import UserSection from './modules/search/user';
import TaskManagerPage from './modules/tasks';
import TOSPage from './modules/statics/tos';
import PrivacyPage from './modules/statics/privacy';
import CookiePage from './modules/statics/cookie';
import ContactUsPage from './modules/statics/contact';
import FooterComponent from './components/footer';
import EmptyComponent from './components/empty';
import DiagnosticQuestionPage from './modules/subject/questions/diagnostic_index';
import { UsePageViews } from './helpers/global';

// to detect every store of redux/state changed
function mapStateToProps(state) {
  return { 
      auth: state.auth,
  }
}

// if route not found
const NoPage = () =>{
  return (
    <div style={{textAlign: 'center', paddingTop: 80, minHeight: '66.5vh'}}>
      <h1>404</h1>
      <h2>Page Not Found</h2>
    </div>
  )
}

const NoFoundData = () =>{
  return (
    <div style={{textAlign: 'center', paddingTop: 80, minHeight: '66.5vh'}}>
      <EmptyComponent/>
    </div>
  )
}

class App extends React.Component{
  componentDidMount(){
    UsePageViews(window.location.pathname + window.location.search)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      UsePageViews(window.location.pathname + window.location.search)
    }
  }

  render() {
    let path = document.location.pathname.split('/')
    const stateAuth = this.props.auth
    const isLogged = stateAuth !== null ? stateAuth.is_logged : false
    const userInfo = stateAuth !== null ? stateAuth.user : null
    let header = null
    if(isLogged){
      if(path[1] === 'completion'){
        header = <TopNavPublicComponent/>
      }else{
        header = <TopNavComponent/>
      }
    }else if(path[1] === 'faq' || path[1] === 'tos' || path[1] === 'privacy' || path[1] === 'cookie'
    || path[1] === 'contact'){
      header = <TopNavPublicComponent/>
    }

    if(userInfo !== null){
      if(!userInfo.profile_complete && path[1] !== 'completion'){
        window.location.href = '/completion'
        return false
      }else if(userInfo.profile_complete && path[1] === 'completion'){
        window.location.href = '/subject'
        return false
      }
    }

    return (
      <>
          {header}
          <Switch>
            {/* root page */}
            <Route path="/" exact>
              {isLogged ? <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
              <Redirect to="/subject" />
              </Container> : <LandingPage/>}
            </Route>

            {/* login page */}
            <Route path="/login" exact>
              {isLogged ? <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
              <Redirect to="/subject" />
              </Container> : <LoginPage />}
            </Route>

            {/* register page */}
            <Route path="/register" exact>
              {isLogged ? <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
              <Redirect to="/subject" />
              </Container> : <RegisterPage/>}
            </Route>

            {/* subject page */}
            <Route path="/subject" exact>
              {!isLogged ? <Redirect to="/" /> : <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                <SubjectPage />
              </Container>
              }
            </Route>

            {/* add new subject page */}
            <Route path="/subject/add" exact>
              {!isLogged ? <Redirect to="/" /> : <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                <AddSubjectPage/>
              </Container>}
            </Route>

            {/* add new chapter page */}
            {/* <Route path="/chapter/add/:code" exact>
              {!isLogged ? <Redirect to="/" /> : <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                <AddChapterPage/>
              </Container>}
            </Route> */}
            <Route path="/chapter/add/:code" render={(props)=>{
              if(!isLogged){
                return <Redirect to="/" />
              }else{
                return <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                  <AddChapterPage {...props}/>
                </Container>
              }
            }}/>

            {/* subject detail page */}
            <Route path="/subject/detail/:code" render={(props)=>{
              if(!isLogged){
                return <Redirect to="/" />
              }else{
                return <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                  <DetailPage {...props}/>
                </Container>
              }
            }}/>

            {/* subject question manage page */}
            <Route path="/subject/:code/:mg/question" exact render={(props)=>{
              if(!isLogged){
                return <Redirect to="/" />
              }else{
                return <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                  <QuestionPage {...props}/>
                </Container>
              }
            }}/>

            {/* browse page */}
            <Route path="/browse" exact>
              {!isLogged ? <Redirect to="/" /> : <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                <BrowsePage/>
              </Container>}
            </Route>

            {/* profile page */}
            <Route path="/profile/:username" render={(props)=>{
              if(!isLogged){
                return <Redirect to="/" />
              }else{
                return <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                <ProfilePage {...props}/>
              </Container>
              }
            }}>
            </Route>

            {/* notification page */}
            <Route path="/notification" exact>
              {!isLogged ? <Redirect to="/" /> : <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                <NotificationPage/>  
              </Container>}
            </Route>

            {/* update task page */}
            <Route path="/updates" exact>
              {!isLogged ? <Redirect to="/" /> : <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                <TaskPage/>  
              </Container>}
            </Route>

            {/* profile completion page */}
            <Route path="/completion" exact>
              {!isLogged ? <Redirect to="/" /> : <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                <CompletionProfilePage/>
              </Container>}
            </Route>

            {/* profile completion page */}
            <Route path="/search" exact>
              {!isLogged ? <Redirect to="/" /> : <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                <MainSearchPage/>
              </Container>}
            </Route>

            {/* profile completion page */}
            <Route path="/search/entry" exact>
              {!isLogged ? <Redirect to="/" /> : <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                <EntrySection/>
              </Container>}
            </Route>

            {/* profile completion page */}
            <Route path="/search/user" exact>
              {!isLogged ? <Redirect to="/" /> : <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                <UserSection/>
              </Container>}
            </Route>

            {/* profile completion page */}
            <Route path="/task" exact>
              {!isLogged ? <Redirect to="/" /> : <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                <TaskManagerPage/>
              </Container>}
            </Route>

            {/* diagnostic content page */}
            <Route path="/diagnostic/:code/:mg" exact render={(props)=>{
              if(!isLogged){
                return <Redirect to="/" />
              }else{
                return <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                  <DiagnosticQuestionPage {...props}/>
                </Container>
              }
            }}/>

            {/* faq page */}
            <Route path="/faq" exact>
              <FaqPage/>
            </Route>

            {/* tos page */}
            <Route path="/tos" exact>
              <TOSPage/>
            </Route>

            {/* privacy policy page */}
            <Route path="/privacy" exact>
              <PrivacyPage/>
            </Route>

            {/* cookie page */}
            <Route path="/cookie" exact>
              <CookiePage/>
            </Route>

            {/* cookie page */}
            <Route path="/contact" exact>
              <ContactUsPage/>
            </Route>

            {/* verification page */}
            <Route path="/verify/:token" exact component={VerifyPage}/>

            {/* forgot password page */}
            <Route path="/forgot" exact component={ForgotPasswordPage}/>

            {/* reset password page */}
            <Route path="/reset/:token" exact component={ResetPasswordPage}/>

            {/* error page */}
            <Route path="/not-found" component={NoFoundData}/>
            <Route path="*" component={NoPage}/>
          </Switch>

          {/* toaster */}
          <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
          />

            <FooterComponent/>
    </>
    )
  }
}

export default connect(mapStateToProps)(withRouter(App))

