import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Grid, List, Button, Icon, Image, Input, Divider,
    Modal, Label, Loader, Segment, Message} from 'semantic-ui-react'
import EmptyComponent from '../../../components/empty'
import { WithContext as ReactTags } from 'react-tag-input';
import {toast} from 'react-toastify'
import storage from 'redux-persist/lib/storage'
import { getSubjectRedux } from '../../../helpers/redux'
import * as Sentry from "@sentry/browser";

function mapStateToProps(state) {
    return { 
        add_subject_step: state.add_subject_step,
        auth: state.auth,
        is_contributor_load: state.is_contributor_load
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        loadContributor: (n) => dispatch({ type: 'CONTRIBUTOR_LOAD', payload: n }),
    }
}

class ContributorPage extends React.Component{
    constructor(props){
        super(props)
        const currentSubject = getSubjectRedux(this.props.add_subject_step, this.props.match.params.code)
        if(currentSubject.category_id === null && currentSubject.role === 3 && !currentSubject.adaptive){
            window.location.href = '/subject/add?code='+this.props.match.params.code
            return false
        }
        this.state = {
            tags: [],
            suggestions: [],
            requestList: [],
            requestListOld: [],
            activeConttList: [],
            activeConttListOld: [],
            loadingContReq: false,
            loadingCont: false,
            modalOpen: false,
            subjectDetail: currentSubject,
            myHeaders: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            deleteConfirm: false,
            deleteLoading: false,
            selectedContributor: null,
            promoteLoading: false,
            demoteLoading: false,
            inviteMsg: true,
            inviteLoading: false
        }
    }

    componentDidMount(){
        this.loadData()
    }

    componentDidUpdate(oldProps, oldState){
        if(this.props.is_contributor_load){
            this.loadData()
            this.props.loadContributor(false)
        }
    }

    loadData = () =>{
        this.setState({loadingContReq: true, loadingCont: true})
        if(this.state.subjectDetail.role === 3){
            fetch(process.env.REACT_APP_API_URL+'api/v1/contributors/request?code='+this.state.subjectDetail.code, {
                headers: this.state.myHeaders  
            })
            .then((res)=>{
                return res.json()
            })
            .then((res)=>{
                if(res.status === undefined){
                    storage.removeItem('persist:root')
                    window.location.href = '/'
                }

                this.setState({requestList: res.data, requestListOld: res.data, loadingContReq: false})
            })
            .catch((e)=>{
                Sentry.captureException(e);
                toast.error(<div>
                    Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                </div>)
            })
        }

        fetch(process.env.REACT_APP_API_URL+'api/v1/contributors?code='+this.state.subjectDetail.code, {
            headers: this.state.myHeaders  
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            this.setState({activeConttList: res.data, activeConttListOld: res.data, loadingCont: false})
        })
        .catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    handleDelete = (i) =>{
        const tagsTemp = this.state.tags;
        this.setState({tags: tagsTemp.filter((tag, index) => index !== i)})
    }

    handleAddition = (tag) =>{
        let tagsTemp = this.state.tags
        tagsTemp.push({
            id: tag.id,
            text: <div style={{float:'left', padding:5}}>
                <img alt='user' style={{borderRadius:'50%'}} src={tag.avatar === null || tag.avatar === undefined ? require('../../../assets/images/avatar-default.png') : tag.avatar} />
                {tag.text}
            </div>
        })
        this.setState({tags: tagsTemp})
    }

    handleInputChange = (param) =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/contributors/candidates?code='+this.state.subjectDetail.code+'&search='+param, {
            headers: this.state.myHeaders
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            let tmp = []
            res.data.forEach(val=>{
                tmp.push({
                    id: val.email,
                    text: val.email,
                    name: val.username,
                    avatar: val.avatar
                })
            })
            this.setState({suggestions: tmp})
        })
        .catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    handleAccept = (id, is_multi, e) =>{
        let requestData = []
        const myHeaders = this.state.myHeaders

        if(!is_multi){
            requestData.push({id:id})
        }else{
            requestData = this.state.requestList
        }
        let idx = 0

        requestData.forEach(val=>{
            fetch(process.env.REACT_APP_API_URL+'api/v1/contributors/request/'+val.id+'/accept', {
                headers: myHeaders
            })
            .then((res)=>{
                return res.json()
            })
            .then((res)=>{
                if(res.status === undefined){
                    storage.removeItem('persist:root')
                    window.location.href = '/'
                }

                if(idx + 1 === requestData.length){
                    this.loadData()
                }
                idx++
            })
            .catch((e)=>{
                Sentry.captureException(e);
                toast.error(<div>
                    Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                </div>)
            })
        })
    }

    handleReject = (id, is_multi, e) =>{
        let requestData = []
        const myHeaders = this.state.myHeaders

        if(!is_multi){
            requestData.push({id:id})
        }else{
            requestData = this.state.requestList
        }
        let idx = 0

        requestData.forEach(val=>{
            fetch(process.env.REACT_APP_API_URL+'api/v1/contributors/request/'+val.id+'/reject', {
                headers: myHeaders
            })
            .then((res)=>{
                return res.json()
            })
            .then((res)=>{
                if(res.status === undefined){
                    storage.removeItem('persist:root')
                    window.location.href = '/'
                }
                if(idx + 1 === requestData.length){
                    this.loadData()
                }
                idx++
            })
            .catch((e)=>{
                Sentry.captureException(e);
                toast.error(<div>
                    Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                </div>)
            })
        })
    }

    handleInvite = () =>{
        let tags = this.state.tags
        if(tags.length === 0){
            let input = document.getElementsByClassName('tagInputFieldClass')
            if(input[0].value !== ''){
                tags.push({
                    id: input[0].value,
                    text: <div style={{float:'left', padding:5}}>
                        <img alt='user' style={{borderRadius:'50%'}} src={require('../../../assets/images/avatar-default.png')} />
                        {input[0].value}
                    </div>
                })
            }
            input[0].value = ''
        }
        this.setState({tags: tags})
        if(tags.length === 0){
            toast.error('Please input contributor email.')
            return false
        }
        
        let tag = tags
        tag = tags.map(function(value) {
            return value.id;
        })

        let is_valid_email = true
        let list_error_email = []
        tag.forEach(x=>{
            let emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ // eslint-disable-line
            if (emailRegex.test(x)){
            }else{
                is_valid_email = false
                list_error_email.push(x)
            }
        })

        if(!is_valid_email){
            toast.error(<>
                Invalid email detected for <b>{list_error_email.join(', ')}</b>, please remove or change before invite.
            </>)
            return false
        }

        this.setState({inviteLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/contributors/request/invite/', {
            method: 'post',
            body: JSON.stringify({
                code: this.state.subjectDetail.code,
                invitees: tag
            }),
            headers: this.state.myHeaders
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                toast.success(res.message)
                this.loadData()
                this.setState({modalOpen: false, tags: [], inviteLoading: false})
            }else{
                toast.error(res.message)
            }
        })
        .catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    handleSearchContributor = (e, data) =>{
        if(data.name === 'search_active'){
            if(data.value !== ''){
                let currCont = this.state.activeConttList
                currCont = this.state.activeConttList.filter(x=>x.username.includes(data.value) || x.email.includes(data.value))
                this.setState({activeConttList: currCont})
            }else{
                this.setState({activeConttList: this.state.activeConttListOld})
            }
        }else{
            if(data.value !== ''){
                let reqCont = this.state.requestList
                reqCont = this.state.requestList.filter(x=>x.username.includes(data.value) || x.email.includes(data.value))
                this.setState({requestList: reqCont})
            }else{
                this.setState({requestList: this.state.requestListOld})
            }
        }
    }

    handleContributorAction = (data, e) =>{
        if(data.type === 'remove'){
            this.setState({deleteLoading: true})
        }else if(data.type === 'demote'){
            this.setState({demoteLoading: true})
        }else{
            this.setState({promoteLoading: true})
        }
        fetch(process.env.REACT_APP_API_URL+'api/v1/contributors/'+data.type+'/'+data.id, {
            headers: this.state.myHeaders
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(data.type === 'remove'){
                this.setState({deleteLoading: false})
            }else if(data.type === 'demote'){
                this.setState({demoteLoading: false})
            }else{
                this.setState({promoteLoading: false})
            }
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                toast.success(res.message)
                this.loadData()
                this.setState({deleteConfirm: false})
            }else{
                toast.error(res.message)
            }
        })
        .catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    render() {
        const KeyCodes = {
            comma: 188,
            enter: 13,
        };
        const delimiters = [KeyCodes.comma, KeyCodes.enter];

        return (
            <Segment>
                <Grid columns='equal' divided>
                <Grid.Column>
                    <Grid columns='equal'>
                        <Grid.Column>
                            <h4>Current Contributor</h4>
                        </Grid.Column>
                        <Grid.Column style={{textAlign: 'right'}}>
                            {this.state.subjectDetail.role === 3 ? <Button size='small' primary onClick={()=>this.setState({modalOpen: true, inviteMsg: true})} id='add_cont_btn'>
                                <Icon name='add circle'/> Invite Contributor
                            </Button> : ''}
                        </Grid.Column>
                    </Grid>
                    <div className="ui icon rounded input">
                        <Input name='search_active' id='search_active' onChange={this.handleSearchContributor} size='small' placeholder="Search Contributor..."/>
                        <i className="search icon"></i>
                    </div>
                    <Divider hidden/>
                    {this.state.loadingCont ? <Loader active/> : 
                        this.state.activeConttList.length > 0 ?
                            <List divided verticalAlign='middle' className='contList'>
                                {this.state.activeConttList.map((v, k)=>
                                    <List.Item key={k}>
                                        {v.username !== this.props.auth.user.username && this.state.subjectDetail.role === 3 && v.role !== 'Owner' ? 
                                        <List.Content floated='right'>
                                            {v.role_id === 3 ? <>
                                                <Button className='tertiary' onClick={this.handleContributorAction.bind(null, {
                                                    id: v.contributor_id,
                                                    type: 'demote'
                                                })} disabled={this.state.demoteLoading} loading={this.state.demoteLoading}>
                                                    <Icon name='arrow down'/> Demote
                                                </Button>
                                                
                                            </> : <Button className='tertiary' onClick={this.handleContributorAction.bind(null, {
                                                    id: v.contributor_id,
                                                    type: 'promote'
                                                })} disabled={this.state.promoteLoading} loading={this.state.promoteLoading}>
                                                    <Icon name='arrow up'/> Promote
                                                </Button>}
                                            <Button className='tertiary' onClick={()=>this.setState({deleteConfirm: true, selectedContributor: v})}>
                                                <Icon name='trash'/>
                                            </Button>
                                        </List.Content>
                                        : null}
                                        <Image avatar src={v.avatar !== null ? v.avatar :
                                            require('../../../assets/images/avatar-default.png')
                                        } />
                                        <List.Content>
                                            {v.username === this.props.auth.user.username ? 
                                            <List.Header>
                                                {v.username} (You)
                                                <Label size='mini' basic className='labelContributor' 
                                                as='a' color='green'>
                                                    {v.role}
                                                </Label>
                                            </List.Header> : 
                                            !v.active ? <List.Header>{v.username} (Invited)</List.Header>  : 
                                            <Link to={'/profile/'+v.username} className="header">
                                                {v.username} 
                                                {v.verified ? <Icon size='tiny' inverted circular color='green' name='check'/> : null}
                                                {v.role_id === 3 ? <Label size='mini' basic className='labelContributor' color='green'>
                                                    {v.role}
                                                </Label> : null}
                                            </Link>}
                                            <List.Description>
                                                {v.email === '' ? '-' : v.email}
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                )}
                            </List>
                        : <EmptyComponent/>
                    }
                </Grid.Column>
                {this.state.subjectDetail.role === 3 ? <Grid.Column style={{minHeight: 408}}>
                    <Grid columns='equal'>
                        <Grid.Column>
                            <h4>Contributor Request</h4>
                        </Grid.Column>
                        {this.state.requestListOld.length > 0 ? <Grid.Column style={{textAlign: 'right'}}>
                            <Button primary size='small' onClick={this.handleAccept.bind(null, 0, true)}>Accept All</Button>
                            <Button className='tertiary' onClick={this.handleReject.bind(null, 0, true)} size='small'>Decline All</Button>
                        </Grid.Column> : null}
                    </Grid>
                    {this.state.requestListOld.length > 0 ? <div className="ui icon rounded input">
                        <Input name='cont_request' id='cont_request' onChange={this.handleSearchContributor} size='small' placeholder="Search Contributor..."/>
                        <i className="search icon"></i>
                    </div> : null}
                    <Divider hidden/>
                    {this.state.loadingContReq ? <Loader active/> : 
                        this.state.requestList.length > 0 ? <List divided verticalAlign='middle' className='contReqList'>
                            {
                                this.state.requestList.map((v,k)=>
                                <List.Item key={k}>
                                    <List.Content floated='right'>
                                        <Button icon size='small' onClick={this.handleReject.bind(null, v.id, false)} negative><Icon name='remove'/></Button>
                                        <Button icon size='small' onClick={this.handleAccept.bind(null, v.id, false)} positive><Icon name='check'/></Button>
                                    </List.Content>
                                    <Image avatar src={v.avatar !== null ? v.avatar :
                                        require('../../../assets/images/avatar-default.png')
                                    } />
                                    <List.Content>
                                        <Link to={'/profile/'+v.username} className="header">
                                            {v.username}
                                        </Link>
                                        <List.Description>
                                            {v.email === '' ? '-' : v.email}
                                        </List.Description>
                                    </List.Content>
                                </List.Item>
                                )
                            }
                        </List>
                        : <EmptyComponent/>
                    }
                </Grid.Column> : ''}

                {/* modal */}
                <Modal size='tiny' open={this.state.modalOpen}>
                    <Modal.Header>Invite Contributor</Modal.Header>
                    <Modal.Content id='invite_cont'>
                        {this.state.inviteMsg ? 
                            <Message info onDismiss={()=>this.setState({inviteMsg: false})}>
                                <p style={{fontSize: 12}}>
                                    This is autocomplete form, when you type the email, system will display the suggestions. 
                                    This feature also support multiple email invitation if you want to invite many by hitting enter key after you type the email.
                                </p>
                            </Message>    
                        : null}
                        <ReactTags 
                            classNames={{
                                tags: 'test',
                                tagInput: 'ui input',
                                tagInputField: 'tagInputFieldClass',
                                selected: 'selectedClass',
                                tag: 'ui image large label',
                                remove: 'delete icon',
                                suggestions: 'ReactTags__suggestions',
                                // activeSuggestion: 'test'
                            }} 
                            minQueryLength={1}
                            tags={this.state.tags}
                            allowDragDrop={false}
                            suggestions={this.state.suggestions}
                            handleDelete={this.handleDelete}
                            handleAddition={this.handleAddition}
                            handleInputChange={this.handleInputChange}
                            renderSuggestion = {({ text, avatar, name }, query) => 
                            <div className="itemWrapper">
                                <img alt='avatar' src={avatar === null ? require('../../../assets/images/avatar-default.png') : avatar} className="ui avatar image"/>
                                <div className="content">
                                    <div className="header">{name}</div>
                                    {text}
                                </div>
                            </div>
                            }
                            placeholder='Type email...'
                            autocomplete
                            delimiters={delimiters} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className='tertiary' style={{color: 'grey'}} onClick={()=>{
                            this.setState({modalOpen: false, tags: []})
                        }}>Cancel</Button>
                        <Button disabled={this.state.inviteLoading} loading={this.state.inviteLoading}
                        onClick={this.handleInvite} className='tertiary'>Invite</Button>
                    </Modal.Actions>
                </Modal>
            </Grid>

                {/* confirm modal */}
                <Modal open={this.state.deleteConfirm} size='mini'>
                    <Modal.Content>
                        <p>Remove this contributor?</p>
                        <div style={{textAlign: 'right', marginBottom: 45}}>
                        <Divider hidden/>
                        {this.state.selectedContributor !== null ? 
                        <Button onClick={this.handleContributorAction.bind(null, {
                            id: this.state.selectedContributor.contributor_id,
                            type: 'remove'
                        })} disabled={this.state.deleteLoading}
                        loading={this.state.deleteLoading} floated='right' negative>Yes, Remove it</Button>
                        : null}
                        <Button onClick={()=>this.setState({deleteConfirm: false})} 
                        floated='right' className='tertiary' disabled={this.state.deleteLoading}>Cancel</Button>
                        </div>
                    </Modal.Content>
                </Modal>
            </Segment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContributorPage)