import React from 'react'
import {
    Header, Popup, Container, Button, Icon, Modal, Table,
    Checkbox, Dropdown
} from 'semantic-ui-react'
import BrowsePage from "../../browse";
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import storage from 'redux-persist/lib/storage';
import { getSubjectRedux } from '../../../helpers/redux';
import * as Sentry from "@sentry/browser";

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        preq_subject: state.preq_subject,
        preq_subject_selected: state.preq_subject_selected,
        add_subject_step: state.add_subject_step
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setPreqSubjectSelected: (n) => dispatch({ type: 'SELECTED_PREQ_SUBJECT', payload: n }),
        setPreqSubject: (n) => dispatch({type: 'PREQ_SUBJECT', payload: n})
    }
}

// to display PrerequisiteSection section on create new subject
class PrerequisiteSection extends React.Component{
    constructor(props){
        super(props)
        const currentSubject = getSubjectRedux(this.props.add_subject_step, this.props.code) 
        this.state = {
            modalOpen: false,
            levelDetail: !this.props.preq_subject_selected || this.props.preq_subject_selected === null ?
                [] : this.props.preq_subject_selected,
            preqData: this.props.preq_subject,
            currentSubject: currentSubject
        }
    }

    // fetch subject level detail when selected exist
    componentDidMount(){
        if(this.state.preqData.length > 0 && this.state.levelDetail.length === 0){
            this.fetchSubjectLevel()
        }
    }

    // fetch subject level
    fetchSubjectLevel = () =>{
        let codes = null
        if(this.state.preqData[0].subject_code !== undefined){
            codes = this.state.preqData.map(function (obj) { return obj.subject_code; });
        }else{
            codes = this.state.preqData.map(function (obj) { return obj.code; });
            codes = codes.toString()
        }
        codes = codes.length > 0 ? codes.toString() : ''
        if(codes === ''){
            toast.error('Cannot find subject code.')
            return false
        }

        fetch(process.env.REACT_APP_API_URL+'api/v1/prerequisite?codes='+codes, {
            headers: this.props.myHeaders,
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if(res.status){
                let levelState = []
                res.data.forEach(val=>{
                    let currentSelect = this.state.preqData.filter(x=>x.subject_code === val.code)
                    if(currentSelect.length > 0){
                        currentSelect = currentSelect[0]
                    }
                    let levels = []
                    if(val.levels !== null){
                        val.levels.forEach(lvl => {
                            let selected = false
                            if(currentSelect.level !== undefined && currentSelect.level === lvl.level_name){
                                selected = true
                            }
                            levels.push({ key: lvl.level_id, text: lvl.level_name, value: lvl.level_id, selected: selected })
                        });
                    }
                    val.levels = levels
                    val.is_required = currentSelect.is_required !== undefined ? currentSelect.is_required : false
                    val.not_sure = currentSelect.level !== undefined && currentSelect.level === 'not sure' ? true : false
                    levelState.push(val)
                })
                this.doDispatch(levelState)
                this.setState({levelDetail: levelState})
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // when press choose button
    handleChoose = () =>{
        if(this.state.preqData.length === 0){
            toast.error('Please select subject first.')
            return false
        }

        this.fetchSubjectLevel()
        this.setState({modalOpen: false})
    }

    // required changed toggle
    onChangeRequired = (code, e) =>{
        let levels = this.state.levelDetail
        let idx = this.state.levelDetail.findIndex(x=>x.code === code)
        if(idx !== -1){
            levels[idx].is_required = !levels[idx].is_required
        }
        if(levels[idx].is_required){
            levels[idx].not_sure = false
        }

        this.doDispatch(levels)
        this.setState({levelDetail: levels})
    }

    // i'm not sure checkox change
    onChangeCheckbox = (code, e) =>{
        let levels = this.state.levelDetail
        let idx = this.state.levelDetail.findIndex(x=>x.code === code)
        if(idx !== -1){
            levels[idx].not_sure = !levels[idx].not_sure
            for (let i = 0; i < levels[idx].levels.length; i++) {
                levels[idx].levels[i].selected = false
            }
        }

        this.doDispatch(levels)
        this.setState({levelDetail: levels})
    }

    // dropdown level change
    onChangeLevel = (e, param) =>{
        let levels = this.state.levelDetail
        let idx = this.state.levelDetail.findIndex(x=>x.code === param.code)
        if(idx !== -1){
            let levelIdx = levels[idx].levels.findIndex(x=>x.value === param.id)
            if(levelIdx !== -1){
                levels[idx].levels[levelIdx].selected = true
            }
            
            let levelOther = levels[idx].levels.filter(x=>x.value !== param.id)
            levelOther.forEach(val => {
                let idxOther = levels[idx].levels.findIndex(x=>x.value === val.value)
                if(idxOther !== -1){
                    levels[idx].levels[idxOther].selected = false
                }
            });
        }

        this.doDispatch(levels)
        this.setState({levelDetail: levels})
    }

    // set selected level value
    doDispatch = (data) =>{
        this.props.setPreqSubjectSelected([])
        if(data.length !== 0){
            setTimeout(()=>{
                this.props.setPreqSubjectSelected(data)
            }, 0)
        }
    }

    // remove action from table
    removePreqItem = (code, e) =>{
        let levels = this.state.levelDetail
        let idx = levels.findIndex(x=>x.code === code)
        if(idx !== -1){
            levels.splice(idx, 1)
        }

        let preqLevel = this.state.preqData
        let preqIdx = preqLevel.findIndex(x=>x.code === code)
        if(preqIdx !== -1){
            preqLevel.splice(preqIdx, 1)
        }

        this.doDispatch(levels)
        this.props.setPreqSubject(preqLevel)
        this.setState({levelDetail: levels, preqData: preqLevel})
    }

    render() {
        return (
            <div>
                <Header as='h4' color='grey'>Prerequisite Subject 
                    <Popup trigger={<Icon style={{fontSize:14, position:'absolute'}} name='question outline circle' color='grey' link/>}
                    content="Select other subjects that a user will have had to complete before gaining access to this subject."
                    basic/>
                </Header>
                <span style={{color:'grey'}}>
                    You can define the prerequisite subject for your subject (can be mandatory or just a suggestion).
                </span>
                {this.state.preqData.length > 0 ? 
                    <Container style={{padding:50, textAlign: 'center'}}>
                        <Table basic>
                            <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Subject Names</Table.HeaderCell>
                                <Table.HeaderCell>
                                    Required 
                                    <Popup content='The required column on prerequisite.' basic trigger={<Icon link name='question circle outline'/>}/>
                                </Table.HeaderCell>
                                <Table.HeaderCell>Level</Table.HeaderCell>
                                <Table.HeaderCell>Action</Table.HeaderCell>
                            </Table.Row>
                            </Table.Header>
                        
                            <Table.Body>
                                {this.state.levelDetail.map((v, k)=>
                                    <Table.Row key={k}>
                                        <Table.Cell>{v.name}</Table.Cell>
                                        <Table.Cell><Checkbox checked={v.is_required} onChange={this.onChangeRequired.bind(null, v.code)} toggle /></Table.Cell>
                                        <Table.Cell>
                                            {v.levels.length > 0 ? 
                                                <div>
                                                    <Dropdown value={
                                                        v.levels.filter(x=>x.selected).length > 0 ? 
                                                        v.levels.filter(x=>x.selected)[0].value : 1
                                                    } onChange={(e, data)=> this.onChangeLevel(null, {code: v.code, id: data.value})} 
                                                    disabled={v.not_sure} options={v.levels} selection />
                                                    <br/>
                                                </div> 
                                                : ''
                                            }
                                            <Checkbox checked={v.not_sure} onChange={this.onChangeCheckbox.bind(null, v.code)} 
                                            disabled={v.is_required} label="i'm not sure" />
                                        </Table.Cell>
                                        <Table.Cell>
                                            <Button onClick={this.removePreqItem.bind(null, v.code)} style={{color: 'grey'}} className='tertiary'>
                                                <Icon name='trash'/> Delete
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                )}
                            </Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='4'>
                                        <Button secondary onClick={()=>this.setState({modalOpen: true})}>
                                            <Icon name='add'/> Add Prerequisite
                                        </Button>
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </Container>
                    : 
                    <Container text style={{padding:100, textAlign: 'center'}}>
                        <Header as='h3' color='grey'>You have no prerequisite subject yet.</Header>
                        <Button secondary onClick={()=>this.setState({modalOpen: true})}>
                            <Icon name='add'/> Add Prerequisite
                        </Button>
                    </Container>
                }

                <Modal open={this.state.modalOpen} closeIcon={<Icon name='close' onClick={()=>this.setState({modalOpen: false})}/>}>
                    <Modal.Header>
                        <Icon name='folder'/> Add Prerequisite Subject
                    </Modal.Header>
                    <Modal.Content scrolling>
                        {this.state.currentSubject !== undefined && this.state.currentSubject !== null ? 
                        <BrowsePage is_preq={true} current={this.state.currentSubject.code}/>
                        : null
                        }
                    </Modal.Content>
                    <Modal.Actions>
                        <Button positive onClick={this.handleChoose}>Choose</Button>
                    </Modal.Actions>
                </Modal>
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PrerequisiteSection);
