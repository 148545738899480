(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"));
	else if(typeof define === 'function' && define.amd)
		define("reactAdvancedForm", ["react"], factory);
	else if(typeof exports === 'object')
		exports["reactAdvancedForm"] = factory(require("react"));
	else
		root["reactAdvancedForm"] = factory(root["react"]);
})(typeof self !== 'undefined' ? self : this, function(__WEBPACK_EXTERNAL_MODULE__18__) {
return 