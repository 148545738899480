import React, {useEffect, useState} from 'react'
import { Route } from "react-router-dom";
import ContentPage from './content'
import SubjectMenuComponent from './menu';
import { Grid, Icon, Button, Header, Divider, Loader, Modal } from 'semantic-ui-react';
import { connect } from 'react-redux'
import './style.scss'
import SubjectActivitiesPage from './activities';
import AboutPage from './about';
import DiagnosticPage from './diagnostic';
import SettingPage from './setting';
import ContributorPage from './contributor';
import storage from 'redux-persist/lib/storage'
import { toast } from 'react-toastify';
import AdaptiveContent from './adaptive';
import DetailAdaptive from './adaptive/detail';
import { filterSubjectRedux, getSubjectRedux } from '../../../helpers/redux';
import { CheckExistData } from '../../../helpers/global';

function mapStateToProps(state) {
    return { 
        add_subject_step: state.add_subject_step,
        auth: state.auth
    }
}

function mapDispatchToProps (dispatch) {  
    return {
        addSubjectStepAction: (n) => dispatch({ type: 'CURRENT_SUBJECT', payload: n }),
        loadContributor: (n) => dispatch({ type: 'CONTRIBUTOR_LOAD', payload: n }),
        setPreqSubjectSelected: (n) => dispatch({ type: 'SELECTED_PREQ_SUBJECT', payload: n }),
        setPreqSubject: (n) => dispatch({type: 'PREQ_SUBJECT', payload: n })
    }
}

const DetailPage = (props) =>{
    const [loading, setLoading] = useState(true)
    const [modal, setModal] = useState(false)
    const [modal2, setModal2] = useState(false)
    const [modal3, setModal3] = useState(false)
    const [information, setInformation] = useState('')
    const [infoHeader, setInfoHeader] = useState('')
    const [infoBody, setInfoBody] = useState('')
    const [nextVersion, setNextVersion] = useState(0)
    const currentSubject = getSubjectRedux(props.add_subject_step, props.match.params.code)
    const [currentState, setCurrentState] = useState('')
    const [defaultImage, setDefaultImage] = useState({})
    const [publishLoading, setPublishLoading] = useState(false)
    const userInfo = props.auth
    const myHeaders = new Headers({
        'Authorization': 'Token '+userInfo.user.token
    });
    
    CheckExistData({
        header: myHeaders,
        category: 'subject',
        identifier: props.match.params.code
    }).then(val=>{
        if(!val.status){
            window.location.href = '/not-found'
            return false
        }
    })

    useEffect(()=>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/'+props.match.params.code, {
            headers: myHeaders,
        }).then(function(res){
            return res.json()
        }).then(function(res){
            if(res.status !== undefined){
                if(res.status){
                    let currSub = res.data
                    setCurrentState(currSub.last_publish_date !== null && currSub.last_publish_version !== null ?
                        currSub.last_publish_date+' (v'+currSub.last_publish_version+')' : '')
                    props.addSubjectStepAction(filterSubjectRedux(props.add_subject_step, res.data))
                    let preq = res.data.pre_requisite !== null ? JSON.parse(res.data.pre_requisite) : []
                    props.setPreqSubject(preq)
                    props.setPreqSubjectSelected([])
                }
            }else{
                storage.removeItem('persist:root')
                window.location.href='/'
            }

            setLoading(false)
        })
    }, []) // eslint-disable-line react-hooks/exhaustive-deps

    const requestSubject = () =>{
        setLoading(true)
        fetch(process.env.REACT_APP_API_URL+'api/v1/contributors/request/apply/', {
            method:'post',
            headers: myHeaders,
            body: JSON.stringify({code: currentSubject.code})
        }).then(function(res){
            return res.json()
        }).then(function(res){
            if(res.status !== undefined){
                if(res.status){
                    let crSubj = currentSubject
                    crSubj.request_status = res.data.status
                    props.addSubjectStepAction(filterSubjectRedux(props.add_subject_step, crSubj))
                }
            }else{
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            setLoading(false)
        })
    }

    const leaveSubject = () =>{
        setLoading(true)

        fetch(process.env.REACT_APP_API_URL+'api/v1/contributors/leave?code='+currentSubject.code, {
            headers: myHeaders,
        }).then(function(res){
            return res.json()
        }).then(function(res){
            if(res.status !== undefined){
                if(res.status){
                    props.addSubjectStepAction(filterSubjectRedux(props.add_subject_step, res.data))
                    props.loadContributor(true)
                }
            }else{
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            setLoading(false)
        })
    }

    const publishSubject = (e, data, is_valid=false) =>{
        if(!is_valid){
            if(defaultImage.avatar !== undefined && defaultImage.subject_cover !== undefined){
                if(defaultImage.avatar === null || defaultImage.subject_cover === null){
                    setModal3(true)
                    return false
                }
            }
        }
        setModal3(false)
        setLoading(true)
        setModal2(false)
        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/publish/'+currentSubject.code, {
            headers: myHeaders,
        }).then(function(res){
            return res.json()
        }).then(function(res){
            if(res.status !== undefined){
                if(res.status){
                }
            }else{
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            setLoading(false)
        })
        toast.success('Publishing package is on process.')
        setTimeout(()=>{
            window.location.href = ''
        }, 3000)
    }

    const checkSubject = () =>{
        setPublishLoading(true)
        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/publish/'+currentSubject.code+'/check', {
            headers: myHeaders
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            setPublishLoading(false)
            setLoading(false)
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(!res.status){
                setModal(true)
                setInformation(res.data.error)
                setInfoHeader(res.data.error_header)
                setInfoBody(res.data.error_body)
                setLoading(false)
            }else{
                setDefaultImage({avatar: res.data.avatar, subject_cover: res.data.subject_cover})
                setNextVersion(res.data.version)
                setInformation(res.data.update_detail)
                setModal2(true)
            }
        })
    }

    if(currentSubject !== null){
        return(
            <div>
                <Grid columns='equal'>
                    <Grid.Column>
                        <Header className='header_detail' as='h1'>
                            <Icon name='folder' />
                            <Header.Content>{currentSubject.name} <span>{
                                !currentSubject.published ? '-Draft' : '-Published'
                            }</span></Header.Content>
                        </Header>
                    </Grid.Column>
                    <Grid.Column className='publishAction'>
                        {currentState !== '' ? <span>Last publish : {currentState}</span> : null}
                        {currentSubject.role === null ? <div>
                            {currentSubject.request_status !== null && currentSubject.request_status !== 2 ? <Button floated='right' disabled>Access Requested</Button> : 
                            <Button floated='right' onClick={requestSubject} primary>Request Access</Button>}
                            {/* <span className='report_subject'><Icon name='flag'/> Report This Subject</span> */}
                        </div>
                        : currentSubject.mine ? <Button floated='right' onClick={checkSubject} color={currentSubject.last_publish_status === 'Requested'?'grey':'green'} 
                        disabled={currentSubject.last_publish_status === 'Requested' ? true : false}>
                            {currentSubject.last_publish_status === 'Requested' ? 'ON REVIEW' : 'PUBLISH'}
                        </Button> 
                        : <Button onClick={leaveSubject} floated='right' negative>Leave Subject</Button>}
                    </Grid.Column>
                </Grid>
                <Divider hidden/>
                <SubjectMenuComponent data={props}/>
                {loading ? <Loader active/> : <>
                    <Route path="/subject/detail/:code/content" exact>
                        {currentSubject.adaptive ? 
                        <AdaptiveContent {...props}/>
                        : 
                        <ContentPage {...props}/>
                        }
                    </Route>
                    {currentSubject.adaptive ? 
                    <Route path="/subject/detail/:code/content/:mid" exact component={DetailAdaptive}/>
                    : null }

                    <Route path="/subject/detail/:code/activities" exact>
                        <SubjectActivitiesPage {...props}/>
                    </Route>
                    <Route path="/subject/detail/:code/contributor" exact>
                        <ContributorPage {...props}/>
                    </Route>
                    <Route path="/subject/detail/:code/about" exact>
                        <AboutPage {...props}/>
                    </Route>
                    <Route path="/subject/detail/:code/diagnostic" exact>
                        <DiagnosticPage {...props}/>
                    </Route>
                    <Route path="/subject/detail/:code/setting" exact>
                        <SettingPage {...props}/>
                    </Route>
                </>}

                {/* modal */}
                <Modal size='small' open={modal}>
                    <Modal.Content style={{padding: 20, paddingBottom: 50}}>
                        <h3>Publish Subject Alert</h3>
                        <div className='publishModal'>
                            <div>
                                <Icon name='exclamation triangle' circular/>
                            </div>
                            <div className='content'>
                                <h4>{infoHeader}</h4>
                                <p>{infoBody}</p>
                                <b>Information:</b>
                                <p>{information}</p>
                            </div>
                        </div>
                        <Divider hidden/>
                        <Button onClick={()=>setModal(false)} floated='right' primary>Ok</Button>
                    </Modal.Content>
                </Modal>

                <Modal size='small' open={modal2}>
                    <Modal.Content style={{padding: 20, paddingBottom: 50}}>
                        <h3>Publish Subject</h3>
                        <div className='publishModal'>
                            <div>
                                <Icon name='send' circular/>
                            </div>
                            <div className='content'>
                                <h4>Submit for review and publishing by Solve Education?</h4>
                                <p>The process will take 7 working days and you will receive notification of the final decision. Any changes made after submission will not be sent for review. New changes will be reviewed for publishing the next time you submit the subject.</p>
                                <b>Update for v{nextVersion}:</b>
                                <p>{information}</p>
                            </div>
                        </div>
                        <Divider hidden/>
                        <Button style={{marginLeft: 15}} onClick={publishSubject} floated='right' positive>Yes, Sure</Button>
                        <Button className='tertiary' onClick={()=>setModal2(false)} floated='right'>Cancel</Button>
                    </Modal.Content>
                </Modal>

                <Modal size='small' open={modal3}>
                    <Modal.Content style={{padding: 20, paddingBottom: 50}}>
                        <h3>Publish Subject Alert</h3>
                        <div className='publishModal'>
                            <div>
                                <Icon name='exclamation triangle' circular/>
                            </div>
                            <div className='content'>
                                {defaultImage.avatar === null && defaultImage.subject_cover === null ? 
                                <>
                                <h4>You are using a default subject cover and a default avatar</h4>
                                <p>The published subject will display the default subject cover and display the default owner avatar when published on our app.</p>
                                <a href="/profile/me">Change Avatar</a> or <a href={"/subject/detail/"+currentSubject.code+"/setting"}>Change Subject Cover</a>
                                </>
                                : defaultImage.avatar === null ? 
                                <>
                                <h4>You are using a default avatar</h4>
                                <p>The published subject will display the default owner avatar when published on our app.</p>
                                <a href="/profile/me">Change Avatar</a>
                                </>
                                : 
                                <>
                                <p>The published subject will display the default subject cover when published on our app.</p>
                                <h4>You are using a default subject cover</h4>
                                <a href={"/subject/detail/"+currentSubject.code+"/setting"}>Change Subject Cover</a>
                                </>
                                }
                            </div>
                        </div>
                        <Divider hidden/>
                        <Button style={{marginLeft: 15}} floated='right' positive onClick={publishSubject.bind(null, true)}>Continue</Button>
                        <Button className='tertiary' onClick={()=>setModal3(false)} floated='right'>Cancel</Button>
                    </Modal.Content>
                </Modal>
                
                <Modal basic open={publishLoading}>
                    <Header icon>
                        <Icon loading name='spinner' />
                        Publish Processing...
                    </Header>
                </Modal>
            </div>
        )
    }

    return (
        <Loader active/>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(DetailPage);
