import React from 'react'
/* Fields */
import { Input, Textarea, Radio } from '../fields'
import { Button, Icon, Modal, Form, Grid } from 'semantic-ui-react'
import { toast } from 'react-toastify'

// module 13 form
class Module13Form extends React.Component{
    constructor(props){
        super(props)
        let list = [
            'Was the answer relevant to the question posed?',
            'Did the answer contain good/correct grammar?',
            'Were the words in the answer repetitive?',
        ]

        if(this.props.is_adaptive !== undefined && this.props.is_adaptive){
            list.push('')
            list.push('')
        }

        let res_ev = []

        if(this.props.q_object !== undefined && this.props.q_object !== null){
            list = []
            res_ev = this.props.q_object.response_evaluation
            
        }
        if(this.props.data !== undefined){
            list = []
            res_ev = this.props.data.question_object.response_evaluation
        }

        for (let i = 0; i < res_ev.length; i++) {
            list.push(res_ev[i].text)
        }

        this.state = {
            responseEva: list,
            modalOpen: false,
            response: {
                input1: list[0] !== undefined ? list[0] : '',
                input2: list[1] !== undefined ? list[1] : '',
                input3: list[2] !== undefined ? list[2] : '',
                input4: list[3] !== undefined ? list[3] : '',
                input5: list[4] !== undefined ? list[4] : ''
            }
        }
    }

    configure = () =>{
        let response = this.state.response
        let responseEva = this.state.responseEva
        if(response.input1 === '' || response.input2 === '' || response.input3 === ''){
            toast.error('All input is required!')
            return false
        }
        responseEva[0] = response.input1
        responseEva[1] = response.input2
        responseEva[2] = response.input3

        this.setState({responseEva: responseEva, modalOpen: false})
    }

    onChangeResponse = (e, data) =>{
        let response = this.state.response
        switch (data.name) {
            case 'input1':
                response.input1 = data.value
                break;
            case 'input2':
                response.input2 = data.value
                break;
            default:
                response.input3 = data.value
                break;
        }
        this.setState({response: response})
    }

    render(){
        return(
        <>
            <td>
                <Input label='Topic' name="topic" required placeholder='Type here...' wlength={30}/>
            </td>
            <td>
                <Textarea label='Question' name="question" required placeholder='Type here...' wlength={160}/>
            </td>
            <td>
                <Textarea label='Response' name="response" required placeholder='Type here...' wlength={1200}/>
            </td>
            <td>
                {this.props.is_review !== undefined ? 
                    <>
                    <Button onClick={()=>this.setState({modalOpen: true})} className='tertiary' type='button'>
                        <Icon name='cog'/> Configure Response Evaluation
                    </Button>
                    </>
                : null}
                <Textarea name='response_1' hidden={true} values={this.state.response.input1}
                wlength={50} initialValue={this.state.response.input1}/>
                <Textarea name='response_2' hidden={true} values={this.state.response.input2}
                wlength={50} initialValue={this.state.response.input2}/>
                <Textarea name='response_3' hidden={true} values={this.state.response.input3}
                wlength={50} initialValue={this.state.response.input3}/>
                {this.props.is_adaptive !== undefined && this.props.is_adaptive ? 
                    <>
                    <Textarea name='response_4' hidden={true} values={this.state.response.input4}
                    wlength={50} initialValue={this.state.response.input4}/>
                    <Textarea name='response_5' hidden={true} values={this.state.response.input5}
                    wlength={50} initialValue={this.state.response.input5}/>
                    </>
                : null}
                <Grid columns='equal'>
                    <Grid.Row>
                        <Grid.Column><b>Response Evaluation</b></Grid.Column>
                        <Grid.Column width={2}><b>Yes</b></Grid.Column>
                        <Grid.Column width={2}><b>No</b></Grid.Column>
                    </Grid.Row>
                    {this.state.responseEva.map((v,k)=>{
                        let comp = null
                        if(v !== '' && v !== undefined){
                            comp = <Grid.Row key={k}>
                                <Grid.Column>{v}</Grid.Column>
                                <Grid.Column width={2}>
                                <Radio name={'radioGroup_'+k} value='Yes' checked='checked' required/>
                                </Grid.Column>
                                <Grid.Column width={2}>
                                <Radio name={'radioGroup_'+k} value='No' required/>
                                </Grid.Column>
                            </Grid.Row>
                        }
                        return comp
                    }
                    )}
                </Grid>
            </td>
            
            {this.props.is_review !== undefined ? 
            <Modal size='tiny' open={this.state.modalOpen}>
                <Modal.Header>Response Evaluation</Modal.Header>
                <Modal.Content>
                    <p>This configuration will be used for this chapter.</p>
                    <Form>
                        <Form.Input onChange={this.onChangeResponse} name='input1' 
                        value={this.state.response.input1}/>
                        <Form.Input onChange={this.onChangeResponse} name='input2' 
                        value={this.state.response.input2}/>
                        <Form.Input onChange={this.onChangeResponse} name='input3' 
                        value={this.state.response.input3}/>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button type='button' onClick={()=>this.setState({modalOpen: false})} className='tertiary'>Cancel</Button>
                    <Button type='button' onClick={this.configure} primary>Save Changes for All</Button>
                </Modal.Actions>
            </Modal>
            : null}
        </>
        )
    }
}

export default Module13Form