import React from 'react'
import {Grid, Icon} from 'semantic-ui-react'
import Moment from 'react-moment';

const ActivityText = (props) =>{
    let data = props.data
    let icon = 'folder'
    let content = ''
    switch (data.type) {
        case 1:
            content = <div>
                Created <b style={{textTransform:'capitalize'}}>{data.info.old_subject_name}</b>
                </div>
            break;
        case 2:
            content = <div>
                Changed the name of <b style={{textTransform:'capitalize'}}>{data.info.old_subject_name}</b> to <b>{data.info.new_subject_name}</b>
                </div>
            break;
        case 3:
            content = <div>
                Changed the description of <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
                </div>
            break;
        case 4:
            content = <div>
                Set <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> as private
                </div>
            break;
        case 5:
            content = <div>
                Set <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> as public
                </div>
            break;
        case 6:
            content = <div>
                Changed the cover image of <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
                </div>
            break;
        case 7:
            content = <div>
            Changed the subject prerequisites of <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 9:
            content = <div>
            Began contributing to <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 10:
            content = <div>
            Stopped contributing to <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 11:
            content = <div>
            Created chapter <a href={
                '/subject/detail/'+data.identifier+'/content?id='+data.info.chapter_id
            } rel='chapter_created'>{data.info.chapter_name}</a> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 12:
            content = <div>
            Changed the name of chapter <b>{data.info.old_chapter_name}</b> to <b>{data.info.new_chapter_name}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 13:
            content = <div>
            Added <a href={
                '/subject/'+data.identifier+'/'+data.info.chapter_mini_game_id+'/question'
            } rel='chapter_created'>{data.info.mini_game_name}</a> to chapter <b>{data.info.chapter_name}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 14:
            content = <div>
            Deleted chapter <b>{data.info.chapter_name}</b> from <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 15:
            content = <div>
            Deleted <b>{data.info.mini_game_name}</b> from chapter <b>{data.info.chapter_name}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 16:
            content = <div>
            Added a new <a href={
                '/subject/'+data.info.subject_code+'/'+data.identifier+'/question?id='+data.info.question_id
            } rel='chapter_created'>entry</a> in chapter <b>{data.info.chapter_name}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 17:
            content = <div>
            Edited an <a href={
                '/subject/'+data.info.subject_code+'/'+data.identifier+'/question?id='+data.info.question_id
            } rel='chapter_created'>entry</a> in chapter <b>{data.info.chapter_name}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 18:
            content = <div>
            Approved <b>{data.info.number_of_entry}</b> entries in chapter <b>{data.info.chapter_name}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 19:
            content = <div>
            Declined <b>{data.info.number_of_entry}</b> entries in chapter <b>{data.info.chapter_name}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 20:
            content = <div>
            Published subject <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b> to the game
            </div>
            break;
        case 21:
            content = <div>
            Changed the completion percentage of chapter <a href={
                '/subject/detail/'+data.identifier+'/content?id='+data.info.chapter_id
            } rel='chapter_created'>{data.info.chapter_name}</a> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 22:
            content = <div>
            Hid <b>{data.info.mini_game_name}</b> in chapter <b>{data.info.chapter_name}</b> of <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 23:
            content = <div>
            Promoted <a rel='uname' href={'/profile/'+data.info.affected_username}>{data.info.affected_username}</a> to Manager in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 25:
            content = <div>
            Unhid <b>{data.info.mini_game_name}</b> in chapter <b>{data.info.chapter_name}</b> of <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 26:
            content = <div>
            Assigned level <b>{data.info.level_name}</b> in <b>{data.info.module_name}</b> of <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 27:
            content = <div>
            Deleted level <b>{data.info.level_name}</b> in <b>{data.info.module_name}</b> of <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 28:
            content = <div>
            Hid <b>{data.info.level_name}</b> from <b>{data.info.module_name}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 29:
            content = <div>
            Unhid <b>{data.info.level_name}</b> from <b>{data.info.module_name}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 30:
            content = <div>
            Added topic <b>{data.info.topic_name_tree}</b> to <b>{data.info.module_name}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 31:
            content = <div>
            Changed the name of topic <b>{data.info.old_topic_name_tree}</b> to <b>{data.info.new_topic_name_tree}</b> from <b>{data.info.module_name}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 32:
            content = <div>
            Deleted topic <b>{data.info.topic_name_tree}</b> from <b>{data.info.module_name}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 33:
            content = <div>
            Excluded <b>{data.info.mini_game_name}</b> from topic <b>{data.info.topic_name_tree}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 34:
            content = <div>
            Included <b>{data.info.mini_game_name}</b> from topic <b>{data.info.topic_name_tree}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 35:
            content = <div>
            Added a new entry in topic <b>{data.info.topic_name_tree}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 36:
            content = <div>
            Edited an entry in topic <b>{data.info.topic_name_tree}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 37:
            content = <div>
            Approved <b>{data.info.number_of_entry}</b> entries in topic <b>{data.info.topic_name_tree}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 38:
            content = <div>
            Declined <b>{data.info.number_of_entry}</b> entries in topic <b>{data.info.topic_name_tree}</b> in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
        case 39:
            content = <div>
            Deleted an entry in {data.info.chapter_id === null ? <b>{data.info.topic_name_tree}</b> : <>chapter <b>{data.info.chapter_name}</b></> } in <b>{data.info.subject_name}</b>
            </div>
            break;
        case 40:
            content = <div>
            Added a new entry in diagnostic test game <b>{data.info.game_name}</b> in <b>{data.info.subject_name}</b>
            </div>
            break;
        case 41:
            content = <div>
            Edited an entry in diagnostic test game <b>{data.info.game_name}</b> in <b>{data.info.subject_name}</b>
            </div>
            break;
        case 42:
            content = <div>
            Deleted an entry in diagnostic test game <b>{data.info.game_name}</b> in <b>{data.info.subject_name}</b>
            </div>
            break;
        case 43:
            content = <div>
            Approved <b>{data.info.number_of_entry}</b> entries in diagnostic test game <b>{data.info.game_name}</b> in <b>{data.info.subject_name}</b>
            </div>
            break;
        case 44:
            content = <div>
            Declined <b>{data.info.number_of_entry}</b> entries in diagnostic test game <b>{data.info.game_name}</b> in <b>{data.info.subject_name}</b>
            </div>
            break;
        case 45:
            content = <div>
            Moved <b>{data.info.origin.topic_name_tree}</b> to <b>{data.info.destination.topic_name_tree}</b> in module <b>{data.info.destination.module_name}</b> level <b>{data.info.destination.subject_module_level_name}</b>
            </div>
            break;
        case 46:
            content = <div>
            Moved <b>{data.info.origin.topic_name_tree}</b> to level <b>{data.info.destination.subject_module_level_name}</b> in module <b>{data.info.destination.module_name}</b>
            </div>
            break;
        case 47:
            content = <div>
            Moved <b>{data.info.origin.number_of_entries}</b> in folder <b>{data.info.origin.topic_name_tree}</b> to <b>{data.info.destination.topic_name_tree}</b> in module <b>{data.info.destination.module_name}</b> level <b>{data.info.destination.subject_module_level_name}</b>
            </div>
            break;
        case 48:
            content = <div>
            Undo moved <b>{data.info.origin.topic_name_tree}</b> to <b>{data.info.destination.topic_name_tree}</b> in module <b>{data.info.destination.module_name}</b> level <b>{data.info.destination.subject_module_level_name}</b>
            </div>
            break;
        case 49:
            content = <div>
            Undo moved <b>{data.info.origin.topic_name_tree}</b> to level <b>{data.info.destination.subject_module_level_name}</b> in module <b>{data.info.destination.module_name}</b>
            </div>
            break;
        case 50:
            content = <div>
            Undo moved <b>{data.info.origin.number_of_entries}</b> in folder <b>{data.info.origin.topic_name_tree}</b> to <b>{data.info.destination.topic_name_tree}</b> in module <b>{data.info.destination.module_name}</b> level <b>{data.info.destination.subject_module_level_name}</b>
            </div>
            break;
        // type 24
        default:
            content = <div>
            Demoted <a rel='uname' href={'/profile/'+data.info.affected_username}>{data.info.affected_username}</a> to Contributor in <b style={{textTransform:'capitalize'}}>{data.info.subject_name}</b>
            </div>
            break;
    }


    switch (data.category) {
        case 'question':
            icon = 'pencil'
            break;
        case 'mini_game':
            icon = 'gamepad'
            break;
        case 'subject':
            icon = 'folder'
            break;
        default:
            icon = 'bookmark'
            break;
    }

    return (
        <Grid.Row className='activityWrapper'>
            <Grid.Column width='1'>
                <Icon size='large' circular name={icon} />
                <i></i>
            </Grid.Column>
            <Grid.Column style={{marginLeft: 5}}>{content}</Grid.Column>
            <Grid.Column width='4'>
                <Moment format="DD MMM YYYY">{data.time}</Moment>
            </Grid.Column>
        </Grid.Row>
    )
}

export default ActivityText