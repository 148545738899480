import React, {useEffect, useState} from 'react'
import Input from '../fields/input'
import { Radio, Select, TextArea as TextAreaNew, Input as InputNew, 
    Confirm, FormField, Button, Divider, Message, Checkbox, 
    Label} from 'semantic-ui-react';

import Textarea from '../fields/textarea'
import { toast } from 'react-toastify';

const Module31Form = (props) => {
    const {
        data, passages
    } = props
    const [radio, setRadio] = useState('new')
    const [title, setTitle] = useState('')
    const [showTitle, setShowTitle] = useState(false)
    const [passage, setPassage] = useState('')


    const [questions, setQuestions] = useState([])
    const [confirm, setConfirm] = useState(false)
    const [formOpen, setFormOpen] = useState(false)
    const [selectedIdx, setSelectedIdx] = useState(null)
    const [tempQuestions, setTempQuestions] = useState('')
    const [passageList, setPassageList] = useState([])

    const [passageId, setPassageId] = useState('')
    const [duplicateTitle, setDuplicateTitle] = useState(false)

    const [form, setForm] = useState({
        question: '',
        answer: '',
        wrong1: '',
        wrong2: '',
        wrong3: '',
    })


    useEffect(()=>{
        if(data) {
            setQuestions([data.question_object.data])
            setTempQuestions(JSON.stringify([data.question_object.data]))
            setFormOpen(true)
            setSelectedIdx(0)
            setForm(data.question_object.data)
            setRadio('new')
            setPassage(data.question_object.passage)
            setPassageId(data.question_object.passage_id)
            setTitle(data.question_object.title)
            setShowTitle(data.question_object.show_title)
        }else{
            setPassageList(passages.map(function(v) {
                const shortTitle = v.title.length > 34 ? v.title.substr(0,33).concat('...') : v.title
                const shortPassage = v.passage.length > 40 ? v.passage.substr(0,39).concat('...') : v.passage
                return { 
                    key: v.passage_id, 
                    value: v.passage_id, 
                    text: `${shortTitle} ${!shortTitle ? '' : '-' } ${shortPassage}`, 
                    title: v.title
                 }
            }).sort((a, b) => a.title ? -1 : 1))
        }
    }, [data, passages])

    const handleChange = (e, { value }) => {
        setTitle('')
        setDuplicateTitle('')
        setShowTitle(false)
        setPassage('')
        setPassageId('')
        setRadio(value)
    }

    const hint = (e) => {
        return <span style={{textAlign: 'right', float: 'right'}}>{e}</span>
    }

    const onChange = (e) => {
        switch (e.target.name) {
            case 'question':
                setForm({...form, question: e.target.value})
                break;
            case 'answer':
                setForm({...form, answer: e.target.value})
                break;
            case 'wrong1':
                setForm({...form, wrong1: e.target.value})
                break;
            case 'wrong2':
                setForm({...form, wrong2: e.target.value})
                break;
            case 'wrong3':
                setForm({...form, wrong3: e.target.value})
                break;
            default:
                break;
        }
    }

    const onTypeForm = (e) => {
        switch (e.target.name) {
            case 'question':
                setForm({...form, question: e.target.value})
                break;
            case 'answer':
                setForm({...form, answer: e.target.value})
                break;
            case 'wrong1':
                setForm({...form, wrong1: e.target.value})
                break;
            case 'wrong2':
                setForm({...form, wrong2: e.target.value})
                break;
            case 'wrong3':
                setForm({...form, wrong3: e.target.value})
                break;
            default:
                break;
        }
        if(data){
            if(form.answer === '' || form.question === '' || form.wrong1 === '' || form.wrong2 === '' || form.wrong3 === '') {
                setTempQuestions('')
            } else{
                setTempQuestions(JSON.stringify(form))
            }  
        }
    }

    const addQuestion = () => {
        if(selectedIdx !== null) {
            questions[selectedIdx] = form
            const _q = [...questions]
            setQuestions([...questions])
            resetForm()
            setFormOpen(false)
            setSelectedIdx(null)
            saveTempQuestions(_q)
            return
        }
        const _q = [...questions, form]
        setQuestions(_q)
        resetForm()
        setFormOpen(false)
        setSelectedIdx(null)
        saveTempQuestions(_q)
    }

    const resetForm = () => {
        setFormOpen(false)
        setForm({
            question: '',
            answer: '',
            wrong1: '',
            wrong2: '',
            wrong3: '',
        })
    }

    const removeQuestion = () => {
        const _q = questions.filter((v, k)=>k!==selectedIdx)
        setQuestions(_q)
        setConfirm(false)
        setSelectedIdx(null)
        saveTempQuestions(_q)
    }

    const editQuestion = (idx) => {
        setForm(questions[idx])
        setSelectedIdx(idx)
        setFormOpen(true)
    }

    const saveTempQuestions = (data) => {
        setTempQuestions(JSON.stringify(data))
    }

    const titleChange = (e) =>{
        let radioNew = document.getElementById('new')
        if(radioNew !== null){

            let _title = passages.findIndex((x)=> x.title.toLowerCase() === e.nextValue.toLowerCase())
            if(data){
                _title = passages.findIndex((x)=> e.nextValue && x.title.toLowerCase() !== data.question_object.title.toLowerCase() && x.title.toLowerCase() === e.nextValue.toLowerCase())
            }
            setDuplicateTitle(_title !== -1)
        }else{
            setDuplicateTitle(false)
        }
        setTitle(e.nextValue)
    }

    return <>
        <td>
            {
            !data && <>
                <label>Passage Type <b style={{color:'#db2828'}}> *</b></label>
                <FormField>
                <Radio
                    label='New passage'
                    name='radioGroup'
                    value='new'
                    checked={radio === 'new'}
                    onChange={handleChange}
                />
                <Radio
                    label='Previous passage'
                    name='radioGroup'
                    value='old'
                    checked={radio === 'old'}
                    onChange={handleChange}
                    style={{marginLeft: 10}}
                />
                </FormField>
            </>
            }
            
            
            {
            
            radio === 'old' &&  <>
                <Select 
                    value={passageId}
                    placeholder='Select passage' 
                    search 
                    options={passageList} 
                    fluid
                    onChange={(e, data)=>{
                        setTimeout(()=>{
                            const selected = passages.find(d => d.passage_id === data.value)
                            if(selected.questions_count < 5){
                                setPassageId(data.value)
                                setTitle(selected.title)
                                setShowTitle(selected.show_title)
                                setPassage(selected.passage)
                            }else{
                                toast.info('The passage has 5 questions already')
                                setPassageId('')
                                setTitle('')
                                setShowTitle(false)
                                setPassage('')
                            }

                        }, 20)
                    }}/>
                    <br/>
                </>
            }



            <Input
                required
                id={radio}
                input_error={duplicateTitle}           
                onChange={titleChange}     
                value={title}
                initialValue={title}
                disabled={radio === 'old'}
                label='Title'
                name="title"
                placeholder='Type your title'
                wlength={50} />
            { duplicateTitle && <>
                <Label  style={{marginTop: 0}} color='red'>Title already exist, try different one!</Label>
                <br/>
                <br/>
            </>}


            <Checkbox
                disabled={radio === 'old'}             
                name='show_title'
                checked={showTitle}
                onChange={(e, data) => setShowTitle(data.checked)}
                label={'Display title on Edbot.ai'} 
                />

            <Textarea
                rows={10}
                onChange={v => setPassage(v.nextValue)}     
                value={passage}
                disabled={radio === 'old'}
                label='Passage'
                name="passage"
                required
                placeholder='Type your passage'
                useWordAsCount={true}
                wlength={1500} />

            <Input
                value={passageId}
                type="hidden"
                touched
                name="passage_id" />
            <Input
                value={showTitle}
                type="hidden"
                touched
                name="show_title" />
        </td>
        <td>
            <Input
            value={tempQuestions}
            required
            touched={data}
            type="hidden"
            name="questions" />
            {questions.length > 0 && !data ? questions.map((v, k)=><div key={k} style={{ display: 'flex', flexDirection: 'column' }}>
                <h5>Question #{k+1}</h5>
                <p style={{ marginBottom: 2 }}>{v.question}</p>

                <h5>Correct Answer</h5>
                <p style={{ marginBottom: 2 }}>{v.answer}</p>

                <h5 style={{ color: 'red' }}>Wrong Answer</h5>
                <li>{v.wrong1}</li>
                <li>{v.wrong2}</li>
                <li>{v.wrong3}</li>
                <div style={{ alignSelf: 'flex-end', justifyItems: 'center' }}>
                    <Button
                        type="button"
                        key={1}
                        floated='left'
                        secondary
                        onClick={()=>editQuestion(k)}>
                        Edit
                    </Button>
                    <Button 
                        type="button"
                        key={2} negative onClick={() => {
                        setSelectedIdx(k)
                        setConfirm(true)
                    }}>Delete</Button>
                </div>
                <Divider/>
            </div>) : !data ? <Message color='orange'>
            <p>Please add at least 1 question to submit</p></Message> : null}
            {formOpen && <>
                <FormField>
                <TextAreaNew placeholder='Type here' name='question' onKeyUp={data ? onTypeForm : null} onChange={onChange} maxLength={200}
                value={form.question}/>
                {hint(200-form.question.length)}
            </FormField>
            <FormField>
            <label>Correct Answer</label>
            <InputNew
                name="answer"
                required
                onChange={onChange}
                onKeyUp={data ? onTypeForm : null}
                placeholder='Type your correct answer' maxLength={150}
                value={form.answer}/>
                {hint(150-form.answer.length)}
            </FormField>
            <FormField>
                <label style={{ color: 'red' }}>Wrong Answer*</label>
                <InputNew
                name="wrong1"
                required
                onChange={onChange}
                onKeyUp={data ? onTypeForm : null}
                placeholder='Type your wrong answer' maxLength={150}
                value={form.wrong1}/>
                {hint(150-form.wrong1.length)}
            </FormField>
            <FormField>
            <InputNew
                name="wrong2"
                required
                onChange={onChange}
                onKeyUp={data ? onTypeForm : null}
                placeholder='Type your wrong answer' maxLength={150}
                value={form.wrong2}/>
                {hint(150-form.wrong2.length)}
            </FormField>
            <FormField>
            <InputNew
                name="wrong3"
                required
                onChange={onChange}
                onKeyUp={data ? onTypeForm : null}
                placeholder='Type your wrong answer' maxLength={150}
                value={form.wrong3}/>
                {hint(150-form.wrong3.length)}
            </FormField>
            </>}
            {!data && formOpen ? <FormField>
                <Button type='button' disabled={form.question.length < 1 || form.answer.length < 1 
                || form.wrong1.length < 1 || form.wrong2.length < 1 || form.wrong3.length < 1}
                color='green' onClick={addQuestion}>
                    Save question
                </Button>
                <Button type='button' className='tertiary' onClick={resetForm}>
                    Decline
                </Button>
            </FormField> : !data ? <FormField>
                <Button type='button' color='green' disabled={questions.length >= 5} onClick={()=>setFormOpen(true)}>
                    Add new question
                </Button>
            </FormField> : null}
            
            
        </td>

        <Confirm
            centered
            open={confirm}
            onCancel={()=>setConfirm(false)}
            onConfirm={removeQuestion}
            content='Are you sure want to delete question?'
        /> 
    </>
}


export default Module31Form

