import React from 'react'
/* Fields */
import { Select, Textarea } from '../fields'
import {Button, Icon, Grid, Label, Input as UIInput, Popup} from 'semantic-ui-react';
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html"
import TooltipVariableComponent from '../../components/tooltip_variable';

class A2B1Form extends React.Component{
    constructor(props){
        super(props)
        this.contentEditableSentence = React.createRef();
        this.state = {
            passageWidth: 0,
            wrong_options: this.props.data !== undefined ? this.props.data.question_object.wrong_options.split(',') : [],
            showAnswer: false,
            words: '',
            selectedKey: null,
            wrong_options_text: this.props.data !== undefined ? this.props.data.question_object.wrong_options : '',
            isTouch: false,
            showVarError: false,
            min_wrong_options: 4,
            max_wrong_options: 4,

            sentenceLength: 250,
            sentenceLengthConst: 250,
            isVariableExist: false,
            errorSentence: '',
            sentenceWidth: 0,
            showAnswer2: false,
            isTouch2: false,
            showVarError2: false,

            max_char: 20,
            html: ''
        }
    }

    // for set form data when edit
    componentDidMount(){
        let data = this.props.data
        if(data !== undefined){
            this.countWord(data.question_object.sentence)
            this.setState({
                html: data.question_object.sentence,
            })
        }

        setTimeout(()=>{
            this.setState({
                sentenceWidth: this.contentEditableSentence.current.clientWidth,
            })
        }, 0)
    }

    addTag = () =>{
        let word = this.state.words
        if(word.trim() === ''){
            return false
        }
        if(word.length > this.state.max_char){
            this.setState({showVarError: true})
            return false
        }
        word = word.replace(/[^\w\s]/gi, '')
        let key = this.state.selectedKey
        let wrong_options = this.state.wrong_options
        if(word !== ''){
            if(key !== null){
                wrong_options[key] = word
            }else{
                wrong_options.push(word)
            }
        }
        this.setState({wrong_options: wrong_options, showAnswer: false, words: '', selectedKey: null, 
        wrong_options_text: wrong_options.join(','), isTouch: true})
    }

    removeTag = (key, e) =>{
        let wrong_options = this.state.wrong_options
        wrong_options.splice(key, 1)
        this.setState({wrong_options: wrong_options, wrong_options_text: wrong_options.join(','), isTouch: true})
    }

    // sanitize html
    sanitizeConf = {
        allowedTags: ["a"],
        allowedAttributes: { a: ["class"] }
    };

    // handle editable content kye press
    handleKeyDown = (e) =>{
        let html = this.state.html
        if(e.which === 13) {
            html = html+ '&nbsp;'
            html = sanitizeHtml(html, this.sanitizeConf)
            this.setState({html: html})
            e.preventDefault()
        }

        if(e.which === 192){
            html = html+ `<a class="ui green label"></a>`
            html = sanitizeHtml(html, this.sanitizeConf)
            this.setState({html: html})
            e.preventDefault()
        }

        this.countWord(html)
    }

    // count editable content
    countWord = (p) => {
        let val = p;
        val = sanitizeHtml(val, this.sanitizeConf);
        let kl = val.replace(/^\s+|\s+$/g, "").replace(/\s+/g, " ");
        kl = kl.replace(/&amp;/g, '&');
        kl = kl.replace(/&gt;/g, '>'); 
        kl = kl.replace(/&lt;/g, '<'); 
      
        let wordCount = kl.split(/\s+/).length;
      
        let textLength = this.state.sentenceLengthConst;
        this.setState({ sentenceLength: kl.length === 0 ? textLength : textLength - wordCount });
    }

    // add tag/value on editable content
    addTag2 = () =>{
        let hint = ''
        hint = this.state.html
        let inp = document.getElementById('i_sentence').value
        if(inp.trim() === ''){
            return false
        }
        if(inp.length > this.state.max_char){
            this.setState({showVarError2: true})
            return false
        }
        hint += `<a class="ui green label">`+inp+`</a>&nbsp;`
        this.setState({html: hint, showAnswer2: false, 
            isTouch2: true, showVarError2: false})
        this.contentEditableSentence.current.focus();
        setTimeout(()=>{
            this.countWord(hint)
        }, 0)
    }

    handleChange = evt => {
        let html = sanitizeHtml(evt.target.value, this.sanitizeConf)
        let varData = html.match(/<a [^>]+>(.*?)<\/a>/g)
        let valueText = html
        if(varData !== null){
            varData.forEach(val=>{
                let extractWord = /<a [^>]+>(.*?)<\/a>/g.exec(val)
                extractWord[1] = extractWord[1].replace('&nbsp;', ' ')
                extractWord[1] = extractWord[1].replace('&amp;', '&')
                if(extractWord[1].length > this.state.max_char){
                    valueText = valueText.replace(extractWord.input, '<a class="ui green label">'+extractWord[1].slice(0,this.state.max_char)+'</a>')
                }
            })
        }
        html = valueText
        this.countWord(html)
        this.setState({ html: html, isTouch2: true});
    };

    render(){
        let sentenceError = ''
        // let isTouch = false
        let sentenceEl = document.getElementById('sentence')
        sentenceEl = sentenceEl !== null ? document.getElementById('sentence').getAttribute('class') : ''
        if(sentenceEl.includes('error')){
            sentenceError = ' error'
        }

        let sentenceVar = true
        if(this.state.html !== '' && !sanitizeHtml(this.state.html, this.sanitizeConf).includes('</a>')){
            sentenceVar = false
        }
        return <>
            <td>
                <Select label='Topic' name='topic' children={
                    this.props.topics.map((v, k)=>
                        <option value={v.category_id} key={k}>{v.name}</option>
                    )
                } required/>
            </td>
            <td style={this.state.sentenceWidth !== 0 ? {width: this.state.sentenceWidth} : null}>
                <div className='field'>
                    <label>Sentence <b style={{color:'#db2828'}}> *</b>
                    <TooltipVariableComponent open={false}/>
                    </label>
                    <ContentEditable 
                    innerRef={this.contentEditableSentence}
                    className={'editableContent'+ sentenceError} 
                    style={this.state.sentenceWidth !== 0 ? {width: this.state.sentenceWidth} : null}
                    html={this.state.html}
                    disabled={false}
                    onChange={this.handleChange}
                    onKeyDown={this.handleKeyDown}
                    id='content_sentence'
                    />
                    <span style={this.state.sentenceLength < 0 ? {color: '#db2828', textAlign: 'right', float: 'right'} : 
                    {textAlign: 'right', float: 'right'}}>{this.state.sentenceLength}</span>
                    <Textarea input_error={(this.state.sentenceLength < 0 || !sentenceVar) && this.state.isTouch2 
                    ? true : false} 
                    values={this.state.html} hidden={true} name="sentence" required/>
                </div>
                
                {this.state.showAnswer2 ? 
                    <Grid columns='equal'>
                        <Grid.Column width={10}>
                            <Popup content={'Max characters is '+this.state.max_char} trigger={
                                <div className='field'>
                                    <input placeholder='Type here...' id='i_sentence' onKeyDown={(e)=>{
                                        let val = document.getElementById('i_sentence').value
                                        this.setState({showVarError2: val.length > this.state.max_char ? true : false})
                                        if ((e.key === 'Enter' || e.key === 'Tab') && val.trim() !== '') {
                                            if(val.length <= this.state.max_char){
                                                this.addTag2()
                                            }
                                        }
                                    }}/>
                                    {this.state.showVarError2 ? 
                                    <div className="ui red pointing label">Your input is more than {this.state.max_char} characters</div>
                                    : null}
                                </div>
                            }/>
                        </Grid.Column>
                        <Grid.Column>
                            <Button className='tertiary' size='small' type='button' icon onClick={this.addTag2} disabled={this.state.showVarError2}>
                                <Icon color='green' name='check'/>
                            </Button>
                            <Button className='tertiary' size='small' type='button' icon onClick={()=>this.setState({showAnswer2: false})}>
                                <Icon color='grey' name='remove'/>
                            </Button>
                        </Grid.Column>
                    </Grid> : 
                    <>
                    <Button type='button' secondary size='small' onClick={()=>{
                        this.setState({showAnswer2: true})
                        setTimeout(()=>{
                            document.getElementById('i_sentence').focus()
                        }, 100)
                    }}>
                        Add correct answer
                    </Button>
                    <i style={{fontSize: 12, color: 'grey'}}>*required at least: 1 answer</i>
                    </>
                    }
            </td>
            <td style={this.state.passageWidth !== 0 ? {width: this.state.passageWidth} : null}>
                <div className='field'>
                    <label>Random Words <b style={{color:'#db2828'}}> *</b></label>
                    <div className={(this.state.wrong_options.length < this.state.min_wrong_options || this.state.wrong_options.length > this.state.max_wrong_options) && this.state.isTouch ? 'editableContent error' : 'editableContent'}>
                        {this.state.wrong_options.map((v,k)=>
                            <Label key={k} as='a' style={{marginTop: 5}}>
                                <span onClick={()=>this.setState({
                                    showAnswer: true, words: v, selectedKey: k
                                })}>{v}</span>
                                <Icon name='delete' onClick={this.removeTag.bind(null, k)}/>
                            </Label>
                        )}
                    </div>
                    {this.state.wrong_options.length < this.state.min_wrong_options && this.state.isTouch ? 
                        <Label color='red' pointing>
                            Not enough random word
                        </Label>
                    : null}
                    {this.state.wrong_options.length > this.state.max_wrong_options && this.state.isTouch ? 
                        <Label color='red' pointing>
                            Wrong options reached the limit : {this.state.max_wrong_options} words
                        </Label>
                    : null}
                    <Textarea input_error={(this.state.wrong_options.length < this.state.min_wrong_options || this.state.wrong_options.length > this.state.max_wrong_options) && this.state.isTouch 
                    ? true : false} 
                    values={this.state.wrong_options_text} hidden={true} name="wrong_options" required/>
                </div>
                
                {this.state.showAnswer ? 
                    <Grid columns='equal'>
                        <Grid.Column width={10}>
                            <Popup content={'Max characters is '+this.state.max_char} trigger={
                                <div className='field'>
                                    <UIInput id='random_word_i' placeholder='Type here...' onChange={(e, data)=>this.setState({words: data.value})} 
                                    value={this.state.words} onKeyDown={(e)=>{
                                        this.setState({showVarError: this.state.words.length > this.state.max_char ? true : false})
                                        if ((e.key === 'Enter' || e.key === 'Tab') && this.state.words.trim() !== '') {
                                            if(this.state.words.length <= this.state.max_char){
                                                this.addTag()
                                            }
                                        }
                                    }}/>
                                    {this.state.showVarError ? 
                                    <div className="ui red pointing label">Your input is more than {this.state.max_char} characters</div>
                                    : null}
                                </div>
                            }/>
                        </Grid.Column>
                        <Grid.Column>
                            <Button className='tertiary' disabled={this.state.words === '' || this.state.showVarError ? true : false}
                            size='small' type='button' icon onClick={this.addTag}>
                                <Icon color='green' name='check'/>
                            </Button>
                            <Button className='tertiary' 
                            size='small' type='button' icon onClick={()=>this.setState({showAnswer: false})}>
                                <Icon color='grey' name='remove'/>
                            </Button>
                        </Grid.Column>
                    </Grid> : 
                    <div>
                    <Button type='button' secondary size='small' 
                    disabled={this.state.wrong_options.length === this.state.max_wrong_options ? true : false}
                    onClick={()=>{
                        this.setState({showAnswer: true, words: ''})
                        setTimeout(()=>{
                            document.getElementById('random_word_i').focus()
                        }, 100)
                    }}>
                        {this.state.min_wrong_options - this.state.wrong_options.length <= 0 ? 
                        'Add Word'
                        : 'Add Word ('+(this.state.min_wrong_options - this.state.wrong_options.length)+')'
                        }
                    </Button>     
                    <i style={{fontSize: 12, color: 'grey'}}>*required : {this.state.min_wrong_options} options</i>
                    </div>}
            </td>
        </>
    }
}

export default A2B1Form