import React from "react";
import { Button, Icon, Container, Header, Divider, Input, Grid } from "semantic-ui-react";
import { connect } from "react-redux";
import { Slider } from "react-semantic-ui-range"

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        chapter_step_active: state.chapter_step_active,
        chapter_request_data: state.chapter_request_data
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        setStepActiveIndex: (n) => dispatch({ type: 'CHAPTER_INDEX_ACTIVE', payload: n }),
        setChapterRequestData: (n) => dispatch({ type: 'CHAPTER_REQUEST_DATA', payload: n })
    }
}

// display teaching format/module list
class CompletionPercentageSection extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            value: this.props.chapter_request_data.completion_rate === 0 ? 100 : this.props.chapter_request_data.completion_rate
        }
    }

    nextHandle = () =>{
        let chaperRequestData = this.props.chapter_request_data
        chaperRequestData.completion_rate = this.state.value
        this.props.setChapterRequestData(chaperRequestData)
        this.props.setStepActiveIndex(3)
    }

    backToChapter = () =>{
        this.props.setStepActiveIndex(1)
    }

    handleChangeInput = (e, data) =>{
        if(data.value.includes('-')){
            this.setState({value: '0'})
            return false
        }

        let val = parseInt(data.value)
        if(data.value > 100){
            val = 100
        }

        if(data.value < 0){
            val = 0
        }

        if(!Number.isInteger(val)){
            val = 0
        }

        this.setState({value: val})
    }

    render() {
        const settings = {
            start: 0,
            min: 0,
            max: 100,
            step: 25,
            onChange: value => {
                this.setState({value: value})
            }
        };
        return(
            <div>
                <Container text textAlign='center'>
                    <Header as='h2' style={{color: 'rgba(3, 28, 48, 0.65)'}}>Chapter Completion Percentage.</Header>
                    <p>The percentage for this chapter to be considered completed by the user.
                    The next chapter, if any, will be unlocked for the user to access after it is considered completed.</p>
                    <Divider hidden/>
                    <Grid columns='equal' style={{alignItems: 'center'}}>
                        <Grid.Column>
                            <Slider discrete value={this.state.value} color="blue" settings={settings} />
                        </Grid.Column>
                        <Grid.Column width={2}>
                            <Input value={this.state.value} type='number' onChange={this.handleChangeInput}
                            style={{width: 80}} label='%' labelPosition='right'/>
                        </Grid.Column>
                    </Grid>
                </Container>
                <Divider hidden/>
                <Divider hidden/>
                <Divider hidden/>
                <Button className='tertiary' onClick={this.backToChapter}>
                    <Icon name='arrow left'/> Back to rename the chapter
                </Button>
                <Button floated='right' primary onClick={this.nextHandle} id='btn_continue_chapter'>Save & Continue</Button>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CompletionPercentageSection)