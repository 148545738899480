// reducer of add Subject
export const addSubjectReducer = (state = [], action) =>{
    switch (action.type) {
        case 'CURRENT_SUBJECT':
            return state = action.payload
        default:
            return state
    }
}

// reducer of add filter Subject
export const filterSubjectReducer = (state = null, action) =>{
    switch (action.type) {
        case 'FILTER_SUBJECT':
                return state = action.payload
        default:
            return state
    }
}

// reducer of add load Subject
export const loadSubjectReducer = (state = false, action) =>{
    switch (action.type) {
        case 'SUBJECT_LOAD':
                return state = action.payload
        default:
            return state
    }
}

// reducer of add Prerequisite Subject
export const setPreqSubject = (state = [], action) =>{
    switch (action.type) {
        case 'PREQ_SUBJECT':
                return state = action.payload
        default:
            return state
    }
}

// reducer of add Prerequisite Subject
export const setPreqSubjectSelected = (state = [], action) =>{
    switch (action.type) {
        case 'SELECTED_PREQ_SUBJECT':
                return state = action.payload
        default:
            return state
    }
}

// reducer of chapter step active
export const chapterStepActive = (state = false, action) =>{
    switch (action.type) {
        case 'CHAPTER_INDEX_ACTIVE':
                return state = action.payload
        default:
            return state
    }
}

// reducer of chapter request data
export const chapterRequestData = (state = null, action) =>{
    switch (action.type) {
        case 'CHAPTER_REQUEST_DATA':
                return state = action.payload
        default:
            return state
    }
}

// reducer of is new question flag
export const isNewQuestion = (state = false, action) =>{
    switch (action.type) {
        case 'IS_NEW_QUESTION':
                return state = action.payload
        default:
            return state
    }
}

// reducer of is banner loading detect
export const isBannerLoading = (state = false, action) =>{
    switch (action.type) {
        case 'IS_BANNER_LOADING':
                return state = action.payload
        default:
            return state
    }
}

// reducer of add Subject
export const addSubjectListReducer = (state = [], action) =>{
    switch (action.type) {
        case 'LIST_SUBJECT':
            return state = action.payload
        default:
            return state
    }
}

// reducer of is_rc_question_open
export const isRCQuestionOpen = (state = [], action) =>{
    switch (action.type) {
        case 'IS_RC_NEW_QUESTION':
            return state = action.payload
        default:
            return state
    }
}

// reducer of banner data
export const bannerData = (state = [], action) =>{
    switch (action.type) {
        case 'BANNER_DATA':
            return state = action.payload
        default:
            return state
    }
}