import React from 'react'
import PropTypes from 'prop-types'
import { createField, fieldPresets } from 'react-advanced-form'
import { Label } from 'semantic-ui-react'

class Toggle extends React.Component {
  static propTypes = {
    /* General */
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    hint: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),

    /* Inherites */
    fieldProps: PropTypes.object.isRequired,
    fieldState: PropTypes.object.isRequired,
  }

  render() {
    const { fieldProps, fieldState, id, name, label, hint } = this.props
    const {
      touched,
      pristine,
      validating,
      validatedSync,
      validatedAsync,
      valid,
      validSync,
      validAsync,
      invalid,
      errors,
    } = fieldState

    const inputClassNames = [
      'form-control',
      touched && 'is-touched',
      pristine && 'is-pristine',
      validating && 'is-validating',
      validatedSync && 'validated-sync',
      validatedAsync && 'validated-async',
      valid && 'is-valid',
      validSync && 'valid-sync',
      validAsync && 'valid-async',
      invalid && 'error',
    ]
      .filter(Boolean)
      .join(' ')

    return (
      <div className="field">

          <div className="ui toggle checkbox">
            <input onChange={this.changeToggle} type="checkbox" {...fieldProps} id={id || name} className={inputClassNames}/>
            <label>{label}</label>
          </div>
        {hint && <small className="form-text text-muted">{hint}</small>}
        {errors &&
          errors.map((error, index) => (
            <Label color='red' key={index} pointing>{error}</Label>
          ))}
      </div>
    )
  }
}

export default createField(fieldPresets.checkbox)(Toggle)