
const rules = {
  // fieldGroup: {
  //   primaryInfo: {
  //     type: {
  //       email: ({ value }) => {
  //         return !value.startsWith('foo')
  //       },
  //     },
  //     name: {
  //       userEmail: ({ value }) => {
  //         return !value.startsWith('bar')
  //       },
  //     },
  //   },
  // },

  type: {
    // email: ({ value }) => /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(value),
    // password: {
    //   capitalLetter: ({ value }) => /[A-Z]/.test(value),
    //   oneNumber: ({ value }) => /[0-9]/.test(value),
    //   minLength: ({ value }) => value.length > 5,
    // },
  },

  name: {
    // birthDate: {
    //   day: ({ date: { day } }) => day.length > 0 && day <= 31,
    //   month: ({ date: { month } }) => month.length > 0 && month <= 12,
    //   year: ({ date: { year } }) => year.length === 4,
    // },
    confirm_password: {
      matches: ({ value, get }) => {
        return value === get(['new_password', 'value'])
      },
    }
  },
}

export default rules