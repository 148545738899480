import React from 'react';
import {
  Route, Switch, Redirect, withRouter
} from "react-router-dom";
import { connect } from 'react-redux'
import { ToastContainer } from 'react-toastify';

// modules
// import LandingPage from './modules/landing_page';
import TopNavAdminComponent from './components/top_nav_admin';
import { Container } from 'semantic-ui-react';
import FooterAdminComponent from './components/footer_admin';
import LoginAdminPage from './modules/auth/login_admin';
import DashboardPage from './modules/admin/dashboard';
import UsersPage from './modules/admin/users';
import SubjectsPage from './modules/admin/subjects';
import MinigamesPage from './modules/admin/minigames';
import ContactUsPage from './modules/admin/contact_us';
import FAQPage from './modules/admin/faq';
import LegalPage from './modules/admin/legals';
import { UsePageViews } from './helpers/global';

// to detect every store of redux/state changed
function mapStateToProps(state) {
  return { 
      auth: state.auth,
  }
}

// if route not found
const NoPage = () =>{
  return (
    <div style={{textAlign: 'center', paddingTop: 80, minHeight: '66.5vh'}}>
      <h1>404</h1>
      <h2>Page Not Found</h2>
    </div>
  )
}

class AppAdmin extends React.Component{
  componentDidMount(){
    UsePageViews(window.location.pathname + window.location.search)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      UsePageViews(window.location.pathname + window.location.search)
    }
  }

  render() {
    const stateAuth = this.props.auth
    const isLogged = stateAuth !== null ? stateAuth.is_logged : false
    let header = isLogged ? <TopNavAdminComponent/> : null
    return (
      <>
          {header}
          <Switch>
            {/* root page */}
            <Route path="/" exact>
              {isLogged ? <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
              <Redirect to="/dashboard" />
              </Container> : <Redirect to="/login" />}
            </Route>

            {/* login page */}
            <Route path="/login" exact>
              {isLogged ? <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
              <Redirect to="/dashboard" />
              </Container> : <LoginAdminPage/>
              }
            </Route>

            <Route path="/dashboard" exact>
              {isLogged ? <DashboardPage/>
                : <Redirect to="/login" />
              }
            </Route>

            <Route path="/users" exact>
              {isLogged ? <UsersPage/>
                : <Redirect to="/login" />
              }
            </Route>

            <Route path="/subjects" exact>
              {isLogged ? <SubjectsPage/>
                : <Redirect to="/login" />
              }
            </Route>

            <Route path="/minigames" exact>
              {isLogged ? <MinigamesPage/>
                : <Redirect to="/login" />
              }
            </Route>

            <Route path="/contact" exact>
              {isLogged ? <ContactUsPage/>
                : <Redirect to="/login" />
              }
            </Route>

            <Route path="/faq" exact>
              {isLogged ? <FAQPage/>
                : <Redirect to="/login" />
              }
            </Route>

            <Route path="/legal" exact>
              {isLogged ? <LegalPage/>
                : <Redirect to="/login" />
              }
            </Route>

            {/* error page */}
            <Route path="*" component={NoPage}/>
          </Switch>

          {/* toaster */}
          <ToastContainer
          position="bottom-left"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover
          />

            <FooterAdminComponent/>
    </>
    )
  }
}

export default connect(mapStateToProps)(withRouter(AppAdmin))

