const messages = {
  name: {
    name: {
      missing: 'Please provide subject name',
    },
    description: {
      missing: 'Please provide subject description',
    }
  },
}

export default messages