import React from 'react'
import { Menu, Dropdown, Image, Label, Input, Button, Icon, Modal } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import storage from 'redux-persist/lib/storage'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import NotifText from './notif_text'
import TaskText from './task_text';
import * as Sentry from "@sentry/browser";

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth,
        nav: state.nav,
        user_updated: state.user_updated,
        task_manager_called: state.task_manager_called
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        navAction: (n) => dispatch({ type: 'CHANGE_NAV', payload: n }),
        userUpdated: (n) => dispatch({type: 'SET_USER_UPDATED', payload: n}),
        setTaskManager: (n) => dispatch({ type: 'SET_TASK_MANAGER', payload: n })
    }
}



class TopNavComponent extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            modalOpen: false,
            btnLoading: false,
            myHeaders: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            avatar: require('../assets/images/avatar-default.png'),
            notifList: [],
            isNewNotif: false,
            taskList: [],
            isNewTask: false,
            searchText: '',
            taskManager: 0
        }
    }

    componentDidUpdate(){
        if(this.props.user_updated){
            this.loadData()
        }

        if(this.props.task_manager_called){
            this.checkTaskManager()
        }
    }

    componentDidMount(){
        this.checkTaskManager()
        this.loadNotification()
        this.loadTask()
        this.loadData()
    }

    checkTaskManager = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/manager/tasks/number', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            this.setState({taskManager: res.data})
            this.props.setTaskManager(false)
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    loadNotification = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/notifications', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            let isNew = res.data.filter(x=>!x.read)
            isNew = isNew.length > 0 ? true : false
            this.setState({notifList: res.data, isNewNotif: isNew})
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    loadTask = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/tasks', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            let isNew = res.data.filter(x=>!x.read)
            isNew = isNew.length > 0 ? true : false
            this.setState({taskList: res.data, isNewTask: isNew})
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    loadData = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/user/info?username='+this.props.auth.user.username, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if(res.status){
                this.props.userUpdated(false)
                this.setState({avatar: res.data.avatar})
            }
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    form = null

    changeNav = (subject, e) =>{
        this.props.navAction(subject)
    }

    logout = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/auth/logout',{
            headers: this.state.myHeaders
        }).then(function(response) {
            return response.json()
        }).then(function(response) {
            window.localStorage.clear()
            storage.removeItem('persist:root')
            window.location = '/'
        })
    }

    updateNotifRead = () =>{
        const loadNotif = this.loadNotification
        let data = this.state.notifList
        if(data.length > 0){
            data = data.filter(x=>!x.read)
            data = data.map(x => {
                return x.notification_id
            })

            fetch(process.env.REACT_APP_API_URL+'api/v1/user/notifications/read?ids=['+data.toString()+']',{
                headers: this.state.myHeaders
            }).then(function(res) {
                return res.json()
            }).then(function(res) {
                if(res.status === undefined){
                    storage.removeItem('persist:root')
                    window.location.href = '/'
                }

                loadNotif()
            }).catch((e)=>{
                Sentry.captureException(e);
                toast.error(<div>
                    Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                </div>)
            })
        }
    }
    
    updateTaskRead = () =>{
        const loadTask = this.loadTask
        let data = this.state.taskList
        if(data.length > 0){
            data = data.filter(x=>!x.read)
            data = data.map(x => {
                return x.task_id
            })

            fetch(process.env.REACT_APP_API_URL+'api/v1/user/tasks/read?ids=['+data.toString()+']',{
                headers: this.state.myHeaders
            }).then(function(res) {
                return res.json()
            }).then(function(res) {
                if(res.status === undefined){
                    storage.removeItem('persist:root')
                    window.location.href = '/'
                }

                loadTask()
            }).catch((e)=>{
                Sentry.captureException(e);
                toast.error(<div>
                    Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                </div>)
            })
        }
    }

    handleChangeSearch = (e, data) =>{
        this.setState({searchText: data.value})
    }

    handleSearchEnter = (e) =>{
        let text = this.state.searchText
        if(e.key === 'Enter'){
            if(text !== ''){
                localStorage.setItem('main_search', text)
                window.location.href = '/search'
            }
        }
    }
        

    render() {
        let pathName = window.location.pathname.split('/')
        pathName = pathName[pathName.length-1]
        pathName = pathName === '' ? 'subject' : pathName
        let activeMenu = this.props.nav
        if(activeMenu === null){
            this.props.navAction(pathName)
        }
        activeMenu = activeMenu === '' ? pathName : activeMenu
        const user = (
            <><Image src={this.state.avatar !== '' && this.state.avatar !== null ? this.state.avatar : 
            require('../assets/images/avatar-default.png')} avatar/> {this.props.auth.user.username}</>
        )

        return (
            <Menu className='fixed TopNav'>
            <Link onClick={this.changeNav.bind(null, 'subject')} to='/subject' className="header item">
                <Image src={require('../assets/images/content.png')}/>
            </Link>
            <Link onClick={this.changeNav.bind(null, 'subject')} to='/subject' className={'item'+ (activeMenu === 'subject' ? ' active' : '')}>
                My Subjects
            </Link>
            <Link onClick={this.changeNav.bind(null, 'browse')} to='/browse' className={'item'+ (activeMenu === 'browse' ? ' active' : '')}>
                Browse
            </Link>
            <Link onClick={this.changeNav.bind(null, 'task')} to='/task' className={'item'+ (activeMenu === 'task' ? ' active' : '')}>
                Task {this.state.taskManager > 0 ? 
                <Label circular color='red' size='mini'>
                    {this.state.taskManager}
                </Label> : null}
            </Link>
            <Modal closeIcon trigger={
                <div className="item">
                    <Button color='teal' className='mathKeyboard'>
                    <Icon name='keyboard'/> Keyboard
                    </Button>
                </div>
            }>
                <Modal.Content>
                    <iframe title='keyboard' src="https://mathkeyboard.dawnofcivilization.net/" width="100%" height="500"></iframe>
                </Modal.Content>
            </Modal>
            
            <Menu.Menu position='right'>
                <div className="item">
                    <div className="ui icon rounded input">
                        <Input onChange={this.handleChangeSearch} style={{width:273}} onKeyPress={this.handleSearchEnter}
                        type="text" icon='search' placeholder="Search by words or question id..."/>
                    </div>
                </div>
                <div className='item'>
                    {this.state.isNewTask ? <Label size='mini' circular empty color='red' floating style={{top: 10,left: 50}}/> : null}
                    <Dropdown onOpen={this.updateTaskRead}
                        icon='clipboard list'
                        floating
                        className='notifDropdown'
                    >
                        <Dropdown.Menu>
                            {this.state.taskList.length > 0 ? 
                                this.state.taskList.map((v,k)=>
                                    <TaskText key={k} data={v}/>
                                )
                                : <div style={{padding: 20, textAlign: 'center'}}>No Recent Updates</div>   
                            }
                            <div className='btnAll'>
                                <Link onClick={this.changeNav.bind(null, null)} to='/updates' className='ui primary button'>Show All Updates</Link>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className='item'>
                    {this.state.isNewNotif ? <Label size='mini' circular empty color='red' floating style={{top: 10,left: 50}}/> : null}
                    <Dropdown onOpen={this.updateNotifRead}
                        icon='bell'
                        floating
                        className='notifDropdown'
                    >
                        <Dropdown.Menu>
                            {this.state.notifList.length > 0 ? 
                                this.state.notifList.map((v,k)=>
                                    <NotifText key={k} data={v}/>
                                )
                                : <div style={{padding: 20, textAlign: 'center'}}>No Recent Notification</div>   
                            }
                            <div className='btnAll'>
                                <Link onClick={this.changeNav.bind(null, null)} to='/notification' className='ui primary button'>Show All Notifications</Link>
                            </div>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <Dropdown trigger={user} className='item'>
                    <Dropdown.Menu>
                        <Dropdown.Item onClick={this.changeNav.bind(null, null)} as={Link} to='/profile/me' text='Profile' icon='user'/>
                        <Dropdown.Item text='Logout' icon='sign-out' onClick={this.logout}/>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
        </Menu>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopNavComponent)