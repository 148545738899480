import React from 'react'
/* Fields */
import { Input, Textarea } from '../fields'
import {Button} from 'semantic-ui-react'
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html"

// module 1a form
class Module1AForm extends React.Component{
    constructor(props){
        super(props)
        this.contentEditableHint1 = React.createRef();
        this.contentEditableHint2 = React.createRef();
        this.contentEditableHint3 = React.createRef();
        this.state = {
            hint1: '', 
            hint2: '', 
            hint3: '', 
            hint1Length: 140,
            hint2Length: 140,
            hint3Length: 140,
            isVariableExist: false,
            currentWord: '',
            errorHint1: '',
            errorHint2: '',
            errorHint3: '',
            hint1Width: 0,
            hint2Width: 0,
            hint3Width: 0,
            maxHintLength: 140
        }
    }

    // sanitize html
    sanitizeConf = {
        allowedTags: ["a"],
        allowedAttributes: { a: ["class"] }
    };

    // for set form data when edit
    componentDidMount(){
        let data = this.props.data
        if(data !== undefined){
            this.countWord(data.question_object.hint1, 'hint1')
            this.countWord(data.question_object.hint2, 'hint2')
            this.countWord(data.question_object.hint3, 'hint3')
            this.setState({
                hint1: data.question_object.hint1,
                hint2: data.question_object.hint2,
                hint3: data.question_object.hint3,
                currentWord: data.question_object.text
            })
        }

        setTimeout(()=>{
            this.setState({
                hint1Width: this.contentEditableHint1.current.clientWidth,
                hint2Width: this.contentEditableHint2.current.clientWidth,
                hint3Width: this.contentEditableHint3.current.clientWidth
            })
        }, 0)
    }

    // handle editable content
    handleChange = evt => {
        let valueWord = evt.target.value
        valueWord = valueWord.replace('&lt;', '<')
        valueWord = valueWord.replace('&gt;', '>')
        let varWord = /<a class="ui green label">(.*?)<\/a>/g.exec(valueWord)
        if(varWord !== null){
            valueWord = valueWord.replace('<a class="ui green label">'+varWord[1]+'</a>', '<a class="ui green label">'+this.state.currentWord+'</a>')
        }
        switch (evt.currentTarget.id) {
            case 'content_hint1':
                this.countWord(valueWord, 'hint1')
                this.setState({hint1: valueWord});
                break;
            case 'content_hint2':
                this.countWord(valueWord, 'hint2')
                this.setState({hint2: valueWord});
                break;
            // content_hint3
            default:
                this.countWord(valueWord, 'hint3')
                this.setState({hint3: valueWord});
                break;
        }
        
    }

    // count editable content
    countWord = (p, type) =>{
        let val = p
        val = sanitizeHtml(val, this.sanitizeConf)
        let list = val.split('<a class="ui green label">')
        let words = []
        list.forEach(ed => {
            ed = ed.trim()
            ed = ed.replace('</a>', '')
            words.push(ed)
        });

        let kl = words.join(' ')
        let textLength = this.state.maxHintLength
        kl = kl.replace(/^\s+|\s+$/g, "").replace(/\s+/g, "")
        kl = kl.replace(/&amp;/g, '&')
        kl = kl.replace(/&lt;/g, '<')
        kl = kl.replace(/&gt;/g, '>')
        switch (type) {
            case 'hint1':
                this.setState({hint1Length: kl.length === 0 ? textLength : 
                    textLength - kl.length});
                break;
            case 'hint2':
                this.setState({hint2Length: kl.length === 0 ? textLength : 
                    textLength - kl.length});
                break;
            // hint3
            default:
                this.setState({hint3Length: kl.length === 0 ? textLength : 
                    textLength - kl.length});
                break;
        }
    }

    // add tag/value on editable content
    addTag = (type, e) =>{
        let hint = ''
        switch (type) {
            case 'hint1':
                hint = this.state.hint1
                hint += `&nbsp;<a class="ui green label">`+this.state.currentWord+`</a>&nbsp;`
        
                this.setState({hint1: hint})
                this.contentEditableHint1.current.focus();
                setTimeout(()=>{
                    this.countWord(hint, 'hint1')
                }, 0)
                break;
            case 'hint2':
                hint = this.state.hint2
                hint += `&nbsp;<a class="ui green label">`+this.state.currentWord+`</a>&nbsp;`
        
                this.setState({hint2: hint})
                this.contentEditableHint2.current.focus();
                setTimeout(()=>{
                    this.countWord(hint, 'hint2')
                }, 0)
                break;
            // hint3
            default:
                hint = this.state.hint3
                hint += `&nbsp;<a class="ui green label">`+this.state.currentWord+`</a>&nbsp;`
        
                this.setState({hint3: hint})
                this.contentEditableHint3.current.focus();
                setTimeout(()=>{
                    this.countWord(hint, 'hint3')
                }, 0)
                break;
        }
    }

    wordVarChange = (e) =>{
        let hint1 = this.generatedVar(this.state.hint1, e.nextValue)
        this.countWord(hint1, 'hint1')
        let hint2 = this.generatedVar(this.state.hint2, e.nextValue)
        this.countWord(hint2, 'hint2')
        let hint3 = this.generatedVar(this.state.hint3, e.nextValue)
        this.countWord(hint3, 'hint3')

        this.setState({
            currentWord: e.nextValue,
            hint1: hint1,
            hint2: hint2,
            hint3: hint3
        })
    }

    generatedVar = (valueWord, currVar) =>{
        let varWord = /<a class="ui green label">(.*?)<\/a>/g.exec(valueWord)
        if(varWord !== null){
            valueWord = valueWord.replace('<a class="ui green label">'+varWord[1]+'</a>', '<a class="ui green label">'+currVar+'</a>')
        }
        return valueWord
    }

    // handle editable content kye press
    handleKeyDown = (e) =>{
        switch (e.currentTarget.id) {
            case 'content_hint1':
                let html = this.state.hint1
                if(e.which === 13) {
                    html = html+ '&nbsp;'
                    html = sanitizeHtml(html, this.sanitizeConf)
                    this.setState({answer1: html})
                    e.preventDefault()
                }
            break;
            case 'content_hint2':
                let html2 = this.state.hint2
                if(e.which === 13) {
                    html2 = html2+ '&nbsp;'
                    html2 = sanitizeHtml(html2, this.sanitizeConf)
                    this.setState({answer2: html2})
                    e.preventDefault()
                }
            break;
            default:
                let html3 = this.state.hint3
                if(e.which === 13) {
                    html3 = html3+ '&nbsp;'
                    html3 = sanitizeHtml(html3, this.sanitizeConf)
                    this.setState({answer3: html3})
                    e.preventDefault()
                }
            break;
        }

    }

    render() {
        let hint1Error = ''
        let hint1El = document.getElementById('hint1')
        hint1El = hint1El !== null ? document.getElementById('hint1').getAttribute('class') : ''
        if(hint1El.includes('error')){
            hint1Error = ' error'
        }

        let hint2Error = ''
        let hint2El = document.getElementById('hint2')
        hint2El = hint2El !== null ? document.getElementById('hint2').getAttribute('class') : ''
        if(hint2El.includes('error')){
            hint2Error = ' error'
        }

        let hint3Error = ''
        let hint3El = document.getElementById('hint3')
        hint3El = hint3El !== null ? document.getElementById('hint3').getAttribute('class') : ''
        if(hint3El.includes('error')){
            hint3Error = ' error'
        }

        let hint1Var = true
        if(this.state.hint1 !== '' && !sanitizeHtml(this.state.hint1, this.sanitizeConf).includes('</a>')){
            hint1Var = false
        }

        let hint2Var = true
        if(this.state.hint2 !== '' && !sanitizeHtml(this.state.hint2, this.sanitizeConf).includes('</a>')){
            hint2Var = false
        }

        let hint3Var = true
        if(this.state.hint3 !== '' && !sanitizeHtml(this.state.hint3, this.sanitizeConf).includes('</a>')){
            hint3Var = false
        }

        return (
            <>
                <td>
                    <Input onChange={this.wordVarChange} 
                    label='Word' name="word" required placeholder='Exp: Car' wlength={13}/>
                </td>
                <td>
                    <Textarea label='Definition' name="definition" required placeholder='Type here...' wlength={170}/>
                </td>
                <td style={this.state.hint1Width !== 0 ? {width: this.state.hint1Width} : null}>
                    <div className='field'>
                        <label>Hint 1 <b style={{color:'#db2828'}}> *</b></label>
                        <ContentEditable className={'editableContent'+ hint1Error} 
                        style={this.state.hint1Width !== 0 ? {width: this.state.hint1Width} : null}
                            innerRef={this.contentEditableHint1}
                            html={this.state.hint1}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={'Type here...'}
                            id='content_hint1'
                        />
                        <span style={this.state.hint1Length < 0 ? {color: '#db2828', textAlign: 'right', float: 'right'} : 
                        {textAlign: 'right', float: 'right'}}>{this.state.hint1Length}</span>
                        
                        <Textarea input_error={this.state.hint1Length < 0 || !hint1Var ? true : false} 
                        values={this.state.hint1} hidden={true} name="hint1" required/>
                    </div>
                    
                    <Button secondary disabled={
                        sanitizeHtml(this.state.hint1, this.sanitizeConf).includes('</a>') || this.state.currentWord === '' 
                        ? true : false
                    } size='small' type='button' onClick={this.addTag.bind(null, 'hint1')}>
                        Add {this.state.currentWord !== '' ? '*'+this.state.currentWord+'*' : null}
                    </Button>
                </td>
                
                <td style={this.state.hint2Width !== 0 ? {width: this.state.hint2Width} : null}>
                    <div className='field'>
                        <label>Hint 2 <b style={{color:'#db2828'}}> *</b></label>
                        <ContentEditable style={this.state.hint2Width !== 0 ? {width: this.state.hint2Width} : null} 
                        className={'editableContent'+ hint2Error}
                            innerRef={this.contentEditableHint2}
                            html={this.state.hint2}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={'Type here...'}
                            id='content_hint2'
                        />
                        <span style={this.state.hint2Length < 0 ? {color: '#db2828', textAlign: 'right', float: 'right'} : 
                        {textAlign: 'right', float: 'right'}}>{this.state.hint2Length}</span>
                        
                        <Textarea input_error={this.state.hint2Length < 0 || !hint2Var ? true : false} 
                        values={this.state.hint2} hidden={true} name="hint2" required/>
                    </div>
                    
                    <Button secondary disabled={
                        sanitizeHtml(this.state.hint2, this.sanitizeConf).includes('</a>') || this.state.currentWord === '' 
                        ? true : false
                    } size='small' type='button' onClick={this.addTag.bind(null, 'hint2')}>
                        Add {this.state.currentWord !== '' ? '*'+this.state.currentWord+'*' : null}
                    </Button>
                </td>
                <td style={this.state.hint3Width !== 0 ? {width: this.state.hint3Width} : null}>
                    <div className='field'>
                        <label>Hint 3 <b style={{color:'#db2828'}}> *</b></label>
                        <ContentEditable style={this.state.hint3Width !== 0 ? {width: this.state.hint3Width} : null}
                            className={'editableContent'+ hint3Error}
                            innerRef={this.contentEditableHint3}
                            html={this.state.hint3}
                            onChange={this.handleChange}
                            onKeyDown={this.handleKeyDown}
                            placeholder={'Type here...'}
                            id='content_hint3'
                        />
                        <span style={this.state.hint3Length < 0 ? {color: '#db2828', textAlign: 'right', float: 'right'} : 
                        {textAlign: 'right', float: 'right'}}>{this.state.hint3Length}</span>
                        
                        <Textarea input_error={this.state.hint3Length < 0 || !hint3Var ? true : false} 
                        values={this.state.hint3} hidden={true} name="hint3" required/>
                    </div>
                    
                    <Button secondary disabled={
                        sanitizeHtml(this.state.hint3, this.sanitizeConf).includes('</a>') || this.state.currentWord === '' 
                        ? true : false
                    } size='small' type='button' onClick={this.addTag.bind(null, 'hint3')}>
                        Add {this.state.currentWord !== '' ? '*'+this.state.currentWord+'*' : null}
                    </Button>
                </td>
            </>
        )
    }
}

export default Module1AForm