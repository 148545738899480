import React from 'react';
import { toast } from 'react-toastify';
import { Container, Grid, Button, Segment, Image, Divider, List, Icon, Form, Input } from 'semantic-ui-react';

export default class RCPreview extends React.Component{
    constructor(props){
        super(props)
        let passage = localStorage.getItem('passage')
        passage = JSON.parse(passage)

        let questionObject = localStorage.getItem('question')
        questionObject = JSON.parse(questionObject)

        this.state = {
          passage: passage,
          question: questionObject,
          shownQuestion: questionObject.question.filter(x=>!x.is_draf),
          passageText: passage.passage.split("\n")
        }
    }

    questionResponseTimeChange = (e, data) =>{
      let questionData = this.state.question
      let question = questionData.question
      let idx = question.findIndex(x=>x.id === parseInt(data.id.split('_')[1]))
      if(idx !== -1){
        question[idx].response_time = data.value
      }

      questionData.question = question

      this.setState({question: questionData, shownQuestion: question.filter(x=>!x.is_draf)})
      localStorage.setItem('question', JSON.stringify(questionData))
    }

    render() {
      return (
        <Container>
        <Divider hidden/>
        <Grid columns="equal">
          <Grid.Column>
            <p>
              <b>Topic: </b> {this.state.passage.topic}
            </p>
          </Grid.Column>
          <Grid.Column>
            <p>
              <b>Reading Time: </b> {this.state.passage.reading_time} min
            </p>
          </Grid.Column>
          <Grid.Column width="six"></Grid.Column>
        </Grid>
        {this.state.passage.show_image ? 
        <Segment>
          {this.state.passageText.map((v, k)=>{
            let txt = null
            if(v !== ""){
              txt = <p key={k}>{v}</p>
            }
            return txt
          })}
          {this.state.passage.image !== null ? 
          <><Image src={this.state.passage.image} size='medium' centered/><Divider hidden/></>
          : null}
        </Segment>
        : <Segment>
          {this.state.passage.image !== null ? 
          <><Image src={this.state.passage.image} size='medium' centered/><Divider hidden/></>
          : null}
          {this.state.passageText.map((v, k)=>{
            let txt = null
            if(v !== ""){
              txt = <p key={k}>{v}</p>
            }
            return txt
          })}
        </Segment>}
                
        <Divider hidden/>
        <Grid columns="equal">
          <Grid.Row>
            <Grid.Column>
              <p><b>Source: </b> {this.state.passage.source_type}</p>
            </Grid.Column>
            <Grid.Column width="ten">
              {this.state.passage.source_type === 'Web Article' || this.state.passage.source_type === 'Text Book' ? 
              <p><b>Source Title: </b> {this.state.passage.source_title} <span onClick={()=>{
                navigator.clipboard.writeText(this.state.passage.source_title).then(function() {
                  toast.info('Copied')
                }, function(err) {
                  toast.warn('Cannot Copy')
                });
              }}><Icon name='copy' color='grey' link/></span></p>
              : null}
            </Grid.Column>
          </Grid.Row>
          {this.state.passage.source_type === 'Web Article' ? 
          <Grid.Row>
            <Grid.Column>
              <p><b>Source URL: </b> <a href={this.state.passage.source_url} target="_blank" rel="noopener noreferrer">{this.state.passage.source_url}</a> <span onClick={()=>{
                navigator.clipboard.writeText(this.state.passage.source_url).then(function() {
                  toast.info('Copied')
                }, function(err) {
                  toast.warn('Cannot Copy')
                });
              }}><Icon name='copy' color='grey' link/></span></p>
            </Grid.Column>
          </Grid.Row>
          : null}
          <Grid.Row>
            <Grid.Column>
            {this.state.passage.source_type === 'Web Article' || this.state.passage.source_type === 'Text Book' ? 
              <p><b>Retrieve Date: </b> {this.state.passage.retrieve_date}</p>
            : null}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider hidden/>
        <Divider/>
        <Divider hidden/>
        <Grid columns='equal'>
          <Grid.Column>
            <p><b>Questions</b></p>
          </Grid.Column>
          {/* <Grid.Column width="ten">
            <p><b>Response Time: </b> {this.state.question.response_time} min</p>
          </Grid.Column> */}
        </Grid>

        <List ordered size='large'>
          {this.state.shownQuestion.map((v, k)=>
          <List.Item key={k}>
            <List.Content>
              <List.Header>{v.question}</List.Header>
              <List.Description>
                <b>Answer: </b> {v.answer}
                <Form>
                  <Form.Field inline>
                    <label>Response Time</label>
                    <Input id={"res_"+v.id} size='mini' min={1} type='number' readOnly={this.props.is_preview}
                    value={v.response_time} onChange={this.questionResponseTimeChange}/> minute(s)
                  </Form.Field>
                </Form>
              </List.Description>
            </List.Content>
          </List.Item>
          )}
        </List>
        
        {!this.props.is_preview ? 
        <Button floated='right' primary
          onClick={this.props.action.bind(null, {type: 'preview', data: {
            questions: this.state.question,
            passage: this.state.passage
          }})}>
            Save & Finish
        </Button>
        : null}
        
        </Container>
      )
    };
}