import React from 'react';
import {Icon, Segment, Header, Grid, Table, Dimmer, Loader} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import {connect} from 'react-redux';
import { toast } from 'react-toastify';
import storage from 'redux-persist/lib/storage';
import EmptyComponent from '../../../components/empty';

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        dashboard_update: state.dashboard_update
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        navAction: (n) => dispatch({ type: 'CHANGE_NAV', payload: n })
    }
}

class PlayedSubjects extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            subjects: []
        }
    }

    componentDidMount(){
        this.loadData()
    }

    componentDidUpdate(oldProps, oldState){
        if(oldProps.dashboard_update !== this.props.dashboard_update){
            this.loadData(this.props.dashboard_update.query)
        }
    }

    loadData = (query = '') =>{
        this.setState({loading: true, subjects: []})
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/dashboard/most-played-subjects'+query, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({loading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                this.setState({subjects: res.data})
            }
        }).catch((e)=>{
            toast.error('Subject not found.')
        })
    }

    render(){
        return(
            <>
            <Grid columns='equal'>
                <Grid.Column>
                    <Header as='h3'>
                        <Icon name='folder'/> Top 5 Most Played Subjects
                    </Header>
                </Grid.Column>
                <Grid.Column width={4} textAlign='right'>
                    <Link to='/subjects' className='linkMore'
                    onClick={()=>this.props.navAction('subjects')}>See All Subjects</Link>
                </Grid.Column>
            </Grid>
            <Segment>
                {this.state.loading ? <Dimmer inverted active>
                    <Loader active/>
                </Dimmer> :
                this.state.subjects.length > 0 ? 
                <Table className='noborder'>
                    <Table.Body>
                        {this.state.subjects.map((v,k)=>
                        <Table.Row key={k}>
                            <Table.Cell>{k+1}</Table.Cell>
                            <Table.Cell>
                                <Header as='h5'>
                                    {v.subject_name}
                                    <Header.Subheader>
                                    by {v.subject_owner}
                                    </Header.Subheader>
                                </Header>
                            </Table.Cell>
                            <Table.Cell textAlign='right'>{v.play_session} times played</Table.Cell>
                        </Table.Row>
                        )}
                    </Table.Body>
                </Table>
                : <EmptyComponent size='tiny'/>
                }
                
            </Segment>
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlayedSubjects)