import React from 'react'
import { Grid, Button, Icon, Divider, Table, Pagination, Dropdown,
Modal, Label, Popup,
Loader, Image,
Checkbox,
Container,
Input,
Form} from 'semantic-ui-react'
import '../style.scss'
import {connect} from 'react-redux'
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify'
import {DatesRangeInput} from 'semantic-ui-calendar-react';
import {formatBytes} from '../../../helpers/file_size';

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        auth: state.auth
    }
}

class ContactUsPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            ticketList: [],
            loading: true,
            filter: {
                page: 1,
                n: 10,
                order_by: 2,
                asc: 0,
                unreplied_only: 0,
                search: '',
                start: '',
                end: ''
            },
            totalPage: 0,
            totalRecord: 0,
            dateRange: '',
            selectedAch: '',
            achModal: false,
            attachments: [],
            replyModal: false,
            replyMessage: '',
            selectedTicket: null,
            loadingReply: false
        }
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/contacts/tickets', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify(this.state.filter)
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            this.setState({ticketList: res.data, loading: false, 
                totalPage: res.total_page, 
                totalRecord: res.total_record})
        }).catch((e)=>{
            toast.error('something wrong happened on getting contact us data.')
        })
    }

    handleSort = (clickedColumn) => () => {
        let filter = this.state.filter
        filter.order_by = clickedColumn
        filter.asc = filter.asc === 1 ? 0 : 1
        this.setState({
            filter: filter
        })

        setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    handleChangePagination = (e, data) =>{
        let filter = this.state.filter
        filter.page = data.activePage
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    handleChangeEntries = (e, data) =>{
        let filter = this.state.filter
        filter.n = data.value
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    handleFilter = (e, data) =>{
        let filter = this.state.filter
        switch (data.name) {
            case 'search':
                filter.search = data.value
                break;
            case 'all':
                filter.start = ''
                filter.end = ''
                this.setState({dateRange: ''})
                break;
            default:
                filter.unreplied_only = data.checked
                break;
        }
        this.setState({filter: filter})
        setTimeout(()=>{
            this.loadData()
        }, 200)
    }

    handleDateFilter = (event, {name, value}) => {
        let filter = this.state.filter
        let val = value.split('-')
        for (let i = 0; i < val.length; i++) {
            val[i] =  val[i].trim()           
        }
        let start = val[0]
        let end = val[1]
        if(start !== '' && end !== ''){
            filter.start = start.replace('/', '-')
            filter.start = filter.start.replace('/', '-')
            filter.end = end.replace('/', '-')
            filter.end = filter.end.replace('/', '-')
            this.setState({filter: filter})
            setTimeout(()=>{
                this.loadData()
            }, 200)
        }
        this.setState({dateRange: value})
    }

    renderNumber = (k)=>{
        let n = this.state.filter.n * this.state.filter.page
        n = n - this.state.filter.n + k + 1
        return n
    }

    fileInput = null

    onChangeImage = (e)=>{
        e.preventDefault();
        let files;

        if(e.target.files[0] === undefined){
            return false
        }

        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            this.fileInput.value = "";
            return false
        }

        if(e.target.files[0].size > 2000000){
            toast.error('Image should be equal to or less than 2MB.')
            return false
        }
        
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        let size = formatBytes(files[0].size).split(' ')
        let totSize = this.state.totalSize
        totSize = totSize + parseFloat(size[0])
        let setFile = {
            name: files[0].name,
            size: formatBytes(files[0].size),
            data: ''
        }

        if(totSize > 2000){
            toast.error('Attachment size cannot more than 2MB.')
            this.fileInput.value = "";
            return false
        }

        const reader = new FileReader();
        reader.onload = () => {
            let atts = this.state.attachments
            setFile.data = reader.result
            let is_exist = atts.filter(x=>x.name === setFile.name && x.size === setFile.size)
            if(is_exist.length > 0){
                toast.error('image already attached, please choose another image.')
                this.fileInput.value = "";
                return false
            }
            atts.push(setFile)
            this.setState({attachments: atts, totalSize: totSize})
            this.fileInput.value = "";
        };
        reader.readAsDataURL(files[0]);
    }

    removeAttachment = (key) =>{
        let attachment = this.state.attachments
        attachment.splice(key, 1)
        this.setState({attachments: attachment})
    }

    sendMessage = () =>{
        this.setState({loadingReply: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/contacts/tickets/reply/'+this.state.selectedTicket.ticket_id, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify({
                message: this.state.replyMessage,
                attachments: this.state.attachments.map(function(e){
                    return e.data
                })
            })
        })
        .then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                toast.success('Ticket replied.')
                this.loadData()
            }else{
                toast.error('Error reply ticket.')
            }
            this.setState({replyMessage: '', replyModal: false, attachments: [], loadingReply: false})
        }).catch((e)=>{
            toast.error('something wrong happened on sending the ticket.')
        })
    }

    render() {
        return (
            <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                {this.state.loading ? <Loader active/> :
                    <>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <div className="ui icon rounded input">
                                    <Input type="text" icon='search' name='search' onChange={this.handleFilter}
                                    placeholder="Search a word or sentences..."/>
                                </div>
                                <Checkbox name='unreplied' toggle onChange={this.handleFilter}
                                label='Unreplied Only' style={{marginLeft: 15}}/>
                            </Grid.Column>
                            <Grid.Column width={6} style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center', justifyContent: 'space-between'
                            }}>
                                <span>Date Filter</span>
                                <DatesRangeInput
                                maxDate={new Date()}
                                animation='scale'
                                duration={200}
                                hideMobileKeyboard
                                iconPosition='right'
                                autoComplete='off'
                                dateFormat='YYYY/MM/DD'
                                placeholder="Start to End"
                                value={this.state.dateRange}
                                onChange={this.handleDateFilter}
                                closable
                                />
                                <Checkbox name='all' onChange={this.handleFilter} 
                                label='All Time' checked={
                                    this.state.dateRange === '' ? true : false
                                }/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Table fixed sortable className='admin' striped>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}>
                            No
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 0 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(0)}
                            >
                            Sender
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 1 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(1)}
                            >
                            Topic
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Message
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Attachment
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 2 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(2)}
                            >
                            Date
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Action
                            </Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {this.state.ticketList.map((v, k)=>
                        <Table.Row key={k} className={v.banned ? 'banned' : ''}>
                            <Table.Cell>{this.renderNumber(k)}</Table.Cell>
                            <Table.Cell>{v.sender_email}</Table.Cell>
                            <Table.Cell>{v.topic}</Table.Cell>
                            <Table.Cell>{v.message}</Table.Cell>
                            <Table.Cell>
                                {v.screenshots.map((x,y)=>
                                    <div key={y} onClick={()=>this.setState({achModal: true, selectedAch: x})}
                                    className='seeDetail'>Attachment {y+1}</div>
                                )}
                            </Table.Cell>
                            <Table.Cell>{v.date}</Table.Cell>
                            <Table.Cell>
                                {!v.replied ? 
                                    <Button size='mini' onClick={()=>this.setState({replyModal: true, selectedTicket: v})}
                                    id='reply' secondary>
                                        <Icon name='reply'/> Reply
                                    </Button>
                                : <><i>Replied</i><br/><i>{v.reply_date}</i></>
                                }
                            </Table.Cell>
                        </Table.Row>
                        )}
                        </Table.Body>
                    </Table>
                    
                    
                    {/* pagination */}
                    <Divider hidden/>
                    <div>
                        Show <Dropdown defaultValue={10} compact selection options={[
                            {key: 10, text: '10', value: 10},
                            {key: 25, text: '25', value: 25},
                            {key: 50, text: '50', value: 50},
                            {key: 100, text: '100', value: 100},
                        ]} onChange={this.handleChangeEntries} /> entries
                        <div className='pagination'>
                            <span style={{marginRight: 10}}>
                                Showing {this.state.ticketList.length} of {this.state.totalRecord} entries
                            </span>
                            <Pagination activePage={this.state.filter.page} size='small' onPageChange={this.handleChangePagination} 
                            totalPages={this.state.totalPage} />
                        </div>
                    </div>
                    </>
                }

                <Modal open={this.state.achModal} size='small' closeIcon 
                onClose={()=>this.setState({achModal: false, selectedAch: ''})}>
                    {this.state.selectedAch !== '' ? 
                    <Image src={this.state.selectedAch} centered/>
                    : null}
                </Modal>

                <Modal open={this.state.replyModal} size='tiny'>
                    <Modal.Header>Message Reply</Modal.Header>
                    <Modal.Content style={{marginBottom: 30}}>
                        <Form onSubmit={this.sendMessage}>
                            <Form.TextArea onChange={(e, data)=>this.setState({replyMessage: data.value})} 
                            name='message' placeholder='Write your message here...' required rows={3}/>
                            <input onChange={this.onChangeImage} accept="image/jpeg" 
                            style={{display:'none'}} 
                            ref={el => this.fileInput = el} type='file' name='avatar'/>
                            <div className='attachment'>
                                {this.state.attachments.length > 0 ?
                                    <Label.Group color='blue' size='small'>
                                        {this.state.attachments.map((v, k)=>
                                        <Label key={k}>{v.name} ({v.size}) <Icon name='delete' onClick={this.removeAttachment.bind(k)}/></Label>
                                        )}
                                    </Label.Group>
                                    : null
                                }
                                
                                <Popup basic content={
                                    <p>Image attachments. <b style={{color:'#db2828'}}>max size is 2MB</b></p>
                                } trigger={
                                    <Icon.Group size='big' onClick={()=>this.fileInput.click()}>
                                        <Icon name='image' link/>
                                        <Icon corner name='add' color='green'/>
                                    </Icon.Group>
                                }/>
                            </div>
                            <Divider hidden/>
                            <Divider/>
                            <div className='action'>
                                <Button primary disabled={this.state.loadingReply} 
                                loading={this.state.loadingReply} floated='right'>
                                    Send Message
                                </Button>
                                <Button type='button' floated='right' disabled={this.state.loadingReply} 
                                onClick={()=>this.setState({replyModal: false})} className='tertiary'>
                                    Cancel
                                </Button>
                            </div>
                        </Form>
                    </Modal.Content>
                </Modal>
            </Container>
        )
    }
}

export default connect(mapStateToProps)(ContactUsPage)