import React, {useState} from 'react'
import { Button, Modal, Grid, Header, Image, Loader, Dimmer } from 'semantic-ui-react'
import Cropper from 'react-cropper';

// image cropper modal component that can be used on other page
// params : open, loading, ration, imgSrc, fileInput, act
const ImageCropperComponent = (props) =>{
    const [cropper, setCropper] = useState(null)
    const [cropResult, setCropResult] = useState(null)

    // image crop process will return the file cropped
    const cropImage = () => {
        if (typeof cropper.getCroppedCanvas() === 'undefined') {
          return;
        }
        setCropResult(cropper.getCroppedCanvas().toDataURL('image/jpeg'))
    }

    const cancelCrop = () =>{
        resetState()
        props.act(null)
    }

    const resetState = () =>{
        setCropResult(null)
        setCropper(null)
    }

    const finishBtn = () =>{
        props.act(cropResult)
        resetState()
    }
    return(
        <>
        {props === null || props === undefined ? <Loader active/> : 
        <Modal open={props.open}>
            <Modal.Header>Crop Your Photo</Modal.Header>
            <Modal.Content>
                <Dimmer active={props.loading}>
                    <Loader/>
                </Dimmer>
                <Grid columns='equal'>
                    <Grid.Column>
                        <Cropper viewMode={2}
                            style={{ height: 400, width: '100%' }}
                            aspectRatio={props.ratio}
                            preview=".img-preview"
                            guides={false}
                            src={props.imgSrc}
                            ref={cropper => { setCropper(cropper) }}
                        />
                    </Grid.Column>
                    <Grid.Column>
                        {cropResult !== null ? <div>
                            <Header as='h2'>Result</Header>
                            <Image style={{ width: '100%' }} src={cropResult}/>
                        </div> : ''}
                    </Grid.Column>
                </Grid>
            </Modal.Content>
            <Modal.Actions>
                <Button className='tertiary' onClick={cancelCrop}>
                    Cancel
                </Button>
                <Button color='orange' onClick={()=>props.fileInput.click()}>
                    Change Image
                </Button>
                <Button primary onClick={cropImage}>
                    Crop
                </Button>
                {cropResult !== null ? <Button positive onClick={finishBtn}>
                    Finish
                </Button> : ''}
            </Modal.Actions>
        </Modal>
        }
        </>
    )
}

export default ImageCropperComponent