import React, {useState} from 'react'
/* Fields */
import { Input } from '../fields'
import { Image, Button, Popup } from 'semantic-ui-react'
import ImageCropperComponent from '../../components/image_cropper'
import { toast } from 'react-toastify'

// module 4 form
const Module4Form = (props) =>{
    const [image, setImage] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [fileInput, setFileInput] = useState(null)

    // process the image after attached
    const onChangeImage = (e)=>{
        e.preventDefault();
        let files;
        if(e.target.files[0] === undefined){
            return false
        }
        
        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            return false
        }

        if(e.target.files[0].size > 5000000){
            toast.error('Image should be equal to or less than 5MB.')
            return false
        }
        
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result)
            setModalOpen(true)
        };
        reader.readAsDataURL(files[0]);
    }

    // set image value after crop
    const setImageDisplay = (src) =>{
        fileInput.value = null
        setFileInput(fileInput)
        if(src === null){
            setImage(null)
            setModalOpen(false)
            return false
        }
        setImage(src)
        setModalOpen(false)
    }

    return(
        <>
            <td>
                <Image style={{width: 100, height: 100}} size='small' src={image !== null ? image : require('../../assets/images/default.png')}/>
                <Input required value={image} type='hidden' name="image"/>
                <input style={{display:'none'}} accept="image/jpeg" ref={(file)=>setFileInput(file)} id='q_image' 
                type="file" onChange={onChangeImage}/>
                <Popup trigger={
                    <Button type='button' size='mini' secondary onClick={()=>{
                        setImage(null)
                        fileInput.click()
                    }}>Upload Image</Button>
                } content='Image size (Max: 5MB)'></Popup>
                <ImageCropperComponent open={modalOpen} loading={false} imgSrc={image} fileInput={fileInput} 
                ratio={1 / 1} act={setImageDisplay}/>
            </td>
            <td>
                <Input type='text' label='Word' name="word" wlength={17}
                required placeholder='Exp: English'/>
            </td>
        </>
    )
}

export default Module4Form