import React from 'react'
import { Menu, Image } from 'semantic-ui-react'
import storage from 'redux-persist/lib/storage'
import {connect} from 'react-redux'

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth
    }
}

class TopNavPublicComponent extends React.Component{
    logout = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/auth/logout',{
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
        }).then(function(response) {
            return response.json()
        }).then(function(response) {
            window.localStorage.clear()
            storage.removeItem('persist:root')
            window.location = '/'
        })
    }

    render() {
        let head = <>
            <Menu.Item as='a' href='/register'
            name='Register'
            icon='signup'
            />
            <Menu.Item as='a' href='/login'
            name='Login'
            icon='sign-in'
            />
        </>
        if(this.props.auth !== null && this.props.auth.is_logged){
            head = <Menu.Item onClick={this.logout}
            name='Logout'
            icon='sign-out'
            />
        }
        return (
            <Menu className='fixed TopNav'>
                <Menu.Item className='header item' as='a' href='/'>
                    <Image src={require('../assets/images/content.png')}/>
                </Menu.Item>
                <Menu.Menu position='right'>
                    {head}
                </Menu.Menu>
            </Menu>
        )
    }
}

export default connect(mapStateToProps)(TopNavPublicComponent)