import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import storage from 'redux-persist/lib/storage';
import EmptyChapterComponent from '../../../components/chapter/empty';
import { Loader, Tab, Segment, Card, Header, Container, Divider,
    Icon, Grid, MenuItem, Dropdown, Modal, Button, Image, Label, Input, Form, Dimmer, GridColumn } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import ReactPlayer from 'react-player'
import EmptyComponent from '../../../components/empty';
import { Slider } from "react-semantic-ui-range"
import { getSubjectRedux, filterSubjectRedux } from '../../../helpers/redux';
import { PlatformInit, CheckExistData } from '../../../helpers/global';
import * as Sentry from "@sentry/browser";

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        add_subject_step: state.add_subject_step,
        auth: state.auth
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        subNavAction: (n) => dispatch({ type: 'CHANGE_SUB_NAV', payload: n }),
        addSubjectStepAction: (n) => dispatch({ type: 'CURRENT_SUBJECT', payload: n }),
    }
}

// subject detail content includes chapter and minigames info
class ContentPage extends React.Component{
    constructor(props){
        super(props)
        const userInfo = this.props.auth
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const paramId = urlParams.get('id')

        if(paramId !== null){
            CheckExistData({
                header: new Headers({
                    'Authorization': 'Token '+userInfo.user.token
                }),
                category: 'chapter',
                identifier: paramId
            }).then(val=>{
                if(!val.status){
                    window.location.href = '/not-found'
                    return false
                }
            })
        }

        const subjectDetail = getSubjectRedux(this.props.add_subject_step, this.props.match.params.code)
        let is_incomplete = false
        if(subjectDetail.category_id === null && subjectDetail.role === 3 && !subjectDetail.adaptive){
            is_incomplete = true
        }

        this.state = {
            subjectDetail: subjectDetail,
            myHeaders: new Headers({
                'Authorization': 'Token '+userInfo.user.token
            }),
            loading: true,
            chapters: [],
            chapterData: [],
            chapterDataIds: [],
            minigames: null,
            modalOpen: false,
            currentChapter: 0,
            modalOpenVideo: false,
            videoUrl: [],
            teachingFormats: [],
            teachingFormatMaster: [],
            teachingFormatModal: false,
            currentModule: 0,
            modalConfirm: false,
            isLinkable: false,
            selectedMinigame: 0,
            selectedMinigameParent: null,
            renameId: 0,
            stayedIndexTab: null,
            activeIndexChapter: 0,
            firstModule: '',
            showHideModal: false,
            showHideSelected: null,
            editChapterModal: false,
            selectedChapterName: '',
            completionValue: 0,
            is_incomplete: is_incomplete,
            addMGLoader: false,
            platform: PlatformInit(),
            selectedPlatform: '',
            moduleLoading: true,
            minigameLoading: true,
            materialName: '',
            deleteConfirm: false,
            selectedChapterID: 0,
            deleteLoading: false,
            deleteType: '',
            selectedMGId: 0
        }
    }

    // fetch chapter data
    componentDidMount(){
        if(!this.state.is_incomplete){
            this.loadData()
            this.props.subNavAction('content')
        }
    }

    // render tabs content
    renderPanes = (data) =>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const paramId = urlParams.get('id')
        let panes = []
        let i = 0
        let chapterIds = []
        data.forEach(val => {
            if(val.chapter_id === parseInt(paramId)){
                this.setState({activeIndexChapter: i})
            }

            chapterIds.push(val.chapter_id)
            if(i === 0){
                this.setState({currentChapter: this.state.stayedIndexTab !== -1 && this.state.stayedIndexTab !== null ? 
                    this.state.stayedIndexTab : val.chapter_id, 
                    currentModule: val.minigames.length > 0 ? val.minigames[val.minigames.length - 1].module_id : 1})
            }
            panes.push(
                { menuItem: 
                    <MenuItem idx={val.chapter_id} key={val.chapter_id }>
                        {val.chapter_name}
                        <br/>
                        <div style={{
                            color: 'rgba(3, 28, 48, 0.5)',                            
                            fontSize: 12,
                            marginTop: 10
                        }}>Completion Percentage: {val.completion_rate}%</div>
                        {
                            this.state.subjectDetail.role === 3 ?
                                <Dropdown className='actionChapter' icon='ellipsis vertical' direction='left'>
                                    <Dropdown.Menu className='ui inverted vertical menu'>
                                        <Dropdown.Item onClick={()=>{
                                            this.setState({editChapterModal: true, renameId: val.chapter_id,
                                                selectedChapterName: val.chapter_name, 
                                                completionValue: val.completion_rate})
                                        }} text='Edit Chapter' icon='pencil'/>
                                        <Dropdown.Item onClick={()=>this.setState({deleteConfirm: true, selectedChapterID: val.chapter_id, deleteType: 'chapter'})} 
                                        text='Delete' icon='trash'/>
                                        <Dropdown.Item text='Top' 
                                        onClick={this.orderChapter.bind(null, {id: val.chapter_id, up: true})} icon='arrow up'/>
                                        <Dropdown.Item text='Down' 
                                        onClick={this.orderChapter.bind(null, {id: val.chapter_id, up: false})}icon='arrow down'/>
                                    </Dropdown.Menu>
                                </Dropdown>
                            : ''
                        }
                    </MenuItem>, render: () => <Segment className='chapterSegment'>
                    <Card.Group className='minigameCardShort' itemsPerRow={2}>
                        {val.minigames.map((v,k)=>{
                            return this.generateMGCard(v, k)
                        })}
                        {this.state.subjectDetail.role === 3 ? <Card link className='addNew' onClick={this.openMinigameModal}>
                            <Card.Content>
                                <Icon name='add' size='large'/>
                            </Card.Content>
                            <div style={{color: 'rgba(3, 28, 48, 0.25)', marginTop: 10, fontWeight: 'bold'}}>
                                Add A New Minigame
                            </div>
                        </Card> : ''
                        }
                    </Card.Group>
                </Segment> }
            )
            i++
        });

        this.setState({loading: false, chapters: panes, chapterData: data, chapterDataIds: chapterIds})
    }

    generateMGCard = (v, k) =>{
        let idx = 0
        let img = ''
        Object.keys(v.minigame_screenshot).forEach(x=>{
            if(idx === 0){
                img = v.minigame_screenshot[x]
            }
            idx++
        })
        return <Link to={'/subject/'+this.state.subjectDetail.code+'/'+v.chapter_minigame_id+'/question'} className='ui link raised card' key={k}>
            <div></div>
            {v.hidden ? <div className='hidden'>
                <Icon name='eye slash'/>
            </div> : null}
            <div className='mgImage' 
            style={{background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.25) 100%),url('+img+')'}}></div>
            <div className='mgIcon' style={{background: 'url('+v.minigame_icon+') no-repeat center'}}></div>
            <Card.Content>
                <Header as='h3'>
                    {v.minigame_name} {v.is_doc ? <Icon color='blue' circular name='gamepad'/> : null}{v.is_chatbot ? <Icon circular color='blue' name='telegram'/> : null}{v.is_webed ? <Icon circular color='blue' name='globe'/> : null}
                </Header>
                {
                    this.state.subjectDetail.role === 3 ?
                        <Dropdown onClick={(e)=>e.preventDefault()} className='actionMinigame' icon='ellipsis vertical' direction='left'>
                            <Dropdown.Menu className='ui inverted vertical menu'>
                                {v.hidden ? 
                                <Dropdown.Item onClick={this.hideShowMinigame.bind(null, v, 'show')} text='Unhide Minigame' icon='eye'/>
                                : <Dropdown.Item onClick={()=>{
                                    this.setState({showHideModal: true, showHideSelected: v})
                                }} text='Hide Minigame' icon='eye slash'/>}
                                <Dropdown.Item onClick={()=>this.setState({deleteConfirm: true, selectedMGId: v.minigame_id, deleteType: 'minigame'})} 
                                text='Delete' icon='trash'/>
                            </Dropdown.Menu>
                        </Dropdown>
                    : ''
                }
            </Card.Content>
            <Card.Description style={{padding:10}}>
                <Grid columns='equal'>
                    <Grid.Column>
                        <span style={{fontSize: 14, fontWeight: 'bold', color: 'rgba(3, 28, 48, 0.65)'}}>
                            {v.module_name}
                        </span><br/>
                        {v.minigame_parent_id !== null ? 
                            <Label color='teal' size='mini'>Shared with {v.minigame_parent_name}</Label>
                            : <span style={{fontSize: 9}}>{v.question_number} Questions</span>
                        }
                    </Grid.Column>
                    {v.minigame_parent_id === null ?
                        <Grid.Column width={9}>
                            <span style={{fontSize: 12, float: 'right'}}>Last updated: {v.last_update}</span><br/>
                            <span style={{fontSize: 12, float: 'right'}}>by {v.creator_username}</span>
                        </Grid.Column>
                    : ''}
                </Grid>
            </Card.Description>
        </Link>
    }

    // fetching chapter and minigames data
    loadData = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/chapters/get/'+this.state.subjectDetail.code, {
            headers: this.state.myHeaders
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            this.renderPanes(res.data)
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // open minigame modal and display list of minigame
    openMinigameModal = () =>{
        let chapter = this.state.chapterData.filter(x=>x.chapter_id === this.state.currentChapter)
        if(this.state.currentModule === 0){
            if(chapter.length > 0){
                chapter = chapter[0].minigames
                chapter = chapter[chapter.length - 1]
                chapter = chapter.module_id
            }
        }else{
            chapter = this.state.currentModule
        }

        let url = process.env.REACT_APP_API_URL+'api/v1/modules/'+chapter+'/minigames?chapter_id='+this.state.currentChapter
        url = this.state.selectedPlatform !== '' ? url+'&platform='+this.state.selectedPlatform : url
        fetch(url, {
            headers: this.state.myHeaders
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }

            if(res.status){
                let mg = res.data.length > 0 ? [] : null
                let moduleName = ''
                let materialName = this.state.materialName
                let mID = this.state.currentModule
                res.data.forEach(val =>{
                    if(mg[val.module_name] !== undefined){
                        mg[val.module_name].push(val)
                    }else{
                        moduleName = val.module_name
                        mg[val.module_name] = [val]
                    }
                })
                if(mg !== null){
                    if(mID !== 'all'){
                        materialName = mg[moduleName][0].teaching_material_name
                        mID = mg[moduleName][0].teaching_material_id
                    }
                }
                this.setState({minigames: mg, materialName: materialName, currentModule: mID , modalOpen: true})
                setTimeout(()=>{
                    this.loadModule()
                }, 100)
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })       
    }

    // when tab item changed
    onChangeTab = (e, data) =>{
        this.setState({currentChapter: data.panes[data.activeIndex].menuItem.props.idx, 
            stayedIndexTab: data.panes[data.activeIndex].menuItem.props.idx, minigames: [], activeIndexChapter: data.activeIndex})
    }

    // open teaching format/module modal
    openModuleModal = () =>{
        this.loadModule()
        this.setState({teachingFormatModal: true})
    }

    loadModule = () =>{
        this.setState({moduleLoading: true})
        let url = process.env.REACT_APP_API_URL+'api/v1/modules'
        url = this.state.selectedPlatform !== '' ? url+'?platform='+this.state.selectedPlatform : url
        fetch(url, {
            headers: this.state.myHeaders
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(this.state.selectedPlatform === ''){
                let tfSelect = []
                res.data.forEach(x=>{
                    tfSelect.push({
                        text: x.teaching_material_name,
                        value: x.module_id,
                        image: { avatar: false, src: x.logo }
                    })
                })
                this.setState({teachingFormatMaster: tfSelect})
            }
            this.setState({teachingFormats: res.data, moduleLoading: false})
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    cardSelect = (data, e) =>{
        this.setState({teachingFormatModal: false, currentModule: data })
        setTimeout(()=>{
            this.openMinigameModal()
        }, 10)
    }

    // add minigame into chapter
    addNewMinigame = (pid = null, e) =>{
        let param = {
            minigame_id: this.state.selectedMinigame.minigame_id,
            minigame_parent_id: pid,
            module_id: this.state.currentModule === 'all' ? this.state.selectedMinigame.teaching_material_id : this.state.currentModule
        }
        this.setState({addMGLoader: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/chapters/'+this.state.currentChapter+'/minigames/save', {
            headers: this.state.myHeaders,
            method: 'post',
            body: JSON.stringify(param)
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({addMGLoader: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                toast.success('Minigame added successfully.')
                let csData = this.state.subjectDetail
                csData.publishable = false
                this.props.addSubjectStepAction(filterSubjectRedux(this.props.add_subject_step, csData))
                this.setState({modalConfirm: false, modalOpen: false})
                this.loadData()
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // order minigame top and down
    orderChapter = (param, e) =>{
        let ids = this.state.chapterDataIds
        let index = ids.findIndex(x=>x === param.id)
        let is_update = false
        if(param.up){
            if(index !== 0){
                const temp = ids[index] 
                ids[index] = ids[index - 1] 
                ids[index - 1] = temp
                is_update = true
            }else{
                toast.error('Cannot move up chapter. This is the highest chapter')
            }
        }else{
            if(index !== ids.length - 1){
                const temp = ids[index] 
                ids[index] = ids[index + 1] 
                ids[index + 1] = temp
                is_update = true
            }else{
                toast.error('Cannot move down chapter. This is the lowest chapter')
            }
        }

        if(is_update){
            fetch(process.env.REACT_APP_API_URL+'api/v1/chapters/order', {
                headers: this.state.myHeaders,
                method: 'post',
                body: JSON.stringify({
                    code: this.state.subjectDetail.code,
                    orders: ids.toString()
                })
            }).then((res)=>{
                return res.json()
            }).then((res)=>{
                if(res.status === undefined){
                    storage.removeItem('persist:root')
                    window.location.href = '/'
                }
                if(res.status){
                    this.setState({chapterDataIds: []})
                    this.loadData()
                }else{
                    toast.error(res.message)
                }
            }).catch((e)=>{
                Sentry.captureException(e);
                toast.error(<div>
                    Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
                </div>)
            })
        }
    }

    // remove minigame from chapter
    removeMinigame = () =>{
        this.setState({deleteLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/chapters/'+this.state.currentChapter+'/minigames/'+this.state.selectedMGId+'/delete', {
            headers: this.state.myHeaders,
            method: 'delete'
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({deleteLoading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                toast.success('minigame removed.')
                this.loadData()
                this.setState({modalConfirm: false, modalOpen: false, deleteConfirm: false})
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // remove chapter from subject
    removeChapter = () =>{
        this.setState({deleteLoading: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/chapters/delete/'+this.state.selectedChapterID, {
            method: 'delete',
            headers: this.state.myHeaders
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            this.setState({deleteLoading: false})
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                let data = this.state.chapters
                data = data.length > 0 ? data[0] : null
                if(data !== null){
                    this.setState({currentChapter: data.menuItem.props.idx, activeIndexChapter: 0, 
                        stayedIndexTab: data.menuItem.props.idx, minigames: [], deleteConfirm: false})
                }
                toast.success('chapter removed.')
                this.loadData()
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    // rename chapter process
    renameChapter = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/chapters/update/'+this.state.renameId, {
            method: 'post',
            headers: this.state.myHeaders,
            body: JSON.stringify({
                name: this.state.selectedChapterName,
                completion_rate: this.state.completionValue
            })
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                toast.success('chapter updated.')
                this.setState({renameId: 0, isRename: false, newChapterName:'', editChapterModal: false})
                this.loadData()
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    hideShowMinigame = (data, type, keep_linked = true, e) =>{
        let message = 'Minigame is hidden'
        let config = {
            headers: this.state.myHeaders,
            method: 'delete',
            body: JSON.stringify({
                keep_linked: keep_linked ? 1 : 0
            })
        }
        let url = process.env.REACT_APP_API_URL+'api/v1/chapters/'+this.state.currentChapter+'/minigames/'+data.minigame_id+'/hide'
        
        if(type === 'show'){
            message = 'Minigame is shown'
            url = process.env.REACT_APP_API_URL+'api/v1/chapters/'+this.state.currentChapter+'/minigames/'+data.minigame_id+'/unhide'
            config = {
                headers: this.state.myHeaders
            }
        }

        fetch(url, config)
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                toast.success(message)
                this.setState({showHideModal: false, showHideSelected: null})
                this.loadData()
            }else{
                toast.error(res.message)
            }
        }).catch((e)=>{
            Sentry.captureException(e);
            toast.error(<div>
                Sorry, there is an issue with the server. We will address the issue immediately, please wait a few hours or <a href="/contact" rel='contact'>contact us</a> for further info.
            </div>)
        })
    }

    handleChangeInput = (e, data) =>{
        if(data.value.includes('-')){
            this.setState({completionValue: '0'})
            return false
        }

        let val = parseInt(data.value)
        if(data.value > 100){
            val = 100
        }

        if(data.value < 0){
            val = 0
        }

        if(!Number.isInteger(val)){
            val = 0
        }

        this.setState({completionValue: val})
    }

    openVideo = (data, e) =>{
        let tabPlatform = []
        PlatformInit().forEach(x=>{
            tabPlatform.push({
                menuItem: x.text,
                render: () => <Tab.Pane attached={false}>
                    {data.video[x.value] !== undefined && data.video[x.value] !== '' ? 
                    <ReactPlayer width={'100%'} url={data.video[x.value]}/>
                    : <EmptyComponent/> }
                </Tab.Pane>
            })
        })
        this.setState({modalOpenVideo: true, videoUrl: tabPlatform})
    }

    generateMGCardModal = (v, k) =>{
        let idx = 0
        let img = ''
        Object.keys(v.screenshot).forEach(x=>{
            if(idx === 0){
                img = v.screenshot[x]
            }
            idx++
        })
        return <Card raised key={k}>
            <div></div>
            <div className='mgImage' 
            style={{background: 'linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.25) 100%),url('+img[0]+')'}}></div>
            <div className='mgIcon' style={{background: 'url('+v.icon+') no-repeat center'}}></div>
            <Card.Content>
                <Header as='h3'>
                    {v.minigame_name} {v.is_doc ? <Icon color='blue' circular name='gamepad'/> : null}{v.is_chatbot ? <Icon circular color='blue' name='telegram'/> : null}{v.is_webed ? <Icon circular color='blue' name='globe'/> : null}
                </Header>
            </Card.Content>
            <Card.Description style={{padding:10}}>
                <p>{v.description}</p>
            </Card.Description>
            <div className='mgAction'>
                <Button className='tertiary' onClick={this.openVideo.bind(null, v)}>
                    <Icon name='play'/> Play Video
                </Button>
                <Button primary onClick={()=>{
                    let isLink = false
                    if(v.parent.length > 0){
                        isLink = true
                    }
                    this.setState({selectedMinigame: v, isLinkable: isLink, modalConfirm: true})
                }}>
                    Choose
                </Button>
            </div>
        </Card>
    }

    teachingFormatChange = (e, data) =>{
        if(this.state.currentModule !== data.value){
            let idx = data.options.findIndex(x=>x.value === data.value)
            this.setState({currentModule: data.value, materialName: idx !== -1 ? data.options[idx].text : ''})
            setTimeout(()=>{
                this.openMinigameModal()
            }, 100)
        }
    }

    filterPlatform = (e, data) =>{
        let platform = this.state.platform
        let ids = data.id.split('_')
        platform = platform.filter(x=>x.value !== '').map(x=>{
            if(x.value === ids[0]){
                x.active = !x.active
            }
            return x
        })
        let selected = platform.filter(x=>x.active).map(x=>{
            return x.value
        }).join(',')

        this.setState({platform: platform, selectedPlatform: selected})
        setTimeout(()=>{
            if(ids.length > 1){
                this.loadModule()
            }else{
                this.openMinigameModal()
            }
        }, 100)
    }

    render() {
        const settings = {
            start: 0,
            min: 0,
            max: 100,
            step: 25,
            onChange: value => {
                this.setState({completionValue: value})
            }
        }
        return (
            <Segment>
                <Modal size='mini' open={this.state.is_incomplete}>
                    <Modal.Content>
                        <p>This subject is incomplete, you will redirect to complete the subject</p>
                    </Modal.Content>
                    <Modal.Actions>
                        <a ref='add' className='ui primary button' href={'/subject/add?code='+this.props.match.params.code}>
                            OK
                        </a>
                    </Modal.Actions>
                </Modal>
                {this.state.loading ? 
                    <Loader active/>
                    : 
                    this.state.chapters.length > 0 ?
                        <div>
                            <Grid>
                                <Grid.Column width={5}>
                                    <Header color='grey' as='h3'>List of Chapters</Header>
                                </Grid.Column>
                                <Grid.Column width={11}>
                                    <Header color='grey' as='h3'>Chapter's Minigame</Header>
                                </Grid.Column>
                            </Grid>
                            <Tab activeIndex={this.state.activeIndexChapter}
                            onTabChange={this.onChangeTab} className='chapterHome' 
                            menu={{ pointing: true, vertical: true }} 
                            grid={{paneWidth: 11, tabWidth: 5}} panes={this.state.chapters} />
                            {
                                this.state.subjectDetail.role === 3 ?
                                    <Link id='btn_add_chapter' to={'/chapter/add/'+this.state.subjectDetail.code} className='ui secondary button' style={{position: 'absolute', bottom: 50}}>
                                        <Icon name='add'/> Add A New Chapter
                                    </Link>
                                : ''
                            }
                        </div>
                    : <EmptyChapterComponent subject={this.state.subjectDetail}/>
                }

                <Modal size='large' open={this.state.modalOpen} closeIcon onClose={()=>this.setState({modalOpen: false})}>
                    <Modal.Header>
                        Add A New Minigame
                    </Modal.Header>
                    <Modal.Content scrolling>
                        <Container textAlign='center'>
                            <Header as='h3' style={{color: 'rgba(3, 28, 48, 0.65)'}}>
                                Choose one from the following “{this.state.materialName}” templates.
                                <Button className='tertiary' onClick={this.openModuleModal}>or choose another teaching format.</Button>
                            </Header>
                            <Dropdown placeholder='Teaching Format' search 
                            value={this.state.currentModule}
                            selection options={this.state.teachingFormatMaster || []} onChange={this.teachingFormatChange}/>
                            <Button.Group className='platformSwitch' style={{marginLeft: 10}}>
                                {this.state.platform.map((v,k)=>
                                <Button key={k} secondary id={v.value} active={v.active}
                                onClick={this.filterPlatform}>
                                    <Icon name={v.icon}/> {v.text}
                                </Button>
                                )}
                            </Button.Group>
                        </Container>
                        <Divider hidden/>
                        {this.state.minigames !== null ? this.state.currentModule === 'all' ? (this.state.teachingFormatMaster || []).filter(tf => tf.value !== 'all').map(tf => {
                            return <>
                                {
                                    Object.values(this.state.minigames).flat().find(mg => mg.teaching_material_id.toString() === tf.value.toString()) === undefined ? <></> 
                                    : <Header as='h2'>{tf.text}</Header>
                                }

                                <Grid columns={2} relaxed>
                                {
                                    Object.entries(this.state.minigames).map((keyAndValuesArr) => {
                                        const isFoundCategoryTf = keyAndValuesArr[1]
                                        .filter(mg => {
                                            return mg.teaching_material_id.toString() === tf.value.toString() 
                                        })
                                        if(isFoundCategoryTf.length !== 0){
                                            return <GridColumn>
                                                    <Header as='h3' color='grey'>
                                                        {keyAndValuesArr[0]}
                                                    </Header>
                                                    <Card.Group className='minigameCard'>
                                                        {
                                                        isFoundCategoryTf.filter(mg => mg.module_name === keyAndValuesArr[0]).map((v,k)=> {
                                                            return this.generateMGCardModal(v, k)
                                                        }
                                                        )}
                                                    </Card.Group>

                                                </GridColumn>
        
        
                                        }
                                        return <></>
                                    })
                                }        
                                </Grid>
                            </>
                        }) : 

                        <Grid columns={2} relaxed>
                            {
                                Object.keys(this.state.minigames).map((item, k)=>
                                <div key={k} style={{padding: 20}}>
                                <Header as='h2' color='grey'>{item}</Header>
                                <Card.Group className='minigameCard'>
                                    {this.state.minigames[item].map((v,k)=>{
                                        return this.generateMGCardModal(v, k)
                                    })}
                                </Card.Group>
                                </div>
                            )
                            }                            
                         </Grid>

                        : <EmptyComponent/>
                        }
                </Modal.Content>
                </Modal>

                {/* video modal will displayed when play video clicked */}
                <Modal open={this.state.modalOpenVideo}  closeIcon onClose={()=>this.setState({modalOpenVideo: false})}>
                    <Modal.Header>Minigame Video</Modal.Header>
                    <Modal.Content>
                    <Tab menu={{ secondary: true }} panes={this.state.videoUrl}/>
                    </Modal.Content>
                </Modal>

                {/* modal teaching format */}
                <Modal open={this.state.teachingFormatModal} closeIcon onClose={()=>this.setState({teachingFormatModal:false})}>
                    <Modal.Content>
                        <Container textAlign='center'>
                            <Header as='h2' style={{color: 'rgba(3, 28, 48, 0.65)'}}>
                                What’s your teaching format?
                            </Header>
                            <Button.Group className='platformSwitch'>
                                {this.state.platform.map((v,k)=>
                                <Button key={k} secondary id={v.value+'_module'} active={v.active}
                                onClick={this.filterPlatform}>
                                    <Icon name={v.icon}/> {v.text}
                                </Button>
                                )}
                            </Button.Group>
                        </Container>
                        {this.state.moduleLoading ? <Loader active/> : 
                        this.state.teachingFormats.length > 0 ? 
                        <Card.Group className='teachingFormat' itemsPerRow={3} centered>
                            {this.state.teachingFormats.map((v, k)=>
                                <Card key={k} onClick={this.cardSelect.bind(null, v.module_id)} link>
                                    <Card.Content>
                                        <Image src={v.logo}/>
                                        <Header as='h4'>{v.teaching_material_name}</Header>
                                    </Card.Content>
                                </Card>
                            )}
                        </Card.Group>
                        : <EmptyComponent/>
                        }
                    </Modal.Content>
                </Modal>

                {/* modal link or new one */}
                <Modal size='mini' open={this.state.modalConfirm}>
                    <Modal.Content>
                        <Dimmer active={this.state.addMGLoader} inverted>
                            <Loader active={this.state.addMGLoader} inverted>
                                Processing...
                            </Loader>
                        </Dimmer>
                        <Container textAlign='center'>
                            {this.state.isLinkable ? 
                                <div>
                                    <Icon.Group size='huge'>
                                        <Icon size='big' color='orange' name='circle outline' />
                                        <Icon name='exclamation' color='orange'/>
                                    </Icon.Group>
                                    <Header as='h2'>Same module detected!</Header>
                                    <p>You’ve created a minigame that uses the same module. You can link the content with another minigame.</p>
                                    {this.state.selectedMinigame.parent.map((v,k)=>
                                        <Button key={k} primary fluid onClick={this.addNewMinigame.bind(null, v.minigame_id)}
                                        style={{marginTop: 5}}>
                                            Link It With {v.minigame_name}
                                        </Button>
                                    )}
                                    
                                    <Button secondary fluid style={{marginTop: 5}} onClick={this.addNewMinigame.bind(null, null)}>
                                        Create A New Content
                                    </Button>
                                </div>
                            : 
                                <div>
                                    <Icon.Group size='huge'>
                                        <Icon size='big' color='green' name='circle outline' />
                                        <Icon name='check' color='green'/>
                                    </Icon.Group>
                                    <Header as='h2'>Congratulation!</Header>
                                    <p>You are about to add new minigame to your chapter.</p>
                                    <Button secondary fluid style={{marginTop: 5}} onClick={this.addNewMinigame.bind(null, null)}>
                                        Create A New Content
                                    </Button>
                                </div>
                            }
                        </Container>
                    </Modal.Content>
                </Modal>
                
                {/* modal show hide mg */}
                <Modal open={this.state.showHideModal} size='mini'>
                    {this.state.showHideSelected !== null ? 
                    <Modal.Content>
                        <p>Hidden minigames won’t appear in the published game until you unhide them. Hide this minigame?</p>
                        {this.state.showHideSelected.linked_mini_games.length > 0 ? 
                        <>
                        <p>This minigame is linked by the same content to <b>
                            {
                                this.state.showHideSelected.linked_mini_games.slice(0, -1).join(', ')+
                                (this.state.showHideSelected.linked_mini_games.length > 1 ? ' and '+this.state.showHideSelected.linked_mini_games.slice(-1) :
                                this.state.showHideSelected.linked_mini_games.join(', ')
                                )
                            }
                        </b>. Hide minigame?</p>
                        <Divider hidden/>
                        <div style={{
                                textAlign: 'center',
                        }}>
                            <Button onClick={this.hideShowMinigame.bind(null, this.state.showHideSelected, 'hide', true)} 
                            primary fluid>Yes, hide this minigame only</Button>
                            <br/>
                            <Button primary fluid 
                            onClick={this.hideShowMinigame.bind(null, this.state.showHideSelected, 'hide', false)}>Yes, hide all linked minigames</Button><br/>
                            <Button fluid className='tertiary' onClick={()=>this.setState({showHideModal: false, showHideSelected: null})}>Cancel</Button>
                        </div>
                        </>
                        :
                        <div style={{textAlign: 'right', marginBottom: 45}}>
                        <Divider hidden/>
                        <Button onClick={this.hideShowMinigame.bind(null, this.state.showHideSelected, 'hide', true)} floated='right' primary>Yes</Button>
                        <Button onClick={()=>this.setState({showHideModal: false, showHideSelected: null})} floated='right' className='tertiary'>Cancel</Button>
                        </div>
                        }
                    </Modal.Content>
                    : null}
                </Modal>
                <Modal open={this.state.editChapterModal} size='tiny'>
                    <Modal.Header>Edit Chapter</Modal.Header>
                    {this.state.editChapterModal ? 
                    <Modal.Content>
                    <Form>
                        <label>Chapter Name</label>
                        <Form.Input placeholder='Chapter Name' onChange={(e, data)=>this.setState({selectedChapterName: data.value})} 
                        value={this.state.selectedChapterName}/>
                        <label>Completion Percentage</label>
                        <Grid columns='equal' style={{
                                paddingRight: 32,
                                display: 'flex',
                                alignItems: 'center'
                        }}>
                            <Grid.Column>
                                <Slider discrete value={this.state.completionValue} color="blue" settings={settings} />
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Input value={this.state.completionValue} type='number' onChange={this.handleChangeInput}
                                label='%' labelPosition='right'/>
                            </Grid.Column>
                        </Grid>
                    </Form>
                    </Modal.Content>
                    : null}
                    <Modal.Actions>
                        <Button className='tertiary' onClick={()=>this.setState({editChapterModal: false})}>Cancel</Button>
                        <Button primary onClick={this.renameChapter}>Save</Button>
                    </Modal.Actions>
                </Modal>

                {/* confirm modal */}
                <Modal open={this.state.deleteConfirm} size='mini'>
                    <Modal.Content>
                        <p>{this.state.deleteType === 'chapter' ? 'Delete this chapter?' : 'Delete this minigame?'}</p>
                        <div style={{textAlign: 'right', marginBottom: 45}}>
                        <Divider hidden/>
                        {this.state.deleteType === 'chapter' ? 
                        <Button onClick={this.removeChapter} disabled={this.state.deleteLoading}
                        loading={this.state.deleteLoading} floated='right' negative>Yes, Delete It</Button>
                        : <Button onClick={this.removeMinigame} disabled={this.state.deleteLoading}
                        loading={this.state.deleteLoading} floated='right' negative>Yes, Delete It</Button>
                        }
                        <Button onClick={()=>this.setState({deleteConfirm: false})} 
                        floated='right' className='tertiary' disabled={this.state.deleteLoading}>Cancel</Button>
                        </div>
                    </Modal.Content>
                </Modal>
            </Segment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentPage)