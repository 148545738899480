import React from 'react'
import { Container, Image } from 'semantic-ui-react'

const EmptyComponent = (props) =>{
    return(
        <Container textAlign='center' className='empty_section'>
            {props.size !== undefined ? 
            <Image size={props.size} centered src={require('../assets/images/empty.png')}/>
            :
            <Image centered src={require('../assets/images/empty.png')}/>
            }
            <p>Ooppss... No Data Found.</p>
        </Container>
    )
}

export default EmptyComponent