import React from 'react'
import { Icon, Loader } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { getSubjectRedux } from '../../../helpers/redux'

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        add_subject_step: state.add_subject_step,
        subnav: state.subnav
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        subNavAction: (n) => dispatch({ type: 'CHANGE_SUB_NAV', payload: n }),
    }
}

class SubjectMenuComponent extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            currentSubject: getSubjectRedux(this.props.data.add_subject_step, this.props.data.match.params.code)
        }
    }
    componentDidMount(){
        let pathname = window.location.pathname.split('/')
        pathname = pathname[pathname.length - 1]
        this.props.subNavAction(pathname)
    }

    render() {
        if(this.props.subnav === null){
            this.props.subNavAction('content')
        }
        return(
            <>
            {this.state.currentSubject === null ? <Loader adaptive/> : 
                <div className="ui menu" id='submenu_subject'>
                    <Link to={this.props.data.match.url+'/content'} onClick={()=>this.props.subNavAction('content')} className={'item'+(this.props.subnav === 'content' ? ' active' : '')}>
                        <Icon name='file alternate' size='large'/> Content
                    </Link>
                    <Link to={this.props.data.match.url+'/contributor'} onClick={()=>this.props.subNavAction('contributor')} className={'item'+(this.props.subnav === 'contributor' ? ' active' : '')}>
                        <Icon name='user' size='large'/>
                        Contributors
                    </Link>
                    <Link to={this.props.data.match.url+'/activities'} onClick={()=>this.props.subNavAction('activities')} className={'item'+(this.props.subnav === 'activities' ? ' active' : '')}>
                        <Icon name='history' size='large'/>
                        Subject Activities
                    </Link>
                    {this.state.currentSubject.is_having_diagnostic_test ? 
                    <Link to={this.props.data.match.url+'/diagnostic'} onClick={()=>this.props.subNavAction('diagnostic')} className={'item'+(this.props.subnav === 'diagnostic' ? ' active' : '')}>
                        <Icon name='clipboard' size='large'/>
                        Diagnostic Test
                    </Link>
                    : null}
                    <Link to={this.props.data.match.url+'/about'} onClick={()=>this.props.subNavAction('about')} className={'item'+(this.props.subnav === 'about' ? ' active' : '')}>
                        <Icon name='exclamation circle' size='large'/>
                        About
                    </Link>
                    
                    <div className="right menu">
                        {this.state.currentSubject.role !== 3 ? '' : <Link to={this.props.data.match.url+'/setting'} onClick={()=>this.props.subNavAction('setting')} className={'item'+(this.props.subnav === 'setting' ? ' active' : '')}>
                            <Icon name='setting' size='large'/>
                            Settings
                        </Link>}
                    </div>
                </div>
            }
            </>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SubjectMenuComponent)