import React from 'react';
import {Editor, EditorState, RichUtils, ContentState } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';
import { Button, Checkbox, Container, Divider, Form, Grid, Header, Icon, Input, Modal, Popup, Tab } from 'semantic-ui-react';
import { toast } from 'react-toastify';

export default class RCQuestionForm extends React.Component{
    constructor(props){
        super(props)

        let passage = localStorage.getItem('passage')
        passage = JSON.parse(passage)

        let generated_question = localStorage.getItem('question')
        generated_question = JSON.parse(generated_question)
        let q_temp = []
        if(generated_question === null){
          q_temp.push({
            id: 1,
            answer: '',
            question: '',
            selected: false,
            is_draf: false,
            modal: false,
            response_time: 1
          })
        }else{
          let i = 1
          generated_question.question.forEach(x=>{
            q_temp.push({
              id: i,
              answer: x.answer,
              question: x.question,
              selected: x.selected !== undefined ? x.selected : false,
              is_draf: x.is_draf !== undefined ? x.is_draf : false,
              modal: x.modal !== undefined ? x.modal : false,
              response_time: x.response_time
            })
            i++
          })
        }

        this.state = {
          editorState: EditorState.createWithContent(ContentState.createFromText(passage.passage)),
          editorStateOld: EditorState.createWithContent(ContentState.createFromText(passage.passage)),
          questionList: q_temp,
          responseTime: generated_question !== null ? generated_question.response_time : 1,
          tabIndex: 0,
          isShowAction: false,
          allChecked: false,
          isBolded: false,
          passageData: passage
        };
    }

    onChange = editorState => this.setState({editorState});

    handleKeyCommand = (command, editorState) => {
      const newState = RichUtils.handleKeyCommand(editorState, command);
  
      if (newState) {
        this.onChange(newState);
        return 'handled';
      }
  
      return 'not-handled';
    }

    _onBoldClick = () => {
      let selectionState = this.state.editorState.getSelection();
      let anchorKey = selectionState.getAnchorKey();
      let currentContent = this.state.editorState.getCurrentContent();
      let currentContentBlock = currentContent.getBlockForKey(anchorKey);
      let start = selectionState.getStartOffset();
      let end = selectionState.getEndOffset();
      let selectedText = currentContentBlock.getText().slice(start, end);

      if(selectedText === ''){
        toast.warn('Please select or block the text for highlight.')
        return false
      }

      this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, 'BOLD'));
      this.setState({isBolded: !this.state.isBolded})
    }

    selectQuestion = (e, data) =>{
      let question = this.state.questionList
      if(data.name === "all"){
        for (let i = 0; i < question.length; i++) {
          if(!this.state.tabIndex){
            if(!question[i].is_draf){
              question[i].selected = data.checked
            }
          }else{
            if(question[i].is_draf){
              question[i].selected = data.checked
            }
          }
        }

        if(question.length > 0){
          this.setState({allChecked: data.checked})
        }else{
          toast.warn('No data to be selected.')
          data.checked = false
        }
      }else{
        let qidx = question.findIndex(x=>x.id === data.gid)
        let other = question.filter(x=>x.id !== data.gid && x.selected)
        if(qidx !== -1){
          question[qidx].selected = data.checked
        }

        if(other.length > 0){
          data.checked = true
        }
      }

      this.setState({questionList: question.length > 0 ? question : this.state.questionList, 
        isShowAction: data.checked})
    }

    changeTab = (e, data) =>{
      let question = this.state.questionList
      for (let i = 0; i < question.length; i++) {
        question[i].selected = false
      }

      this.setState({tabIndex: data.activeIndex, questionList: question, allChecked: false,
        isShowAction: false})
    }

    highlightAnswer = (e, data) =>{
      let rawText = stateToHTML(this.state.editorState.getCurrentContent())
      let extractWord = /<strong>(.*?)<\/strong>/g.exec(rawText)
      let question = this.state.questionList
      let idx = question.findIndex(x=>x.id === data.gid)
      if(idx !== -1 && extractWord !== null){
        question[idx].answer = extractWord[1]
        question[idx].modal = false
      }

      this.setState({questionList: question, editorState: this.state.editorStateOld, isBolded: false})
    }

    listAction = (data, e) =>{
      let question = this.state.questionList
      if(data.type === 'bulk'){
        if(data.action === 'remove'){
          if(window.confirm('are you sure to delete this question?')){
            for (let i = question.length -1; i >= 0; i--){
              if(question[i].selected){
                question.splice(i,1);
              }
            }
          }else{
            return false
          }
        }else{
          for (let i = 0; i < question.length; i++) {
            if(question[i].selected){
              question[i].is_draf = data.action === 'd-q' ? false : true
            }
          }
        }
      }else{
        let idx = question.findIndex(x=>x.id === data.id)
        if(idx !== -1){
          switch (data.action) {
            case 'd-q':
              question[idx].is_draf = false
              break;
            case 'q-d':
              question[idx].is_draf = true
              break;
            default:
              if(window.confirm('are you sure to delete this question?')){
                question.splice(idx, 1)
              }else{
                return false
              }
              break;
          }
        }
      }

      localStorage.setItem('question', JSON.stringify({
        response_time: this.state.responseTime,
        question: question
      }))

      this.setState({questionList: question, allChecked: false, isShowAction: false})
    }

    questionChange = (e, data) =>{
      let question = this.state.questionList
      let idx = question.findIndex(x=>x.id === parseInt(data.id.split('_')[1]))
      if(idx !== -1){
        question[idx].question = data.value
      }

      this.setState({questionList: question})
    }

    questionResponseTimeChange = (e, data) =>{
      let question = this.state.questionList
      let idx = question.findIndex(x=>x.id === parseInt(data.id.split('_')[1]))
      if(idx !== -1){
        question[idx].response_time = data.value
      }

      this.setState({questionList: question})
    }

    addMoreQuestion = () =>{
      let qs = this.state.questionList
      qs.push({
        id: qs.length+1,
        answer: '',
        question: '',
        selected: false,
        is_draf: false,
        modal: false,
        response_time: 1
      })

      this.setState({questionList: qs})
    }

    changeAllResponseTime = (e, data) =>{
      let question = this.state.questionList
      if(this.state.isShowAction){
        let qs = question.filter(x=>x.selected)
        qs.forEach(x=>{
          let idx = question.findIndex(p=>p.id === x.id)
          if(idx !== -1){
            question[idx].response_time = data.value
          }
        })
      }else{
        question.forEach(x=>{
          x.response_time = data.value
        })
      }

      this.setState({questionList: question, responseTime: data.value})
    }

    render() {
      return (
        <>
        <Header as='h2'>
            Questions
        </Header>
        <Container className='topBarQuestion'>
          <Checkbox size='large' label='Select All' onChange={this.selectQuestion} name='all' checked={this.state.allChecked}/>
          {this.state.isShowAction ? 
          <div className='linkAction'>
            {this.state.tabIndex ? 
            <Popup trigger={<div onClick={this.listAction.bind(null, {type: 'bulk', action: 'd-q'})}><Icon name='upload' link/></div>} basic content='Move out of Draft'/>
            : 
            <Popup trigger={<div onClick={this.listAction.bind(null, {type: 'bulk', action: 'q-d'})}><Icon name='download' link/></div>} basic content='Move to Draft'/>
            }
            <Popup trigger={<div onClick={this.listAction.bind(null, {type: 'bulk', action: 'remove'})}><Icon name='trash' link/></div>} basic content='Remove Question(s)'/>
            <Header as='h4'>Set Response Time</Header>
            <div>
              <Input type='number' value={this.state.responseTime} min={1}
              onChange={this.changeAllResponseTime}/>
              <span>minute(s)</span>
            </div>
          </div>
          : null}
          
        </Container>
        <Divider/>
        <Tab onTabChange={this.changeTab} panes={[
          { menuItem: 'Your Questions', render: () => <Tab.Pane>
            <div className='questionWrapper'>
            {this.state.questionList.filter(x=>!x.is_draf).map((v, k)=>
              <Grid columns='equal' className='questionList' key={k}>
                <Grid.Column width='one'>
                  <Checkbox name="single" onChange={this.selectQuestion} checked={v.selected} gid={v.id}/>
                </Grid.Column>
                <Grid.Column>
                  <Input id={"input_"+v.id} fluid value={v.question} onChange={this.questionChange}/>
                  <div className='answerQuestion'>
                    <p>Answer :</p>
                    <p>{v.answer}</p>
                    <Modal size='small' open={v.modal} trigger={<div onClick={()=>{
                      let qs = this.state.questionList
                      let idx = qs.findIndex(x=>x.id === v.id)
                      if(idx !== -1){
                        qs[idx].modal = true
                      }
                      this.setState({questionList: qs})
                    }}><Icon name='pencil' link/></div>} closeIcon onClose={()=>{
                      let qs = this.state.questionList
                      let idx = qs.findIndex(x=>x.id === v.id)
                      if(idx !== -1){
                        qs[idx].modal = false
                      }
                      this.setState({questionList: qs})
                    }}>
                      <Modal.Header>Highlight Answer</Modal.Header>
                      <Modal.Content>
                        <Header as='h3'>
                          <Header.Content>
                            Question
                            <Header.Subheader>{v.question}</Header.Subheader>
                          </Header.Content>
                        </Header>
                        <div className="editableContent">
                        <Editor editorState={this.state.editorState}
                          handleKeyCommand={this.handleKeyCommand}
                          onChange={this.onChange}
                        />
                        </div>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button primary onClick={this._onBoldClick}>
                          {this.state.isBolded ? 
                          <><Icon name='crop'/> Remove Highlight</>
                          : <><Icon name='eye dropper'/> Highlight Answer</> }
                        </Button>
                        <Button primary onClick={this.highlightAnswer} gid={v.id} disabled={!this.state.isBolded}>
                          <Icon name='save'/> Save Changes
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  </div>
                  <Form>
                    <Form.Field inline>
                      <label>Response Time</label>
                      <Input size='mini' type='number' value={v.response_time} id={"res_"+v.id}
                      onChange={this.questionResponseTimeChange} min={1}/> minute(s)
                    </Form.Field>
                  </Form>
                </Grid.Column>
                <Grid.Column width='two'>
                  <div className='linkAction'>
                    <Popup trigger={<div onClick={this.listAction.bind(null, {type: 'single', action: 'q-d', id: v.id})}><Icon name='download' link/></div>} basic content='Move out of Draft'/>
                    <Popup trigger={<div onClick={this.listAction.bind(null, {type: 'single', action: 'remove', id: v.id})}><Icon name='trash' link/></div>} basic content='Remove Question(s)'/>
                  </div>
                </Grid.Column>
              </Grid>
            )}
            {this.state.questionList.filter(x=>!x.is_draf).length === 0 ? <Container textAlign='center'>
              <p>No question data.</p>
            </Container> : null}

            {!this.state.passageData.is_automatic ? 
              <Button secondary style={{marginTop: 40}} onClick={this.addMoreQuestion}>
                <Icon name='add'/> Add Question
              </Button>
            : null}
            </div>
          </Tab.Pane> },
          { menuItem: 'Draft', render: () => <Tab.Pane>
            <div className='questionWrapper'>
            {this.state.questionList.filter(x=>x.is_draf).map((v, k)=>
              <Grid columns='equal' className='questionList' key={k}>
                <Grid.Column width='one'>
                  <Checkbox name="single" onChange={this.selectQuestion} checked={v.selected} gid={v.id}/>
                </Grid.Column>
                <Grid.Column>
                  <Input id={"input_"+v.id} fluid value={v.question} onChange={this.questionChange}/>
                  <div className='answerQuestion'>
                    <p>Answer :</p>
                    <p>{v.answer}</p>
                    <Modal size='small' open={v.modal} trigger={<div onClick={()=>{
                      let qs = this.state.questionList
                      let idx = qs.findIndex(x=>x.id === v.id)
                      if(idx !== -1){
                        qs[idx].modal = true
                      }
                      this.setState({questionList: qs})
                    }}><Icon name='pencil' link/></div>} closeIcon onClose={()=>{
                      let qs = this.state.questionList
                      let idx = qs.findIndex(x=>x.id === v.id)
                      if(idx !== -1){
                        qs[idx].modal = false
                      }
                      this.setState({questionList: qs})
                    }}>
                      <Modal.Header>Highlight Answer</Modal.Header>
                      <Modal.Content>
                        <Header as='h3'>
                          <Header.Content>
                            Question
                            <Header.Subheader>{v.question}</Header.Subheader>
                          </Header.Content>
                        </Header>
                        <div className="editableContent">
                        <Editor editorState={this.state.editorState}
                          handleKeyCommand={this.handleKeyCommand}
                          onChange={this.onChange}
                        />
                        </div>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button primary onClick={this._onBoldClick}>
                          {this.state.isBolded ? 
                          <><Icon name='crop'/> Remove Highlight</>
                          : <><Icon name='eye dropper'/> Highlight Answer</> }
                        </Button>
                        <Button primary onClick={this.highlightAnswer} gid={v.id} disabled={!this.state.isBolded}>
                          <Icon name='save'/> Save Changes
                        </Button>
                      </Modal.Actions>
                    </Modal>
                  </div>
                  <Form>
                    <Form.Field inline>
                      <label>Response Time</label>
                      <Input size='mini' type='number' value={v.response_time} id={"res_"+v.id}
                      onChange={this.questionResponseTimeChange} min={1}/> minute(s)
                    </Form.Field>
                  </Form>
                </Grid.Column>
                <Grid.Column width='two'>
                  <div className='linkAction'>
                    <Popup trigger={<div onClick={this.listAction.bind(null, {type: 'single', action: 'd-q', id: v.id})}><Icon name='upload' link/></div>} basic content='Move out of Draft'/>
                    <Popup trigger={<div onClick={this.listAction.bind(null, {type: 'single', action: 'remove', id: v.id})}><Icon name='trash' link/></div>} basic content='Remove Question(s)'/>
                  </div>
                </Grid.Column>
              </Grid>
            )}
            {this.state.questionList.filter(x=>x.is_draf).length === 0 ? <Container textAlign='center'>
              <p>No question data.</p>
            </Container> : null}
            </div>
          </Tab.Pane> },
        ]} menu={{ pointing: false }} className='tabQuestions'/>

        <Button floated='right' primary
          onClick={this.props.action.bind(null, {type: 'question', data: {
            response_time: this.state.responseTime,
            question: this.state.questionList
          }})}>
            Continue
        </Button>
        </>
      )
    };
}