import React from 'react'
/* Fields */
import { Input, Textarea, Toggle } from '../fields'
import { Popup, Icon } from 'semantic-ui-react'
const AddSubjectForm = (props) =>{
    return(
        <div>
            <Popup
                trigger={<Icon style={{position: 'absolute', left: 129}} name='question circle outline' link/>}
                content="The title of your subject. This can be changed later."
                basic
            />
            <Input label='Subject Name' initialValue={props.currentSubject !== null ? props.currentSubject.name : ''}
            name="name"
            required placeholder='Exp: English'/>
            
            <Popup
                trigger={<Icon style={{position: 'absolute', left: 173}} name='question circle outline' link/>}
                content="Short description to explain what the subject’s content will be. This can be changed later."
                basic
            />
            <Textarea required name="description" initialValue={props.currentSubject !== null ? props.currentSubject.description : ''} placeholder='Desribe your subject here...' 
            label='Subject Description'/>

            <Popup
                trigger={<Icon style={{position: 'absolute', left: 178}} name='question circle outline' link/>}
                content="Private subjects will only be accessible to groups that you assign it to through Learnalytics and will not appear when searched."
                basic
            />
            <Toggle checked={props.currentSubject !== null ? props.currentSubject.private : false} name="private" label='Set as Private'/> 
            <p>As private, the other contributors can’t access your subject except you invite them.</p>
        </div>
    )
}

export default AddSubjectForm