import React from 'react'
/* Fields */
import { Input, Textarea } from '../fields'
import {Button, Grid, Icon, Popup} from 'semantic-ui-react'
import ContentEditable from 'react-contenteditable'
import sanitizeHtml from "sanitize-html"
import TooltipVariableComponent from '../../components/tooltip_variable'

// module 10 form
class Module10Form extends React.Component{
    constructor(props){
        super(props)
        this.contentEditablePassage = React.createRef();
        this.state = {
            passage: '', 
            passageLength: 360,
            passageLengthConst: 360,
            max_passage_answer: 3,
            isVariableExist: false,
            errorPassage: '',
            passageWidth: 0,
            showAnswer: false,
            countAnswer: 3,
            isTouch: false,
            showVarError: false,
            max_char: 25
        }
    }

    // sanitize html
    sanitizeConf = {
        allowedTags: ["a"],
        allowedAttributes: { a: ["class"] }
    };

    // for set form data when edit
    componentDidMount(){
        let data = this.props.data
        if(data !== undefined){
            this.countWord(data.question_object.passage)
            this.setState({
                passage: data.question_object.passage,
            })
        }

        setTimeout(()=>{
            this.setState({
                passageWidth: this.contentEditablePassage.current.clientWidth,
            })
        }, 0)
    }

    // handle editable content
    handleChange = evt => {
        let html = sanitizeHtml(evt.target.value, this.sanitizeConf)
        let varData = html.match(/<a [^>]+>(.*?)<\/a>/g)
        let valueText = html
        if(varData !== null){
            varData.forEach(val=>{
                let extractWord = /<a [^>]+>(.*?)<\/a>/g.exec(val)
                extractWord[1] = extractWord[1].replace('&nbsp;', ' ')
                extractWord[1] = extractWord[1].replace('&amp;', '&')
                extractWord[1] = extractWord[1].replace('&lt;', '<')
                extractWord[1] = extractWord[1].replace('&gt;', '>')
                if(extractWord[1].length > this.state.max_char){
                    valueText = valueText.replace(extractWord.input, '<a class="ui green label">'+extractWord[1].slice(0,this.state.max_char)+'</a>')
                }
            })
        }
        html = valueText
        this.countWord(html)
        this.setState({ passage: html, isTouch: true});
    }

    // handle editable content kye press
    handleKeyDown = (e) =>{
        let html = this.state.passage
        if(e.which === 13) {
            html = html+ '&nbsp;'
            html = sanitizeHtml(html, this.sanitizeConf)
            this.setState({passage: html})
            e.preventDefault()
        }

        if(e.which === 192){
            html = html+ `<a class="ui green label"></a>`
            html = sanitizeHtml(html, this.sanitizeConf)
            if(this.state.countAnswer <= 0){
                return false
            }
            this.setState({passage: html})
            e.preventDefault()
        }

        this.countWord(html)
    }

    // count editable content
    countWord = (p) =>{
        let val = p
        val = sanitizeHtml(val, this.sanitizeConf)
        let list = val.split('<a class="ui green label">')
        let words = []
        list.forEach(ed => {
            ed = ed.trim()
            ed = ed.replace('</a>', '')
            words.push(ed)
        });

        let kl = words.join(' ')
        let textLength = this.state.passageLengthConst
        kl = kl.replace(/^\s+|\s+$/g, "").replace(/\s+/g, "")
        kl = kl.replace(/&amp;/g, '&')
        kl = kl.replace(/&lt;/g, '<')
        kl = kl.replace(/&gt;/g, '>')

        let varData = p.match(/<a [^>]+>(.*?)<\/a>/g)

        this.setState({passageLength: kl.length === 0 ? textLength : 
            textLength - kl.length, countAnswer: varData !== null ? 
            this.state.max_passage_answer - varData.length : this.state.max_passage_answer});
    }

    // add tag/value on editable content
    addTag = () =>{
        let hint = ''
        hint = this.state.passage
        let inp = document.getElementById('i_passage').value
        if(inp.trim() === ''){
            return false
        }
        if(inp.length > this.state.max_char){
            this.setState({showVarError: true})
            return false
        }
        hint += `<a class="ui green label">`+inp+`</a>&nbsp;`
        this.setState({passage: hint, showAnswer: false, 
            isTouch: true, showVarError: false})
        this.contentEditablePassage.current.focus();
        setTimeout(()=>{
            this.countWord(hint)
        }, 0)
    }

    render() {
        let passageError = ''
        // let isTouch = false
        let passageEl = document.getElementById('passage')
        passageEl = passageEl !== null ? document.getElementById('passage').getAttribute('class') : ''
        if(passageEl.includes('error')){
            passageError = ' error'
        }

        let passageVar = true
        if(this.state.passage !== '' && !sanitizeHtml(this.state.passage, this.sanitizeConf).includes('</a>')){
            passageVar = false
        }

        return (
            <>
                <td style={this.state.passageWidth !== 0 ? {width: this.state.passageWidth} : null}>
                    <div className='field'>
                        <label>Passage <b style={{color:'#db2828'}}> *</b>
                        <TooltipVariableComponent open={false}/>
                        </label>
                        <ContentEditable 
                        innerRef={this.contentEditablePassage}
                        className={'editableContent'+ passageError} 
                        style={this.state.passageWidth !== 0 ? {width: this.state.passageWidth} : null}
                        html={this.state.passage}
                        disabled={false}
                        onChange={this.handleChange}
                        onKeyDown={this.handleKeyDown}
                        placeholder={'Type here...'}
                        id='content_passage'
                        />
                        <span style={this.state.passageLength < 0 ? {color: '#db2828', textAlign: 'right', float: 'right'} : 
                        {textAlign: 'right', float: 'right'}}>{this.state.passageLength}</span>
                        <Textarea input_error={this.state.passageLength < 0 || !passageVar || 
                        (this.state.countAnswer > 0 && this.state.isTouch)  
                        ? true : false} 
                        values={this.state.passage} hidden={true} name="passage" required/>
                    </div>
                    
                    {this.state.showAnswer ? 
                        <Grid columns='equal'>
                            <Grid.Column width={10}>
                                <Popup content={'Max characters is '+this.state.max_char} trigger={
                                    <div className='field'>
                                        <input placeholder='Type here...' id='i_passage' onKeyDown={(e)=>{
                                            let val = document.getElementById('i_passage').value
                                            this.setState({showVarError: val.length > this.state.max_char ? true : false})
                                            if ((e.key === 'Enter' || e.key === 'Tab') && val.trim() !== '') {
                                                if(val.length <= this.state.max_char){
                                                    this.addTag()
                                                }
                                            }
                                        }}/>
                                        {this.state.showVarError ? 
                                        <div className="ui red pointing label">Your input is more than {this.state.max_char} characters</div>
                                        : null}
                                    </div>
                                }/>
                            </Grid.Column>
                            <Grid.Column>
                                <Button className='tertiary' size='small' type='button' icon onClick={this.addTag} disabled={this.state.showVarError}>
                                    <Icon color='green' name='check'/>
                                </Button>
                                <Button className='tertiary' size='small' type='button' icon onClick={()=>this.setState({showAnswer: false})}>
                                    <Icon color='grey' name='remove'/>
                                </Button>
                            </Grid.Column>
                        </Grid> : 
                        <>
                        <Button type='button' secondary size='small' disabled={this.state.countAnswer === 0}
                        onClick={()=>{
                            this.setState({showAnswer: true})
                            setTimeout(()=>{
                                document.getElementById('i_passage').focus()
                            }, 100)
                            }}>
                            Add correct answer({this.state.countAnswer})
                        </Button>
                        <i style={{fontSize: 12, color: 'grey'}}>*required : {this.state.max_passage_answer} answers</i>
                        </>
                    }
                </td>

                <td>
                    <Input name="wrong1" required placeholder='Exp: are' wlength={20}/>
                    <Input name="wrong2" required placeholder='Exp: are' wlength={20}/>
                    <Input name="wrong3" required placeholder='Exp: are' wlength={20}/>
                </td>
            </>
        )
    }
}

export default Module10Form