import React from 'react'
/* Fields */
import { Input, Textarea } from '../fields'
import {Button, Icon, Grid, Label, Input as UIInput, Popup} from 'semantic-ui-react';



// module 14 form
class Module14Form extends React.Component{
    constructor(props){
        super(props)
        this.contentEditablePassage = React.createRef();
        this.state = {
            passageWidth: 0,
            keywords: this.props.data !== undefined ? this.props.data.question_object.keywords.split(',') : [],
            showAnswer: false,
            words: '',
            selectedKey: null,
            keywordsText: this.props.data !== undefined ? this.props.data.question_object.keywords : '',
            isTouch: false,
            showVarError: false
        }
    }

    addTag = () =>{
        let word = this.state.words
        if(word.trim() === ''){
            return false
        }
        if(word.length > 20){
            this.setState({showVarError: true})
            return false
        }
        word = word.replace(/[^\w\s]/gi, '')
        let key = this.state.selectedKey
        let keywords = this.state.keywords
        if(word !== ''){
            if(key !== null){
                keywords[key] = word
            }else{
                keywords.push(word)
            }
        }
        this.setState({keywords: keywords, showAnswer: false, words: '', selectedKey: null, 
        keywordsText: keywords.join(','), isTouch: true})
    }

    removeTag = (key, e) =>{
        let keywords = this.state.keywords
        keywords.splice(key, 1)
        this.setState({keywords: keywords, keywordsText: keywords.join(','), isTouch: true})
    }

    render(){
        return <>
            <td>
                <Input label='Topic' name="topic" required placeholder='Type Here...' wlength={30}/>
            </td>
            <td>
                <Textarea label='Question' name="question" required placeholder='Type here...' wlength={120}/>
            </td>
            <td style={this.state.passageWidth !== 0 ? {width: this.state.passageWidth} : null}>
                <div className='field'>
                    <label>Keywords <b style={{color:'#db2828'}}> *</b></label>
                    <div className={this.state.keywords.length < 3 && this.state.isTouch ? 'editableContent error' : 'editableContent'}>
                        {this.state.keywords.map((v,k)=>
                            <Label key={k} as='a' style={{marginTop: 5}}>
                                <span onClick={()=>this.setState({
                                    showAnswer: true, words: v, selectedKey: k
                                })}>{v}</span>
                                <Icon name='delete' onClick={this.removeTag.bind(null, k)}/>
                            </Label>
                        )}
                    </div>
                    {this.state.keywords.length < 3 && this.state.isTouch ? 
                        <Label color='red' pointing>
                            Not enough keywords
                        </Label>
                    : null}
                    <Textarea input_error={this.state.keywords.length < 3 && this.state.isTouch 
                    ? true : false} 
                    values={this.state.keywordsText} hidden={true} name="keywords" required/>
                </div>
                
                {this.state.showAnswer ? 
                    <Grid columns='equal'>
                        <Grid.Column width={10}>
                            <Popup content='Max characters is 20' trigger={
                                <div className='field'>
                                    <UIInput id='keyword_i' placeholder='Type here...' onChange={(e, data)=>this.setState({words: data.value})} 
                                    value={this.state.words} onKeyDown={(e)=>{
                                        this.setState({showVarError: this.state.words.length > 20 ? true : false})
                                        if ((e.key === 'Enter' || e.key === 'Tab') && this.state.words.trim() !== '') {
                                            if(this.state.words.length <= 20){
                                                this.addTag()
                                            }
                                        }
                                    }}/>
                                    {this.state.showVarError ? 
                                    <div className="ui red pointing label">Your input is more than 20 characters</div>
                                    : null}
                                </div>
                            }/>
                        </Grid.Column>
                        <Grid.Column>
                            <Button className='tertiary' disabled={this.state.words === '' || this.state.showVarError ? true : false}
                            size='small' type='button' icon onClick={this.addTag}>
                                <Icon color='green' name='check'/>
                            </Button>
                            <Button className='tertiary' 
                            size='small' type='button' icon onClick={()=>this.setState({showAnswer: false})}>
                                <Icon color='grey' name='remove'/>
                            </Button>
                        </Grid.Column>
                    </Grid> : 
                    <div>
                    <Button type='button' secondary size='small' 
                    disabled={this.state.keywords.length === 30 ? true : false}
                    onClick={()=>{
                        this.setState({showAnswer: true, words: ''})
                        setTimeout(()=>{
                            document.getElementById('keyword_i').focus()
                        }, 100)
                    }}>
                        {3 - this.state.keywords.length <= 0 ? 
                        'Add Keyword'
                        : 'Add Keyword ('+(3 - this.state.keywords.length)+')'
                        }
                    </Button>     
                    <i style={{fontSize: 12, color: 'grey'}}>*minimum keywords is 3</i>
                    </div>}
            </td>
        </>
    }
}

export default Module14Form