import React, { useEffect, useState } from 'react';
import { Grid, Icon, Divider, Loader, Pagination, Dimmer } from 'semantic-ui-react';
import { useDispatch, useSelector, connect } from 'react-redux';
import FilterComponent from '../../components/filter';
import './style.scss'
import EmptySubjectComponent from '../../components/subject/empty';
import CardComponent from '../../components/card';
import { filterSubject, loadSubject } from '../../store/actions/subject'
import SearchComponent from '../../components/filter/search';
import storage from 'redux-persist/lib/storage';

function mapStateToProps(state) {
    return { 
        filter_subject: state.filter_subject,
        is_load: state.filter_subject !== null ? true : false
    }
}

const SubjectPage = (props) =>{
    useEffect(()=>{
        dispatch(filterSubject(null))
        loadData(true)
    }, []) // eslint-disable-line react-hooks/exhaustive-deps
    const dispatch = useDispatch()
    const userInfo = useSelector(x=>x.auth)
    const myHeaders = new Headers({
        'Authorization': 'Token '+userInfo.user.token
    });
    const isLoad = useSelector(x=>x.is_subject_load)
    const [filterParam, setFilterParam] = useState({
        search: '',
        sort: '0-a',
        category: '',
        filter:[],
        n: '6',
        page: '1'
    })
    const [mySubject, setMySubject] = useState([])
    const [loading, setLoading] = useState(true)
    const [pageLoading, setPageLoading] = useState(false)
    const [totalPage, setTotalPage] = useState(0)
    const subjectFilter = useSelector(x=>x.filter_subject)
    const [totalSubject, setTotalSubject] = useState(0)
    const loadData = (is_fresh = false) =>{
        if(subjectFilter === null){
            dispatch(filterSubject(filterParam))
        }
        let params = null
        if(is_fresh){
            params = filterParam
        }else{
            params = subjectFilter === null ? filterParam : subjectFilter
        }
        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/mine', {
            method: 'post',
            headers: myHeaders,
            body: JSON.stringify(params)
        }).then(function(res){
            return res.json()
        }).then(function(res){
            setLoading(false)
            setPageLoading(false)
            if(res.status !== undefined){
                dispatch(loadSubject(false))
                setTotalPage(res.total_page)
                setMySubject(res.data)
                setTotalSubject(res.total_record)
            }else{
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
        })
    }

    const handleChangePage = (page) =>{
        setPageLoading(true)
        let filter = filterParam
        filter.page=page
        setFilterParam(filter)
        loadData()
    }

    if(isLoad){
        loadData()
    }

    return(
        <Grid columns='equal'>
            <Grid.Column width={4}>
                <FilterComponent/>
            </Grid.Column>
            <Grid.Column>
                <Grid columns='equal'>
                    <Grid.Column>
                        <div className='c_header'>
                            <Icon name='folder'/>
                            My Subject
                        </div>
                    </Grid.Column>
                    <Grid.Column>
                        <SearchComponent count={totalSubject}/>
                    </Grid.Column>
                </Grid>
                <Divider/>
                {loading ? 
                    <Loader active style={{marginTop: 60}}>Loading...</Loader>
                : mySubject.length > 0 ? <div>
                <CardComponent data={mySubject}/>
                <Divider hidden/>
                <div style={{textAlign:'center'}}>
                    <Pagination onPageChange={(event, data) => handleChangePage(data.activePage)} defaultActivePage={1} totalPages={totalPage} />
                </div>
                <Dimmer inverted active={pageLoading}>
                    <Loader active/>
                </Dimmer>
                </div> : <EmptySubjectComponent/>}
            </Grid.Column>
        </Grid>
    )
}

export default connect(mapStateToProps)(SubjectPage);