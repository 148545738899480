import React from 'react'
/* Fields */
import { Input, Textarea } from '../fields'

// module 1 form
const Module1Form = (props) =>{
    return <>
        <td>
            <Input label='Word' name="word" required placeholder='Exp: Car' wlength={13}/>
        </td>
        <td>
            <Textarea label='Definition' name="definition" required placeholder='Type here...' wlength={170}/>
        </td>
        <td>
            <Textarea label='Hint 1' name="hint1" required placeholder='Type here...' wlength={140}/>
        </td>
        <td>
            <Textarea label='Hint 2' name="hint2" required placeholder='Type here...' wlength={140}/>
        </td>
        <td>
            <Textarea label='Hint 3' name="hint3" required placeholder='Type here...' wlength={140}/>
        </td>
    </>
}

export default Module1Form