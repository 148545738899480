import React from 'react'
import { Grid, Button, Icon, Divider, Table, Pagination, Dropdown,
Modal,
Loader,
Container,
Input,
Form,
Image,
List,
Popup} from 'semantic-ui-react'
import '../style.scss'
import {connect} from 'react-redux'
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify'
import ImageCropperComponent from '../../../components/image_cropper';

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        auth: state.auth
    }
}

class FAQPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            faqList: [],
            loading: true,
            filter: {
                page: 1,
                n: 10,
                search: ''
            },
            totalPage: 0,
            totalRecord: 0,
            imageModal: false,
            selectedImage: '',
            cropModal: false,
            imgSrc: '',
            form: {
                question: '',
                answer: '',
                image: ''
            },
            selectedFAQ: null,
            loadingSave: false
        }
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/faq', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify(this.state.filter)
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            this.setState({faqList: res.data, loading: false, 
                totalPage: res.total_page, 
                totalRecord: res.total_record})
        }).catch((e)=>{
            toast.error('something wrong happened on getting faq data.')
        })
    }

    handleChangePagination = (e, data) =>{
        let filter = this.state.filter
        filter.page = data.activePage
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    handleChangeEntries = (e, data) =>{
        let filter = this.state.filter
        filter.n = data.value
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    deleteFAQ = (id, e) =>{
        if(window.confirm('Are you sure want to delete this?')){
            fetch(process.env.REACT_APP_API_URL+'api/v1/admin/faq/delete/'+id, {
                headers: new Headers({
                    'Authorization': 'Token '+this.props.auth.user.token
                }),
                method: 'delete'
            }).then((res)=>{
                return res.json()
            }).then((res)=>{
                if(res.status === undefined){
                    storage.removeItem('persist:root')
                    window.location.href = '/'
                }
                if(res.status){
                    toast.success('FAQ deleted.')
                    this.loadData()
                }else{
                    toast.error('FAQ cannot be deleted.')
                }
            }).catch((e)=>{
                toast.error('something wrong happened on deleting faq.')
            })
        }
    }

    handleFilter = (e, data) =>{
        let filter = this.state.filter
        filter.search = data.value
        this.setState({filter: filter})
        setTimeout(()=>{
            this.loadData()
        }, 200)
    }

    renderNumber = (k)=>{
        let n = this.state.filter.n * this.state.filter.page
        n = n - this.state.filter.n + k + 1
        return n
    }

    fileInput = null

    onChangeImage = (e)=>{
        e.preventDefault();
        let files;

        if(e.target.files[0] === undefined){
            return false
        }

        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            return false
        }

        if(e.target.files[0].size > 2000000){
            toast.error('Image should be equal to or less than 2MB.')
            return false
        }
        
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        const reader = new FileReader();
        reader.onload = () => {
            this.setState({cropModal: true, imgSrc: reader.result})
        };
        reader.readAsDataURL(files[0]);
    }

    setImageFAQ = (src) =>{
        this.fileInput.value = null
        let form = this.state.form
        if(src === null){
            form.image = ''
            this.setState({form: form, cropModal: false})
            return false
        }
        form.image = src
        this.setState({form: form, cropModal: false})
    }

    saveFAQ = () =>{
        let form = this.state.form
        let messages = []
        if(form.question === ''){
            messages.push(<List.Item>Question is required.</List.Item>)
        }
        if(form.answer === ''){
            messages.push(<List.Item>Answer is required.</List.Item>)
        }

        if(messages.length > 0){
            toast.error(<List bulleted>{messages}</List>)
            return false
        }
        this.setState({loadingSave: true})
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/faq/save', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify(form)
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                toast.success('FAQ saved.')
                this.setState({selectedFAQ: null, loadingSave: false, formModal: false, form:{
                    question: '',
                    answer: '',
                    image: '',
                }})
                this.loadData()
            }else{
                toast.error('FAQ cannot be saved.')
            }
        }).catch((e)=>{
            toast.error('something wrong happened on saving faq.')
        })
    }

    handleFormChange = (e, data) =>{
        let form = this.state.form
        switch (data.name) {
            case 'question':
                form.question = data.value
                break;
            default:
                form.answer = data.value
                break;
        }
        this.setState({form: form})
    }

    editSelected = (data, e) =>{
        let form = this.state.form
        form.id = data.faq_id
        form.question = data.question
        form.answer = data.answer
        form.image = data.image !== '' && data.image !== null ? data.image : ''
        this.setState({selectedFAQ: data, form: form, formModal: true})
    }

    removeImage = () =>{
        let form = this.state.form
        form.image = ''
        this.setState({form: form, imgSrc: ''})
    }

    render() {
        return (
            <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                {this.state.loading ? <Loader active/> :
                    <>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <div className="ui icon rounded input">
                                    <Input type="text" icon='search' name='search' onChange={this.handleFilter}
                                    placeholder="Search a word or sentences..."/>
                                </div>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Button primary floated='right' onClick={()=>this.setState({formModal: true})}>
                                    <Icon name='add'/> Add FAQ
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Table fixed sortable className='admin' striped>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}>
                            No
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Question
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Answer
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Image
                            </Table.HeaderCell>
                            <Table.HeaderCell width={2}>
                            Action
                            </Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {this.state.faqList.map((v, k)=>
                        <Table.Row key={k} className={v.banned ? 'banned' : ''}>
                            <Table.Cell>{this.renderNumber(k)}</Table.Cell>
                            <Table.Cell>{v.question}</Table.Cell>
                            <Table.Cell>{v.answer}</Table.Cell>
                            <Table.Cell>
                                {v.image !== '' && v.image !== null ? 
                                <div className='seeDetail' 
                                onClick={()=>this.setState({imageModal: true, selectedImage: v.image})}>Open Image</div>
                                : '-'}
                            </Table.Cell>
                            <Table.Cell>
                                <Button size='mini' secondary
                                onClick={this.deleteFAQ.bind(null, v.faq_id)}>
                                    <Icon name='trash'/> Delete
                                </Button>
                                <Button size='mini' secondary
                                onClick={this.editSelected.bind(null, v)}>
                                    <Icon name='pencil'/> Edit
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                        )}
                        </Table.Body>
                    </Table>
                    
                    
                    {/* pagination */}
                    <Divider hidden/>
                    <div>
                        Show <Dropdown defaultValue={10} compact selection options={[
                            {key: 10, text: '10', value: 10},
                            {key: 25, text: '25', value: 25},
                            {key: 50, text: '50', value: 50},
                            {key: 100, text: '100', value: 100},
                        ]} onChange={this.handleChangeEntries} /> entries
                        <div className='pagination'>
                            <span style={{marginRight: 10}}>
                                Showing {this.state.faqList.length} of {this.state.totalRecord} entries
                            </span>
                            <Pagination activePage={this.state.filter.page} size='small' onPageChange={this.handleChangePagination} 
                            totalPages={this.state.totalPage} />
                        </div>
                    </div>
                    </>
                }

                <Modal open={this.state.imageModal} size='small' closeIcon 
                onClose={()=>this.setState({imageModal: false, selectedImage: ''})}>
                    {this.state.selectedImage !== '' ? 
                    <Image src={this.state.selectedImage} centered/>
                    : null}
                </Modal>

                <Modal open={this.state.formModal} size='small'>
                    <Modal.Header>Saving FAQ</Modal.Header>
                    <Modal.Content>
                        <Form>
                            <Grid columns='equal'>
                                <Grid.Column>
                                    <Form.Input name='question' placeholder='Type question here...' label='Question'
                                    onChange={this.handleFormChange} value={this.state.form.question}/>
                                    <Form.TextArea name='answer' placeholder='Type answer here...' label='Answer'
                                    onChange={this.handleFormChange} value={this.state.form.answer}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <label>Image</label>
                                    {this.state.form.image !== '' ? 
                                        <Image size='small' src={this.state.form.image}/>
                                    : <><Divider hidden/><Divider hidden/></>}
                                    <input onChange={this.onChangeImage} 
                                    accept="image/jpeg" 
                                    style={{display:'none'}} ref={el => this.fileInput = el} 
                                    type='file' name='icon'/>
                                    <Divider hidden/>
                                    <Popup trigger={
                                        <Button secondary size='mini' onClick={()=>this.fileInput.click()}>
                                            <Icon name='folder open'/> Browse File
                                        </Button>
                                    } content='Image size (Max: 2MB)'></Popup>
                                    {this.state.form.image !== '' ?
                                    <Button className='tertiary' size='mini' 
                                    onClick={this.removeImage}>
                                        Remove Image
                                    </Button> : null}
                                    <ImageCropperComponent open={this.state.cropModal} loading={this.state.loading} 
                                    imgSrc={this.state.imgSrc} fileInput={this.fileInput} ratio={1 / 1} act={this.setImageFAQ}/>
                                </Grid.Column>
                            </Grid>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className='tertiary' disabled={this.state.loadingSave}
                        onClick={()=>this.setState({formModal: false, selectedFAQ: null, form:{
                            question: '',
                            answer: '',
                            image: '',
                        }})}>Cancel</Button>
                        <Button primary onClick={this.saveFAQ} disabled={this.state.loadingSave}
                        loading={this.state.loadingSave}>Save FAQ</Button>
                    </Modal.Actions>
                </Modal>
            </Container>
        )
    }
}

export default connect(mapStateToProps)(FAQPage)