import React from 'react';
import { Segment, Icon, Header, Divider, Form, Label, Button, Popup,
Message } from 'semantic-ui-react';
import {connect} from 'react-redux';
import { toast } from 'react-toastify';
import {formatBytes} from '../../helpers/file_size';

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth
    }
}

class ContactUsPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            attachments: [],
            totalSize: 0,
            form: {
                email: this.props.auth !== null ? this.props.auth.user.email : '',
                topic: 'General Question',
                message: '',
                attachments: []
            },
            is_send: false,
            loading: false
        }
    }

    fileInput = null

    onChangeImage = (e)=>{
        e.preventDefault();
        let files;

        if(e.target.files[0] === undefined){
            return false
        }

        if(e.target.files[0].type !== 'image/jpeg'){
            toast.error('Please choose correct format image.')
            this.fileInput.value = "";
            return false
        }

        if(e.target.files[0].size > 2000000){
            toast.error('Image should be equal to or less than 2MB.')
            return false
        }
        
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        
        let size = formatBytes(files[0].size).split(' ')
        let totSize = this.state.totalSize
        totSize = totSize + parseFloat(size[0])
        let setFile = {
            name: files[0].name,
            size: formatBytes(files[0].size),
            data: ''
        }

        if(totSize > 2000){
            toast.error('Attachment size cannot more than 2MB.')
            this.fileInput.value = "";
            return false
        }

        const reader = new FileReader();
        reader.onload = () => {
            let atts = this.state.attachments
            setFile.data = reader.result
            let is_exist = atts.filter(x=>x.name === setFile.name && x.size === setFile.size)
            if(is_exist.length > 0){
                toast.error('image already attached, please choose another image.')
                this.fileInput.value = "";
                return false
            }
            atts.push(setFile)
            this.setState({attachments: atts, totalSize: totSize})
            this.fileInput.value = "";
        };
        reader.readAsDataURL(files[0]);
    }

    removeAttachment = (key) =>{
        let attachment = this.state.attachments
        attachment.splice(key, 1)
        this.setState({attachments: attachment})
    }

    sendMessage = () =>{
        let form = this.state.form
        form.attachments = this.state.attachments.map(function(e){
            return e.data
        })
        this.setState({loading: true})

        fetch(process.env.REACT_APP_API_URL+'api/v1/contact-us/send-ticket', {
            method: 'post',
            body: JSON.stringify(form)
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status){
                this.setState({loading: false, is_send: true, attachments: [], totalSize: 0})
            }else{
                toast.error(res.message)
            }
        })
    }

    onChangeForm = (e, data) =>{
        let form = this.state.form
        switch (data.name) {
            case 'email':
                form.email = data.value
                break;
            case 'topic':
                form.topic = data.value
                break;
            default:
                form.message = data.value
                break;
        }
        this.setState({form: form})
    }

    render(){
        let specific = [
            'Subject Verification',
            'User Verification',
        ]

        if(this.props.auth === null){
            specific = [
                'Sign up and Login',
                'Banned Profile',
            ]
        }
        let general = [
            'General Question',
            'Technical Issue',
            ...specific,
            'Report',
            'Copyright Issue',
            'Feedback',
            'Others'
        ]

        let options = []
        general.forEach(v =>{
            options.push({
                key: v,
                text: v,
                value: v
            })
        })
        return(
            <div className="ui middle aligned container" style={{width: 500, marginTop: 60, minHeight: '66.5vh'}}>
                <div className="ui middle aligned grid" style={{height:'100vh'}}>
                    <div className="row">
                        <div className="column">
                            <Segment className='contactUs'>
                                <Header textAlign='center' as='h1'>
                                    <Icon name='envelope open'/>
                                    Contact Us
                                </Header>
                                <Divider hidden/>
                                {this.state.is_send ? 
                                    <div className='sendMessage'>
                                        <Message color='green'>
                                            <Icon name='check circle' color='green'/>
                                            Thank you. We’ll respond your message through your email.
                                        </Message>
                                        <Divider hidden/>
                                        <Button onClick={()=>this.setState({is_send: false})} primary>Send Another Message</Button>
                                        <Divider hidden/>
                                        <a href='/faq' rel='faq'>See Frequently Asked Questions (FAQ)</a>
                                    </div>
                                    :
                                    <Form onSubmit={this.sendMessage} id="contact_form">
                                        {this.props.auth === null ? 
                                            <>
                                                <label>Email</label>
                                                <Form.Input name='email' onChange={this.onChangeForm} 
                                                required type='email' placeholder='your@email.com'/>
                                            </>
                                        : null}
                                        <label>Topic</label>
                                        <Form.Select onChange={this.onChangeForm} fluid name='topic'
                                        options={options} defaultValue={general[0]} id='topic'/>
                                        <label>Message</label>
                                        <Form.TextArea onChange={this.onChangeForm} name='message' id='message'
                                        placeholder='Write your message here...' required rows={3}/>
                                        <input onChange={this.onChangeImage} accept="image/jpeg" 
                                        style={{display:'none'}}  id='avatar'
                                        ref={el => this.fileInput = el} type='file' name='avatar'/>
                                        <div className='attachment'>
                                            {this.state.attachments.length > 0 ?
                                                <Label.Group color='blue' size='small'>
                                                    {this.state.attachments.map((v, k)=>
                                                    <Label key={k}>{v.name} ({v.size}) <Icon name='delete' onClick={this.removeAttachment.bind(k)}/></Label>
                                                    )}
                                                </Label.Group>
                                                : null
                                            }
                                            
                                            <Popup basic content={
                                                <p>Image attachments. <b style={{color:'#db2828'}}>max size is 2MB</b></p>
                                            } trigger={
                                                <Icon.Group size='big' onClick={()=>this.fileInput.click()}>
                                                    <Icon name='image' link/>
                                                    <Icon corner name='add' color='green'/>
                                                </Icon.Group>
                                            }/>
                                        </div>
                                        <Divider hidden/>
                                        <Divider/>
                                        <div className='action'>
                                            <a href='/faq' rel='faq'>See Frequently Asked Questions (FAQ)</a>
                                            <Button primary disabled={this.state.loading} id='contact_btn_send'
                                            loading={this.state.loading}>
                                                Send Message
                                            </Button>
                                        </div>
                                    </Form>
                                }
                            </Segment>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps)(ContactUsPage)