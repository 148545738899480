import React from 'react'
import { Grid, Button, Icon, Divider, Table, Pagination, Dropdown,
Modal,
Loader,
Checkbox,
Container,
Input,
Form} from 'semantic-ui-react'
import '../style.scss'
import {connect} from 'react-redux'
import storage from 'redux-persist/lib/storage';
import { toast } from 'react-toastify'
import { downloadCSV } from '../../../helpers/download';

// to detect every store of redux/state changed
function mapStateToProps(state){
    return{
        auth: state.auth
    }
}

class UsersPage extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            userList: [],
            loading: true,
            filter: {
                page: 1,
                n: 10,
                order_by: 3,
                asc: 0,
                verified_only: 0,
                banned_only: 0,
                search: ''
            },
            totalPage: 0,
            totalRecord: 0,
            banModal: false,
            selectedUsername: '',
            reason: '',
            handleActionStatus: false
        }
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/users', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify(this.state.filter)
        }).then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            this.setState({userList: res.data, loading: false, 
                totalPage: res.total_page, 
                totalRecord: res.total_record})
        }).catch((e)=>{
            toast.error('something wrong happened on getting user data.')
        })
    }

    handleSort = (clickedColumn) => () => {
        let filter = this.state.filter
        filter.order_by = clickedColumn
        filter.asc = filter.asc === 1 ? 0 : 1
        this.setState({
            filter: filter
        })

        setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    handleChangePagination = (e, data) =>{
        let filter = this.state.filter
        filter.page = data.activePage
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    handleChangeEntries = (e, data) =>{
        let filter = this.state.filter
        filter.n = data.value
        this.setState({filter: filter})
        window.setTimeout(()=>{
            this.loadData()
        }, 100)
    }

    handleActions = (username, e, btn) =>{
        this.setState({handleActionStatus: true})
        let config = {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        }
        let type = btn.id
        let user = username !== '' ? username : this.state.selectedUsername
        if(type === 'ban'){
            config.method = 'post'
            config.body = JSON.stringify({reason: this.state.reason})
        }
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/users/'+type+'/'+user, config)
        .then((res)=>{
            return res.json()
        }).then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href = '/'
            }
            if(res.status){
                toast.success(type+ ' user success.')
                this.loadData()
            }else{
                toast.error('error '+type+ ' user.')
            }
            this.setState({selectedUsername: '', reason: '', banModal: false, handleActionStatus: false})
        }).catch((e)=>{
            toast.error('something wrong happened on handling '+type+' of user.')
        })
    }

    handleFilter = (e, data) =>{
        let filter = this.state.filter
        switch (data.name) {
            case 'search':
                filter.search = data.value
                break;
            case 'verified':
                filter.verified_only = data.checked
                break;
            default:
                filter.banned_only = data.checked
                break;
        }
        this.setState({filter: filter})
        setTimeout(()=>{
            this.loadData()
        }, 200)
    }

    downloadContent = () =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/admin/users/download', {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.text()
        }).then((res)=>{
            downloadCSV('users.csv', res)
        }).catch((e)=>{
            toast.error('something wrong happened on download user data.')
        })
    }

    renderNumber = (k)=>{
        let n = this.state.filter.n * this.state.filter.page
        n = n - this.state.filter.n + k + 1
        return n
    }

    render() {
        return (
            <Container style={{paddingTop: 80, minHeight: '66.5vh'}}>
                {this.state.loading ? <Loader active/> :
                    <>
                    <Grid columns='equal'>
                        <Grid.Row>
                            <Grid.Column>
                                <div className="ui icon rounded input">
                                    <Input type="text" icon='search' name='search' onChange={this.handleFilter}
                                    placeholder="Search a word or sentences..."/>
                                </div>
                                <Checkbox name='verified' toggle onChange={this.handleFilter}
                                label='Verified User Only' style={{marginLeft: 15}}/>
                                <Checkbox name='banned' toggle onChange={this.handleFilter}
                                label='Banned User Only' style={{marginLeft: 15}}/>
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <Button primary floated='right' onClick={this.downloadContent}>
                                    <Icon name='download'/> Download Table Data
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Table fixed sortable className='admin' striped>
                        <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell width={1}>
                            No
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 0 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(0)}
                            >
                            Username
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 1 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(1)}
                            >
                            Full Name
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 2 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(2)}
                            >
                            Email
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Phone Number
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 3 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(3)}
                            >
                            Created Date
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 4 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(4)}
                            >
                            Last Login
                            </Table.HeaderCell>
                            <Table.HeaderCell
                            sorted={this.state.filter.order_by === 5 ? 
                                this.state.filter.asc === 1 ? 'ascending' : 'descending'    
                            : null}
                            onClick={this.handleSort(5)}
                            >
                            Subject
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                            Action
                            </Table.HeaderCell>
                        </Table.Row>
                        </Table.Header>
                        <Table.Body>
                        {this.state.userList.map((v, k)=>
                        <Table.Row key={k} className={v.banned ? 'banned' : ''}>
                            
                            <Table.Cell>{this.renderNumber(k)}</Table.Cell>
                            <Table.Cell>
                                {v.username} {v.verified ? <Icon name='check circle' color='green'/>:null}
                            </Table.Cell>
                            <Table.Cell>{v.full_name}</Table.Cell>
                            <Table.Cell>{v.email}</Table.Cell>
                            <Table.Cell>{v.phone_number}</Table.Cell>
                            <Table.Cell>{v.created_date}</Table.Cell>
                            <Table.Cell>{v.last_login}</Table.Cell>
                            <Table.Cell>
                                <p>Owned: <b>{v.subject_owned}</b></p>
                                <p>Contributed: <b>{v.subject_contributed}</b></p>
                            </Table.Cell>
                            <Table.Cell>
                                {v.verified ? 
                                    <Button size='mini' onClick={this.handleActions.bind(null, v.username)}
                                    id='unverify' secondary disabled={v.banned || this.state.handleActionStatus}>
                                        <Icon name='remove circle' color='red'/> Unverify
                                    </Button>
                                : 
                                    <Button size='mini' onClick={this.handleActions.bind(null, v.username)}
                                    id='verify' secondary disabled={v.banned || this.state.handleActionStatus}>
                                        <Icon name='check circle' color='green'/> Verify
                                    </Button>
                                }
                                {v.banned ? 
                                    <Button size='mini' onClick={this.handleActions.bind(null, v.username)}
                                    id='unban' secondary disabled={this.state.handleActionStatus}>
                                        <Icon name='ban' color='blue'/> Unban
                                    </Button>
                                : 
                                    <Button size='mini' 
                                    onClick={()=>this.setState({banModal: true, selectedUsername: v.username})} 
                                    secondary>
                                        <Icon name='ban' color='blue'/> Ban
                                    </Button>
                                }
                            </Table.Cell>
                        </Table.Row>
                        )}
                        </Table.Body>
                    </Table>
                    
                    
                    {/* pagination */}
                    <Divider hidden/>
                    <div>
                        Show <Dropdown defaultValue={10} compact selection options={[
                            {key: 10, text: '10', value: 10},
                            {key: 25, text: '25', value: 25},
                            {key: 50, text: '50', value: 50},
                            {key: 100, text: '100', value: 100},
                        ]} onChange={this.handleChangeEntries} /> entries
                        <div className='pagination'>
                            <span style={{marginRight: 10}}>
                                Showing {this.state.userList.length} of {this.state.totalRecord} entries
                            </span>
                            <Pagination activePage={this.state.filter.page} size='small' onPageChange={this.handleChangePagination} 
                            totalPages={this.state.totalPage} />
                        </div>
                    </div>
                    </>
                }

                <Modal open={this.state.banModal} size='tiny'>
                    <Modal.Header>Ban User</Modal.Header>
                    <Modal.Content>
                        <p>Why do you ban this user?</p>
                        <Form>
                            <Form.TextArea onChange={(e, data)=>this.setState({reason: data.value})} 
                            placeholder='Tell your reason here...'/>
                        </Form>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button className='tertiary'
                        onClick={()=>this.setState({banModal: false})}>Cancel</Button>
                        <Button disabled={this.state.reason === '' || this.state.handleActionStatus ? true : false} 
                        negative loading={this.state.handleActionStatus} onClick={this.handleActions.bind(null, '')} id='ban'>Ban User</Button>
                    </Modal.Actions>
                </Modal>
            </Container>
        )
    }
}

export default connect(mapStateToProps)(UsersPage)