export const loginAction = (n) =>{
    let state = {
        is_logged: true,
        user: n
    }
    return{
        type: 'LOGIN',
        payload: state
    }
}

export const logoutAction = () =>{
    let state = {
        is_logged: false,
        user: null
    }
    return{
        type: 'LOGOUT',
        payload: state
    }
}