import React from 'react'
import PropTypes from 'prop-types'
import { createField, fieldPresets } from 'react-advanced-form'
import { Label } from 'semantic-ui-react'

class Textarea extends React.Component {
  static propTypes = {
    /* General */
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    rows: PropTypes.number | undefined,
    hint: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),

    /* Inherites */
    fieldProps: PropTypes.object.isRequired,
    fieldState: PropTypes.object.isRequired,
  }

  render() {
    const { fieldProps, fieldState, id, name, label, rows, hint, wlength, hidden, input_error, values, useWordAsCount } = this.props
    const {
      touched,
      pristine,
      required,
      validating,
      validatedSync,
      validatedAsync,
      valid,
      validSync,
      validAsync,
      invalid,
      errors,
    } = fieldState

    const inputClassNames = [
      'form-control',
      touched && 'is-touched',
      pristine && 'is-pristine',
      validating && 'is-validating',
      validatedSync && 'validated-sync',
      validatedAsync && 'validated-async',
      valid && 'is-valid',
      validSync && 'valid-sync',
      validAsync && 'valid-async',
      invalid && 'error',
    ]
      .filter(Boolean)
      .join(' ')

    if (hidden !== undefined && hidden) {
      fieldState.controlled = false
      fieldState.value = values
      if (values !== '') {
        fieldState.errors = false
        fieldState.valid = true
      } else {
        fieldState.valid = false
      }

      if (input_error !== undefined && input_error) {
        fieldState.invalid = true
      } else {
        fieldState.invalid = false
      }
      return (
        <div className="field">
          <textarea style={{ display: 'none' }} {...fieldProps} rows='3'
            id={id || name}
            className={inputClassNames}
            autoComplete="off" />

          {errors &&
            errors.map((error, index) => (
              <Label color='red' key={index} pointing>{error}</Label>
            ))}
        </div>
      )
    }

    if (fieldState.value === undefined) {
      fieldState.value = ''
    }


    let counter = Boolean(useWordAsCount) ? wlength - fieldState.value.split(' ').filter(Boolean).length : wlength - fieldState.value.length
    if (counter < 0 || (fieldState.value === '' && fieldState.validatedSync)) {
      fieldState.invalid = true
    } else {
      fieldState.invalid = false
    }



    return (
      <div className="field">
        {label && (
          <label htmlFor={id || name}>
            {label}
            {required && <b style={{ color: '#db2828' }}> *</b>}
          </label>
        )}

        <textarea {...fieldProps}
          rows={rows ? `${rows}` : '3'}
          id={id || name}
          className={inputClassNames}
          autoComplete="off" />

        {wlength !== undefined ?
          <span style={counter < 0 ? { color: '#db2828', textAlign: 'right', float: 'right' } :
            { textAlign: 'right', float: 'right' }}>{counter} {useWordAsCount ? 'words' : ''}</span> : null}

        {hint && <small className="form-text text-muted">{hint}</small>}

        {errors &&
          errors.map((error, index) => (
            <Label color='red' key={index} pointing>{error}</Label>
          ))}
      </div>
    )
  }
}

export default createField(fieldPresets.textarea)(Textarea)