import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import * as serviceWorker from "./serviceWorker";
import "semantic-ui-css/semantic.min.css";
import "./assets/styles/style.scss";
import "react-toastify/dist/ReactToastify.css";
import "cropperjs/dist/cropper.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import * as Sentry from "@sentry/browser";
import AppAdmin from "./app_admin";
import { BrowserRouter } from "react-router-dom";

Sentry.init({
  dsn: "https://7aa417a7243fff6d46e8099f35ba73db@o280953.ingest.sentry.io/4505843334774784",
  environment: process.env.REACT_APP_ENV_NAME
    ? process.env.REACT_APP_ENV_NAME
    : "LOCAL",
  release: "contentfe@23.9.8",
});

let location = document.location.origin;
ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        {location === process.env.REACT_APP_ADMIN_SITE ? <AppAdmin /> : <App />}
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
