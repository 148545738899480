import React from 'react'
import PropTypes from 'prop-types'
import { createField, fieldPresets } from 'react-advanced-form'
import { Label } from 'semantic-ui-react'

class Input extends React.Component {
  static propTypes = {
    /* General */
    id: PropTypes.string,
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    hint: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),

    /* Inherites */
    fieldProps: PropTypes.object.isRequired,
    fieldState: PropTypes.object.isRequired,
  }

  render() {
    const { fieldProps, fieldState, id, name, label, hint, hidden, type, wlength, input_error } = this.props
    const {
      touched,
      pristine,
      required,
      validating,
      validatedSync,
      validatedAsync,
      valid,
      validSync,
      validAsync,
      invalid,
      errors,
    } = fieldState

    const inputClassNames = [
      'form-control',
      touched && 'is-touched',
      pristine && 'is-pristine',
      validating && 'is-validating',
      validatedSync && 'validated-sync',
      validatedAsync && 'validated-async',
      valid && 'is-valid',
      validSync && 'valid-sync',
      validAsync && 'valid-async',
      invalid && 'error',
    ]
      .filter(Boolean)
      .join(' ')
    
      if(input_error !== undefined && input_error){
        fieldState.invalid= true
    }
    if(hidden !== undefined && hidden){
      return(
        <div className="field">
          <input style={{display: 'none'}}
            {...fieldProps}
            id={id || name}
            type={type}
            className={inputClassNames}
            autoComplete="off"
          />

          {errors &&
            errors.map((error, index) => (
              <Label color='red' key={index} pointing>{error}</Label>
            ))}
        </div>
      )
    }
    if(fieldState.value === undefined){
      fieldState.value = ''
    }
    let counter = wlength - (fieldState.value === null ? 0 : fieldState.value.length)
    if(counter < 0){
      fieldState.invalid= true
    }
    return (
      <div className="field">
        {label && (
          <label htmlFor={id || name}>
            {label}
            {required && <b style={{color:'#db2828'}}> *</b>}
          </label>
        )}

        <input
          {...fieldProps}
          id={id || name}
          type={type}
          className={inputClassNames}
          autoComplete="off"
        />
        {wlength!==undefined ? 
        <span style={counter < 0 ? {color: '#db2828', textAlign: 'right', float: 'right'} : 
        {textAlign: 'right', float: 'right'}}>{counter}</span> : null}

        {hint && <small className="form-text text-muted">{hint}</small>}

        {errors &&
          errors.map((error, index) => (
            <Label color='red' key={index} pointing>{error}</Label>
          ))}
      </div>
    )
  }
}

export default createField(fieldPresets.input)(Input)