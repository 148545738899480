import React from 'react'
import { Card, Pagination, Divider, Dropdown, Loader } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import {connect} from 'react-redux'
import storage from 'redux-persist/lib/storage';
import { filterSubjectRedux } from '../../helpers/redux';

// to detect every store of redux/state changed
function mapStateToProps(state) {
    return { 
        auth: state.auth,
        add_subject_step: state.add_subject_step
    }
}

// to detect every store of redux/state changed
function mapDispatchToProps(dispatch) {
    return { 
        addSubjectStepAction: (n) => dispatch({type: 'CURRENT_SUBJECT', payload: n}),
    }
}

class EntrySection extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            activePage: 1,
            totalPage: 0,
            entries: [],
            loading: true,
            search: localStorage.getItem('main_search')
        }
    }

    componentDidMount(){
        this.loadData()
    }

    loadData = (activePage = 1, filter = 2) =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/search/entries/all?keyword='+this.state.search, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            }),
            method: 'post',
            body: JSON.stringify({
                page: activePage,
                n: 10,
                filter: filter
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                localStorage.removeItem('main_search')
                window.location.href = '/'
            }

            this.setState({loading: false, entries: res.data, totalPage: res.total_page !== undefined ? res.total_page : 1})
        })
    }

    handleChangePage = (data) =>{
        this.setState({activePage: data})
        this.loadData(data)
    }

    filterEntry = (e, d) =>{
        this.loadData(this.state.activePage, d.value)
    }

    loadSubject = (data, e) =>{
        fetch(process.env.REACT_APP_API_URL+'api/v1/subjects/'+data.subject_code, {
            headers: new Headers({
                'Authorization': 'Token '+this.props.auth.user.token
            })
        })
        .then((res)=>{
            return res.json()
        })
        .then((res)=>{
            if(res.status === undefined){
                storage.removeItem('persist:root')
                window.location.href='/'
            }
            
            this.props.addSubjectStepAction(filterSubjectRedux(this.props.add_subject_step, res.data))
            if(data.chapter_mini_game_id){
                window.location.href = '/subject/'+data.subject_code+'/'+data.chapter_mini_game_id+'/question?id='+data.question_id
            }else{
                window.location.href = '/subject/'+data.subject_code+'/'+data.topic_id+'/question?id='+data.question_id
            }
        })
    }

    render() {
        let filter = [
            {text: 'Managed/contributed', key:'1', value: 0},
            {text: 'Last updated', key:'2', value: 1},
            {text: 'Most popular', key:'3', value: 2}
        ]
        return (
            <div style={{paddingBottom: 50}}>
                <p style={{fontSize: 18}}>Search Result for “<b>{this.state.search}</b>”</p>
                <div className='searchHeader'>
                    <Link to='/search'>Back to All Search</Link>
                    <div>
                        <span style={{marginRight:5}}>sort by</span>
                        <Dropdown onChange={this.filterEntry} defaultValue={2} labeled selection options={filter}/>
                    </div>
                </div>
                <Divider/>
                {this.state.loading ? <Loader active/> : <>
                    <Card.Group itemsPerRow={2} className='entryCard'>
                        {this.state.entries.map((v,k)=>
                            <Card raised key={k} onClick={this.loadSubject.bind(null, v)} link>
                                <Card.Content header={
                                    v.subject_name + ' / ' + 
                                    (
                                        v.chapter_mini_game_id ?
                                        v.chapter_name+' / '+v.mini_game_name :
                                        v.topic_name_tree
                                    )
                                    } />
                                <div className="content">
                                    <div className="description" dangerouslySetInnerHTML={{__html:v.highlight}}/>
                                </div>
                            </Card>
                        )}
                    </Card.Group>
                    <Divider hidden/>
                    <div style={{display:'flex', justifyContent: 'center'}}>
                        <Pagination onPageChange={(event, data) => this.handleChangePage(data.activePage)} 
                            defaultActivePage={this.state.activePage} totalPages={this.state.totalPage} />
                    </div>
                </>}
            </div>
        )
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EntrySection)