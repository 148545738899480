import React from 'react'
import { Container, Image, Divider, Icon } from 'semantic-ui-react'
import { Link } from 'react-router-dom'
import './style.scss'
import { useDispatch } from 'react-redux';
import { navAction } from '../../store/actions/nav'

const EmptySubjectComponent = () =>{
    const dispatch = useDispatch()
    return(
        <Container text className='empty_section'>
            <Image centered src={require('../../assets/images/empty.png')}/>
            <p>Ooppss... You don’t have any subject. Please contribute to the other subject or create a new one.</p>
            <Divider hidden/>
            <Link onClick={()=>dispatch(navAction('browse'))} to='/browse' className='ui primary button'>
                <Icon name='search'/> Browse Subjects
            </Link>
            <Link 
            to='/subject/add' className='ui primary button' style={{marginLeft:10}}>
                <Icon name='add'/> Create New Subject
            </Link>
        </Container>
    )
}

export default EmptySubjectComponent