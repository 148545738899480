import React from 'react'
import { Redirect, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Grid, List, Image, Segment } from 'semantic-ui-react'
import {getSubjectRedux} from '../../../helpers/redux';

const AboutPage = (props) =>{
    const subjectDetail = getSubjectRedux(useSelector(x=>x.add_subject_step), props.match.params.code)
    const auth = useSelector(x=>x.auth)
    if(subjectDetail.category_id === null && subjectDetail.role === 3 && !subjectDetail.adaptive){
        return <Redirect to={'/subject/add?code='+props.match.params.code}/>
    }
    let category = []
    let subCat = ''

    if(subjectDetail.category_name !== undefined && subjectDetail.category_name !== null){
        category = subjectDetail.category_name.split('/')
        subCat = category.length > 1 ? category[1].trim() : '-'
        category = category[0].trim()
    }
    return(
        <Segment>
            <Grid columns='equal'>
                <Grid.Column>
                    <table className='tableAbout'>
                        <tbody>
                            <tr>
                                <td>Subject Name</td>
                                <td></td>
                                <td>{subjectDetail.name}</td>
                            </tr>
                            <tr>
                                <td>Category</td>
                                <td></td>
                                <td>{category}</td>
                            </tr>
                            <tr>
                                <td>Sub Category</td>
                                <td></td>
                                <td>{subCat}</td>
                            </tr>
                            <tr>
                                <td>Description</td>
                                <td></td>
                                <td>{subjectDetail.description}</td>
                            </tr>
                            <tr>
                                <td>Date Created</td>
                                <td></td>
                                <td>{subjectDetail.created}</td>
                            </tr>
                        </tbody>
                    </table>
                </Grid.Column>
                <Grid.Column>
                <table className='tableAbout'>
                        <tbody>
                            <tr>
                                <td>Subject Creator</td>
                                <td></td>
                                <td>
                                    <List horizontal>
                                    <List.Item>
                                        <Image avatar src={subjectDetail.owner_avatar !== null ? subjectDetail.owner_avatar :
                                            require('../../../assets/images/avatar-default.png')
                                        } />
                                        <List.Content>
                                            <Link to={
                                                auth.user.username === subjectDetail.owner_username ? 
                                                '/profile/me'
                                                : '/profile/'+subjectDetail.owner_username
                                            } role="listitem" className="item">
                                                {subjectDetail.owner_username}
                                            </Link>
                                            <List.Description style={{fontSize:12}}>
                                                {subjectDetail.owner_email}
                                            </List.Description>
                                        </List.Content>
                                    </List.Item>
                                    </List>
                                </td>
                            </tr>
                            <tr>
                                <td>Verified</td>
                                <td></td>
                                <td>{subjectDetail.verified ? 'Yes' : 'No'}</td>
                            </tr>
                            <tr>
                                <td>Private Subject</td>
                                <td></td>
                                <td>{subjectDetail.private ? 'Yes' : 'No'}</td>
                            </tr>
                        </tbody>
                    </table>
                </Grid.Column>
            </Grid>
        </Segment>
    )
}
export default AboutPage